import {useDispatch } from "react-redux";
import { useNavigate } from 'react-router-dom';
import * as Actions from "../state/login/actions";
import {Logout} from '../../services/Common';


export default function useHandleLogOut(cookie,remCookie) {
    const dispatch = useDispatch()
    let navigate = useNavigate()
    const handleLogOut=()=>{
        dispatch(Actions.loginReset());
        Logout(cookie, remCookie);
        navigate("/", { replace: true });
    }
    return handleLogOut;
}
