/*screen name + methodname+ actiontype*/
/*Acuity Screen */
export const MENU_GET_PAGE = 'MENU_GET_PAGE';
export const MENU_GET_PAGE_SUCCESS = 'MENU_GET_PAGE_SUCCESS';
export const MENU_GET_PAGE_ERROR = 'MENU_GET_PAGE_ERROR';
export const MENU_GET_PAGE_CLEAR = 'MENU_GET_PAGE_CLEAR';

export const REFERAL_ADD = 'REFERAL_ADD';
export const REFERAL_SUCCESS = 'REFERAL_SUCCESS';
export const REFERAL_ERROR = 'REFERAL_ERROR';
export const REFERAL_CLEAR = 'REFERAL_CLEAR';

export const PROFILE_PWD_UPDATE = 'PROFILE_PWD_UPDATE';
export const PROFILE_PWD_SUCCESS = 'PROFILE_PWD_SUCCESS';
export const PROFILE_PWD_ERROR = 'PROFILE_PWD_ERROR';
export const PROFILE_PWD_CLEAR = 'PROFILE_PWD_CLEAR';

export const DMA_PROFILE_DONOR_GET = 'DMA_PROFILE_DONOR_GET';
export const DMA_PROFILE_DONOR_SUCCESS = 'DMA_PROFILE_DONOR_SUCCESS';
export const DMA_PROFILE_DONOR_ERROR = 'DMA_PROFILE_DONOR_ERROR';
export const DMA_PROFILE_DONOR_CLEAR = 'DMA_PROFILE_DONOR_CLEAR';
