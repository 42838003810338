import { ImFileExcel, ImFilePdf, ImFileText, ImFileWord, ImFileZip, ImImage } from "react-icons/im";

const getMimeType = (attachment) => {
    if (attachment !== null) {
        let filename = attachment.FileName.split(".").pop().toLowerCase();
        switch (filename) {
            case "jpg":
            case "jpeg":
                return "image/jpeg";
            case "png":
                return "image/png";
            case "doc":
            case "docx":
                return "application/msword";
            case "pdf":
                return "application/pdf";
            case "xlsx":
            case "xls":
                return "application/vnd.ms-excel";
            case "zip":
                return "application/zip";
            case "txt":
                return "text/plain";
            default:
                return "text/plain";
        }
    }
    return "";
}

const getFileTypeIcon = (ext) => {
    let Icon;

    switch (ext) {
        case "jpg":
        case "png":
            Icon = ImImage;
            break;
        case "doc":
        case "docx":
            Icon = ImFileWord;
            break;
        case "pdf":
            Icon = ImFilePdf;
            break;
        case "xlsx":
            Icon = ImFileExcel;
            break;
        case "zip":
            Icon = ImFileZip;
            break;
        default:
            Icon = ImFileText;
            break;
    }

    return Icon;
}

export { getFileTypeIcon, getMimeType };
