import * as React from "react";


const Buttons = (props) => {
    const {
        type = "button",
        onClick,
        disabled = false,
        lable,
        hide,
        buttonStyle,
        style,
        lableStyle,
        bgColor,
        transparent,
        ...rest
    } = props;

    let customisedProps = {
        disabled,
        lableStyle,
        style: { ...style },
    };

    if(transparent){
        customisedProps.color= 'red'

    }
    return (
        <button 
            disabled={disabled}
            style={{backgroundColor : disabled && '#d6d8dd' }}
            className ={buttonStyle}  
            // className ={`buttonStyle ${disabled ? bgColor: ''}`}  
            type={type} 
            onClick={onClick} 
            {...rest}
        >
            <div className={lableStyle}>{lable} </div>
        </button>
    );
};

export default Buttons;
