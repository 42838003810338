/*screen name + methodname+ actiontype*/
/*Acuity Screen */
export const MENU_GET_PAGE = 'MENU_GET_PAGE';
export const MENU_GET_PAGE_SUCCESS = 'MENU_GET_PAGE_SUCCESS';
export const MENU_GET_PAGE_ERROR = 'MENU_GET_PAGE_ERROR';
export const MENU_GET_PAGE_CLEAR = 'MENU_GET_PAGE_CLEAR';

export const REFERAL_ADD = 'REFERAL_ADD';
export const REFERAL_SUCCESS = 'REFERAL_SUCCESS';
export const REFERAL_ERROR = 'REFERAL_ERROR';
export const REFERAL_CLEAR = 'REFERAL_CLEAR';

export const CLINIC_REQUEST = 'CLINIC_REQUEST';
export const CLINIC_REQUEST_SUCCESS = 'CLINIC_REQUEST_SUCCESS';
export const CLINIC_REQUEST_ERROR = 'CLINIC_REQUEST_ERROR';
export const CLINIC_REQUEST_CLEAR = 'CLINIC_REQUEST_CLEAR';