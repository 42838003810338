import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import { BsXLg } from "react-icons/bs";
import styles from "./PageTitle.module.scss";

const PageTitle = ({ title, headerClass, onClose, linkTo, linkState, lightBg = false, shadow = true }) => {
    const headerStyle = lightBg ? 'bg-white' : 'bg-primary';
    const textStyle = lightBg ? 'text-primary' : 'text-white';
    const buttonStyle = `${styles.closeIcon} ${textStyle} position-absolute d-flex p-1 border-0 lh-1`;

    return (
        <div className={`${styles.pageTitle} ${headerClass || ''} ${headerStyle} ${!shadow && 'shadow-none'} position-relative`}>
            {
                title &&
                    <h1 className={`${styles.pageHeading} ${textStyle} fw-normal text-center`}>
                        { title }
                    </h1>
            }
            {
                onClose &&
                    <Button variant="link" className={buttonStyle} onClick={onClose}>
                        <BsXLg />
                    </Button>
            }
            {
                linkTo &&
                    <Link to={linkTo} state={linkState} className={buttonStyle}>
                        <BsXLg />
                    </Link>
            }
        </div>
    );
}

export default PageTitle;
