import { all } from "redux-saga/effects";
import { watchHomeSaga } from '../pages/state/home/saga';
import { watchUserSaga } from '../pages/state/login/saga';
import { watchNotificationSaga } from '../pages/state/message/saga';
import { watchUploadSaga } from '../pages/state/upload/saga';
import { watchCalendarSaga } from '../pages/state/calendar/saga';
import { watchCalculatorSaga } from '../pages/state/calculator/saga';
import { watchSchedularSaga } from '../pages/state/schedular/saga';
import { watchMenuPageSaga } from '../pages/state/menupage/saga';
import { watchProfilePage } from '../pages/state/profile/saga';
import { watchAdditionalInfoSaga } from '../pages/state/additionalinfo/saga';
import { watchVideoPage } from '../pages/state/videos/saga';
import { watchTestKitSaga } from '../pages/state/testkit/saga';
import { watchAppointmentSaga } from '../pages/state/appointment/saga';
import { watchNotifySaga } from '../pages/state/notify/saga';
import { watchDocuSignSaga } from '../pages/state/docusign/saga';
import { watchBgSaga } from "../pages/state/background/saga";
import { watchTravelDonorSaga } from '../pages/state/travel/saga';
import { watchUserLogOutSaga } from '../pages/state/logout/saga';

export default function* rootSaga() {
	yield all([
        watchBgSaga(),
		watchUserSaga(),
		watchHomeSaga(),
		watchNotificationSaga(),
		watchUploadSaga(),
		watchCalendarSaga(),
		watchCalculatorSaga(),
        watchSchedularSaga(),
		watchMenuPageSaga(),
		watchProfilePage(),
        watchAdditionalInfoSaga(),
        watchVideoPage(),
        watchTestKitSaga(),
        watchAppointmentSaga(),
        watchNotifySaga(),
        watchDocuSignSaga(),
        watchTravelDonorSaga(),
        watchUserLogOutSaga()
	]
)}
