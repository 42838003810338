import { call, put, takeLatest } from "redux-saga/effects";
import { Endpoints } from '../../../core/networking';
import * as Constants from "./constants";
import * as Actions from "./actions";
import AxiosInstance from "../../../services/AxiosInstance";
import { ERROR_TYPES } from '../types';

export function* watchAdditionalInfoSaga() {
  yield takeLatest(Constants.INFO_MESSAGE_GET_LIST_REQUEST, msgListRequest);
  yield takeLatest(Constants.INFO_ADD_MESSAGE_REQUEST, addMessageRequest);
  yield takeLatest(Constants.INFO_TASK_SUBMIT_REQUEST, taskSubmitRequest);
}

export function* msgListRequest(action) {
  try {
    const pramas = action.payload;
    const response = yield call(taskNetworkRequest, pramas);
    if (response.status === 200) {
      yield put(Actions.infoGetMsgListSuccess(response.data));
    } else {
      yield put(
        Actions.infoGetMsgListError({
          errorCode: ERROR_TYPES.INVALID_DATA,
          message: ERROR_TYPES.INVALID_DATA,
        })
      );
    }
  } catch (error) {
    yield put(Actions.infoGetMsgListError(error));
  }
}

export function taskNetworkRequest(params){
  const url = Endpoints.FAIR_FAX_PORTAL_API;
  const response =  AxiosInstance.post(url, { params });
  return response;
}

export function* addMessageRequest(action) {
  try {
    const response = yield call(addMsgNetRequest, action.payload);
    if (response.status === 200) {
      yield put(Actions.addInfoMsgSuccess(response.data));
    } else {
      yield put(
        Actions.addInfoMsgError({
          errorCode: ERROR_TYPES.INVALID_DATA,
          message: ERROR_TYPES.INVALID_DATA,
        })
      );
    }
  } catch (error) {
    yield put(Actions.addInfoMsgError(error));
  }
}

export function addMsgNetRequest(params){
  const url = Endpoints.UPLOAD_PORTAL_MSG;
  const response =  AxiosInstance.post(url, params );
  return response;
}

export function* taskSubmitRequest(action) {
    try {
        const params = action.payload;
        const response = yield call(taskSubmitNetworkRequest, params);
        if (response.status === 200) {
            yield put(Actions.infoGetMsgListSuccess(response.data));
        } else {
            yield put(
                Actions.infoGetMsgListError({
                    errorCode: ERROR_TYPES.INVALID_DATA,
                    message: ERROR_TYPES.INVALID_DATA,
                })
            );
        }
    } catch (error) {
        yield put(Actions.infoGetMsgListError(error));
    }
}

export function taskSubmitNetworkRequest(params) {
    return AxiosInstance.post(Endpoints.FAIR_FAX_PORTAL_API, {params});
}
