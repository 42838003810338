export const stateSource = [
    {
        code: 125,
        stateName: 'ALASKA',
        regionCode: 'AK'
    },
    {
        code: 124,
        stateName: 'ALABAMA',
        regionCode: 'AL'
    },
    {
        code: 128,
        stateName: 'ARKANSAS',
        regionCode: 'AR'
    },
    {
        code: 126,
        stateName: 'AMERICAN SAMOA',
        regionCode: 'AS'
    },
    {
        code: 127,
        stateName: 'ARIZONA',
        regionCode: 'AZ'
    },
    {
        code: 129,
        stateName: 'CALIFORNIA',
        regionCode: 'CA'
    },
    {
        code: 130,
        stateName: 'COLORADO',
        regionCode: 'CO'
    },
    {
        code: 131,
        stateName: 'CONNECTICUT',
        regionCode: 'CT'
    },
    {
        code: 133,
        stateName: 'DISTRICT OF COLUMBIA',
        regionCode: 'DC'
    },
    {
        code: 132,
        stateName: 'DELAWARE',
        regionCode: 'DE'
    },
    {
        code: 134,
        stateName: 'FLORIDA',
        regionCode: 'FL'
    },
    {
        code: 135,
        stateName: 'GEORGIA',
        regionCode: 'GA'
    },
    {
        code: 136,
        stateName: 'GUAM',
        regionCode: 'GU'
    },
    {
        code: 137,
        stateName: 'HAWAII',
        regionCode: 'HI'
    },
    {
        code: 141,
        stateName: 'IOWA',
        regionCode: 'IA'
    },
    {
        code: 138,
        stateName: 'IDAHO',
        regionCode: 'ID'
    },
    {
        code: 139,
        stateName: 'ILLINOIS',
        regionCode: 'IL'
    },
    {
        code: 140,
        stateName: 'INDIANA',
        regionCode: 'IN'
    },
    {
        code: 142,
        stateName: 'KANSAS',
        regionCode: 'KS'
    },
    {
        code: 143,
        stateName: 'KENTUCKY',
        regionCode: 'KY'
    },
    {
        code: 144,
        stateName: 'LOUISIANA',
        regionCode: 'LA'
    },
    {
        code: 148,
        stateName: 'MASSACHUSETTS',
        regionCode: 'MA'
    },
    {
        code: 147,
        stateName: 'MARYLAND',
        regionCode: 'MD'
    },
    {
        code: 145,
        stateName: 'MAINE',
        regionCode: 'ME'
    },
    {
        code: 146,
        stateName: 'MARSHALL ISLANDS',
        regionCode: 'MH'
    },
    {
        code: 149,
        stateName: 'MICHIGAN',
        regionCode: 'MI'
    },
    {
        code: 150,
        stateName: 'MINNESOTA',
        regionCode: 'MN'
    },
    {
        code: 152,
        stateName: 'MISSOURI',
        regionCode: 'MO'
    },
    {
        code: 151,
        stateName: 'MISSISSIPPI',
        regionCode: 'MS'
    },
    {
        code: 153,
        stateName: 'MONTANA',
        regionCode: 'MT'
    },
    {
        code: 160,
        stateName: 'NORTH CAROLINA',
        regionCode: 'NC'
    },
    {
        code: 161,
        stateName: 'NORTH DAKOTA',
        regionCode: 'ND'
    },
    {
        code: 154,
        stateName: 'NEBRASKA',
        regionCode: 'NE'
    },
    {
        code: 156,
        stateName: 'NEW HAMPSHIRE',
        regionCode: 'NH'
    },
    {
        code: 157,
        stateName: 'NEW JERSEY',
        regionCode: 'NJ'
    },
    {
        code: 158,
        stateName: 'NEW MEXICO',
        regionCode: 'NM'
    },
    {
        code: 155,
        stateName: 'NEVADA',
        regionCode: 'NV'
    },
    {
        code: 159,
        stateName: 'NEW YORK',
        regionCode: 'NY'
    },
    {
        code: 162,
        stateName: 'OHIO',
        regionCode: 'OH'
    },
    {
        code: 163,
        stateName: 'OKLAHOMA',
        regionCode: 'OK'
    },
    {
        code: 164,
        stateName: 'OREGON',
        regionCode: 'OR'
    },
    {
        code: 166,
        stateName: 'PENNSYLVANIA',
        regionCode: 'PA'
    },
    {
        code: 167,
        stateName: 'PUERTO RICO',
        regionCode: 'PR'
    },
    {
        code: 165,
        stateName: 'PALAU',
        regionCode: 'PW'
    },
    {
        code: 168,
        stateName: 'RHODE ISLAND',
        regionCode: 'RI'
    },
    {
        code: 169,
        stateName: 'SOUTH CAROLINA',
        regionCode: 'SC'
    },
    {
        code: 170,
        stateName: 'SOUTH DAKOTA',
        regionCode: 'SD'
    },
    {
        code: 171,
        stateName: 'TENNESSEE',
        regionCode: 'TN'
    },
    {
        code: 172,
        stateName: 'TEXAS',
        regionCode: 'TX'
    },
    {
        code: 173,
        stateName: 'UTAH',
        regionCode: 'UT'
    },
    {
        code: 176,
        stateName: 'VIRGINIA',
        regionCode: 'VA'
    },
    {
        code: 175,
        stateName: 'VIRGIN ISLANDS',
        regionCode: 'VI'
    },
    {
        code: 174,
        stateName: 'VERMONT',
        regionCode: 'VT'
    },
    {
        code: 177,
        stateName: 'WASHINGTON',
        regionCode: 'WA'
    },
    {
        code: 179,
        stateName: 'WISCONSIN',
        regionCode: 'WI'
    },
    {
        code: 178,
        stateName: 'WEST VIRGINIA',
        regionCode: 'WV'
    },
    {
        code: 180,
        stateName: 'WYOMING',
        regionCode: 'WY'
    },
    {
        code: 14,
        stateName: 'AGRIGENTO',
        regionCode: 'AG'
    },
    {
        code: 15,
        stateName: 'ALESSANDRIA',
        regionCode: 'AL'
    },
    {
        code: 16,
        stateName: 'ANCONA',
        regionCode: 'AN'
    },
    {
        code: 17,
        stateName: 'AOSTA',
        regionCode: 'AO'
    },
    {
        code: 19,
        stateName: 'ASCOLI PICENO',
        regionCode: 'AP'
    },
    {
        code: 58,
        stateName: 'L\'AQUILA',
        regionCode: 'AQ'
    },
    {
        code: 18,
        stateName: 'AREZZO',
        regionCode: 'AR'
    },
    {
        code: 20,
        stateName: 'ASTI',
        regionCode: 'AT'
    },
    {
        code: 21,
        stateName: 'AVELLINO',
        regionCode: 'AV'
    },
    {
        code: 22,
        stateName: 'BARI',
        regionCode: 'BA'
    },
    {
        code: 26,
        stateName: 'BERGAMO',
        regionCode: 'BG'
    },
    {
        code: 27,
        stateName: 'BIELLA',
        regionCode: 'BI'
    },
    {
        code: 24,
        stateName: 'BELLUNO',
        regionCode: 'BL'
    },
    {
        code: 25,
        stateName: 'BENEVENTO',
        regionCode: 'BN'
    },
    {
        code: 28,
        stateName: 'BOLOGNA',
        regionCode: 'BO'
    },
    {
        code: 31,
        stateName: 'BRINDISI',
        regionCode: 'BR'
    },
    {
        code: 30,
        stateName: 'BRESCIA',
        regionCode: 'BS'
    },
    {
        code: 23,
        stateName: 'BARLETTA-ANDRIA-TRANI',
        regionCode: 'BT'
    },
    {
        code: 29,
        stateName: 'BOLZANO',
        regionCode: 'BZ'
    },
    {
        code: 32,
        stateName: 'CAGLIARI',
        regionCode: 'CA'
    },
    {
        code: 34,
        stateName: 'CAMPOBASSO',
        regionCode: 'CB'
    },
    {
        code: 36,
        stateName: 'CASERTA',
        regionCode: 'CE'
    },
    {
        code: 39,
        stateName: 'CHIETI',
        regionCode: 'CH'
    },
    {
        code: 35,
        stateName: 'CARBONIA-IGLESIAS',
        regionCode: 'CI'
    },
    {
        code: 33,
        stateName: 'CALTANISSETTA',
        regionCode: 'CL'
    },
    {
        code: 44,
        stateName: 'CUNEO',
        regionCode: 'CN'
    },
    {
        code: 42,
        stateName: 'CREMONA',
        regionCode: 'CR'
    },
    {
        code: 41,
        stateName: 'COSENZA',
        regionCode: 'CS'
    },
    {
        code: 37,
        stateName: 'CATANIA',
        regionCode: 'CT'
    },
    {
        code: 38,
        stateName: 'CATANZARO',
        regionCode: 'CZ'
    },
    {
        code: 45,
        stateName: 'ENNA',
        regionCode: 'EN'
    },
    {
        code: 50,
        stateName: 'FORLI-CESENA',
        regionCode: 'FC'
    },
    {
        code: 47,
        stateName: 'FERRARA',
        regionCode: 'FE'
    },
    {
        code: 49,
        stateName: 'FOGGIA',
        regionCode: 'FG'
    },
    {
        code: 48,
        stateName: 'FLORENCE',
        regionCode: 'FI'
    },
    {
        code: 46,
        stateName: 'FERMO',
        regionCode: 'FM'
    },
    {
        code: 51,
        stateName: 'FROSINONE',
        regionCode: 'FR'
    },
    {
        code: 52,
        stateName: 'GENOA',
        regionCode: 'GE'
    },
    {
        code: 53,
        stateName: 'GORIZIA',
        regionCode: 'GO'
    },
    {
        code: 54,
        stateName: 'GROSSETO',
        regionCode: 'GR'
    },
    {
        code: 55,
        stateName: 'IMPERIA',
        regionCode: 'IM'
    },
    {
        code: 56,
        stateName: 'ISERNIA',
        regionCode: 'IS'
    },
    {
        code: 43,
        stateName: 'CROTONE',
        regionCode: 'KR'
    },
    {
        code: 61,
        stateName: 'LECCO',
        regionCode: 'LC'
    },
    {
        code: 60,
        stateName: 'LECCE',
        regionCode: 'LE'
    },
    {
        code: 62,
        stateName: 'LIVORNO',
        regionCode: 'LI'
    },
    {
        code: 63,
        stateName: 'LODI',
        regionCode: 'LO'
    },
    {
        code: 59,
        stateName: 'LATINA',
        regionCode: 'LT'
    },
    {
        code: 64,
        stateName: 'LUCCA',
        regionCode: 'LU'
    },
    {
        code: 73,
        stateName: 'MONZA AND BRIANZA',
        regionCode: 'MB'
    },
    {
        code: 65,
        stateName: 'MACERATA',
        regionCode: 'MC'
    },
    {
        code: 70,
        stateName: 'MESSINA',
        regionCode: 'ME'
    },
    {
        code: 71,
        stateName: 'MILAN',
        regionCode: 'MI'
    },
    {
        code: 66,
        stateName: 'MANTUA',
        regionCode: 'MN'
    },
    {
        code: 72,
        stateName: 'MODENA',
        regionCode: 'MO'
    },
    {
        code: 67,
        stateName: 'MASSA AND CARRARA',
        regionCode: 'MS'
    },
    {
        code: 68,
        stateName: 'MATERA',
        regionCode: 'MT'
    },
    {
        code: 74,
        stateName: 'NAPLES',
        regionCode: 'NA'
    },
    {
        code: 75,
        stateName: 'NOVARA',
        regionCode: 'NO'
    },
    {
        code: 76,
        stateName: 'NUORO',
        regionCode: 'NU'
    },
    {
        code: 77,
        stateName: 'OGLIASTRA',
        regionCode: 'OR'
    },
    {
        code: 79,
        stateName: 'ORISTANO',
        regionCode: 'OR'
    },
    {
        code: 78,
        stateName: 'OLBIA-TEMPIO',
        regionCode: 'OT'
    },
    {
        code: 81,
        stateName: 'PALERMO',
        regionCode: 'PA'
    },
    {
        code: 87,
        stateName: 'PIACENZA',
        regionCode: 'PC'
    },
    {
        code: 80,
        stateName: 'PADUA',
        regionCode: 'PD'
    },
    {
        code: 86,
        stateName: 'PESCARA',
        regionCode: 'PE'
    },
    {
        code: 84,
        stateName: 'PERUGIA',
        regionCode: 'PG'
    },
    {
        code: 88,
        stateName: 'PISA',
        regionCode: 'PI'
    },
    {
        code: 90,
        stateName: 'PORDENONE',
        regionCode: 'PN'
    },
    {
        code: 92,
        stateName: 'PRATO',
        regionCode: 'PO'
    },
    {
        code: 82,
        stateName: 'PARMA',
        regionCode: 'PR'
    },
    {
        code: 89,
        stateName: 'PISTOIA',
        regionCode: 'PT'
    },
    {
        code: 85,
        stateName: 'PESARO AND URBINO',
        regionCode: 'PU'
    },
    {
        code: 83,
        stateName: 'PAVIA',
        regionCode: 'PV'
    },
    {
        code: 91,
        stateName: 'POTENZA',
        regionCode: 'PZ'
    },
    {
        code: 94,
        stateName: 'RAVENNA',
        regionCode: 'RA'
    },
    {
        code: 95,
        stateName: 'REGGIO CALABRIA',
        regionCode: 'RC'
    },
    {
        code: 96,
        stateName: 'REGGIO EMILIA',
        regionCode: 'RE'
    },
    {
        code: 93,
        stateName: 'RAGUSA',
        regionCode: 'RG'
    },
    {
        code: 97,
        stateName: 'RIETI',
        regionCode: 'RI'
    },
    {
        code: 99,
        stateName: 'ROME',
        regionCode: 'RM'
    },
    {
        code: 98,
        stateName: 'RIMINI',
        regionCode: 'RN'
    },
    {
        code: 100,
        stateName: 'ROVIGO',
        regionCode: 'RO'
    },
    {
        code: 101,
        stateName: 'SALERNO',
        regionCode: 'SA'
    },
    {
        code: 104,
        stateName: 'SIENA',
        regionCode: 'SI'
    },
    {
        code: 105,
        stateName: 'SONDRIO',
        regionCode: 'SO'
    },
    {
        code: 40,
        stateName: 'COMO',
        regionCode: 'SO'
    },
    {
        code: 57,
        stateName: 'LA SPEZIA',
        regionCode: 'SP'
    },
    {
        code: 106,
        stateName: 'SYRACUSE',
        regionCode: 'SR'
    },
    {
        code: 102,
        stateName: 'SASSARI',
        regionCode: 'SS'
    },
    {
        code: 103,
        stateName: 'SAVONA',
        regionCode: 'SV'
    },
    {
        code: 107,
        stateName: 'TARANTO',
        regionCode: 'TA'
    },
    {
        code: 108,
        stateName: 'TERAMO',
        regionCode: 'TE'
    },
    {
        code: 111,
        stateName: 'TRENTO',
        regionCode: 'TN'
    },
    {
        code: 114,
        stateName: 'TURIN',
        regionCode: 'TO'
    },
    {
        code: 110,
        stateName: 'TRAPANI',
        regionCode: 'TP'
    },
    {
        code: 109,
        stateName: 'TERNI',
        regionCode: 'TR'
    },
    {
        code: 113,
        stateName: 'TRIESTE',
        regionCode: 'TS'
    },
    {
        code: 112,
        stateName: 'TREVISO',
        regionCode: 'TV'
    },
    {
        code: 115,
        stateName: 'UDINE',
        regionCode: 'UD'
    },
    {
        code: 116,
        stateName: 'VARESE',
        regionCode: 'VA'
    },
    {
        code: 118,
        stateName: 'VERBANO-CUSIO-OSSOLA',
        regionCode: 'VB'
    },
    {
        code: 119,
        stateName: 'VERCELLI',
        regionCode: 'VC'
    },
    {
        code: 117,
        stateName: 'VENICE',
        regionCode: 'VE'
    },
    {
        code: 122,
        stateName: 'VICENZA',
        regionCode: 'VI'
    },
    {
        code: 120,
        stateName: 'VERONA',
        regionCode: 'VR'
    },
    {
        code: 69,
        stateName: 'MEDIO CAMPIDANO',
        regionCode: 'VS'
    },
    {
        code: 123,
        stateName: 'VITERBO',
        regionCode: 'VT'
    },
    {
        code: 121,
        stateName: 'VIBO VALENTIA',
        regionCode: 'VV'
    },
    {
        code: 1,
        stateName: 'ALBERTA',
        regionCode: 'AB'
    },
    {
        code: 2,
        stateName: 'BRITISH COLUMBIA',
        regionCode: 'BC'
    },
    {
        code: 3,
        stateName: 'MANITOBA',
        regionCode: 'MB'
    },
    {
        code: 4,
        stateName: 'NEW BRUNSWICK',
        regionCode: 'NB'
    },
    {
        code: 5,
        stateName: 'NEWFOUNDLAND AND LABRADOR',
        regionCode: 'NL'
    },
    {
        code: 7,
        stateName: 'NOVA SCOTIA',
        regionCode: 'NS'
    },
    {
        code: 6,
        stateName: 'NORTHWEST TERRITORIES',
        regionCode: 'NT'
    },
    {
        code: 8,
        stateName: 'NUNAVUT',
        regionCode: 'NU'
    },
    {
        code: 9,
        stateName: 'ONTARIO',
        regionCode: 'ON'
    },
    {
        code: 10,
        stateName: 'PRINCE EDWARD ISLAND',
        regionCode: 'PE'
    },
    {
        code: 11,
        stateName: 'QUEBEC',
        regionCode: 'QC'
    },
    {
        code: 12,
        stateName: 'SASKATCHEWAN',
        regionCode: 'SK'
    },
    {
        code: 13,
        stateName: 'YUKON TERRITORY',
        regionCode: 'YT'
    },
];
