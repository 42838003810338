import styles from './Footer.module.scss';
import { ReactComponent as HomeIcon } from '../../assets/images/Menu-Icon-Home.svg';
import { ReactComponent as HomeCal } from '../../assets/images/Menu-Icon-Cal.svg';
import { ReactComponent as HomeChat } from '../../assets/images/Menu-Icon-Chat.svg';
import { ReactComponent as PeriodTracker } from '../../assets/images/period-tracker-icon.svg';
import { ReactComponent as PeriodActiveTracker } from '../../assets/images/period-tracker-icon-active.svg';
import { NavLink } from "react-router-dom";

function Footer() {
    return (
        <footer className={"footerElement " + styles.footer} > {/*'text-center text-muted ' + */}
            <ul className='d-flex align-items-center justify-content-between'>
                <li>
                    <NavLink to="/home">
                        <HomeIcon />
                    </NavLink>
                </li>
                <li>
                    <NavLink  to="/task-calendar">
                        <HomeCal/>
                    </NavLink>
                </li>
                <li>
                    <NavLink  to="/messages">
                        <HomeChat/>
                    </NavLink>
                </li>
                <li>
                    <NavLink  to="/period-tracker"
                     className={({ isActive }) => (isActive ? `active ${styles.trackerActive}` : '')}
                    >
                        <PeriodTracker className={styles.inActivePT}/>
                        <PeriodActiveTracker className={styles.activePT}/>
                    </NavLink>
                </li>
            </ul>
        </footer>
  )
}

export default Footer;
