/*screen name + methodname+ actiontype*/
export const CALENDAR_GET_DONOR_EVENT_REQUEST = 'CALENDAR_GET_DONOR_EVENT_REQUEST';
export const CALENDAR_GET_DONOR_EVENT_SUCCESS = 'CALENDAR_GET_DONOR_EVENT_SUCCESS';
export const CALENDAR_GET_DONOR_EVENT_ERROR = 'CALENDAR_GET_DONOR_EVENT_ERROR';
export const CALENDAR_GET_DONOR_EVENT_CLEAR = 'CALENDAR_GET_DONOR_EVENT_CLEAR';

export const CALENDAR_GET_EVENT_REQUEST = 'CALENDAR_GET_EVENT_REQUEST';
export const CALENDAR_GET_EVENT_SUCCESS = 'CALENDAR_GET_EVENT_SUCCESS';
export const CALENDAR_GET_EVENT_ERROR = 'CALENDAR_GET_EVENT_ERROR';
export const CALENDAR_GET_EVENT_CLEAR = 'CALENDAR_GET_EVENT_CLEAR';

export const CALENDAR_ADD_DONOR_EVENT_REQUEST = 'CALENDAR_ADD_DONOR_EVENT_REQUEST';
export const CALENDAR_ADD_DONOR_EVENT_SUCCESS = 'CALENDAR_ADD_DONOR_EVENT_SUCCESS';
export const CALENDAR_ADD_DONOR_EVENT_ERROR = 'CALENDAR_ADD_DONOR_EVENT_ERROR';
export const CALENDAR_ADD_DONOR_EVENT_CLEAR = 'CALENDAR_ADD_DONOR_EVENT_CLEAR';

export const CALENDAR_DELETE_DONOR_EVENT_REQUEST = 'CALENDAR_DELETE_DONOR_EVENT_REQUEST';
export const CALENDAR_DELETE_DONOR_EVENT_SUCCESS = 'CALENDAR_DELETE_DONOR_EVENT_SUCCESS';
export const CALENDAR_DELETE_DONOR_EVENT_ERROR = 'CALENDAR_DELETE_DONOR_EVENT_ERROR';
export const CALENDAR_DELETE_DONOR_EVENT_CLEAR = 'CALENDAR_DELETE_DONOR_EVENT_CLEAR';

export const CALENDAR_GET_DOCTOR_LIST_REQUEST = 'CALENDAR_GET_DOCTOR_LIST_REQUEST';
export const CALENDAR_GET_DOCTOR_LIST_SUCCESS = 'CALENDAR_GET_DOCTOR_LIST_SUCCESS';
export const CALENDAR_GET_DOCTOR_LIST_ERROR = 'CALENDAR_GET_DOCTOR_LIST_ERROR';
export const CALENDAR_GET_DOCTOR_LIST_CLEAR = 'CALENDAR_GET_DOCTOR_LIST_CLEAR';

export const CALENDAR_TASK_SCHEDULER_DOCTOR_REQUEST = 'CALENDAR_TASK_SCHEDULER_DOCTOR_REQUEST';
export const CALENDAR_TASK_SCHEDULER_DOCTOR_SUCCESS = 'CALENDAR_TASK_SCHEDULER_DOCTOR_SUCCESS';
export const CALENDAR_TASK_SCHEDULER_DOCTOR_ERROR = 'CALENDAR_TASK_SCHEDULER_DOCTOR_ERROR';
export const CALENDAR_TASK_SCHEDULER_DOCTOR_CLEAR = 'CALENDAR_TASK_SCHEDULER_DOCTOR_CLEAR';

export const CALENDAR_SCHEDULE_PHOTOGRAPHER_REQUEST = 'CALENDAR_SCHEDULE_PHOTOGRAPHER_REQUEST';
export const CALENDAR_SCHEDULE_PHOTOGRAPHER_SUCCESS = 'CALENDAR_SCHEDULE_PHOTOGRAPHER_SUCCESS';
export const CALENDAR_SCHEDULE_PHOTOGRAPHER_ERROR = 'CALENDAR_SCHEDULE_PHOTOGRAPHER_ERROR';
export const CALENDAR_SCHEDULE_PHOTOGRAPHER_CLEAR = 'CALENDAR_SCHEDULE_PHOTOGRAPHER_CLEAR';

export const CALENDAR_PHOTOGRAPHER_LIST_REQUEST = 'CALENDAR_SCHEDULE_PHOTOGRAPHER_LIST_REQUEST';
export const CALENDAR_PHOTOGRAPHER_LIST_SUCCESS = 'CALENDAR_PHOTOGRAPHER_LIST_SUCCESS';
export const CALENDAR_PHOTOGRAPHER_LIST_ERROR = 'CALENDAR_PHOTOGRAPHER_LIST_ERROR';
export const CALENDAR_PHOTOGRAPHER_LIST_CLEAR = 'CALENDAR_PHOTOGRAPHER_LIST_CLEAR';

export const CALENDAR_SCHEDULE_PSYCHOLOGIST_REQUEST = 'CALENDAR_SCHEDULE_PSYCHOLOGIST_REQUEST';
export const CALENDAR_SCHEDULE_PSYCHOLOGIST_SUCCESS = 'CALENDAR_SCHEDULE_PSYCHOLOGIST_SUCCESS';
export const CALENDAR_SCHEDULE_PSYCHOLOGIST_ERROR = 'CALENDAR_SCHEDULE_PSYCHOLOGIST_ERROR';
export const CALENDAR_SCHEDULE_PSYCHOLOGIST_CLEAR = 'CALENDAR_SCHEDULE_PSYCHOLOGIST_CLEAR';

export const CALENDAR_PSYCHOLOGIST_LIST_REQUEST = 'CALENDAR_PSYCHOLOGIST_LIST_REQUEST';
export const CALENDAR_PSYCHOLOGIST_LIST_SUCCESS = 'CALENDAR_PSYCHOLOGIST_LIST_SUCCESS';
export const CALENDAR_PSYCHOLOGIST_LIST_ERROR = 'CALENDAR_PSYCHOLOGIST_LIST_ERROR';
export const CALENDAR_PSYCHOLOGIST_LIST_CLEAR = 'CALENDAR_PSYCHOLOGIST_LIST_CLEAR';



/*period tracker  */
export const PERIOD_TRACKER_ADD_DATE_REQUEST = 'PERIOD_TRACKER_ADD_DATE_REQUEST';
export const PERIOD_TRACKER_ADD_DATE_SUCCESS = 'PERIOD_TRACKER_ADD_DATE_SUCCESS';
export const PERIOD_TRACKER_ADD_DATE_ERROR = 'PERIOD_TRACKER_ADD_DATE_ERROR';
export const PERIOD_TRACKER_ADD_DATE_CLEAR = 'PERIOD_TRACKER_ADD_DATE_CLEAR';

export const PERIOD_TRACKER_GET_DONAR_DETAIL_REQUEST = 'PERIOD_TRACKER_GET_DONAR_DETAIL_REQUEST';
export const PERIOD_TRACKER_GET_DONAR_DETAIL_SUCCESS = 'PERIOD_TRACKER_GET_DONAR_DETAIL_SUCCESS';
export const PERIOD_TRACKER_GET_DONAR_DETAIL_ERROR = 'PERIOD_TRACKER_GET_DONAR_DETAIL_ERROR';
export const PERIOD_TRACKER_GET_DONAR_DETAIL_CLEAR = 'PERIOD_TRACKER_GET_DONAR_DETAIL_CLEAR';

export const PERIOD_TRACKER_UPDATE_DATE_REQUEST = 'PERIOD_TRACKER_UPDATE_DATE_REQUEST';
export const PERIOD_TRACKER_UPDATE_DATE_SUCCESS = 'PERIOD_TRACKER_UPDATE_DATE_SUCCESS';
export const PERIOD_TRACKER_UPDATE_DATE_ERROR = 'PERIOD_TRACKER_UPDATE_DATE_ERROR';
export const PERIOD_TRACKER_UPDATE_DATE_CLEAR = 'PERIOD_TRACKER_UPDATE_DATE_CLEAR';

export const PERIOD_TRACKER_DELETE_SDATE_REQUEST = 'PERIOD_TRACKER_DELETE_SDATE_REQUEST';
export const PERIOD_TRACKER_DELETE_SDATE_SUCCESS = 'PERIOD_TRACKER_DELETE_SDATE_SUCCESS';
export const PERIOD_TRACKER_DELETE_SDATE_ERROR = 'PERIOD_TRACKER_DELETE_SDATE_ERROR';
export const PERIOD_TRACKER_DELETE_SDATE_CLEAR = 'PERIOD_TRACKER_DELETE_SDATE_CLEAR';

export const PERIOD_STATUS_SET = 'PERIOD_STATUS_SET';
export const PERIOD_STATUS_CLEAR = 'PERIOD_STATUS_CLEAR';



