import React, { useEffect, useState } from "react";
import styles from "./Timezone.module.scss";
import { BsCaretDownFill } from "react-icons/bs";
import { createPortal } from "react-dom";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import advancedFormat from "dayjs/plugin/advancedFormat";
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);

export default function TimeZone (props) {

    const Timezones = {
        1: "Eastern Time",
        2: "Central Time",
        3: "Mountain Time",
        4: "Pacific Time",
    }

    const [selectedZone, setSelectedZone] = useState(false);
    const [showTimeZoneModal, setShowTimeZoneModal] = useState(false);

    const closeModal = () => {
        setShowTimeZoneModal(!showTimeZoneModal);
    }

    const selectPopupZone = (zone) => {
        setSelectedZone(Timezones[zone]);
        setShowTimeZoneModal(!showTimeZoneModal);
        props.selectedTimeZone(zone);
    }

    const openModal = (flag) => {
        setShowTimeZoneModal(!showTimeZoneModal);
    }

    const getDefaultTimezone = (abbr) => {
        switch (abbr) {
            case "CST":
            case "CDT":
                return 2;
            case "MST":
            case "MDT":
                return 3;
            case "PST":
            case "PDT":
                return 4;
            default:
                return 1;
        }
    }

    useEffect(() => {
        document.title = "Task calendar";

        // get local time zone
        let timeZoneAbbr = dayjs().tz().format('z');
        console.log('timezone --', timeZoneAbbr);

        // set default time zone
        let defaultTimeZone = getDefaultTimezone(timeZoneAbbr);
        setSelectedZone(Timezones[defaultTimeZone]);
        props.selectedTimeZone(defaultTimeZone);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            {
                showTimeZoneModal && createPortal(
                    <div className={styles.timeZoneOverlay} onClick={() => closeModal()}/>,
                    document.querySelector('.app')
                )
            }
            <div className={styles.timeZoneContainer} >
                <span className={styles.label}>Time zone</span>
                <span className={styles.listHeading} onClick={() => openModal(true)}>{selectedZone} <BsCaretDownFill /></span>
                {
                    showTimeZoneModal &&
                    <div className={`${styles.timeZoneListContainer} position-absolute bottom-0 `} >
                        <ul className={styles.zonelist}>
                            {
                                Object.keys(Timezones).map((zone, i) => {
                                    return (
                                        <li onClick={() => selectPopupZone(zone)} key={i}>{Timezones[zone]}</li>
                                    )
                                })
                            }
                        </ul>
                    </div>
                }
            </div>
        </>
    );
};
