import styles from "./AcuitySchedular.module.scss";
import Layout from "../components/ui/Layout";
import {useNavigate, useLocation} from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import { useEffect,useRef,useState} from "react";
import {FiChevronRight,FiChevronLeft} from "react-icons/fi";
import AcuityCalendarComponent from "./components/AcuityCalendarComponent";
import Loader from "../components/ui/Loader";
import "react-datepicker/dist/react-datepicker.css";
import { addBusinessDays, addDays, subDays, subMonths, addMonths, format, isWeekend } from 'date-fns';
import { Endpoints } from "../core/networking";
import * as Actions from "./state/schedular/actions";
import TaskDescription from "./components/TaskDescription";
import PageTitle from "./components/PageTitle";

const AcuitySchedularScreen = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {
        loginData: { loginUser },
        schedularData: { addAcuityInterview, slotList ,daysList},
    } = useSelector((state) => state);
    const { response: addedInterview, isLoading: addloading } = addAcuityInterview || {};
    const { response: slotInfo, isLoading: slotLoading } = slotList || {};
    const { response: daysListInfo, isLoading: daysLoading } = daysList || {};
    const { response: loginVal } = loginUser || {};

    const [currentDate,setCurrentDate] = useState(new Date());
    const [selectedTime,setSelectedTime] = useState("");
    const [selectedVideo,setSelectedVideo] = useState("");
    const [showInterviewForm,setShowInterviewForm] = useState(true);
    const [index,setIndex] = useState(-1);
	const [scrollbarHeight,setScrollBarHeight] = useState(window.screen.availHeight);
    const [,setAvailableDays] = useState([]);
    const [scheduleList,setScheduleList] = useState([]);
    const [step,setStep] = useState(1);
    const [currentMonthDaySlot,setCurrentMonthDaySlot] = useState([]);
    const [skypeId,setSkypeId] = useState("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const Timezones = {
        EST: 1,
        CST: 2,
        MST: 3,
        PST: 4,
        HST: 6,
        AKST: 5
    }
    const [zone,setZone] = useState("");
    const [selectedInterviewDetails, setSelectedInterviewDetails] = useState([]);
    const appointmentDetails = useRef([]);

    const selectTimeZone = useRef();

    const [taskId, setTaskId] = useState();


    // eslint-disable-next-line react-hooks/exhaustive-deps
    var timeZone = [      
        { abr: 'PST',zone:'(GMT-08:00) Pacific Time' },
        { abr: 'MST',zone:'(GMT-07:00) Mountain Time' },
        { abr: 'MST',zone:'(GMT-07:00) Mountain Time Arizona' },
        { abr: 'CST',zone:'(GMT-06:00) Central Time' },
        { abr: 'EST',zone:'(GMT-05:00) Eastern Time' },
    ];

    var ZoneFormat = new Map([
        ['PST', 'America/Los_Angeles'],
        ['MST', 'America/Denver'],
        ['CST', 'America/Chicago'],
        ['EST', 'America/New_York'],
    ]); //https://fairfaxeggbank.as.me/schedule.php?appointmentType=45683533


    useEffect(() => {
        document.title = 'Acuity Schedular';
        setStep(1);
        setSkypeId("");
        setSelectedTime("");
        setIndex("");
        setSelectedVideo("");
       
        setTodayDate();
        setShowInterviewForm(true); // true
        setTaskId(location.state?.id);
        if(location?.state?.taskInfo?.SubTaskType === 9)
            setSelectedVideo("Audio");

    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => { // set scroll window height
        const mainElement = document.getElementsByClassName("app");
		setScrollBarHeight(mainElement[0]?.offsetHeight);
        window.addEventListener("resize", function () {
            setScrollBarHeight(mainElement[0]?.offsetHeight);
        });
	}, [scrollbarHeight]);

    useEffect(() => {
        if (typeof slotInfo !== "undefined" && slotInfo) {
            setStep(3);
            setScheduleList(slotInfo);
            dispatch(Actions.acuityInterviewSlotsClear());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [slotInfo]);

    useEffect(() => {
        if (typeof addedInterview !== "undefined" && addedInterview) {

            if(addedInterview?.data?.status_code >= 400 ) //error
            {
                setShowInterviewForm(true);
            } else{
                setShowInterviewForm(false);
            }
            dispatch(Actions.addAcuityClear());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [addedInterview]);


    function filterSlots(daysListInfo,curdate){
        if (daysListInfo != null && curdate !== "")
        {
            let year = format(curdate,'yyyy');
            let month = format(curdate,'MM');
            let availableSlots = daysListInfo.filter((days) => {
                return (format(new Date(days.Date),'yyyy') === year && format(new Date(days.Date),'MM') === month);
            });

            return availableSlots;
        }
        return [];

    }

    useEffect(() => {
        if (daysListInfo) {
            setCurrentMonthDaySlot(filterSlots(daysListInfo,currentDate));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [daysListInfo]);

    useEffect(() => {
        appointmentDetails.current['selectedAppDate'] = currentDate;
    }, [currentDate]);

    useEffect(() => {
        if (typeof daysListInfo !== "undefined" && daysListInfo) {
            setAvailableDays(daysListInfo);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    });

    function selectedDay(seldate) {
        setCurrentDate(seldate);
        fetchAvailableTimeSlots(seldate);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const fetchAvailableTimeSlots=(curDate)=>{
        let applicantID = loginVal?.data?.applicant_id; //"163581";//
        //applicantID = "163354";
        let year = format(curDate,'yyyy');
        let month = format(curDate,'MM');
        let date = format(curDate,'dd');
        let acuityEndpoint = (selectedVideo === "Audio" ? Endpoints.ACUITY_AUDIO_SLOTS : Endpoints.ACUITY_SLOTS);
        const taskEndPoint = acuityEndpoint+applicantID+"/"+year+"-"+month+"-"+date+"/"+zone;
        let params = {endpoint: taskEndPoint,method: "GET"};
        dispatch(Actions.acuityInterviewSlotsRequest(params));
    }

    const setTodayDate=()=>{
        let todaydate = new Date(currentDate);
        if(isWeekend(todaydate))
        {
            todaydate = addBusinessDays(currentDate,1);
        }
        setCurrentDate(todaydate);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const fetchAvailableDays =(slotDate)=>{
        let applicantID = loginVal?.data?.applicant_id; //"163581";//
        //applicantID = "163354";
        let year = format(slotDate,'yyyy');
        let month = format(slotDate,'MM');
        let acuityEndpoint = (selectedVideo === "Audio" ? Endpoints.ACUITY_AVAIL_AUDIO_DAYS: Endpoints.ACUITY_AVAIL_DAYS);
        let taskEndPoint = acuityEndpoint+applicantID+"/"+year+"/"+month+"/"+zone;
        let params = {endpoint: taskEndPoint,method: "GET"};
        dispatch(Actions.acuityAvailableDaysRequest(params));
    }

    function goToHomeScreen() {
        navigate(-1, { replace: true });
    }

    const beforeMonth = () => {
        let beforeMonth = subMonths(new Date(currentDate),1);
        
        setCurrentDate(beforeMonth);
        
        let monthSlots = filterSlots(daysListInfo,beforeMonth);
        if(monthSlots.length > 0)
        {
            setCurrentMonthDaySlot(monthSlots);
        }else{
            fetchAvailableDays(beforeMonth);
        }
        
    }

    const nextMonth = () => {
        let afterDate = addMonths(new Date(currentDate),1);
        
        setCurrentDate(afterDate);
        let monthSlots = filterSlots(daysListInfo,afterDate);
        if(monthSlots.length > 0)
        {
            setCurrentMonthDaySlot(monthSlots);
        }else{
            fetchAvailableDays(afterDate);
        }
        
    }

    const DayHeader = () => {
        let newDate = currentDate;       

        return (
            <>
                <div className={styles.dayHeader}>
                    <div className={`${styles.headerLeft} ${(format(new Date(), 'MM') === format(newDate, 'MM')) ? styles.disable : ""} `}>
                        <FiChevronLeft onClick={((format(new Date(), 'MM') !== format(newDate, 'MM')) ? () => beforeMonth() : () => null)} />
                    </div>
                    <div className={styles.headerCurrentDate}>
                        <div style={{lineHeight:"52px"}}>
                            {format(newDate,'MMMM yyyy')}
                        </div>
                    </div>
                    <div className={styles.headerRight}>
                        <FiChevronRight onClick={()=>nextMonth()}/>
                    </div>
                </div>
            </>
        )
    }


    const acuityDateFormat = (acuityTime) => {
        let time = new Date(acuityTime);
        const selectedTimeZone = Object.keys(Timezones).find((key) => Timezones[key] === zone);
        let timeZone = ZoneFormat.get(selectedTimeZone);
        return time.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true, timeZone: timeZone }).toLowerCase();
    }

    const TimeZoneList = () => {
        const selectedTimeZone = Object.keys(Timezones).find((key) => Timezones[key] === zone);
        return (
            <select className={styles.availTimeZoneSelect} ref={selectTimeZone} value={selectedTimeZone} onChange={assignTimeSlotZone}>
                {timeZone && timeZone.map(
                    (tzones, i) => {
                        return (
                            <option key={i} value={tzones.abr}>{tzones.zone}</option>
                        )
                    }
                )
                }
            </select>
        )
    }

    const selectInterviewTime = (interviewDetails,i) => {
        setSelectedTime(acuityDateFormat(interviewDetails.AppointmentTime));
        setSelectedInterviewDetails(interviewDetails);
        setIndex(i);
        if (selectedVideo !== "Audio") {
            setStep(4);
        }

    }

    const InterviewList = () => {
        return (
            <>
                {scheduleList?.length > 0 ?
                    scheduleList?.map(
                        (interview, i) => {

                            return (
                                <div key={i} className={`${styles.InterviewList} ${(i === index) ? styles.activeTime : ""} `} onClick={() => { selectInterviewTime(interview,i)}}>
                                    <div style={{width:"100%"}}>
                                        {acuityDateFormat(interview.AppointmentTime)}
                                    </div>
                                </div>
                            );
                        }
                    )
                    :
                    <>
                        <p className={styles.norecords}>Sorry, interview slots are not available. Try another dates</p>
                    </>
                }
            </>
        )
    }

    const handleSubmitInterview = ()=> {
       let appointmentID = selectedInterviewDetails.FacetimeWhatsAppAppointmentTypeID;
       if(selectedVideo === "Skype")
       {
            appointmentID = selectedInterviewDetails.SkypeAppointmentTypeID;
       }else if(selectedVideo === "Audio")
       {
            appointmentID = selectedInterviewDetails.AudioAppointmentTypeID;
       }

       let InterviewMethodTypeID = "";
       let SkypeUsername = null;
       switch(selectedVideo)
       {
         case "Audio":
            InterviewMethodTypeID = 4;
            break;
         case "Skype":
            SkypeUsername = skypeId;
            InterviewMethodTypeID = 3;
            break;
         case "FaceTime":
            InterviewMethodTypeID = 1;
            break;
         case "WhatsApp":
            InterviewMethodTypeID = 2;
            break;
         default:
            break;
        }

       const formData = new FormData();
       formData.append("AppointmentDateTime",selectedInterviewDetails.AppointmentTime);
       formData.append("ApplicantID",loginVal?.data?.applicant_id);
       formData.append("TaskID",taskId);
       formData.append("AppointmentTypeID",appointmentID);
       formData.append("InterviewMethodTypeID",InterviewMethodTypeID);
       formData.append("SkypeUsername", SkypeUsername);
       formData.append("TimezoneID",selectedInterviewDetails.TimezoneID);
       formData.append("method",'POST');
       formData.append("endpoint",'acuity/scheduleinterview');
       //window.console.log('---formData--',formData);
       dispatch(Actions.addAcuityInteviewRequest(formData));
    }

    const updateVideoPref =(elm)=>{
        if(elm.target.value === "Skype")
        {
            setSelectedVideo(elm.target.value);
        }else{
            setSelectedVideo(elm.target.value);
        }
    }

    const prevInterviewSlot = () => {
        let beforeMonth = subDays(new Date(currentDate),1);
        setCurrentDate(beforeMonth);

        fetchAvailableTimeSlots(beforeMonth);
    }

    const nextInterviewSlot = () => {
        let afterDate = addDays(new Date(currentDate),1);
        setCurrentDate(afterDate);

        fetchAvailableTimeSlots(afterDate);
    }

    useEffect(()=>{
        if(zone !== "")
        {
            if(step === 3)
            {
               
                fetchAvailableTimeSlots(currentDate);
            }
            else {
                fetchAvailableDays(new Date());
            }

            let zones = Object.keys(Timezones).find(key => Timezones[key] === zone);
            if (zones) {
                let appointmentZone = timeZone.find(o => o.abr === zones);
                appointmentDetails.current['selectedTimeZone'] = appointmentZone.zone;
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [zone]);

    const assignTimeSlotZone = (e) => {
        setStep(3);
        setIndex(-1);
        setZone(Timezones[e.target.value]);
        setSelectedTime("");
    }

    const setTimeZone = (selZone) => {
        setZone(Timezones[selZone]);
        setCurrentDate(new Date());
        setStep(2);
    }

    const updateSkypeId = (e) => {
        setSkypeId(e.target.value);
    }

    const handleStep = (step) => {
        setStep(step - 1);
    }

    useEffect(() => {
        if (step === 3) {
           if(selectedVideo !== "Audio") setSelectedVideo("");
        } else if (step === 2) {
            setSelectedTime("");
        } else if (step === 1) {
            setZone("");
        }
    }, [step, selectedVideo]);

    return (
        <>
            {(slotLoading || addloading || daysLoading) && <Loader />}
            <Layout showHeader="false" showFooter="false">
                <div className={styles.mainContainer}>
                    <PageTitle
                        title={location.state?.taskname}
                        onClose={goToHomeScreen}
                    />
                    <div className={styles.interviewContainer}>
                        {
                            (showInterviewForm === true) ?
                            <>
                                <div className={`${styles.acuityContainer} position-relative py-3`} id="acuityContainer">

                                        <div className={`${styles.interviewCalc} calendarInner`}>
                                            {
                                                step === 1 &&
                                                <>
                                                    <TaskDescription styles={`px-3 py-2`} taskDetails={location?.state?.taskInfo}/>
                                                    
                                                    <div className={`${styles.timeZone} position-relative mt-4`}>
                                                        <h4>Time Zone</h4>
                                                        <ul className={styles.zoneList}>
                                                            {
                                                                timeZone.length > 0 && timeZone?.map(
                                                                    (zones, i) => {

                                                                        return (
                                                                            <li onClick={()=>setTimeZone(zones.abr)} key={i}>
                                                                                {zones.zone}
                                                                            </li>
                                                                        );
                                                                    }
                                                                )
                                                            }
                                                        </ul>
                                                    </div>
                                                </>
                                            }
                                            {
                                                (step === 2 || step === 3 || step === 4) &&
                                                <div className={styles.backButton}>
                                                     <a onClick={()=>handleStep(step)}><FiChevronLeft/>&nbsp;Back</a>
                                                </div>
                                            }
                                            {
                                                step === 2 &&
                                                <div className={styles.step2}>
                                                    <DayHeader />
                                                    <AcuityCalendarComponent availableDays={currentMonthDaySlot} selectedDay={selectedDay} todaysDate={currentDate} />
                                                </div>
                                            }
                                            {
                                                step === 3 &&
                                                <div className={styles.step3}>
                                                    <>
                                                        <div className={styles.dayHeader}>
                                                            <div className={styles.headerLeft}>
                                                                <FiChevronLeft onClick={()=>prevInterviewSlot()}/>
                                                            </div>
                                                            <div className={styles.headerCurrentDate}>
                                                                <div>
                                                                    <span style={{fontWeight:"300"}}>{format(currentDate,'EEEE')}</span><br/>
                                                                    {format(currentDate,'MMMM dd')}
                                                                </div>
                                                            </div>
                                                            <div className={styles.headerRight}>
                                                                <FiChevronRight onClick={()=>nextInterviewSlot()}/>
                                                            </div>
                                                        </div>
                                                        <div className={styles.availableTimeSlots}>
                                                            <TimeZoneList/>
                                                            <InterviewList/>
                                                        </div>
                                                    </>
                                                </div>
                                            }
                                            {
                                                step === 4 &&
                                                <div className={styles.step4}>
                                                    <>
                                                        {
                                                            (selectedVideo !== "Audio" && selectedTime !== "") &&
                                                            <div style={{ margin: "5px auto", display: "block", width: "100%", textAlign: "left" }}>
                                                                <span style={{ fontSize: "15px", textAlign: "left" }}>Please specify video preference</span>
                                                                <select onChange={updateVideoPref} name="videoPrefence" value={selectedVideo} className={styles.clsVideoPrefence} >
                                                                    <option value="">Select</option>
                                                                    <option value="FaceTime">FaceTime</option>
                                                                    <option value="WhatsApp">WhatsApp</option>
                                                                    <option value="Skype">Skype</option>
                                                                </select>
                                                                {selectedVideo === "Skype" &&
                                                                    <div className={styles.flatControl}>
                                                                        <input type="text" name="videoSkypeId" id="videoSkypeId" placeholder="Enter SkypeID" onChange={updateSkypeId} value={skypeId} />
                                                                    </div>
                                                                }
                                                            </div>
                                                        }
                                                    </>
                                                </div>
                                            }
                                        </div>
                                        <div className={`${styles.bottomSection}`} >
                                            <div className={`${styles.interviewFields} calendarInner `} style={{ paddingTop: "20px", borderTop: `${step === 1 ? "0px" : ""}` }}>
                                                {
                                                    appointmentDetails.current['selectedTimeZone'] && step >= 2 &&
                                                    <div className={styles.interviewInfo}>
                                                            <strong>Time Zone:</strong>&nbsp;&nbsp;
                                                            {appointmentDetails.current['selectedTimeZone']}
                                                    </div>
                                                }
                                                {
                                                    currentDate && step >=3 &&
                                                    <div className={styles.interviewInfo}>
                                                            <strong>Date:</strong>&nbsp;&nbsp;{format(currentDate, 'EEEE, MMMM dd')}
                                                    </div>
                                                }
                                                {
                                                    selectedTime && (step >= 4 || selectedVideo === "Audio") &&
                                                    <div className={styles.interviewInfo}>
                                                            <strong>Time:</strong>&nbsp;&nbsp;{selectedTime}&nbsp;{Object.keys(Timezones).find(key => Timezones[key] === zone)}
                                                    </div>
                                                }
                                                {
                                                    selectedVideo && step >= 4 &&
                                                    <div className={styles.interviewInfo}>
                                                            <strong>Video Platform:</strong>&nbsp;&nbsp;{selectedVideo}
                                                    </div>
                                                }                                                
                                            </div>
                                            <div className={`${styles.scheduleButton} mt-4 mb-0 px-3 `}>
                                                <button onClick={((selectedTime !== "" && currentDate !== "" && ((selectedVideo !== "" && selectedVideo === "Audio") || (selectedVideo !== "" && selectedVideo !== "Skype") || (selectedVideo === "Skype" && skypeId !== ""))) ? () => handleSubmitInterview() : () => null)} className={`${styles.submitArrow} ${(selectedTime !== "" && currentDate !== "" && ((selectedVideo !== "" && selectedVideo === "Audio") || (selectedVideo !== "" && selectedVideo !== "Skype") || (selectedVideo === "Skype" && skypeId !== ""))) ? styles.active : "disabled"} `}>
                                                    Schedule
                                                    <FiChevronRight className={styles.scheduleArrow} />
                                                </button>
                                            </div>
                                        </div>
                                </div>
                            </>
                            :
                            <>
                                <div className={styles.interviewAckPage}>
                                    <div className={styles.scheduledFor}>
                                        <span className={styles.ackTitle}>
                                            Appointment Scheduled
                                        </span>
                                        <span className={styles.ackFor}>
                                            for {loginVal?.data?.user_fname}&nbsp;{loginVal?.data?.user_lname}
                                        </span>
                                    </div>
                                    <div className={styles.scheduledPlace}>
                                        <div className={styles.ackInfo}>
                                            <span>What</span>
                                            <span>Interview</span>
                                        </div>
                                        {
                                            (selectedVideo !== "" && selectedVideo !== "Audio") &&
                                            <div className={styles.ackInfo}>
                                                <span>Where</span>
                                                <span>{selectedVideo}</span>
                                            </div>
                                        }
                                        {
                                            <div className={styles.ackInfo}>
                                            <span>When</span>
                                            <span>{format(new Date(selectedInterviewDetails?.AppointmentTime),"EEEE, MMMM dd , yyyy hh:mm a")}
                                            &nbsp;{Object.keys(Timezones).find((key) => Timezones[key] === zone)}</span>
                                                </div>
                                        }
                                    </div>
                                        <div className={styles.ackButton}>
                                            <div onClick={() => navigate(-1)} className={`${styles.ackArrowButton} `}>
                                             Close
                                             <FiChevronRight className={styles.scheduleArrow} />
                                        </div>
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                </div>
            </Layout>
        </>

    );
}

export default AcuitySchedularScreen;
