/*screen name + methodname+ actiontype*/
export const UPLOAD_GET_MSG_TASK_REQUEST = 'UPLOAD_GET_MSG_TASK_REQUEST';
export const UPLOAD_GET_MSG_TASK_SUCCESS = 'UPLOAD_GET_MSG_TASK_SUCCESS';
export const UPLOAD_GET_MSG_TASK_ERROR = 'UPLOAD_GET_MSG_TASK_ERROR';
export const UPLOAD_GET_MSG_TASK_CLEAR = 'UPLOAD_GET_MSG_TASK_CLEAR';

export const UPLOAD_PORTAL_FILE_REQUEST = 'UPLOAD_PORTAL_FILE_REQUEST';
export const UPLOAD_PORTAL_FILE_SUCCESS = 'UPLOAD_PORTAL_FILE_SUCCESS';
export const UPLOAD_PORTAL_FILE_ERROR = 'UPLOAD_PORTAL_FILE_ERROR';
export const UPLOAD_PORTAL_FILE_CLEAR = 'UPLOAD_PORTAL_FILE_CLEAR';

export const UPLOAD_FILE_DOWNLOAD_REQUEST = 'UPLOADFILE_DOWNLOAD_REQUEST';
export const UPLOAD_FILE_DOWNLOAD_SUCCESS = 'UPLOADFILE_DOWNLOAD_SUCCESS';
export const UPLOAD_FILE_DOWNLOAD_ERROR = 'UPLOFILE_DOWNLOAD_ERROR';
export const UPLOAD_FILE_DOWNLOAD_CLEAR = 'UPLOFILE_DOWNLOAD_CLEAR';
