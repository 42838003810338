import { useEffect,useState } from "react";
import styles from "../AcuitySchedular.module.scss";

import { startOfMonth,endOfMonth,isSameMonth,isSameDay, startOfWeek, endOfWeek,addDays,format } from 'date-fns';

const AcuityCalendarComponent = (props) => {
    const [selectedDate/*,setSelectedDate*/] = useState();
    const [currentMonth] = useState(new Date());
    const [currentDate] = useState(new Date());
    const {selectedDay} = props;

    useEffect(() => {

    },[]);

    const checkDayHasSlot = (calDate) => {
        let availdays = props.availableDays;
        let curdate = format(calDate,'dd');
        let curmonth = format(calDate,'MM');
        let availableSlots = availdays.filter((dates) => {
            let dateStr = dates.Date.split("-");
          
           return (dateStr[2] === curdate && dateStr[1] === curmonth);
        });
        return (availableSlots.length > 0);
    }

    const RenderHeader = () => {
        
        let d = new Date(props.todaysDate);
        let monthlist = [];
       
        monthlist.push(new Date(d.getFullYear(), d.getMonth(), 1));

        return monthlist?.map((calendarMonth, index) => (
            <div key={index}>
                <RenderCells month={calendarMonth} position={index}/>
            </div>
        ));
    }

    const RenderDays = () => {
        const dateFormat = "EEE";
        const days = [];
        let startDate = startOfWeek(currentMonth);
        for (let i = 0; i < 7; i++) {
            days.push(
            <div className={`${styles.col}`} key={i}>
                {format(addDays(startDate, i), dateFormat)}
            </div>
            );
        }
        return <div className={`${styles.days} mt-4 mb-0`}>{days}</div>;
    }
    const RenderCells = (props) => {
       
        const monthStart = startOfMonth(props.month);
        const monthEnd = endOfMonth(monthStart);
        const startDate = startOfWeek(monthStart);
        const endDate = endOfWeek(monthEnd);

        const dateFormat = "d";
        const rows = [];

        let days = [];
        let day = startDate;
        let formattedDate = "";

        while (day <= endDate) {
            for (let i = 0; i < 7; i++) {
                formattedDate = format(day, dateFormat);
                const cloneDay = day;
                days.push(
                <div
                    className={`cell ${
                    (!isSameMonth(day, monthStart))
                        ? styles.disabled
                        : isSameDay(day,currentDate) ? styles.currentDate : isSameDay(day, selectedDate) ? styles.selected : ""
                    } ${styles.calendarDate}`}
                        key={day}
                    >
                        <span onClick={(checkDayHasSlot(day) ? ()=>selectedDay(cloneDay) : ()=> null)} className={`number ${
                        (checkDayHasSlot(day))
                            ? styles.slotAvailable
                            : ""
                            }`}>{formattedDate}</span>
                        {
                            isSameDay(day, currentDate) &&
                            <span className={styles.circleIndicator}></span>
                        }

                </div>
                );
                day = addDays(day, 1);
            }
            rows.push(
                <div className={styles.dayRow} key={day}>
                {days}
                </div>
            );
            days = [];
        }

        return <div className={`${props.position === 0 ? styles.CalendarDays + " " +styles.currentDaySection : styles.CalendarDays}`}>{rows}</div>;
    }


    return (
        <div className={styles.calendar} style={{margin:"0 auto",maxWidth:"100%",height:"300px"}}>
            <RenderDays/>
            <RenderHeader/>
        </div>
    );
}

export default AcuityCalendarComponent;
