import React from "react";
import { FaCalendarAlt, FaBell, FaRegClock } from "react-icons/fa";
import styles from "./TaskCalendar.module.scss";

const List = (props) => {
    const { handleOnClick, event, handleModel, key, showButton, handleCaleLink, showAlert, disabled = false } = props;
    const showTime = event.TaskType === 10 && event.EventTime !== "12:00 AM";

    return (
        <li key={key} className={styles.listOfTask} data-id={event.AssociatedID}>
            <div className={`${styles.appointMent} ${disabled ? 'pe-none' : 'cursor-pointer'} flex-grow-1`}
                 onClick={(val) => handleOnClick(val)}
            >
                <span className={styles.taskName}>
                    {event.EventName ? event.EventName : "task"}
                </span>
                <span className={`${styles.allDays} d-flex align-items-center mt-1`}>
                    { showTime && <><FaRegClock className="opacity-75 me-2" />{event.EventTime}</> }
                    { showButton && <><FaRegClock className="me-2" />All Day</> }
                </span>
            </div>
            <div className={`${styles.appointMentIcon} flex-shrink-0 flex-grow-1 d-flex justify-content-end`}>
                <span className={`${disabled ? 'pe-none text-muted opacity-50' : 'cursor-pointer'}`}>
                    <FaCalendarAlt onClick={() => handleCaleLink()}/>
                </span>
                { showAlert &&
                    <span>
                        <FaBell onClick={() => handleModel()} />
                    </span>
                }
            </div>

            {showButton &&
                <div className={styles.taskButton}>
                    <button className={styles.doneButton}>Done</button>
                </div>
            }
        </li>
    );
};
export default List;
