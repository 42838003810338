import styles from "./TravelDonor.module.scss";
import Layout from "../../components/ui/Layout";
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState, useRef } from "react";
import {  BsChevronRight } from "react-icons/bs";
import { Scrollbars } from "react-custom-scrollbars-2";
import PageTitle from "../components/PageTitle";

const TravelItenary = () => {
    const location = useLocation();
    const navigate = useNavigate();
    let { state } = useLocation();
    const travelDetailsRef = useRef();
    const [scrollbarHeight, setScrollBarHeight] = useState(window.screen.availHeight);
    /*const [, setPage] = useState(0);
    const [, setSection] = useState(0);*/

    useEffect(() => {
        document.title = 'Travel Details';
    }, []);

    
    useEffect(() => {
        // set scroll window height
        const mainElement = document.getElementsByClassName("app");
        const footer = document.querySelector(".footerElement");
        const pgTitle = document.querySelector(".homePageTitle");
        setScrollBarHeight(mainElement[0]?.offsetHeight - footer?.offsetHeight - pgTitle?.offsetHeight);
        window.addEventListener("resize", function () {
            setScrollBarHeight(mainElement[0]?.offsetHeight - footer?.offsetHeight - pgTitle?.offsetHeight);
        });
    }, [scrollbarHeight]);

    /*const setLink = (sec,pg) => {
        setSection(sec);
        setPage(pg);
    }*/


    return (
        <>
            <Layout showHeader="false">
                <div className={styles.stickyHeader}>
                    <PageTitle
                        title="My Travel Details Available"
                        headerClass="homePageTitle"
                        linkTo={state?.prevPage ? state?.prevPage : "/home"}
                        linkState={{ menu: state?.menu }}
                    />
                    {/*<div className={`${styles.pageTitleSection} homePageTitle`}>
                        <h1 className="fw-normal text-white text-center">
                            My Travel Details Available
                        </h1>
                        <Link to={state?.prevPage ? state?.prevPage : "/home"} state={{ menu: state?.menu }}>
                            <BsXLg className={`${styles.closeIcon} float-end position-absolute`} />
                        </Link>
                    </div>*/}
                </div>
                <div className={styles.travelMyDetailsContainer}>
                    <Scrollbars
                        autoHeight
                        autoHeightMax={scrollbarHeight}
                        className={`${styles.travelContainer}`}
                        ref={travelDetailsRef}
                    >
                        <div className={styles.wrapper} style={{ height: scrollbarHeight }}>
                            <div className={styles.topDiv}>
                                <span className={styles.contentText}>
                                    Your travel itinerary is available to view in the menu under My Travel Details.
                                </span>
                            </div>
                            <div className={styles.bottomDiv}>
                                <button type="button"
                                    onClick={
                                        () => navigate("/travel-details", {
                                            state: { prevPage: "/travel-iternary",taskInfo: location?.state?.taskInfo },
                                        })}
                                    className={`${styles.detailsButton} position-relative float-end`}>
                                    <span>View</span>
                                    <BsChevronRight className={styles.buttonIcon} />
                                </button>
                            </div>
                        </div>
                    </Scrollbars>
                </div>
                
            </Layout>
        </>
    );
}

export default TravelItenary;
