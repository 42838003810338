import * as Constants from './constants';

export const acuityInterviewSlotsRequest = (data) => ({
    type: Constants.ACUITY_SLOTS_GET_LIST_REQUEST,
    payload: data,
});

export const acuityInterviewSlotsSuccess = (data) => ({
    type: Constants.ACUITY_SLOTS_GET_LIST_SUCCESS,
    payload: data,
});

export const acuityInterviewSlotsError = (error) => ({
    type: Constants.ACUITY_SLOTS_GET_LIST_ERROR,
    payload: error,
});

export const acuityInterviewSlotsClear = () => ({
    type: Constants.ACUITY_SLOTS_GET_LIST_CLEAR
});

export const addAcuityInteviewRequest= (data) => ({
    type: Constants.ADD_ACUITY_INTERVIEW_REQUEST,
    payload: data,
});

export const addAcuitySuccess = (data) => ({
    type: Constants.ADD_ACUITY_INTERVIEW_SUCCESS,
    payload: data,
});

export const addAcuityError = (error) => ({
    type: Constants.ADD_ACUITY_INTERVIEW_ERROR,
    payload: error,
});

export const addAcuityClear = () => ({
    type: Constants.ADD_ACUITY_INTERVIEW_CLEAR,
});


// Available days

export const acuityAvailableDaysRequest = (data) => ({
    type: Constants.ACUITY_DAYS_GET_LIST_REQUEST,
    payload: data,
});

export const acuityAvailableDaysSuccess = (data) => ({
    type: Constants.ACUITY_DAYS_GET_LIST_SUCCESS,
    payload: data,
});

export const acuityAvailableDaysError = (error) => ({
    type: Constants.ACUITY_DAYS_GET_LIST_ERROR,
    payload: error,
});

export const acuityAvailableDaysClear = () => ({
    type: Constants.ACUITY_DAYS_GET_LIST_CLEAR
});