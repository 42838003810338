import React, { useEffect, useState, useRef } from "react";
import Layout from "../components/ui/Layout";
import { useLocation} from "react-router-dom";
import PageTitle from "./components/PageTitle";
import { Scrollbars } from "react-custom-scrollbars-2";
import PhotoGuideLines from "../assets/images/Egg-Photo-Guidelines.png";
import AdultPhotoGuideLines from "../assets/images/DMA-Adult-Photos_photoguidelines.png";
import { useNavigate } from "react-router-dom";


const PhotoShoot = () => {
    let { state } = useLocation();
    const [scrollbarHeight, setScrollBarHeight] = useState("");
    const photoShootRef = useRef(null);
    const navigate = useNavigate();
    const [photoSrc, setPhotoSrc] = useState("");

  useEffect(() => {
      document.title = "Egg Donor Photo Guidelines";
      if (state?.subtype === 6) {
          setPhotoSrc(AdultPhotoGuideLines);
      } else {
          setPhotoSrc(PhotoGuideLines);
      }

  }, []);

    const goBack = () => {
        navigate(-1);
    };

    useEffect(() => {
        const footerElement = document.querySelector(".footerElement");
        setScrollBarHeight(window.innerHeight - footerElement?.clientHeight);
        window.addEventListener("resize", function () {
            setScrollBarHeight(window.innerHeight - footerElement?.clientHeight);
        });
    }, [scrollbarHeight]);

  return (
    <Layout showHeader="false">
    <PageTitle
              title="Egg Donor Photo Guidelines"
              headerClass="homePageTitle"
              onClose={state?.fromPage === "upload" ? goBack : null}
              linkTo={state?.fromPage === undefined ? (state?.prevPage ? state?.prevPage : -1) : null}
              linkState={{ menu: state?.menu }}
    />
      <Scrollbars
          autoHeight
          style={{marginTop:"40px"} }
          autoHeightMax={scrollbarHeight-120}
          className="scroll-container"
          ref={photoShootRef}
       >
              <div className="container">
                  {
                      photoSrc &&
                      <img style={{ width: "100%", height: "auto", marginBottom: "50px" }} src={photoSrc} alt="Photo Guidelines" />
                  }
                  
              </div>
      </Scrollbars>
    </Layout>
  );
};

export default PhotoShoot;
