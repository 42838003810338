import { combineReducers } from "redux";
import { getBasicReducer } from "../../../core/utils";
import * as Constants from "./constants";

export const bgData = combineReducers({
    tasks: getBasicReducer({
        request: Constants.BG_TASK_FETCH_REQUEST,
        success: Constants.BG_TASK_FETCH_SUCCESS,
        failure: Constants.BG_TASK_FETCH_ERROR,
        clear: Constants.BG_TASK_FETCH_CLEAR,
    }),
    notifications: getBasicReducer({
        request: Constants.BG_NOTIFICATION_GET_REQUEST,
        success: Constants.BG_NOTIFICATION_GET_SUCCESS,
        failure: Constants.BG_NOTIFICATION_GET_ERROR,
        clear: Constants.BG_NOTIFICATION_GET_CLEAR,
    }),
    calendarEvents: getBasicReducer({
        request: Constants.BG_CALENDAR_GET_DONOR_EVENT_REQUEST,
        success: Constants.BG_CALENDAR_GET_DONOR_EVENT_SUCCESS,
        failure: Constants.BG_CALENDAR_GET_DONOR_EVENT_ERROR,
        clear: Constants.BG_CALENDAR_GET_DONOR_EVENT_CLEAR,
    }),
    messages: getBasicReducer({
        request: Constants.BG_MESSAGE_GET_LIST_REQUEST,
        success: Constants.BG_MESSAGE_GET_LIST_SUCCESS,
        failure: Constants.BG_MESSAGE_GET_LIST_ERROR,
        clear: Constants.BG_MESSAGE_GET_LIST_CLEAR,
    }),
});
