
import { combineReducers } from "redux";
import { getBasicReducer } from "../../../core/utils";
import * as Constants from "./constants";

export const addInfoData = combineReducers({
    msgList: getBasicReducer({
        request: Constants.INFO_MESSAGE_GET_LIST_REQUEST,
        success: Constants.INFO_MESSAGE_GET_LIST_SUCCESS,
        failure: Constants.INFO_MESSAGE_GET_LIST_ERROR,
        clear: Constants.INFO_MESSAGE_GET_LIST_CLEAR,
    }),
    addMessage: getBasicReducer({
        request: Constants.INFO_ADD_MESSAGE_REQUEST,
        success: Constants.INFO_ADD_MESSAGE_SUCCESS,
        failure: Constants.INFO_ADD_MESSAGE_ERROR,
        clear: Constants.INFO_ADD_MESSAGE_CLEAR,
    }),
    fileDownload: getBasicReducer({
        request: Constants.UPLOAD_FILE_DOWNLOAD_REQUEST,
        success: Constants.UPLOAD_FILE_DOWNLOAD_SUCCESS,
        failure: Constants.UPLOAD_FILE_DOWNLOAD_ERROR,
        clear: Constants.UPLOAD_FILE_DOWNLOAD_CLEAR,
    }),
    taskSubmit: getBasicReducer({
        request: Constants.INFO_TASK_SUBMIT_REQUEST,
        success: Constants.INFO_TASK_SUBMIT_SUCCESS,
        failure: Constants.INFO_TASK_SUBMIT_ERROR,
        clear: Constants.INFO_TASK_SUBMIT_CLEAR,
    }),
});
