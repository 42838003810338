
import { combineReducers } from "redux";
import { getBasicReducer } from "../../../core/utils";
import * as Constants from "./constants";

export const loginData = combineReducers({
    loginUser: getBasicReducer({
        request: Constants.LOGIN_USER_REQUEST,
        success: Constants.LOGIN_USER_SUCCESS,
        failure: Constants.LOGIN_USER_ERROR,
        clear: Constants.LOGIN_USER_CLEAR,
    }),

    /* forget password screen  */
    verifiedPin: getBasicReducer({
        request: Constants.VERIFIED_PIN_REQUEST,
        success: Constants.VERIFIED_PIN_SUCCESS,
        failure: Constants.VERIFIED_PIN_ERROR,
        clear: Constants.VERIFIED_PIN_CLEAR,
    }),
    updatePass : getBasicReducer({
        request: Constants.UPDATE_PASSWORD_REQUEST,
        success: Constants.UPDATE_PASSWORD_SUCCESS,
        failure: Constants.UPDATE_PASSWORD_ERROR,
        clear: Constants.UPDATE_PASSWORD_CLEAR,
    }),
    validateEmail : getBasicReducer({
        request: Constants.VALIDATE_MAIL_REQUEST,
        success: Constants.VALIDATE_MAIL_SUCCESS,
        failure: Constants.VALIDATE_MAIL_ERROR,
        clear: Constants.VALIDATE_MAIL_CLEAR,
    }),

    // Rejected Applicant Status
    rejectedDonor: getBasicReducer({
       request: Constants.REJECT_STATUS_REQUEST,
       success: Constants.REJECT_STATUS_SUCCESS,
       failure: Constants.REJECT_STATUS_ERROR,
       clear: Constants.REJECT_STATUS_CLEAR,
    })
});
