
import { combineReducers } from "redux";
import { getBasicReducer } from "../../../core/utils";
import * as Constants from "./constants";

export const appointmentData = combineReducers({    
    submitAppointment: getBasicReducer({
        request: Constants.APPOINTMENT_APPROVE_REQUEST,
        success: Constants.APPOINTMENT_APPROVE_SUCCESS,
        failure: Constants.APPOINTMENT_APPROVE_ERROR,
        clear: Constants.APPOINTMENT_APPROVE_CLEAR,
    }),  
    downloadTaskFile: getBasicReducer({
        request: Constants.TASK_DOC_DOWNLOAD_REQUEST,
        success: Constants.TASK_DOC_DOWNLOAD_SUCCESS,
        failure: Constants.TASK_DOC_DOWNLOAD_ERROR,
        clear: Constants.TASK_DOC_DOWNLOAD_CLEAR,
    }),
    getTaskDocument: getBasicReducer({
        request: Constants.TASK_DOCUMENT_REQUEST,
        success: Constants.TASK_DOCUMENT_SUCCESS,
        failure: Constants.TASK_DOCUMENT_ERROR,
        clear: Constants.TASK_DOCUMENT_CLEAR,
    }),
    getAppointmentInterviewData: getBasicReducer({
        request: Constants.APP_SCHEDULE_INTERVIEW_GET,
        success: Constants.APP_SCHEDULE_INTERVIEW_SUCCESS,
        failure: Constants.APP_SCHEDULE_INTERVIEW_ERROR,
        clear: Constants.APP_SCHEDULE_INTERVIEW_CLEAR,
    }),
    mind360Contact: getBasicReducer({
        request: Constants.PSYCHOLOOGICAL_MIND_360CONTACT_REQUEST,
        success: Constants.PSYCHOLOOGICAL_MIND_360CONTACT_SUCCESS,
        failure: Constants.PSYCHOLOOGICAL_MIND_360CONTACT_ERROR,
        clear: Constants.PSYCHOLOOGICAL_MIND_360CONTACT_CLEAR,
    }),
    psychoSubmit: getBasicReducer({
        request: Constants.PSYCHOLOOGICAL_SUBMIT_APPOINTMENT_REQUEST,
        success: Constants.PSYCHOLOOGICAL_SUBMIT_APPOINTMENT_SUCCESS,
        failure: Constants.PSYCHOLOOGICAL_SUBMIT_APPOINTMENT_ERROR,
        clear: Constants.PSYCHOLOOGICAL_SUBMIT_APPOINTMENT_CLEAR,
    }),
    psychologistList: getBasicReducer({
        request: Constants.PSYCHOLOGIST_LIST_REQUEST,
        success: Constants.PSYCHOLOGIST_LIST_SUCCESS,
        failure: Constants.PSYCHOLOGIST_LIST_ERROR,
        clear: Constants.PSYCHOLOGIST_LIST_CLEAR,
    }),
    pcbObgynSubmit: getBasicReducer({
        request: Constants.SUBMIT_PCP_OBGYN_SUBMIT_REQUEST,
        success: Constants.SUBMIT_PCP_OBGYN_SUBMIT_SUCCESS,
        failure: Constants.SUBMIT_PCP_OBGYN_SUBMIT_ERROR,
        clear: Constants.SUBMIT_PCP_OBGYN_SUBMIT_ERROR,
    }),
});
