import AuthHeader from "./AuthHeader";
import styles from "./AuthLayout.module.scss";

const AuthLayout = (props) => {
    return ( 
            <div className='d-flex flex-column flex-grow-1 app-container' >
                <div className={`d-flex justify-content-center align-items-center mt-4 ${styles.authHeader}`}>
                    <AuthHeader/>
                </div>
                <div className={`mt-4 ${styles.authContainer}`}>
                    { props.children }
                </div>
            </div>        
    );
}

export default AuthLayout;
