// import AddToHomeScreen from '../src/screen/AddToHomeScreen';
import Pages from "./pages/Pages";
// import installImg from './assets/images/logo.jpg';
import './App.scss';
import PullToRefresh from "react-simple-pull-to-refresh";

function App() {
    const handleRefresh = async () => {
        console.log('refresh');
        window.location.reload();
    }

  return (
    <div className="app">
        <PullToRefresh
            onRefresh={handleRefresh}
            pullDownThreshold={100} /* default - 67 */
            maxPullDownDistance={142} /* default - 97 */
            resistance={3} /* default - 1 */
        >
        <Pages/>
        </PullToRefresh>
    </div>
  );
}

export default App;
