import { useEffect, useState } from "react";
import styles from "./Calendar.module.scss";
import {
    startOfMonth,
    endOfMonth,
    isSameMonth,
    isSameDay,
    startOfWeek,
    endOfWeek,
    addDays,
    format,
    subDays,
} from "date-fns";
import { dayMonthYearFormat } from "../../../src/utils";

const Calendar = (props) => {
    const {
        onDateClick,
        selectedDate,
        startDotDate,
        calendarData = [],
        calendarDisplayStart
    } = props;
    const [currentMonth, setCurrentMonth] = useState(new Date());
    const [currentDate, setCurrentDate] = useState(new Date());
    const [, setCellHighLight] = useState(0);
    const [estimatedFlowDate, setEstimatedFlowDate] = useState({});
    
    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        setCellHighLight(0);
        setCurrentMonth(new Date());
        setCurrentDate(new Date());
        let proposedList = {};

        let lastStartDate = (calendarData ? calendarData.sort((a, b) => new Date(b.FlowDate) - new Date(a.FlowDate))?.filter((i) => i.IsStartDate === true) : []);
        if (lastStartDate.length > 0) {
            let initialDate = lastStartDate[0]?.FlowDate;
            
            let d = new Date(initialDate);

            let mul = 1;
            let index = 0;
            for (let i = d.getMonth() + 2; i <= (d.getMonth() + 20); i++) {
                
                let nextDate = format(addDays(new Date(initialDate), 28 * mul), "yyyy-MM-dd");
                
                
                if (proposedList[new Date(nextDate).getFullYear()] === undefined) {
                    proposedList[new Date(nextDate).getFullYear()] = {};
                }
                    

                if (proposedList[new Date(nextDate).getFullYear()][new Date(nextDate).getMonth() + 1] === undefined) {
                    index = 0;
                    proposedList[new Date(nextDate).getFullYear()][new Date(nextDate).getMonth() + 1] = {};
                }

                
                if (proposedList[new Date(nextDate).getFullYear()][new Date(nextDate).getMonth() + 1][index] === undefined)
                    proposedList[new Date(nextDate).getFullYear()][new Date(nextDate).getMonth() + 1][index] = nextDate;

               
                mul = mul + 1;
                index = index + 1;
            }
        }
        setEstimatedFlowDate(proposedList);

    }, [calendarData]);

   
    const handleScroll = (e) => {
        window.console.log(e);
    };

    const setBorderRadius = (day, lightHeavy, daypos, isDisabled) => {
        const currentDate = day;
        const previousDate = subDays(day, 1);
        const nextDate = addDays(day, 1);
        let borderStyle = [];
        if (lightHeavy[format(currentDate, "yyyy-MM-dd")] !== undefined) {
            if (daypos === 0) {
                // set left border radius
                borderStyle.push("leftborder");
                if(lightHeavy[format(nextDate, "yyyy-MM-dd")] !== lightHeavy[format(previousDate, "yyyy-MM-dd")]){
                    borderStyle.push("leftborder");
                } 
                if (lightHeavy[format(nextDate, "yyyy-MM-dd")] === undefined || 
                    lightHeavy[format(nextDate, "yyyy-MM-dd")] !== lightHeavy[format(currentDate, "yyyy-MM-dd")]) {
                    // set right border radius
                    borderStyle.push("rightborder");
                }  
            } else if (daypos === 6) {
                // set right border radius
                    borderStyle.push("rightborder");
                if (
                    lightHeavy[format(previousDate, "yyyy-MM-dd")] === undefined ||
                    lightHeavy[format(previousDate, "yyyy-MM-dd")] !== lightHeavy[format(currentDate, "yyyy-MM-dd")]
                ) {
                    // set left border radius
                    borderStyle.push("leftborder");
                }
            } else {
                if (
                    lightHeavy[format(nextDate, "yyyy-MM-dd")] !== undefined &&
                    lightHeavy[format(previousDate, "yyyy-MM-dd")] !== undefined
                ) {
                    // set flat border
                    if(lightHeavy[format(previousDate, "yyyy-MM-dd")] !== lightHeavy[format(currentDate, "yyyy-MM-dd")]){
                        borderStyle.push("leftborder");
                    }
                    if(lightHeavy[format(nextDate, "yyyy-MM-dd")] !== lightHeavy[format(currentDate, "yyyy-MM-dd")]){
                        borderStyle.push("rightborder"); 
                    }
                    
                } 
                else {
                    if (
                        lightHeavy[format(nextDate, "yyyy-MM-dd")] === undefined ||
                        lightHeavy[format(nextDate, "yyyy-MM-dd")] !== lightHeavy[format(currentDate, "yyyy-MM-dd")]

                    ) {
                        
                        borderStyle.push("rightborder");
                    } 
                    if (
                        lightHeavy[format(previousDate, "yyyy-MM-dd")] === undefined ||
                        lightHeavy[format(previousDate, "yyyy-MM-dd")] !== lightHeavy[format(currentDate, "yyyy-MM-dd")]
                    ) {
                        // set left border radius
                        borderStyle.push("leftborder"); 
                    }
                }
            }
        }
        return borderStyle;
    };
    function monthDiff(dateFrom, dateTo) {
        return dateTo.getMonth() - dateFrom.getMonth() +
            (12 * (dateTo.getFullYear() - dateFrom.getFullYear()))
    }
    const RenderHeader = () => {
        const dateFormat = "MMMM yyyy";
        var d;
        if (!calendarDisplayStart) {
            d = new Date();
        } else {
            d = new Date(calendarDisplayStart);
        }
        
        let monthlist = [];
       
        var today = new Date(d.getFullYear(), d.getMonth() - 1, 1);
       
        monthlist.push(new Date(d.getFullYear(), d.getMonth() - 1, 1));
        for (let i = 0; i < monthDiff(d, new Date()) + 13; i++) {
            today.setMonth(today.getMonth() + 1);
            monthlist.push(new Date(today.toLocaleDateString()));
        }
        
        const calendar = [];
        if (monthlist) {
            monthlist.map((calendarmonth, index) => {
                   return calendar.push(
                        <div key={index} className={`${styles.monthDates}`}>
                            <div id={`calendarHeader_${index}`}
                                className={`${styles.header}
                                    ${styles.stickyHeader}
                                    ${isSameMonth(calendarmonth, new Date())
                                        ? "currentMonthSection" : ""
                                    }
                                    `}
                            >
                                <div style={{ alignItems: "center" }}>
                                    <div className={styles.monthText}>
                                        {format(calendarmonth, dateFormat)}
                                    </div>
                                </div>
                            </div>
                            
                            <RenderCells
                                month={calendarmonth}
                                position={index}
                            />
                        </div>
                    );
                });
        }
        return <>{calendar}</>;
    };

    const RenderDays = () => {
        const dateFormat = "EEE";
        const days = [];
        let startDate = startOfWeek(currentMonth);
        for (let i = 0; i < 7; i++) {
            days.push(
                <div className={`${styles.col}`} key={i}>
                    <div className={styles.sticky}>
                        {format(addDays(startDate, i), dateFormat)}
                    </div>
                </div>
            );
        }
        return (
            <div id="daysName" className={`${styles.days} px-5 py-1`}>
                {days}
            </div>
        );
    };

    const projectedPeriodIndication = (cloneDay) => {
        let currentDate = format(cloneDay, "yyyy-MM-dd")
        let cloneYear = new Date(cloneDay).getFullYear();
        let cloneMonth = new Date(cloneDay).getMonth() + 1;        

        if (estimatedFlowDate[cloneYear] !== undefined && estimatedFlowDate[cloneYear][cloneMonth] !== undefined) {
            if (Object.values(estimatedFlowDate[cloneYear][cloneMonth]).includes(currentDate)) {
                return styles.flowStartProjected;
            }
        }
        return "";
    }

    const RenderCells = (props) => {
        const monthStart = startOfMonth(props.month);
        const monthEnd = endOfMonth(monthStart);
        const startDate = startOfWeek(monthStart);
        const endDate = endOfWeek(monthEnd);
        const dateFormat = "d";
        const rows = [];
        
        let days = [];
        let day = startDate;
        let formattedDate = "";
        let lightHeavy = {};
        
        if (calendarData != null) {
            lightHeavy = calendarData.reduce(
                (lightHeavy, { FlowDate, FlowRate }) => {
                    lightHeavy[FlowDate] = FlowRate;
                    return lightHeavy;
                },
                {}
            );

            let flowStart = calendarData.filter((ptDates) => {
                return ptDates.IsStartDate === true;
            });

            if(flowStart){
                flowStart.sort(function (a, b) {
                    let c = new Date(dayMonthYearFormat(a.FlowDate));
                    let d = new Date(dayMonthYearFormat(b.FlowDate));
                    return c - d;
                });

          
            }
        }
       
        while (day <= endDate) {
            for (let i = 0; i < 7; i++) {
                formattedDate = format(day, dateFormat);
                const cloneDay = day;

                days.push(
                    <div
                    className={`${!isSameMonth(day, monthStart)
                        ? styles.disabled
                        : isSameDay(day, selectedDate)
                                            ? `${styles.currentDate}
                                             ${isSameDay(day, currentDate) && isSameDay(day, selectedDate) ? styles.selectCircle : ""}`
                                            : isSameDay(day, currentDate)
                                            ? styles.bold
                                           : ""
                                        }
                        ${projectedPeriodIndication(cloneDay)}
                        ${setBorderRadius(
                                cloneDay,
                                lightHeavy,
                                i,
                                isSameMonth(day, monthStart)
                            ).includes("leftborder")
                                ? styles.leftBorder
                                : ""
                        }
                        ${setBorderRadius(
                                cloneDay,
                                lightHeavy,
                                i,
                                isSameMonth(day, monthStart)
                            ).includes("rightborder")
                                ? styles.rightBorder
                                : ""
                        }                     
                        ${ isSameMonth(day, monthStart) ? lightHeavy[format(cloneDay, "yyyy-MM-dd")] === 2
                            ? styles.flowHeavy
                            : "" : ""}
                        ${isSameMonth(day, monthStart) ? lightHeavy[format(cloneDay, "yyyy-MM-dd")] === 1
                            ? styles.flowLight
                            : "" : ""}
                        ${isSameMonth(day, monthStart) ? startDotDate?.includes(
                            format(cloneDay, "yyyy-MM-dd")
                        ) ? styles.flowStart : "" : ""}
                        ${styles.calendarDate}
                        `}
                        key={day}
                        onClick={(e) => onDateClick(e, cloneDay)}
                    >
                        <span className="number">{formattedDate}</span>
                    </div>
                );
                day = addDays(day, 1);
            }
            rows.push(
                <>
                    {days}
                </>
            );
            days = [];
        }
        return (
            <>
                <div
                    id={`calendarDays_${props.position}`}
                    className={`${
                        props.position === 0
                            ? styles.CalendarDays +
                            " " +
                            styles.currentDaySection
                            : styles.CalendarDays
                    }`}
                >
                    {rows}
                </div>
            </>
        );
    };

    return (
        <div className={styles.calendar}>
            {
                <>
                    <RenderDays />
                    <RenderHeader />
                </>                
            }            
        </div>
    );
};

export default Calendar;
