/*screen name + methodname+ actiontype*/
/*Acuity Screen */
export const VIDEO_GET_LIST = 'VIDEO_GET_LIST';
export const VIDEO_GET_SUCCESS = 'VIDEO_GET_SUCCESS';
export const VIDEO_GET_ERROR = 'VIDEO_GET_ERROR';
export const VIDEO_GET_CLEAR = 'VIDEO_GET_CLEAR';

export const INFO_VIDEO_COMPLETE = 'INFO_VIDEO_COMPLETE';
export const INFO_VIDEO_COMPLETE_SUCCESS = 'INFO_VIDEO_COMPLETE_SUCCESS';
export const INFO_VIDEO_COMPLETE_ERROR = 'INFO_VIDEO_COMPLETE_ERROR';
export const INFO_VIDEO_COMPLETE_CLEAR = 'INFO_VIDEO_COMPLETE_CLEAR';

export const INFO_VIDEO_GET = 'INFO_VIDEO_GET';
export const INFO_VIDEO_GET_SUCCESS = 'INFO_VIDEO_GET_SUCCESS';
export const INFO_VIDEO_GET_ERROR = 'INFO_VIDEO_GET_ERROR';
export const INFO_VIDEO_GET_CLEAR = 'INFO_VIDEO_GET_CLEAR';

// INFO SESSION

export const GET_INFO_SESSION_VIDEO = 'GET_INFO_SESSION_VIDEO';
export const GET_INFO_SESSION_SUCCESS = 'GET_INFO_SESSION_SUCCESS';
export const GET_INFO_SESSION_ERROR = 'GET_INFO_SESSION_ERROR';
export const GET_INFO_SESSION_CLEAR = 'GET_INFO_SESSION_CLEAR';

// INFO VIDEO COMPLETED

export const INFO_VIDEO_COMPLETED = 'INFO_VIDEO_COMPLETED';
export const INFO_VIDEO_COMPLETED_SUCCESS = 'INFO_VIDEO_COMPLETED_SUCCESS';
export const INFO_VIDEO_COMPLETED_ERROR = 'INFO_VIDEO_COMPLETED_ERROR';
export const INFO_VIDEO_COMPLETED_CLEAR = 'INFO_VIDEO_COMPLETED_CLEAR';
