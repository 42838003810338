export const FAIR_FAX_PORTAL_API = 'ffwpmobapi/v1/portalapi';
export const FAIR_FAX_TASK_IFRAME_STATUS = 'ffwpmobapi/v1/iframeTaskStatus';
export const PORTAL_POST = 'ffwpmobapi/v1/portalPost';
export const TRAVEL_DONOR_POST = 'ffwpmobapi/v1/travelDonorPost';
export const VIDEO_PORTAL_API = 'ffwpmobapi/v1/getVideoLists'
// export const MESSAGE_GET_MSG_LIST = `messages/getmessagelist/${'id'}`;
export const MESSAGE_GET_MSG_LIST = 'messages/getmessagelist/';
export const WEB_LOGIN ='ffwpmobapi/v1/login';
export const GET_DONOR_TASK = `task/getdonortasks/`;
export const MESSAGE_GET_MSG_USER_LIST  = 'messages/getmessageuserlist/';
export const HOME_COMPLETED_DONOR = 'task/completedbydonor/';
export const UPLOAD_GET_MSG_FOR_TASK = 'task/getdonormessagesfortasks/';
export const UPLOAD_PORTAL_MSG = 'ffwpmobapi/v1/portaladdmessage/';
export const UPLOAD_PORTAL_FILE = 'ffwpmobapi/v1/portalUploadTask/';
export const UPLOAD_PORTAL_FILE_UPLOAD = 'ffwpmobapi/v1/portalfiledownload';
export const GET_MSG_INFO_LIST =  'messages/getmessageinfolist/';
export const BLOG_CATEGORY = 'wp/v2/categories';
export const BLOG_CATEGORY_LIST = 'wp/v2/posts';
export const UPDATE_PASSWORD = 'ffwpmobapi/v1/updateUserPassword';
export const VERIFIED_PIN = 'ffwpmobapi/v1/validatepin';
export const VALIDATE_MAIL = 'ffwpmobapi/v1/validateUserMail';
export const CALENDAR_DONOR_EVENT = 'calendar/getdonorevents/'
export const CALENDAR_GET_EVENT = 'calendar/getevent/';
export const CALENDAR_ADD_EVENT = 'calendar/adddonorevent';
export const BMI_CALCULATOR = `bmi/Calculate/`;
export const PERIODTRACKER_ADD_DATE = 'periodtracker/adddate';
export const PERIODTRACKER_GET_DONOR_DETAIL = 'periodtracker/getdonordetails/';
export const PERIODTRACKER_UPDATE_DATE ='periodtracker/updatedate'
// export const CALENDAR_ADD_EVENT = `api/calendar/adddonorevent + ${[post]}`;
export const PERIOD_TRACKER_DELETE_START_DATE = 'periodtracker/deletedate/';
export const ACUITY_SLOTS = 'acuity/getavailableslots/';
export const ADD_ACUITY_INTERVIEW = 'acuity/scheduleinterview/';
export const GET_MENU_PAGE = 'ffwpmobapi/v1/wpgetPageDetails';
export const SUBMIT_REFERAL_FORM = 'ffwpmobapi/v1/referalFormPosts';
export const PROGRESS_BAR_DATA = 'applicants/getdonordmastatus/';
export const UPDATE_USER_PWD = 'ffwpmobapi/v1/updateUserPwd';
export const CALENDAR_DOCTOR_LIST = 'applicants/getdoctorofficelist/';
export const CALENDAR_DOCTOR_APPOINTNTMENT_SUBMIT = 'task/scheduledoctor';
export const CALENDAR_SCHEDULE_PHOTOGRAPHER_LIST = 'task/getphotographerlist/';
export const CALENDAR_SCHEDULE_PHOTOGRAPHER_SUBMIT = 'task/schedulephotographer';
export const ACUITY_AVAIL_DAYS = `acuity/getavailabledays/`;
export const ACUITY_AUDIO_SLOTS = 'acuity/getavailableslotsaudio/';
export const ACUITY_AVAIL_AUDIO_DAYS = `acuity/getavailabledaysaudio/`;
export const CALENDAR_PSYCHOLOGIST_APPOINTNTMENT_SUBMIT = 'task/schedulepsychologist';
export const CALENDAR_SCHEDULE_PSYCHOLOGIST_LIST = 'applicants/getpsychiatristofficelist/';
export const NOTIFICATION_GET_DATA = 'notifications/getnotifications/';
export const NOTIFICATION_ACKNOWLEDGE = 'notifications/acknowledge/';
export const MY_CLINIC_GET_DATA = 'applicants/myclinic/';
export const VIDEO_GET_DATA = 'videolibrary/list/';
export const LAB_APPOINTMENT = 'task/labAppointmentDate/';
export const GET_TASK_DOCUMENT = 'task/gettaskdocuments/';
export const GET_BLOOD_DRAW_LABS = 'task/getblooddrawlabs/';
export const APPOINTMENT_SCHEDULE_INTERVIEW = 'task/appointmentscheduledinterview/';
export const PSYCHOLOOGICAL_MIND_360CONTACT = 'task/mind360contact';
export const PSYCHOLOOGICAL_SUBMIT_APPOINTMENT = 'task/schedulepsychologist';
export const PSYCHOLOGIST_LIST = 'task/getpsychiatristofficelist/';
export const GET_REMOTE_DATA_INFO = 'ffwpmobapi/v1/getExternalUrlInfo';
export const FETCH_INFO_VIDEO = 'https://vimeo.com/api/oembed.json?url=https://vimeo.com/';
export const GET_INFO_SESSION_VIDEO = 'task/infosessionvideo/';
export const POST_COMPLETED_VIDEO = 'task/completevideo/';
export const SUBMIT_PCP_OBGYN = 'task/AppointmentDateTime';
export const APPOINTMENT_SCHEDULE_CLINIC_ENTITY = 'task/appointmentscheduledclinicentity/';
export const APPOINTMENT_SCHEDULED = 'task/appointmentscheduled/';
export const APPOINTMENT_SCHEDULED_CLINIC = 'task/appointmentscheduledclinic/';
export const GET_DOCU_DOCUMENT = 'task/GetDocumentSigningUrl/';
export const TRAVEL_GET_DATA = 'tasks/';
export const TRAVEL_ADD_DATA = 'task/SaveTravelItinerary/';
export const TRAVEL_DONOR_INFO = 'travel/donordetails/';
export const TRAVEL_COMPANION_INFO = 'travel/companiondetails/';
export const TRAVEL_DONOR_ITENARY = 'travel/donoritinerary/';
export const TRAVEL_COMPANION_ITENARY = 'travel/companionitinerary/';
export const TRAVEL_MONITORING_SITES = 'travel/monitoringsites/';
export const TRAVEL_ITINERARY_LIST = 'travel/travelitinerarylist/';
export const TRAVEL_RETRIEVAL_CLINIC_DETAILS  = 'applicants/getretrievalclinicinfo/';
export const DMA_DONOR_PROFILE = 'applicants/getdmadonorprofile/';
export const TRAVEL_GET_DETAILS = 'task/gettraveldetails/';
export const TRAVEL_SCREENING_SITES = 'travel/screeningsites/';
export const TRAVEL_RETRIEVAL_SITES = 'travel/retrievalsites/';
export const DMA_LOGOUT = 'ffwpmobapi/v1/dmalogout';
