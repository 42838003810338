import {useState,useRef,useEffect} from "react";
import {
    Form,
    Button,
} from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import styles from './ForgotPass.module.scss';
import { motion } from "framer-motion";
import {BsChevronRight} from "react-icons/bs";
import AuthLayout from "../components/ui/AuthLayout";
import Alert from "../components/ui/Alert";
import Loader from "../components/ui/Loader";
import { useSelector, useDispatch } from "react-redux";
import * as Actions from "./state/login/actions";

function ForgotPass() {
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const {
        loginData: {
            verifiedPin,
            updatePass,
            validateEmail
        },
    } = useSelector((state) => state);
    const { response: verifiyPin, isLoading: loading } = verifiedPin || {};
    const { response: updatedPass, isLoading: passLoading, error: updateError } = updatePass || {};
    const { response: validatedEmail, isLoading: emailLoading, error: emailError } = validateEmail || {};
    
    const [validated, setValidated] = useState(false);
    
    const [mailSent, setMailsent] = useState(false);
    const [showPINForm, setShowPINForm] = useState(false);
    const [, setErrorPwdMismatch] = useState(false);
    const [pinValidate, setPinValidate] = useState("");
    const [, setPoorPassword] = useState("");
    const [, setLowercasePassword] = useState("");
    const [, setUppercasePassword] = useState("");
    const [, setHasDigitPassword] = useState("");
    const [userID, setUserID] = useState("");
    const [,setPwdSuccess] = useState();
    const [inValidMail,setInValidMail] = useState(false);
    const [elementValidation,setElementValidation] = useState("");
    const [, setIsLoading] = useState(false);

    let passwordError = "";
    const emailInputRef = useRef("");
    const newPassword = useRef("");
    const newRetypePassword = useRef("");
    const pinInputRef = useRef("");

    useEffect(() => {
        document.title = 'Forgot Password';
        dispatch(Actions.forgetPasswordReset());
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if(verifiyPin !== null && verifiyPin !== undefined && verifiyPin && validatedEmail && updatedPass) {
            
            if(verifiyPin === "true"){
                setMailsent(true);
                setShowPINForm(false);
            } else {
                setPinValidate(verifiyPin);
            }
        }
    }, [verifiyPin, validatedEmail, updatedPass]);

    useEffect(() => {
        if(updatedPass !== null && updatedPass !== undefined && updatedPass) {
            if(updatedPass)
            {
                setPwdSuccess("true");
                navigate("../", { state: { pwdstatus: "pwdsuccess" }, replace: true });
            }else{
                setPwdSuccess("false");
                setElementValidation("There is no user registered with that email address.");
            }
            setIsLoading(false);
        }
    }, [updatedPass]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if(updateError) {
            setElementValidation(updateError);
        }      
    },[updateError]);

    useEffect(() => {
        if(validatedEmail !== null && validatedEmail !== undefined && validatedEmail) {
            if(validatedEmail?.result?.error && !validatedEmail?.result?.userID){
                setInValidMail(true);
                setElementValidation(validatedEmail?.result?.message);
            } else {
                setInValidMail(false);
                setMailsent(true);
                setShowPINForm(false);
                setUserID(validatedEmail?.result?.userID);
            }
        }
    },[validatedEmail]);
    
    useEffect(() => {
        if(emailError) {
            setInValidMail(true);
            setElementValidation("Network Error");
        }      
    },[emailError]);

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        passwordError= "";
        setElementValidation("");
        if (form.checkValidity() === false) {
            setValidated(true);
            if(form.name === "confirmEmail")
            {
                setInValidMail(true);
                if(emailInputRef.current.validity.valueMissing === true)
                {
                    setElementValidation("Please enter your email.");
                }else if(emailInputRef.current.validity.typeMismatch === true)
                {
                    setElementValidation("Please enter a valid email address.");
                }
            }else if(form.name === "changePwd")
            {
                if(newPassword.current.value === "" || newRetypePassword.current.value === "")
                {
                    setValidated(true);
                    setErrorPwdMismatch(true);
                    passwordError= "error";
                    setElementValidation("Please enter the password.");
                }
            }
        }
        else {

            if(form.name === "changePwd"){
                const oneLowerRegExp = /(.*[a-z].*)/;
                const oneUpperRegExp = /(.*[A-Z].*)/;
                const hasDigitRegExp = /(.*\d.*)/;

                let Password = newPassword.current.value;
                let passwordValue = Password;
                let retypePassword = newRetypePassword.current.value;
                const passwordLength= Password.length;
                const lowerCase = oneLowerRegExp.test(passwordValue);
                const upperCase = oneUpperRegExp.test(passwordValue);
                const hasDigit = hasDigitRegExp.test(passwordValue);
                setErrorPwdMismatch(false);
                if(Password !== retypePassword)
                {
                    setValidated(true);
                    setErrorPwdMismatch(true);
                    passwordError= "error";
                    setElementValidation("Password Mismatch, please enter correct password.");
                }else {
                    setValidated(true);
                    if(passwordLength < 8) {
                        setPoorPassword("true");
                        passwordError= "error";
                    }else {
                        setPoorPassword("false");
                    }
                    if(lowerCase) {
                        setLowercasePassword("true");
                    }else{
                        setLowercasePassword("false");
                        passwordError= "error";
                    }
                    if(upperCase) {
                        setUppercasePassword("true");
                    }else{
                        setUppercasePassword("false");
                        passwordError= "error";
                    }
                    if(hasDigit) {
                        setHasDigitPassword("true");
                    }else {
                        setHasDigitPassword("false");
                        passwordError= "error";
                    }
                }
                if(passwordError === "")
                {
                    // call api to save the new pwd
                    let user = {userPWD: btoa(Password),userID:userID };
                    dispatch(Actions.updatePassRequest(user));
                }
            } else if(form.name === "verifyPin") {
                let pin = pinInputRef.current.value;
                let validatePIN = {Pin: btoa(pin),userID:userID };
                dispatch(Actions.verifiedPinRequest(validatePIN));
            } else {
                // validate email and show reset password
                let email = emailInputRef.current.value;
                let user = {userEmail: email};
                console.log('user' , user)
                dispatch(Actions.validateEmailRequest(user));
            }

        }
        event.preventDefault();
        event.stopPropagation();
    }
    const renderVerifyPin = () => {
        return (
            <>
                <motion.div
                    initial={{ opacity: 0, y: 50 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: .3, delay: .5 }}
                >
                    <h1 className='text-primary h3 text-center'>Verification PIN</h1>
                    <p className='alert alert-info'>Verification PIN was sent to your registered email.</p>
                </motion.div>

                <motion.div className={"p-4 " + styles.containerBg}
                            initial={{ opacity: 0, y: 50 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: .3, delay: 1 }}
                >
                    {pinValidate !== "true" && pinValidate !== "" &&
                        <p className='alert alert-danger'>{pinValidate}</p>
                    }

                    <Form className="auth-form" name='verifyPin' noValidate validated={validated} onSubmit={handleSubmit}>
                        <Form.Group className='mb-3'>
                            <Form.Label className={styles.subTitle}>Please enter verification PIN</Form.Label>
                            <Form.Control required ref={pinInputRef} type='pin' placeholder='Enter PIN'></Form.Control>
                        </Form.Group>
                        <div className='text-center'>
                            <Button variant="danger" type="submit" className="px-3">
                                Validate PIN
                            </Button>
                        </div>
                    </Form>
                </motion.div>
            </>
        );
    }

    const renderForgotPassword = () => {
        return (
            <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: .3, delay: .5 }}
                className={"py-3 px-4 " + styles.containerBg}
            >
                {inValidMail === true &&
                    <Alert message={elementValidation} />
                }

                <Form className="auth-form" name='confirmEmail' noValidate validated={validated} onSubmit={handleSubmit}>
                    <h1 className={styles.title}>Forgot Password</h1>
                    <Form.Group className='mb-5'>
                        <Form.Label className={`${styles.context} mb-4`}>
                            Enter your email address and we will send you an email with a link to reset your password.
                        </Form.Label>
                        <Form.Control required ref = {emailInputRef} type='email' placeholder='Email' {...inValidMail && {'isInvalid' : 'true'}}></Form.Control>
                    </Form.Group>
                    <motion.div className="d-flex justify-content-between align-items-center mt-3 auth-button"
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                transition={{ duration: .3, delay: 1 }}
                    >
                        <div className="text-white text-decoration-underline cursor-pointer" onClick={() => navigate(-1)}>Cancel</div>
                        <Button variant="primary" type="submit" className="d-flex align-items-center">
                            Reset Password
                            <motion.label className="btn-icon"
                                initial={{ opacity: 0, scale: 0 }}
                                animate={{ opacity: 1, scale: 1 }}
                                transition={{ duration: 0.3 , delay: 1 }}
                            >
                                <BsChevronRight/>
                            </motion.label>
                        </Button>
                    </motion.div>
                </Form>
            </motion.div>
        );
    }

    const renderResetConfirmation = () => {
        return (
            <motion.div className={"py-3 px-4 " + styles.containerBg}
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: .3, delay: .5 }}
            >
                <h1 className={`${styles.title} mb-3`}>Forgot Password</h1>
                <p style={{minHeight:"200px",color:"#fff"}}>Check your email to complete your password reset, then log in.</p>
                <motion.div className="d-flex justify-content-end align-items-center mt-3 auth-button"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: .3, delay: 1 }}
                >
                <Button variant="primary" type="button" onClick={()=>navigate("/")} className="d-flex align-items-center">
                    Login
                    <motion.label className="btn-icon"
                        initial={{ opacity: 0, scale: 0 }}
                        animate={{ opacity: 1, scale: 1 }}
                        transition={{ duration: 0.3 , delay: 1 }}
                    >
                        <BsChevronRight/>
                    </motion.label>
                </Button>
                </motion.div>
            </motion.div>
        )
    }
    
    if(loading || passLoading|| emailLoading){
        return <Loader/>;
    }

    return (
            <AuthLayout>
                {showPINForm === true && !mailSent &&
                    renderVerifyPin()
                }

                {!mailSent && !showPINForm &&
                    renderForgotPassword()
                }

                {mailSent &&
                    renderResetConfirmation()
                }
            </AuthLayout>
    )
}

export default ForgotPass;
