
import { combineReducers } from "redux";
import { getBasicReducer ,getBasicSelectReducer} from "../../../core/utils";
import * as Constants from "./constants";

export const calendarData = combineReducers({
    donorEvent: getBasicReducer({
        request: Constants.CALENDAR_GET_DONOR_EVENT_REQUEST,
        success: Constants.CALENDAR_GET_DONOR_EVENT_SUCCESS,
        failure: Constants.CALENDAR_GET_DONOR_EVENT_ERROR,
        clear: Constants.CALENDAR_GET_DONOR_EVENT_CLEAR,
    }),
    getEvent: getBasicReducer({
        request: Constants.CALENDAR_GET_EVENT_REQUEST,
        success: Constants.CALENDAR_GET_EVENT_SUCCESS,
        failure: Constants.CALENDAR_GET_EVENT_ERROR,
        clear: Constants.CALENDAR_GET_EVENT_CLEAR,
    }),
    addDonorEvent: getBasicReducer({
        request: Constants.CALENDAR_ADD_DONOR_EVENT_REQUEST,
        success: Constants.CALENDAR_ADD_DONOR_EVENT_SUCCESS,
        failure: Constants.CALENDAR_ADD_DONOR_EVENT_ERROR,
        clear: Constants.CALENDAR_ADD_DONOR_EVENT_CLEAR,
    }),
    deleteDonorEvent: getBasicReducer({
        request: Constants.CALENDAR_DELETE_DONOR_EVENT_REQUEST,
        success: Constants.CALENDAR_DELETE_DONOR_EVENT_SUCCESS,
        failure: Constants.CALENDAR_DELETE_DONOR_EVENT_ERROR,
        clear: Constants.CALENDAR_DELETE_DONOR_EVENT_CLEAR,
    }),
    doctorList: getBasicReducer({
        request: Constants.CALENDAR_GET_DOCTOR_LIST_REQUEST,
        success: Constants.CALENDAR_GET_DOCTOR_LIST_SUCCESS,
        failure: Constants.CALENDAR_GET_DOCTOR_LIST_ERROR,
        clear: Constants.CALENDAR_GET_DOCTOR_LIST_CLEAR,
    }),
    scheduleDoctor: getBasicReducer({
        request: Constants.CALENDAR_TASK_SCHEDULER_DOCTOR_REQUEST,
        success: Constants.CALENDAR_TASK_SCHEDULER_DOCTOR_SUCCESS,
        failure: Constants.CALENDAR_TASK_SCHEDULER_DOCTOR_ERROR,
        clear: Constants.CALENDAR_TASK_SCHEDULER_DOCTOR_CLEAR,
    }),
    photoGrapherList: getBasicReducer({
        request: Constants.CALENDAR_PHOTOGRAPHER_LIST_REQUEST,
        success: Constants.CALENDAR_PHOTOGRAPHER_LIST_SUCCESS,
        failure: Constants.CALENDAR_PHOTOGRAPHER_LIST_ERROR,
        clear: Constants.CALENDAR_PHOTOGRAPHER_LIST_CLEAR,
    }),
    schedulePhotoGraphy: getBasicReducer({
        request: Constants.CALENDAR_SCHEDULE_PHOTOGRAPHER_REQUEST,
        success: Constants.CALENDAR_SCHEDULE_PHOTOGRAPHER_SUCCESS,
        failure: Constants.CALENDAR_SCHEDULE_PHOTOGRAPHER_ERROR,
        clear: Constants.CALENDAR_SCHEDULE_PHOTOGRAPHER_CLEAR,
    }),
    psychologist: getBasicReducer({
        request: Constants.CALENDAR_SCHEDULE_PSYCHOLOGIST_REQUEST,
        success: Constants.CALENDAR_SCHEDULE_PSYCHOLOGIST_SUCCESS,
        failure: Constants.CALENDAR_SCHEDULE_PSYCHOLOGIST_ERROR,
        clear: Constants.CALENDAR_SCHEDULE_PSYCHOLOGIST_CLEAR,
    }),
    psychologistList: getBasicReducer({
        request: Constants.CALENDAR_PSYCHOLOGIST_LIST_REQUEST,
        success: Constants.CALENDAR_PSYCHOLOGIST_LIST_SUCCESS,
        failure: Constants.CALENDAR_PSYCHOLOGIST_LIST_ERROR,
        clear: Constants.CALENDAR_PSYCHOLOGIST_LIST_CLEAR,
    }),

    /*period tracker  */
    pTaddDate: getBasicReducer({
        request: Constants.PERIOD_TRACKER_ADD_DATE_REQUEST,
        success: Constants.PERIOD_TRACKER_ADD_DATE_SUCCESS,
        failure: Constants.PERIOD_TRACKER_ADD_DATE_ERROR,
        clear: Constants.PERIOD_TRACKER_ADD_DATE_CLEAR,
    }),
    pTgetDonarDetail: getBasicReducer({
        request: Constants.PERIOD_TRACKER_GET_DONAR_DETAIL_REQUEST,
        success: Constants.PERIOD_TRACKER_GET_DONAR_DETAIL_SUCCESS,
        failure: Constants.PERIOD_TRACKER_GET_DONAR_DETAIL_ERROR,
        clear: Constants.PERIOD_TRACKER_GET_DONAR_DETAIL_CLEAR,
    }),
    pTupdateDate: getBasicReducer({
        request: Constants.PERIOD_TRACKER_UPDATE_DATE_REQUEST,
        success: Constants.PERIOD_TRACKER_UPDATE_DATE_SUCCESS,
        failure: Constants.PERIOD_TRACKER_UPDATE_DATE_ERROR,
        clear: Constants.PERIOD_TRACKER_UPDATE_DATE_CLEAR,
    }),
    deleteStartDate: getBasicReducer({
        request: Constants.PERIOD_TRACKER_DELETE_SDATE_REQUEST,
        success: Constants.PERIOD_TRACKER_DELETE_SDATE_SUCCESS,
        failure: Constants.PERIOD_TRACKER_DELETE_SDATE_ERROR,
        clear: Constants.PERIOD_TRACKER_DELETE_SDATE_CLEAR,
    }),
    periodStatus : getBasicSelectReducer ({
        select: Constants.PERIOD_STATUS_SET,
        clear: Constants.PERIOD_STATUS_CLEAR,
    })
});