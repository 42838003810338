// Tasks
export const BG_TASK_FETCH_REQUEST = 'BG_TASK_FETCH_REQUEST';
export const BG_TASK_FETCH_SUCCESS = 'BG_TASK_FETCH_SUCCESS';
export const BG_TASK_FETCH_ERROR = 'BG_TASK_FETCH_ERROR';
export const BG_TASK_FETCH_CLEAR = 'BG_TASK_FETCH_CLEAR';

// Notifications
export const BG_NOTIFICATION_GET_REQUEST = 'BG_NOTIFICATION_GET_REQUEST';
export const BG_NOTIFICATION_GET_SUCCESS = 'BG_NOTIFICATION_GET_SUCCESS';
export const BG_NOTIFICATION_GET_ERROR = 'BG_NOTIFICATION_GET_ERROR';
export const BG_NOTIFICATION_GET_CLEAR = 'BG_NOTIFICATION_GET_CLEAR';

// Calendar Events
export const BG_CALENDAR_GET_DONOR_EVENT_REQUEST = 'BG_CALENDAR_GET_DONOR_EVENT_REQUEST';
export const BG_CALENDAR_GET_DONOR_EVENT_SUCCESS = 'BG_CALENDAR_GET_DONOR_EVENT_SUCCESS';
export const BG_CALENDAR_GET_DONOR_EVENT_ERROR = 'BG_CALENDAR_GET_DONOR_EVENT_ERROR';
export const BG_CALENDAR_GET_DONOR_EVENT_CLEAR = 'BG_CALENDAR_GET_DONOR_EVENT_CLEAR';

// Messages
export const BG_MESSAGE_GET_LIST_REQUEST = 'BG_MESSAGE_GET_LIST_REQUEST';
export const BG_MESSAGE_GET_LIST_SUCCESS = 'BG_MESSAGE_GET_LIST_SUCCESS';
export const BG_MESSAGE_GET_LIST_ERROR = 'BG_MESSAGE_GET_LIST_ERROR';
export const BG_MESSAGE_GET_LIST_CLEAR = 'BG_MESSAGE_GET_LIST_CLEAR';
