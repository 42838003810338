import Form from "react-bootstrap/Form";

const Checkbox = (props) => {
    const {id, label, type, checked = false, onChange} = props;
    return (
        <Form.Check
            checked ={checked}
            aria-label={label}
            id={id}
            type={type}
            onClick= {onChange}
            key={id}
        />
    );
};
export default Checkbox;
