
import { combineReducers } from "redux";
import { getBasicReducer } from "../../../core/utils";
import * as Constants from "./constants";

export const travelData = combineReducers({
    travelList: getBasicReducer({
        request: Constants.TRAVEL_GET_LIST_REQUEST,
        success: Constants.TRAVEL_GET_LIST_SUCCESS,
        failure: Constants.TRAVEL_GET_LIST_ERROR,
        clear: Constants.TRAVEL_GET_LIST_CLEAR,
    }),
    addTravel: getBasicReducer({
        request: Constants.ADD_TRAVEL_REQUEST,
        success: Constants.ADD_TRAVEL_SUCCESS,
        failure: Constants.ADD_TRAVEL_ERROR,
        clear: Constants.ADD_TRAVEL_CLEAR,
    }),
    travelDetails: getBasicReducer({
        request: Constants.TRAVEL_GET_DETAILS_REQUEST,
        success: Constants.TRAVEL_GET_DETAILS_SUCCESS,
        failure: Constants.TRAVEL_GET_DETAILS_ERROR,
        clear: Constants.TRAVEL_GET_DETAILS_CLEAR,
    }),
    travelItineraries: getBasicReducer({
        request: Constants.TRAVEL_GET_ITINERY_REQUEST,
        success: Constants.TRAVEL_GET_ITINERY_SUCCESS,
        failure: Constants.TRAVEL_GET_ITINERY_ERROR,
        clear: Constants.TRAVEL_GET_ITINERY_CLEAR,
    }),
    travelDetailsData: getBasicReducer({
        request: Constants.TRAVEL_DETAILS_DATA,
        success: Constants.TRAVEL_DETAILS_DATA_SUCCESS,
        failure: Constants.TRAVEL_DETAILS_DATA_ERROR,
        clear: Constants.TRAVEL_DETAILS_DATA_CLEAR,
    }),
    completedTravelHandout: getBasicReducer({
        request: Constants.TRAVEL_HANDOUT_APPROVE_REQUEST,
        success: Constants.TRAVEL_HANDOUT_APPROVE_SUCCESS,
        failure: Constants.TRAVEL_HANDOUT_APPROVE_ERROR,
        clear: Constants.TRAVEL_HANDOUT_APPROVE_CLEAR,
    })
});
