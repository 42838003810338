import { call, put, takeLatest } from "redux-saga/effects";
import { Endpoints } from '../../../core/networking';
import * as Constants from "./constants";
import * as Actions from "./actions";
import AxiosInstance from "../../../services/AxiosInstance";
import { ERROR_TYPES } from '../types';


export function* watchUserSaga() {
  yield takeLatest(Constants.LOGIN_USER_REQUEST, logInRequest);
  yield takeLatest(Constants.LOGIN_RESET_REQUEST, logOut);
  yield takeLatest(Constants.VERIFIED_PIN_REQUEST, verifiedPin);
  yield takeLatest(Constants.UPDATE_PASSWORD_REQUEST, updatePassword);
  yield takeLatest(Constants.VALIDATE_MAIL_REQUEST, validateMail);
  yield takeLatest(Constants.UPDATED_PASSWORD_RESET , clearPasword);
  yield takeLatest(Constants.REJECT_STATUS_REQUEST, donorRejected);
}

export function* logInRequest(action) {
  try {
    const pramas = action.payload;
    const response = yield call(loginNetworkCall, pramas);
    if (response.status === 200) {
      yield put(Actions.loginUserSuccess(response));
    }else {
      yield put(
        Actions.loginUserError({
          errorCode: ERROR_TYPES.INVALID_DATA,
          message: ERROR_TYPES.INVALID_DATA,
        })
      );
    }
  } catch (error) {
    if(error?.response?.status === 403){
      yield put(
        Actions.loginUserError({
          errorCode: error.response.statusText,
          message: error.response.data.message

        })
      );
    }else {
      yield put(Actions.loginUserError(error));
    }
  }
}


export function loginNetworkCall(user){
  const url = Endpoints.WEB_LOGIN;
  const response =  AxiosInstance.post(url, {user});
  return response;
}


export function* logOut() {
  try {
    yield put(Actions.loginUserClear())

  }catch(error){
    console.log('---error---', error)
  }
}

/* forget password screen  */
export function* verifiedPin(action) {
  try {
    const pramas = action.payload;
    const response = yield call(pinVerifyNetwork, pramas);
    if (response.status === 200) {
      yield put(Actions.verifiedPinSuccess(response.data));
    } else {
      yield put(
        Actions.verifiedPinError({
          errorCode: ERROR_TYPES.INVALID_DATA,
          message: ERROR_TYPES.INVALID_DATA,
        })
      );
    }
  } catch (error) {
    yield put(Actions.verifiedPinError(error));
  }
}
export function pinVerifyNetwork(validatePIN){
  const url = Endpoints.VERIFIED_PIN;
  const response =  AxiosInstance.post(url, { validatePIN });
  return response;
}

export function* updatePassword(action) {
  try {
    const pramas = action.payload;
    const response = yield call(updatePassNetwork, pramas);
    if (response.status === 200) {
      yield put(Actions.updatePassSuccess(response.data));
    } else {
      yield put(
        Actions.updatePassError({
          errorCode: ERROR_TYPES.INVALID_DATA,
          message: ERROR_TYPES.INVALID_DATA,
        })
      );
    }
  } catch (error) {
    yield put(Actions.updatePassError(error));
  }
}
export function updatePassNetwork(user){
  const url = Endpoints.UPDATE_PASSWORD;
  const response =  AxiosInstance.post(url, { user });
  return response;
}

export function* validateMail(action) {
  try {
    const pramas = action.payload;
    const response = yield call(validateMailNetwork, pramas);
    if (response.status === 200) {
      yield put(Actions.validateEmailSuccess(response.data));
    } else {
      yield put(
        Actions.validateEmailError({
          errorCode: ERROR_TYPES.INVALID_DATA,
          message: ERROR_TYPES.INVALID_DATA,
        })
      );
    }
  } catch (error) {
    yield put(Actions.validateEmailError(error));
  }
}
export function validateMailNetwork(user){
  const url = Endpoints.VALIDATE_MAIL;
  const response =  AxiosInstance.post(url, {user});
  return response;
}


export function* clearPasword() {
  try {
    yield put(Actions.validateEmailClear());
    yield put(Actions.updatePassClear());
    yield put(Actions.verifiedPinClear())

  }catch(error){
    console.log('---error---', error)
  }
}

export function* donorRejected(action) {
    try {
        const params = action.payload;
        const response = yield call(rejectStatusNetworkCall, params);
        if (response.status === 200) {
            yield put(Actions.rejectStatusSuccess(response.data));
        } else {
            yield put(
                Actions.rejectStatusError({
                    errorCode: ERROR_TYPES.INVALID_DATA,
                    message: ERROR_TYPES.INVALID_DATA,
                })
            );
        }
    } catch (error) {
        yield put(Actions.rejectStatusError(error));
    }
}

export function rejectStatusNetworkCall(params){
    return AxiosInstance.post(Endpoints.FAIR_FAX_PORTAL_API, {params});
}
