import React, { useEffect, useRef, useState } from "react";
import styles from "./Schedular.module.scss";
import { useNavigate, useLocation } from "react-router-dom";
import { format } from "date-fns";
import { isEmpty } from "lodash";
import { Scrollbars } from "react-custom-scrollbars-2";
import { useSelector, useDispatch } from "react-redux";
import * as Actions from "../state/calendar/actions";
import { Endpoints } from "../../core/networking";
import Loader from "../../components/ui/Loader";
import SheduleCalendar from "./SheduleCalendar";
import BookingList from "./BookingList";
import ListHeader from "./ListHeader";
import { BsChevronRight } from "react-icons/bs";
import { TASK_SUB_TYPE, TASK_TYPE_ID } from "./type";
import TimeZone from "../components/TimeZone";
import TaskDescription from "../components/TaskDescription";
import { GetPhotoInstructions } from '../../services/Common';
import { createPortal } from "react-dom";
import { motion } from "framer-motion";

const Schedular = () => {
    const { state } = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const { taskname, taskInfo } = state || {};
    const {
        loginData: { loginUser },
        calendarData: {
            photoGrapherList,
            schedulePhotoGraphy,
        },
    } = useSelector((state) => state);
    const { response: photoGraphList, isLoading: photoGhraphLoading } =
        photoGrapherList || {};
    const { response: photoGhraphy, isLoading: schedulePhotoLoading } =
        schedulePhotoGraphy || {};
    const { response: loginVal } = loginUser || {};
    const [, setHeight] = useState(0);
    const [scrollHeight, setScrollHeight] = useState(0);
    const [selectedValue, setSelectedValue] = useState(0);
    const [startDate, setStartDate] = useState();
    const [listOfTask, setListOfTask] = useState([]);
    const [typeOfBooking, setTypeOfBooking] = useState("");
    const [bookingName, setBookingName] = useState("");
    const [handleAppoin, setHandleAppoin] = useState(false);
    const [timeZone, setTimeZone] = useState(0);
    const containerRef = useRef(null);
    const [pageMsg, setPageMsg] = useState("");
    const [, setPageTitle] = useState("");
    const [photoInstructions, setPhotoInstructions] = useState(false);
    const [showSchedularModal, setShowSchedularModal] = useState(false);

    useEffect(() => {
        dispatch(Actions.schedulePhotoGraphyClear());
        setShowSchedularModal(false);
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setHeight(containerRef?.current?.clientHeight);
        dispatch(Actions.schedulePhotoGraphyClear());
        //let applicantID = loginVal?.data?.applicant_id;
        if (loginVal?.data?.applicant_id !== "" && taskInfo?.ID !=="") {

            switch (true) {
                case (taskInfo?.TaskType === TASK_TYPE_ID.TASK_SCHEDULE &&
                    taskInfo?.TaskSubType === TASK_SUB_TYPE.SCHEDULE_PHOTO_SHOOT_4) || taskInfo?.SubTaskType ===
                    TASK_SUB_TYPE.SCHEDULE_PHOTO_SHOOT_4: {
                        const taskEndPoint =
                            Endpoints.CALENDAR_SCHEDULE_PHOTOGRAPHER_LIST +
                            taskInfo?.ID;
                        let params = {
                            endpoint: taskEndPoint,
                            method: "GET",
                        };
                        setTypeOfBooking("Photographer");
                        setBookingName('photo shoot');
                        setPhotoInstructions(true);
                        dispatch(Actions.photoGrapherListRequest(params));
                        break;
                    }
                default:
                    break;
            }
        }
    }, [taskInfo]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (location?.state?.taskInfo)
            setPageTitle(location.state.taskname === '' ? location?.state?.taskInfo?.TaskName : location.state.taskname);
        if (location?.state?.taskInfo?.IsRejected)
            setPageMsg(location?.state?.taskInfo?.RejectReason);
        else
            setPageMsg(location?.state?.taskInfo?.Comment);
    }, [location?.state?.taskInfo, location.state.taskname]);

    const getSchedularHeight = () => {
        setScrollHeight(window.innerHeight);
    };

    useEffect(() => {
        if (!isEmpty(photoGhraphy)) {
            navigate(-1);
        }
    }, [photoGhraphy]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!isEmpty(photoGraphList)) {
            setListOfTask(photoGraphList);
        }
    }, [photoGraphList]);

    useEffect(() => {
        getSchedularHeight();
    }, [selectedValue]);

    function handleGoBack() {
        navigate(-1);
    }

    const handleSubmit = () => {
        const formData = new FormData();
        let applicantID = loginVal?.data?.applicant_id;
        formData.append("ApplicantID", applicantID);
        formData.append("TaskID", taskInfo?.AssociatedID ? taskInfo?.AssociatedID : taskInfo.ID);
        formData.append("ClinicEntityID", selectedValue?.ClinicEntityID);
        formData.append("TimezoneID", timeZone);
        formData.append("AppointmentDateTime", format(startDate, "yyyy-MM-dd'T'HH:mm:ss"));
        formData.append("endpoint", Endpoints.CALENDAR_SCHEDULE_PHOTOGRAPHER_SUBMIT);
        dispatch(Actions.schedulePhotoGraphyRequest(formData));
    };
    const handleDate = (date) => {
        setStartDate(date);
    };
    const handleAppointment = () => {
       setShowSchedularModal(true);
    }
    const selectedTimeZone = (zone) => {
        setTimeZone(zone);
    }

    const goToSchedule = () => {
        setShowSchedularModal(false);
        setHandleAppoin(!handleAppoin);
    }
    const closePopup = () => {
        setShowSchedularModal(false);
    }
    return (
        <>
            {
                (photoGhraphLoading || schedulePhotoLoading ) && (<Loader />)
            }
            {
                showSchedularModal && createPortal(
                    <div className={`${styles.schModalContainer} position-absolute w-100 top-0 start-0 end-0 bottom-0`}>
                        <motion.div className={`${styles.psychModal} position-absolute top-50`}
                            initial={{ opacity: 0, scale: 0.5, translateY: '-50%' }}
                            animate={{ opacity: 1, scale: 1, translateY: '-50%' }}
                            transition={{ duration: 0.3 }}
                        >
                            <div className={styles.psychModal} >
                                <div className={styles.modalHeader}>
                                    Have you scheduled an appointment?
                                </div>
                                <div className={styles.modalDetails}>
                                    If not, contact the provider to schedule an appointment before entering details
                                </div>
                                <div className={styles.modalFooter}>
                                    <input type="button" onClick={() => goToSchedule()} className={styles.footerbutton} value="Yes" />
                                    <input type="button" onClick={() => closePopup()} className={styles.footerbutton} value="No" />
                                </div>
                            </div>
                        </motion.div>
                    </div>,
                    document.querySelector('.app')
                )
            }
            <>
                <ListHeader
                    taskname={taskname}
                    handleNavigation={handleGoBack}
                    title={
                        <>
                          {(!handleAppoin && pageMsg) && (
                            <TaskDescription styles={`px-0 py-0`} taskDetails={taskInfo}/>
                            )}
                        </>
                      }
                    // titleLine={handleAppoin ? '' : bookingName}
                    appointmentShow={false}
                />

                {
                    handleAppoin ?
                    <Scrollbars
                        autoHeight
                        autoHeightMax={scrollHeight - 100}
                        className="scroll-container"
                        >
                            <div className="mt-2 py-3 px-3">
                                <div style={{fontSize:"18px"} }>
                                    <strong>Call, email or go online to schedule an appointment.</strong>
                                    <p>Enter the date and time of your scheduled appointment.</p>
                                    {
                                        (photoInstructions) &&
                                        <div className={styles.classPhotoInst}>
                                            {GetPhotoInstructions()}
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className={`position-relative`} id="doctorContainer">
                                <BookingList
                                    id={`check_${selectedValue.ClinicEntityID}`}
                                    name={selectedValue.Name}
                                    address={null} /* removed the address and website as per card 6402*/
                                    city={null}
                                    zip={null}
                                    phone={selectedValue.Phone}
                                    email={selectedValue.Email}
                                    regionCode={null}
                                    website={null}
                                    schedulingUrl={selectedValue.SchedulingURL}
                                    schedulingIns={selectedValue.SchedulingInstructions}
                                    hideCheckBox={handleAppoin}
                                    selectedValue={selectedValue}
                                />
                            </div>
                            <div className={` mt-4 `}>
                                <TimeZone class={"timeZoneContainer"} selectedTimeZone={selectedTimeZone} />
                                <SheduleCalendar
                                    handleDate={handleDate}
                                    startDate={startDate}
                                    selectedDoctor={timeZone}
                                    handleSubmit={() => handleSubmit()}
                                />
                            </div>
                    </Scrollbars>
                    :
                        <>
                            <div className="px-3 py-3" style={{fontSize:"18px"} }>
                                <p><strong>Call or email Photographer directly to schedule an appointment.</strong></p>
                                Select a convenient {typeOfBooking.toLowerCase()} for your
                                {bookingName && <span className="ms-1">{bookingName}</span>}.
                            </div>
                            <div className={`position-relative`} id="doctorContainer">
                                <Scrollbars
                                    autoHeight
                                    autoHeightMax={scrollHeight - 400}
                                    className="scroll-container"
                                >
                                    <ul ref={containerRef} className={styles.docUL}>
                                        {listOfTask?.map((val) => {
                                            return (
                                                <BookingList
                                                    key={val.ClinicEntityID}
                                                    checkedValue={
                                                        val.ClinicEntityID ===
                                                        selectedValue.ClinicEntityID
                                                    }
                                                    id={`check_${val.ClinicEntityID}`}
                                                    lable={val.Name}
                                                    type={"checkbox"}
                                                    onHandleCheckBox={() =>
                                                        setSelectedValue(val)
                                                    }
                                                    name={val.Name}
                                                    address={null} /* removed the address and website as per card 6402*/
                                                    city={null}
                                                    zip={null}
                                                    phone={val.Phone}
                                                    regionCode={null}
                                                    email={val.Email}
                                                    website={null}
                                                    schedulingUrl={val.SchedulingURL}
                                                    schedulingIns={val.SchedulingInstructions}
                                                    hideCheckBox={handleAppoin}
                                                />
                                            );
                                        })}
                                    </ul>
                                </Scrollbars>
                            </div>
                            <div className={` d-flex justify-content-end pe-4 mt-4 mb-4 ${styles.submit} `}>
                                <button
                                    className={`${styles.button} position-relative px-4`}
                                    onClick={handleAppointment}
                                    disabled={selectedValue === 0}
                                >
                                    Enter Appointment Details &nbsp;{" "}
                                    <BsChevronRight className={styles.buttonIcon} />
                                </button>
                            </div>
                        </>
                }
            </>
        </>
    );
};
export default Schedular;
