import { takeLatest, put, call } from "redux-saga/effects";
import {Endpoints} from '../../../core/networking';
import * as Constants from "./constants";
import * as Actions from "./actions";
import AxiosInstance from "../../../services/AxiosInstance";
import {ERROR_TYPES} from '../types';

export function* watchTravelDonorSaga() {
    yield takeLatest(Constants.TRAVEL_GET_LIST_REQUEST, travelListRequest);
    yield takeLatest(Constants.ADD_TRAVEL_REQUEST, addTravelRequest);
    yield takeLatest(Constants.TRAVEL_GET_DETAILS_REQUEST, travelDonorRequest);
    yield takeLatest(Constants.TRAVEL_GET_ITINERY_REQUEST, travelItineraryRequest);
    yield takeLatest(Constants.TRAVEL_DETAILS_DATA, travelDetailsData);
    yield takeLatest(Constants.TRAVEL_HANDOUT_APPROVE_REQUEST, sendApproveRequest);
}

export function* sendApproveRequest(action) {
    try {
        const pramas = action.payload;
        const response = yield call(taskNetworkRequest, pramas);
        if (response.status === 200) {
            yield put(Actions.sendToApproveSuccess(response));
        } else {
            yield put(
                Actions.sendToApproveError({
                    errorCode: ERROR_TYPES.INVALID_DATA,
                    message: ERROR_TYPES.INVALID_DATA,
                })
            );
        }
    } catch (error) {
        yield put(Actions.sendToApproveError(error));
    }
}

export function* travelItineraryRequest(action) {
    try {
        const pramas = action.payload;
        const response = yield call(taskNetworkRequest, pramas);
        if (response.status === 200) {
            yield put(Actions.getTravelItinerarySuccess(response.data));
        } else {
            yield put(
                Actions.getTravelItineraryError({
                    errorCode: ERROR_TYPES.INVALID_DATA,
                    message: ERROR_TYPES.INVALID_DATA,
                })
            );
        }
    } catch (error) {
        yield put(Actions.getTravelItineraryError(error));
    }
}

export function* travelListRequest(action) {
  try {
    const pramas = action.payload;
    const response = yield call(taskNetworkRequest, pramas); 
    if (response.status === 200) {
      yield put(Actions.travelListSuccess(response.data));
    } else {
      yield put(
        Actions.travelListError({
          errorCode: ERROR_TYPES.INVALID_DATA,
          message: ERROR_TYPES.INVALID_DATA,
        })
      );
    }
  } catch (error) {
    yield put(Actions.travelListError(error));
  }
}

export function* travelDetailsData(action) {
    try {
    const pramas = action.payload;
    const response = yield call(taskNetworkRequest, pramas); 
    if (response.status === 200) {
      yield put(Actions.getTravelDetailsSuccess(response.data));
    } else {
      yield put(
        Actions.getTravelDetailsError({
          errorCode: ERROR_TYPES.INVALID_DATA,
          message: ERROR_TYPES.INVALID_DATA,
        })
      );
    }
  } catch (error) {
    yield put(Actions.getTravelDetailsError(error));
  }
}

export function* travelDonorRequest(action) {
    try {
        const pramas = action.payload;
        const response = yield call(taskNetworkRequest, pramas);
        if (response.status === 200) {
            yield put(Actions.donorListSuccess(response.data));
        } else {
            yield put(
                Actions.donorListError({
                    errorCode: ERROR_TYPES.INVALID_DATA,
                    message: ERROR_TYPES.INVALID_DATA,
                })
            );
        }
    } catch (error) {
        yield put(Actions.donorListError(error));
    }
}

export function taskNetworkRequest(params){
  const url = Endpoints.FAIR_FAX_PORTAL_API;
  const response =  AxiosInstance.post(url, { params });
  return response;
}

export function* addTravelRequest(action) {
  try {
    const response = yield call(addTravelNetRequest, action.payload);
    if (response.status === 200) {
      yield put(Actions.addTravelSuccess(response));
    } else {
      yield put(
        Actions.addTravelError({
          errorCode: ERROR_TYPES.INVALID_DATA,
          message: ERROR_TYPES.INVALID_DATA,
        })
      );
    }
  } catch (error) {
    yield put(Actions.addTravelError(error));
  }
}

export function addTravelNetRequest(params){
  const url = Endpoints.TRAVEL_DONOR_POST;
  const response =  AxiosInstance.post(url, params );
  return response;
}
