import * as Constants from './constants';

export const getDonorEventRequest = (data) => ({
    type: Constants.CALENDAR_GET_DONOR_EVENT_REQUEST,
    payload: data,
});
export const getDonorEventSuccess = (data) => ({
    type: Constants.CALENDAR_GET_DONOR_EVENT_SUCCESS,
    payload: data,
});
export const getDonorEventError = (error) => ({
    type: Constants.CALENDAR_GET_DONOR_EVENT_ERROR,
    payload: error,
});
export const getDonorEventClear = () => ({
    type: Constants.CALENDAR_GET_DONOR_EVENT_CLEAR,
});

export const getEventRequest= (data) => ({
    type: Constants.CALENDAR_GET_EVENT_REQUEST,
    payload: data,
});
export const getEventSuccess = (data) => ({
    type: Constants.CALENDAR_GET_EVENT_SUCCESS,
    payload: data,
});
export const getEventError = (error) => ({
    type: Constants.CALENDAR_GET_EVENT_ERROR,
    payload: error,
});
export const getEventClear = () => ({
    type: Constants.CALENDAR_GET_EVENT_CLEAR,
});

export const addDonorEventRequest= (data) => ({
    type: Constants.CALENDAR_ADD_DONOR_EVENT_REQUEST,
    payload: data,
});
export const addDonorEventSuccess = (data) => ({
    type: Constants.CALENDAR_ADD_DONOR_EVENT_SUCCESS,
    payload: data,
});
export const addDonorEventError = (error) => ({
    type: Constants.CALENDAR_ADD_DONOR_EVENT_ERROR,
    payload: error,
});
export const addDonorEventClear = () => ({
    type: Constants.CALENDAR_ADD_DONOR_EVENT_CLEAR,
});
export const getDoctorListRequest= (data) => ({
    type: Constants.CALENDAR_GET_DOCTOR_LIST_REQUEST,
    payload: data,
});
export const getDoctorListSuccess = (data) => ({
    type: Constants.CALENDAR_GET_DOCTOR_LIST_SUCCESS,
    payload: data,
});
export const getDoctorListError = (error) => ({
    type: Constants.CALENDAR_GET_DOCTOR_LIST_ERROR,
    payload: error,
});
export const getDoctorListClear = () => ({
    type: Constants.CALENDAR_GET_DOCTOR_LIST_CLEAR,
});
export const doctorAppointSubmitRequest= (data) => ({
    type: Constants.CALENDAR_TASK_SCHEDULER_DOCTOR_REQUEST,
    payload: data,
});
export const doctorAppointSubmitSuccess = (data) => ({
    type: Constants.CALENDAR_TASK_SCHEDULER_DOCTOR_SUCCESS,
    payload: data,
});
export const doctorAppointSubmitError = (error) => ({
    type: Constants.CALENDAR_TASK_SCHEDULER_DOCTOR_ERROR,
    payload: error,
});
export const doctorAppointSubmitClear = () => ({
    type: Constants.CALENDAR_TASK_SCHEDULER_DOCTOR_CLEAR,
});
export const schedulePhotoGraphyRequest= (data) => ({
    type: Constants.CALENDAR_SCHEDULE_PHOTOGRAPHER_REQUEST,
    payload: data,
});
export const schedulePhotoGraphySuccess = (data) => ({
    type: Constants.CALENDAR_SCHEDULE_PHOTOGRAPHER_SUCCESS,
    payload: data,
});
export const schedulePhotoGraphyError = (error) => ({
    type: Constants.CALENDAR_SCHEDULE_PHOTOGRAPHER_ERROR,
    payload: error,
});
export const schedulePhotoGraphyClear = () => ({
    type: Constants.CALENDAR_SCHEDULE_PHOTOGRAPHER_CLEAR,
});

export const photoGrapherListRequest= (data) => ({
    type: Constants.CALENDAR_PHOTOGRAPHER_LIST_REQUEST,
    payload: data,
});
export const photoGrapherListSuccess = (data) => ({
    type: Constants.CALENDAR_PHOTOGRAPHER_LIST_SUCCESS,
    payload: data,
});
export const photoGrapherListError = (error) => ({
    type: Constants.CALENDAR_PHOTOGRAPHER_LIST_ERROR,
    payload: error,
});
export const photoGrapherListClear = () => ({
    type: Constants.CALENDAR_PHOTOGRAPHER_LIST_CLEAR,
});


export const psychologistRequest= (data) => ({
    type: Constants.CALENDAR_SCHEDULE_PSYCHOLOGIST_REQUEST,
    payload: data,
});
export const psychologistSuccess = (data) => ({
    type: Constants.CALENDAR_SCHEDULE_PSYCHOLOGIST_SUCCESS,
    payload: data,
});
export const psychologistError = (error) => ({
    type: Constants.CALENDAR_SCHEDULE_PSYCHOLOGIST_ERROR,
    payload: error,
});
export const psychologistClear = () => ({
    type: Constants.CALENDAR_SCHEDULE_PSYCHOLOGIST_CLEAR,
});

export const getPsychologistListRequest= (data) => ({
    type: Constants.CALENDAR_PSYCHOLOGIST_LIST_REQUEST,
    payload: data,
});
export const psychologistListSuccess = (data) => ({
    type: Constants.CALENDAR_PSYCHOLOGIST_LIST_SUCCESS,
    payload: data,
});
export const psychologistListError = (error) => ({
    type: Constants.CALENDAR_PSYCHOLOGIST_LIST_ERROR,
    payload: error,
});
export const psychologistListClear = () => ({
    type: Constants.CALENDAR_PSYCHOLOGIST_LIST_CLEAR,
});

/*period tracker  */
export const pdTaddDateRequest= (data) => ({
    type: Constants.PERIOD_TRACKER_ADD_DATE_REQUEST,
    payload: data,
});
export const pdTaddDateSuccess = (data) => ({
    type: Constants.PERIOD_TRACKER_ADD_DATE_SUCCESS,
    payload: data,
});
export const pdTaddDateError = (error) => ({
    type: Constants.PERIOD_TRACKER_ADD_DATE_ERROR,
    payload: error,
});
export const pdTaddDateClear = () => ({
    type: Constants.PERIOD_TRACKER_ADD_DATE_CLEAR,
});

export const pdTgetDonorDetailRequest= (data) => ({
    type: Constants.PERIOD_TRACKER_GET_DONAR_DETAIL_REQUEST,
    payload: data,
});
export const pdTgetDonorDetailSuccess = (data) => ({
    type: Constants.PERIOD_TRACKER_GET_DONAR_DETAIL_SUCCESS,
    payload: data,
});
export const pdTgetDonorDetailError = (error) => ({
    type: Constants.PERIOD_TRACKER_GET_DONAR_DETAIL_ERROR,
    payload: error,
});
export const pdTgetDonorDetailClear = () => ({
    type: Constants.PERIOD_TRACKER_GET_DONAR_DETAIL_CLEAR,
});

export const pdTupdateDatelRequest= (data) => ({
    type: Constants.PERIOD_TRACKER_UPDATE_DATE_REQUEST,
    payload: data,
});
export const pdTupdateDatelSuccess = (data) => ({
    type: Constants.PERIOD_TRACKER_UPDATE_DATE_SUCCESS,
    payload: data,
});
export const pdTupdateDatelError = (error) => ({
    type: Constants.PERIOD_TRACKER_UPDATE_DATE_ERROR,
    payload: error,
});
export const pdTupdateDatelClear = () => ({
    type: Constants.PERIOD_TRACKER_UPDATE_DATE_CLEAR,
});

export const periodStatueSetRequest= (data) => ({
    type: Constants.PERIOD_STATUS_SET,
    payload: data,
});
export const periodStatueClear = () => ({
    type: Constants.PERIOD_STATUS_CLEAR,
});
export const deletPeriodStartDateRequest= (data) => ({
    type: Constants.PERIOD_TRACKER_DELETE_SDATE_REQUEST,
    payload: data,
});
export const deletPeriodStartDateSuccess = (data) => ({
    type: Constants.PERIOD_TRACKER_DELETE_SDATE_SUCCESS,
    payload: data,
});
export const deletPeriodStartDateError = (error) => ({
    type: Constants.PERIOD_TRACKER_DELETE_SDATE_ERROR,
    payload: error,
});
export const deletPeriodStartDateClear = () => ({
    type: Constants.PERIOD_TRACKER_DELETE_SDATE_CLEAR,
});