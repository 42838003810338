import * as Constants from './constants';

export const getUploadMsgTaskRequest = (data) => ({
    type: Constants.UPLOAD_GET_MSG_TASK_REQUEST,
    payload: data,
});

export const getUploadMsgTaskSuccess = (data) => ({
    type: Constants.UPLOAD_GET_MSG_TASK_SUCCESS,
    payload: data,
});

export const getUploadMsgTaskError = (error) => ({
    type: Constants.UPLOAD_GET_MSG_TASK_ERROR,
    payload: error,
});

export const getUploadMsgTaskClear = () => ({
    type: Constants.UPLOAD_GET_MSG_TASK_CLEAR,
});


export const portalUploadFileRequest= (data) => ({
    type: Constants.UPLOAD_PORTAL_FILE_REQUEST,
    payload: data,
});

export const portalFileSuccess = (data) => ({
    type: Constants.UPLOAD_PORTAL_FILE_SUCCESS,
    payload: data,
});

export const portalFileError = (error) => ({
    type: Constants.UPLOAD_PORTAL_FILE_ERROR,
    payload: error,
});

export const portalFileClear = () => ({
    type: Constants.UPLOAD_PORTAL_FILE_CLEAR,
});

export const fileDownloadRequest= (data) => ({
    type: Constants.UPLOAD_FILE_DOWNLOAD_REQUEST,
    payload: data,
});

export const fileDownloadSuccess = (data) => ({
    type: Constants.UPLOAD_FILE_DOWNLOAD_SUCCESS,
    payload: data,
});

export const fileDownloadError = (error) => ({
    type: Constants.UPLOAD_FILE_DOWNLOAD_ERROR,
    payload: error,
});

export const fileDownloadClear = () => ({
    type: Constants.UPLOAD_FILE_DOWNLOAD_CLEAR,
});
