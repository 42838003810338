import Spinner from 'react-bootstrap/Spinner';
import styles from './Loader.module.scss';
import { motion } from "framer-motion";

const Loader = (props) => {
    let size = props.size || '4rem';

    return (
        <div className={`d-flex align-items-center justify-content-center ${styles.pageOverlay}`}>
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: .3, delay: .1 }}
            >
                <Spinner animation="border" variant="light" role="status" style={{ width: size, height: size, borderWidth: '.5em' }}>
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            </motion.div>
        </div>
    );
}

export default Loader;
