import React, { useRef } from "react";
import styles from "./Schedular.module.scss";
import { BsChevronRight } from "react-icons/bs";
import { BiCalendarAlt } from "react-icons/bi";
import DateTimePicker from "../../components/core/DateTimePicker";

const SheduleCalendar = (props) => {
    const { handleDate, startDate, selectedDoctor, handleSubmit } = props;
    const isSubmitDisabled = !startDate;
    const calendarRef = useRef();

    const openDatepicker = () => {
        calendarRef.current.setOpen(true);
    };

    return (
        <div className="mt-2">
            <div className={`${styles.datepickerWrap} mx-auto`}>
                <div className={styles.datepickerTxt}>
                    <DateTimePicker
                        startDate={startDate}
                        onChange={handleDate}
                        ref={calendarRef}
                    />
                    <BiCalendarAlt className={styles.calendarIcon} onClick={openDatepicker} />
                </div>
            </div>
            <div className={` d-flex justify-content-end pe-4 mt-4 mb-4 ${styles.submit} `}>
                <button className={`position-relative px-4 ${styles.submitButton} ${selectedDoctor !== 0 && !isSubmitDisabled ? styles.active : ""}`}
                    onClick={isSubmitDisabled ? undefined : handleSubmit}
                    disabled={isSubmitDisabled}  >
                    Submit &nbsp;{" "}
                    <BsChevronRight className={styles.buttonIcon} />
                </button>
            </div>
        </div>

    );
};

export default SheduleCalendar;
