import styles from "./TravelHandoutTask.module.scss";
import Layout from "../components/ui/Layout";
import { useLocation,useNavigate } from 'react-router-dom';
import { useEffect,useState} from "react";
import Loader from "../components/ui/Loader";
import { useSelector, useDispatch } from "react-redux";
import * as Actions from "./state/travel/actions";
import { Endpoints } from "../core/networking";
import TaskDescription from "./components/TaskDescription";
import PageTitle from "./components/PageTitle";
import { Link } from 'react-router-dom';


const TravelHandoutTask = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    let { state } = useLocation();
    const [scrollbarHeight, setScrollBarHeight] = useState(window.screen.availHeight);
    const {
        travelData: { completedTravelHandout },
    } = useSelector((state) => state);
    const { response: handoutComplete, isLoading: handoutCompleteLoading } = completedTravelHandout || {};
    const [taskInfo, setTaskInfo] = useState([]);

    useEffect(() => {
        // set scroll window height
        const mainElement = document.getElementsByClassName("app");
        setScrollBarHeight(mainElement[0]?.offsetHeight - 200);
        window.addEventListener("resize", function () {
            setScrollBarHeight(mainElement[0]?.offsetHeight - 200);
        });
    }, [scrollbarHeight]);

    useEffect(() => {
        document.title = 'Travel Program Handout';
        setTaskInfo(location?.state?.taskInfo);
        dispatch(Actions.sendToApproveClear());
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    const confirmTravelHandout = () => {
        if (taskInfo?.ID) {
            const taskcompleteEndpoint =
                Endpoints.HOME_COMPLETED_DONOR + taskInfo?.ID;
            let params = {
                endpoint: taskcompleteEndpoint,
                method: "PUT",
            };
            dispatch(Actions.sendToApproveRequest(params));
        }
    }

    useEffect(() => {
        if (handoutComplete?.status === 200) {
            dispatch(Actions.sendToApproveClear());
            navigate(-1);
        }
    }, [handoutComplete]);// eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            {(handoutCompleteLoading) && <Loader />}
            <Layout showHeader="false">
                <div className={styles.stickyHeader}>
                    <PageTitle
                        title={taskInfo?.EventName ? taskInfo?.EventName : taskInfo?.TaskName}
                        headerClass="homePageTitle"
                        linkTo={state?.prevPage ? state?.prevPage : -1}
                        linkState={{ menu: state?.menu }}
                    />
                </div>
                <div className={`position-relative ${styles.videoContainer}`} id="testingContainer">
                    <div className={styles.scrollContent}>
                        <div className={styles.testingSection}>
                            <TaskDescription taskDetails={taskInfo} />
                            <br/>
                            <div className={styles.testingCompleteInfo}>
                                <p>Please review the attached handout regarding our travel program.<br />Once reviewed please confirm if you are willing to participate in our travel program or not.</p>
                                <p style={{textAlign:"center",fontWeight:"bold"} }><Link to='/travel-handout' state={{ fromPage: 'handouttask' }}>Travel Program Handout</Link></p>
                            </div>
                            <div className={styles.completeButton}>
                                <input type="button" onClick={() => confirmTravelHandout()} className={styles.button} value="I've reviewed Travel Program Handout" />
                            </div>
                        </div>
                    </div>
                </div>                
            </Layout>
        </>
    );
}

export default TravelHandoutTask;
