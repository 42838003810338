
import { combineReducers } from "redux";
import { getBasicReducer } from "../../../core/utils";
import * as Constants from "./constants";

export const videoPageData = combineReducers({
    videoLists: getBasicReducer({
        request: Constants.VIDEO_GET_LIST,
        success: Constants.VIDEO_GET_SUCCESS,
        failure: Constants.VIDEO_GET_ERROR,
        clear: Constants.VIDEO_GET_CLEAR,
    }),
    completedInfoVideo: getBasicReducer({
        request: Constants.INFO_VIDEO_COMPLETE,
        success: Constants.INFO_VIDEO_COMPLETE_SUCCESS,
        failure: Constants.INFO_VIDEO_COMPLETE_ERROR,
        clear: Constants.INFO_VIDEO_COMPLETE_CLEAR,
    }),
    getVideoInfo: getBasicReducer({
        request: Constants.INFO_VIDEO_GET,
        success: Constants.INFO_VIDEO_GET_SUCCESS,
        failure: Constants.INFO_VIDEO_GET_ERROR,
        clear: Constants.INFO_VIDEO_GET_CLEAR,
    }),
    getInfoSessionVideo: getBasicReducer({
        request: Constants.GET_INFO_SESSION_VIDEO,
        success: Constants.GET_INFO_SESSION_SUCCESS,
        failure: Constants.GET_INFO_SESSION_ERROR,
        clear: Constants.GET_INFO_SESSION_CLEAR,
    }),
    infoSessionVideoSeen: getBasicReducer({
        request: Constants.INFO_VIDEO_COMPLETED,
        success: Constants.INFO_VIDEO_COMPLETED_SUCCESS,
        failure: Constants.INFO_VIDEO_COMPLETED_ERROR,
        clear: Constants.INFO_VIDEO_COMPLETED_CLEAR,
    }),
});
