import styles from "./TravelDonor.module.scss";
import Layout from "../../components/ui/Layout";
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState, useRef } from "react";
import {  Accordion } from "react-bootstrap";
import Loader from "../../components/ui/Loader";
import { useCookies } from 'react-cookie';
import { useSelector, useDispatch } from "react-redux";
import * as Actions from "../state/travel/actions";
import { Scrollbars } from "react-custom-scrollbars-2";
import { Endpoints } from "../../core/networking";
import PageTitle from "../components/PageTitle";
import * as HomeActions from "../state/home/actions";
import * as ProfileActions from "../state/profile/actions";

const TravelDetails = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    let { state } = useLocation();
    
    const travelDetailsRef = useRef();
    const [scrollbarHeight, setScrollBarHeight] = useState(window.screen.availHeight);
    //const [, setPage] = useState(0);
    //const [, setSection] = useState(0);
    const [, setItineraryList] = useState({});
    const containerRef = useRef(null);
    const [expandedItem, setExpandedItem] = useState(["0"]);
    const {
        loginData: { loginUser },
        travelData: { travelItineraries },
        profilePageData: { donorProfile },
    } = useSelector((state) => state);
    const { response: loginVal } = loginUser || {};
    const { response: itineraryListResponse, isLoading: itineraryListLoading } = travelItineraries || {};
    const { response: profileDetails, isLoading: profileDetailsLoading } = donorProfile || {};
    const [, setCookie] = useCookies(null);

    useEffect(() => {
        document.title = 'Travel Details';
        dispatch(Actions.getTravelItineraryClear());
        dispatch(Actions.getTravelDetailsClear());

        if (location?.state?.taskInfo) {
            const taskcompleteEndpoint =
                Endpoints.HOME_COMPLETED_DONOR + location?.state?.taskInfo.ID;
            let params = {
                endpoint: taskcompleteEndpoint,
                method: "PUT",
            };
            dispatch(HomeActions.sendToApproveRequest(params));

            const endpPointProfileDetails = Endpoints.DMA_DONOR_PROFILE + loginVal?.data?.applicant_id;
            let profileParams = {
                endpoint: endpPointProfileDetails,
                method: "GET",
            };
            dispatch(ProfileActions.getDonorProfile(profileParams));
        }
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (loginVal) {
            let param = { endpoint: Endpoints.TRAVEL_ITINERARY_LIST + loginVal?.data?.applicant_id, method: "GET" };
            dispatch(Actions.getTravelItinerary(param));
        }
    }, [loginVal]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (profileDetails) {
            setCookie('HasTravelItinerary', profileDetails?.HasTravelItinerary, { path: '/', domain: process.env.REACT_APP_COOKIE_DOMAIN });
        }
    }, [profileDetails, setCookie]);

    useEffect(() => {
        if (itineraryListResponse) {
            //window.console.log("---itineraryListResponse----", itineraryListResponse);
            if (itineraryListResponse.length > 0) {
                expandedItem.push(itineraryListResponse[0]?.TravelItineraryID);
                setExpandedItem(expandedItem);
            }
            setItineraryList(itineraryListResponse);
        }
    }, [itineraryListResponse]);// eslint-disable-line react-hooks/exhaustive-deps

    
    useEffect(() => {
        // set scroll window height
        const mainElement = document.getElementsByClassName("app");
        const footer = document.querySelector(".footerElement");
        const pgTitle = document.querySelector(".homePageTitle");
        setScrollBarHeight(mainElement[0]?.offsetHeight - footer?.offsetHeight - pgTitle?.offsetHeight);
        window.addEventListener("resize", function () {
            setScrollBarHeight(mainElement[0]?.offsetHeight - footer?.offsetHeight - pgTitle?.offsetHeight);
        });
    }, [scrollbarHeight]);

    /*const setLink = (sec,pg) => {
        setSection(sec);
        setPage(pg);
    }*/


    return (
        <>
            {(itineraryListLoading || profileDetailsLoading) && <Loader />}
            <Layout showHeader="false">
                <div className={styles.stickyHeader}>
                    <PageTitle
                        title="Travel Details"
                        headerClass="homePageTitle"
                        linkTo={state?.prevPage ? state?.prevPage : "/home"}
                        linkState={{ menu: state?.menu }}
                    />
                    {/*<div className={`${styles.pageTitleSection} homePageTitle`}>
                        <h1 className="fw-normal text-white text-center">
                            Travel Details
                        </h1>
                        <Link to={state?.prevPage ? state?.prevPage : "/home"} state={{ menu: state?.menu }}>
                            <BsXLg className={`${styles.closeIcon} float-end position-absolute`} />
                        </Link>
                    </div>*/}
                </div>
                <div className={styles.travelDetailsContainer}>
                    <Scrollbars
                        autoHeight
                        autoHeightMax={scrollbarHeight}
                        className={`${styles.travelContainer}`}
                        ref={travelDetailsRef}
                    >
                        <div className={styles.detailsAccordion}>
                            <Accordion
                                style={{ height: scrollbarHeight }}
                                defaultActiveKey={expandedItem}                                
                                className="pb-1 px-3 py-5"
                                ref={containerRef}
                            >
                                {
                                    itineraryListResponse && itineraryListResponse.map(({ TravelItineraryID, ItineraryName, CycleID }) => {
                                        return <>
                                            <Accordion.Item
                                                className="border-bottom iternaryItem"
                                                eventKey={TravelItineraryID}
                                                key={TravelItineraryID}
                                            >
                                                <Accordion.Header>
                                                    <span className={styles.itineraryName}>
                                                            {ItineraryName}
                                                    </span>
                                                </Accordion.Header>
                                                <Accordion.Body className="px-3">
                                                    {
                                                        !CycleID &&
                                                        <div>
                                                            <span className={styles.subHeading}>
                                                                Travel Information
                                                            </span>
                                                            <ul>
                                                                <li onClick={() => navigate("/travel-info", { state: { prevPage: "/travel-details", travelIternaryID: TravelItineraryID, page: "donor-details", title: "My Travel Details" } })} className={`${styles.contentText} cursor-pointer position-relative`}>
                                                                    Travel Details
                                                                </li>
                                                                <li onClick={() => navigate("/travel-info", { state: { prevPage: "/travel-details", travelIternaryID: TravelItineraryID, page: "companion-details", title: "Companion Details" } })} className={`${styles.contentText} cursor-pointer position-relative`}>
                                                                    Companion Travel Details
                                                                </li>
                                                                <li onClick={() => navigate("/travel-info", { state: { prevPage: "/travel-details", travelIternaryID: TravelItineraryID, page: "donor-itinerary", title: "Travel Itinerary" } })} className={`${styles.contentText} cursor-pointer position-relative`}>
                                                                    Travel Itinerary
                                                                </li>
                                                                <li onClick={() => navigate("/travel-info", { state: { prevPage: "/travel-details", travelIternaryID: TravelItineraryID, page: "companion-itinerary", title: "Companion Itinerary" } })} className={`${styles.contentText} cursor-pointer position-relative`}>
                                                                    Companion's Travel Itinerary
                                                                </li>
                                                                <li onClick={() => navigate("/travel-info", { state: { prevPage: "/travel-details", travelIternaryID: TravelItineraryID, page: "screening-clinic-details", title: "Screening Clinic Details" } })} className={`${styles.contentText} cursor-pointer position-relative`}>
                                                                    Screening Clinic Details
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    }
                                                    
                                                    {
                                                        CycleID &&
                                                        <div>
                                                            <span className={styles.subHeading}>
                                                                Retrieval Planning
                                                            </span>
                                                                <ul>
                                                                    <li onClick={() => navigate("/travel-info", { state: { prevPage: "/travel-details", travelIternaryID: TravelItineraryID, page: "donor-details", title: "My Travel Details" } })} className={`${styles.contentText} cursor-pointer position-relative`}>
                                                                        Travel Details
                                                                    </li>
                                                                    <li onClick={() => navigate("/travel-info", { state: { prevPage: "/travel-details", travelIternaryID: TravelItineraryID, page: "companion-details", title: "Companion Details" } })} className={`${styles.contentText} cursor-pointer position-relative`}>
                                                                        Companion Travel Details
                                                                    </li>
                                                                    <li onClick={() => navigate("/travel-info", { state: { prevPage: "/travel-details", travelIternaryID: TravelItineraryID, page: "donor-itinerary", title: "Travel Itinerary" } })} className={`${styles.contentText} cursor-pointer position-relative`}>
                                                                        Travel Itinerary
                                                                    </li>
                                                                    <li onClick={() => navigate("/travel-info", { state: { prevPage: "/travel-details", travelIternaryID: TravelItineraryID, page: "companion-itinerary", title: "Companion Itinerary" } })} className={`${styles.contentText} cursor-pointer position-relative`}>
                                                                        Companion's Travel Itinerary
                                                                    </li>
                                                                    <li onClick={() => navigate("/travel-info", { state: { prevPage: "/travel-details", travelIternaryID: TravelItineraryID, page: "monitoring-sites", title: "Local Monitoring Site Details" } })} className={`${styles.contentText} cursor-pointer position-relative`}>
                                                                        Local Monitoring Site Details
                                                                    </li>
                                                                    <li onClick={() => navigate("/travel-info", { state: { prevPage: "/travel-details", travelIternaryID: TravelItineraryID, cycleID: CycleID, page: "retrieve-clinic", title: "Retrievel Clinic Details" } })} className={`${styles.contentText} cursor-pointer position-relative`}>
                                                                        Retrievel Clinic Details
                                                                    </li>
                                                            </ul>
                                                        </div>
                                                    }
                                                    
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </>
                                    })
                                }
                            </Accordion>
                        </div>
                    </Scrollbars>
                </div>                
            </Layout>
        </>
    );
}

export default TravelDetails;
