import {combineReducers} from 'redux';
import {homeData} from '../pages/state/home/reducer';
import {notificationData} from '../pages/state/message/reducer';
import {loginData} from '../pages/state/login/reducer';
import {uploadData} from '../pages/state/upload/reducer';
import {calendarData} from '../pages/state/calendar/reducer';
import {calculatorData} from '../pages/state/calculator/reducer';
import {schedularData} from '../pages/state/schedular/reducer';
import {menuPageData} from '../pages/state/menupage/reducer';
import {profilePageData} from '../pages/state/profile/reducer';
import { addInfoData } from '../pages/state/additionalinfo/reducer';
import { videoPageData } from '../pages/state/videos/reducer';
import { testkitData } from '../pages/state/testkit/reducer';
import { appointmentData } from '../pages/state/appointment/reducer';
import { notifyData } from '../pages/state/notify/reducer';
import { docuData } from '../pages/state/docusign/reducer';
import { bgData } from "../pages/state/background/reducer";
import { travelData } from '../pages/state/travel/reducer';
import { logoutData } from '../pages/state/logout/reducer';


const rootReducer = combineReducers({
    bgData,
    homeData,
    notificationData,
    loginData,
    uploadData,
    calendarData,
    calculatorData,
    schedularData,
    menuPageData,
    profilePageData,
    addInfoData,
    videoPageData,
    testkitData,
    appointmentData,
    notifyData,
    docuData,
    travelData,
    logoutData
});

export default rootReducer;


