import { useNavigate } from "react-router-dom";
import { Alert, Button } from "react-bootstrap";
import AuthLayout from "../components/ui/AuthLayout";
import styles from "./NoAccess.module.scss";

const NoAccess = () => {
    const navigate = useNavigate();
    const handleBack = () => {
        navigate("/", { replace: true });
    }

    return (
        <AuthLayout>
            <div className="flex-grow-1 text-center my-3">
                <h1 className={`${styles.title} fw-bold text-danger mb-3`}>Access Denied</h1>
                <Alert variant="danger">
                    You no longer have access to the Fairfax EggBank Egg Donor Portal. If you have questions, please contact us at <a className={styles.alertLink}
                    href="mailto:donor@fairfaxeggbank.com">donor@fairfaxeggbank.com</a>.
                </Alert>
                <Button variant="primary" onClick={handleBack}>Go Back</Button>
            </div>
        </AuthLayout>
    )
}

export default NoAccess
