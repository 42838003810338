import * as Constants from './constants';

export const updatePwdRequest = (data) => ({
    type: Constants.PROFILE_PWD_UPDATE,
    payload: data,
});

export const updatePwdSuccess = (data) => ({
    type: Constants.PROFILE_PWD_SUCCESS,
    payload: data,
});

export const updatePwdError = (error) => ({
    type: Constants.PROFILE_PWD_ERROR,
    payload: error,
});

export const updatePwdClear = () => ({
    type: Constants.PROFILE_PWD_CLEAR
});


export const getDonorProfile = (data) => ({
    type: Constants.DMA_PROFILE_DONOR_GET,
    payload: data,
});

export const getDonorProfileSuccess = (data) => ({
    type: Constants.DMA_PROFILE_DONOR_SUCCESS,
    payload: data,
});

export const getDonorProfileError = (error) => ({
    type: Constants.DMA_PROFILE_DONOR_ERROR,
    payload: error,
});

export const getDonorProfileClear = () => ({
    type: Constants.DMA_PROFILE_DONOR_CLEAR,
});
