import {useRef } from "react";
import {BsChevronRight,BsXLg} from "react-icons/bs";

import Modal from 'react-bootstrap/Modal';
import React  from 'react';

const PopModal = (props) => {
    const modalRef = useRef();

    return (        
        <>        
        <div className="modalContainer" ref={modalRef}></div>
        <Modal
            show="true"
            backdrop="static"
            keyboard={false}
            className="modalWindow"
            container={modalRef}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header  className="popupTitle">
                <Modal.Title>{props.title}</Modal.Title>
                <BsXLg className="float-end closeIcon" onClick={() => props.closeButtonAction()}/>
            </Modal.Header>
            <Modal.Body>
                {props.message}
            </Modal.Body>
            <Modal.Footer>
                <div className="popupRight">
                    <button onClick={() => props.callbackContinue(props.taskID)} className="actionButton">
                        Completed <BsChevronRight className="buttonIcon"/>
                    </button>
                </div>
            </Modal.Footer>
        </Modal>
        </>
    );
}

export default PopModal;
