import { useEffect, useState, useRef } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import { Scrollbars} from "react-custom-scrollbars-2";
import { useSelector, useDispatch } from "react-redux";
import {
    format,
    parseISO,
    add,
    isEqual
} from "date-fns";
import { IoIosArrowBack } from "react-icons/io";
import { isEmpty } from 'lodash';
import Calendar from "./Calendar";
import styles from "./PeriodTracker.module.scss";
import Buttons from '../../components/core/Button';
import { ReactComponent as TrackerIcon } from "../../assets/images/period-trackericon.svg";
import Loader from "../../components/ui/Loader";
import Layout from "../../components/ui/Layout";
import * as Actions from "../state/calendar/actions";
import * as menuActions from "../state/menupage/actions";
import { Endpoints } from "../../core/networking";
import {PERIOD_TYPE} from './type';
import { Link } from 'react-router-dom';
import { createPortal } from "react-dom";
import { motion } from "framer-motion";

const PeriodTracker = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    let { state } = useLocation();
    const {
        loginData: {
            loginUser,
        },
        menuPageData: { clinicInfo },
        calendarData: {
            deleteStartDate,
            pTaddDate,
            pTgetDonarDetail,
            pTupdateDate
        },
    } = useSelector((state) => state);
    const { response: periodDonorEvent, isLoading: periodDonorLoading } = pTgetDonarDetail || {};
    const { response: periodUpdate, isLoading: periodUpdateLoading } = pTupdateDate || {};
    const { response: addedDonor, isLoading: addloading } = pTaddDate || {};
    const { response: deletePeriodDate, isLoading: deleteLoading } = deleteStartDate || {};
    const { response: loginVal, } = loginUser || {};
    const { response: clinicVal, isLoading: clinicLoading } = clinicInfo || {};
    const [calendarRefresh, setCalendarRefresh] = useState(1);

    const [periodStartDate, setPeriodStartDate] = useState(null);
    const [selectedDate, setSelectedDate] = useState(null);
    const [periodType, setPeriodType] = useState(1);
    
    const [nextStartDate, setNextStartDate] = useState(0);
    const [nextMonth, setNextMonth] = useState(0);
    const [removeDate, setRemoveDate] = useState(false);
    const [periodUpdated, setPeriodUpdated] = useState(false);
    const [selectedStartDate, setSelectedStartDate] = useState([]);
    
    const [projectedDate, setProjectedDate] = useState(null);
    const [calendarDisplayMonth, setCalendarDisplayMonth] = useState("")
    const calendarScrollRef = useRef();
    const [showNotifyModal, setShowNotifyModal] = useState(false);
    const [coOrdinator, setcoOrdinator] = useState([]);

    useEffect(() => {
        document.title = 'Period Tracker calendar';
      //  setTaskInfo(state?.taskInfo);
        setPeriodStartDate(null);
        setShowNotifyModal(false);
       
        dispatch(Actions.pdTgetDonorDetailClear());
        dispatch(Actions.pdTaddDateClear());
        dispatch(Actions.deletPeriodStartDateClear());
        dispatch(Actions.pdTupdateDatelClear());
        dispatch(menuActions.myClinicGetClear());

        let applicantID = loginVal?.data?.applicant_id;
        if (loginVal?.data?.applicant_id !== "") {
            const taskEndPoint = Endpoints.PERIODTRACKER_GET_DONOR_DETAIL + applicantID;
            let params = {
                endpoint: taskEndPoint,
                method: "GET",
            };
            dispatch(Actions.pdTgetDonorDetailRequest(params));
        }

        if (applicantID) {
            let myclinicEndpoint = Endpoints.MY_CLINIC_GET_DATA + applicantID;
            let param = { endpoint: myclinicEndpoint, method: "GET" };
            dispatch(menuActions.myClinicGetRequest(param));
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        setPeriodStartDate(periodStartDate);
    }, [periodStartDate]);

    useEffect(() => {
        if (typeof clinicVal !== "undefined" && clinicVal) {
            setcoOrdinator(clinicVal);
        }
    }, [clinicVal]);

    const sendMessageToCoordinator = () => {

        if (coOrdinator && (coOrdinator?.primaryClinicCoordinatorID || coOrdinator?.fairfaxCoordinatorID || coOrdinator?.dmaMyClinicCoordinators?.length > 0)) {
            let coOrdinatorList = [];

            if (coOrdinator?.primaryClinicCoordinatorID !== null) {
                coOrdinatorList.push({
                    ID: coOrdinator.primaryClinicCoordinatorID,
                    Name: coOrdinator.primaryClinicCoordinatorName,
                    selected: true
                });
            }

            if (coOrdinator?.fairfaxCoordinatorID !== null) {
                coOrdinatorList.push({
                    ID: coOrdinator.fairfaxCoordinatorID,
                    Name: coOrdinator.fairfaxCoordinatorName,
                    selected: true
                });
            }

            if (coOrdinator && coOrdinator?.dmaMyClinicCoordinators) {

                for (let i = 0; i < coOrdinator?.dmaMyClinicCoordinators?.length; i++) {
                    coOrdinatorList.push({
                        ID: coOrdinator.dmaMyClinicCoordinators[i].clinicCoordinatorID,
                        Name: coOrdinator.dmaMyClinicCoordinators[i].clinicCoordinatorName,
                        selected: true
                    });
                }
            }

            navigate("/messages", {
                state: {
                    redirect: "/period-tracker",
                    receipients: coOrdinatorList,
                    newMessage: true,
                    messageSubject: "Day 1 of period"
                }
            });

        }
    }


    useEffect(() => {
        
        if (!isEmpty(periodDonorEvent)) {
            let d = new Date();
            const currMonth = d.getMonth() + 1;

            let calendarStartMonth = periodDonorEvent.sort((a, b) => new Date(a.FlowDate) - new Date(b.FlowDate));
            
            if (calendarStartMonth?.length > 0) {
                setCalendarDisplayMonth(calendarStartMonth[0]?.FlowDate);
            }

            const startDate = periodDonorEvent?.filter((i)=> i.IsStartDate === true && format(new Date(i.FlowDate), 'MM') === currMonth);
            const allStartDate = periodDonorEvent?.filter((i)=> i.IsStartDate === true).map((j)=> j.FlowDate);
            

            const startDateObjects = periodDonorEvent.filter(obj => obj.IsStartDate === true);
            const sortedStartDateObjects = startDateObjects.sort((a, b) => new Date(b.FlowDate) - new Date(a.FlowDate));
            
            if(!(sortedStartDateObjects?.length === 0)){
                const latestFlowDate = sortedStartDateObjects[0].FlowDate;
                const currentDate = add( parseISO(latestFlowDate), {days:28});
                const newDate = format(currentDate, 'dd');
                const newMonth = format(currentDate, 'MM');
                setNextStartDate(newDate);
                setNextMonth(newMonth);
                setProjectedDate(format(currentDate, "yyyy-MM-dd"));
            }
            
            const checkStart = startDate?.length === 0 ? false : true;
            
            setSelectedStartDate(allStartDate);// all start date
            setPeriodUpdated(checkStart);//
            
        }
    }, [periodDonorEvent]);

    const fetchPeriodDetail = () =>{
        let applicantID = loginVal?.data?.applicant_id;
        if (loginVal?.data?.applicant_id !== "") {
            const taskEndPoint = Endpoints.PERIODTRACKER_GET_DONOR_DETAIL + applicantID;
            let params = {
                endpoint: taskEndPoint,
                method: "GET",
            };
            
            setSelectedStartDate(null);
            setNextMonth(0);
            
            setNextStartDate(0);
            dispatch(Actions.pdTgetDonorDetailRequest(params));
        }
    }

    useEffect(() => {
        if (!(isEmpty(addedDonor))) {
            fetchPeriodDetail();
        }
        // eslint-disable-next-line
    }, [addedDonor]);

    useEffect(() => {
        if (!(isEmpty(deletePeriodDate))) {
            fetchPeriodDetail();
            setRemoveDate(false);
        }
        // eslint-disable-next-line
    }, [deletePeriodDate]);

    useEffect(() => {
        if (!(isEmpty(periodUpdate))) {
            fetchPeriodDetail();
        }
        // eslint-disable-next-line
    }, [periodUpdate]);


    const handlePeriodAdd = (date,isStartDate, type,) => {
        let applicantID = loginVal?.data?.applicant_id
        const formData = new FormData();
        formData.append("FlowRate", type ? type : periodType);
        formData.append("FlowDate",  format(date, 'yyyy-MM-dd'));
        formData.append("ApplicantID", applicantID);
        formData.append("IsStartDate", isStartDate);
        formData.append("endpoint", Endpoints.PERIODTRACKER_ADD_DATE);
        dispatch(Actions.pdTaddDateRequest(formData));
    }

    const handlePeriodUpdate = ( date, finalDate, type, start) => {
        const formData = new FormData();
        formData.append("FlowRate",  type ? type : periodType);
        formData.append("FlowDate", format(date, 'yyyy-MM-dd'));
        formData.append("ApplicantID", finalDate[0]?.ApplicantID);
        formData.append("ID", finalDate[0]?.ID);
        formData.append("IsStartDate", start ? true : finalDate[0]?.IsStartDate  );
        formData.append("method", 'PUT');
        formData.append("endpoint", Endpoints.PERIODTRACKER_UPDATE_DATE);
        dispatch(Actions.pdTupdateDatelRequest(formData));
    }

    const handleLight = () => {
        setPeriodType(PERIOD_TYPE.LIGHT);
        const checkUpdate = periodDonorEvent?.filter((i)=> isEqual(parseISO(i.FlowDate), selectedDate));
        const updateDate = periodDonorEvent?.some((i) => isEqual(parseISO(i.FlowDate), selectedDate));
        if(updateDate){
            handlePeriodUpdate(selectedDate, checkUpdate, PERIOD_TYPE.LIGHT)
        } else{
            handlePeriodAdd(selectedDate, false, PERIOD_TYPE.LIGHT)
        }
    }

    const handleHeavy = () => {
        setPeriodType(PERIOD_TYPE.HEAVY);
        const checkUpdate = periodDonorEvent?.filter((i)=> isEqual(parseISO(i.FlowDate), selectedDate));
        const updateDate = periodDonorEvent?.some((i) => isEqual(parseISO(i.FlowDate), selectedDate));
        if(updateDate){
            handlePeriodUpdate(selectedDate, checkUpdate, PERIOD_TYPE.HEAVY)
        }else{
            handlePeriodAdd(selectedDate, false,  PERIOD_TYPE.HEAVY)
        }
        
    }

    const handleStart = () => {
        if(selectedDate){
            const checkUpdate = periodDonorEvent?.filter((i)=>  isEqual(parseISO(i.FlowDate), selectedDate));
            let finalDates = periodDonorEvent.some((i) => (isEqual(parseISO(i.FlowDate), selectedDate)));
            setPeriodUpdated(finalDates);
            if(finalDates) {
                handlePeriodUpdate(selectedDate, checkUpdate ,  PERIOD_TYPE.LIGHT, PERIOD_TYPE.START)
            }else {
                handlePeriodAdd(selectedDate , true, PERIOD_TYPE.LIGHT )
            }
        }
    }

    const handleDate = (event, day) => {
        setSelectedDate(day);
        const starDate = periodDonorEvent?.some((i) => (isEqual(parseISO(i.FlowDate), day)));
        setRemoveDate(starDate);
    }

    const handleRemove = () => {
        const startDate = periodDonorEvent?.filter((i)=> isEqual(parseISO(i.FlowDate), selectedDate));
        const taskEndPoint = Endpoints.PERIOD_TRACKER_DELETE_START_DATE + startDate[0]?.ID;
        let params = {
            endpoint: taskEndPoint,
            method: "DELETE",
        };
        setSelectedDate(null);
        setPeriodStartDate(null);
        setProjectedDate(null);
        dispatch(Actions.deletPeriodStartDateRequest(params));
    }
   
    useEffect(()=>{
        let elm = document.querySelector(".currentMonthSection");
        if (!periodDonorLoading && !addloading && !periodUpdateLoading && !deleteLoading) {
            if (elm) {
                calendarScrollRef.current.scrollTop(elm.offsetTop - 30);
            }
        }
        setCalendarRefresh(Math.random());
    }, [calendarScrollRef, periodDonorLoading, addloading, periodUpdateLoading, deleteLoading]);

    const submitStartPT = () => {
        setShowNotifyModal(false);
        handleStart();
    }

    const contactCoOrdinator = () => {
        sendMessageToCoordinator();
    }

    const showNotifyPopup = () => {
        setShowNotifyModal(true);
    }

    return (
        <>
            {
                showNotifyModal && createPortal(
                    <div className={`${styles.notifyModalContainer} position-absolute w-100 top-0 start-0 end-0 bottom-0`}>
                        <motion.div className={`${styles.notifyModal} position-absolute top-50`}
                            initial={{ opacity: 0, scale: 0.5, translateY: '-50%' }}
                            animate={{ opacity: 1, scale: 1, translateY: '-50%' }}
                            transition={{ duration: 0.3 }}
                        >
                            <div className={styles.notifyModal} >
                                <div className={styles.modalHeader}>
                                    Notify your Clinic
                                </div>
                                <div className={styles.modalDetails}>
                                    Have you notified your clinic that your period started? If not, message your clinic coordinator now.
                                </div>
                                <div className={styles.modalFooter}>
                                    <input type="button" onClick={() => submitStartPT()} className={styles.footerbutton} value="Yes" />
                                    <input type="button" onClick={() => contactCoOrdinator()} className={styles.footerbutton} value="No" />

                                </div>
                            </div>
                        </motion.div>
                    </div>,
                    document.querySelector('.app')
                )
            }
            <Layout showHeader="false">
                {(periodDonorLoading || addloading || periodUpdateLoading || deleteLoading || clinicLoading) && <Loader />}
                <>
                    <div className={`${styles.pageTitleSection}`}>
                        <div>
                            <TrackerIcon className={styles.periodTrackerIcon} />
                            <p className={styles.periodTrackerText}>Period Tracker</p>
                        </div>
                        <Link to={state?.prevPage ? state?.prevPage : "/home"} state={{ menu: state?.menu }}>
                            <IoIosArrowBack
                                className={`${styles.backIcon} float-end position-absolute`}
                            />
                        </Link>
                    </div>
                    <div className={styles.screenLayout}>
                        <div className={styles.screenoutLayout}>
                            <Scrollbars
                                ref={calendarScrollRef}
                                className="scroll-container"
                            >
                                <div className={styles.calendarContainer}>
                                    {
                                        <Calendar
                                            key={calendarRefresh}
                                            onDateClick={(event, day) => handleDate(event, day)}
                                            selectedDate={selectedDate}
                                            startDotDate={selectedStartDate}
                                            calendarData={periodDonorEvent}
                                            calendarDisplayStart={calendarDisplayMonth}
                                            nextMonthStart={projectedDate}
                                        />
                                    }
                                </div>
                            </Scrollbars>
                        </div>
                    </div>
                    <span className={styles.taskLegend}>
                        <ul className={styles.rowContent}>
                            <li className={styles.task} key="task"></li>
                            <div className={styles.lightPeriod}>Spotting Period</div>
                            <li className={styles.medication} key="medication"></li>
                            <div className={styles.heavyPeriod}>Full Flow Period</div>
                        </ul>
                        <div>

                            <div>
                                {
                                    !periodUpdated
                                        ?
                                        <h1 className={styles.nextProject}>Tap start on the first day of your period and <br />enter your flow for each until it ends </h1>
                                        : null
                                }

                                <div className="d-flex justify-content-center m-0">
                                    <div className={styles.buttonRow}>
                                        <div className={styles.childDiv}>
                                            <Buttons
                                                onClick={showNotifyPopup} 
                                                buttonStyle={styles.primaryButton}
                                                lable={'Start'}
                                                lableStyle={styles.primartText}
                                                bgColor={styles.primaryDisableColor}
                                            />
                                        </div>
                                        <div className={styles.childDiv}>
                                            <Buttons
                                                onClick={handleLight}
                                                buttonStyle={styles.periodEnd}
                                                lable={'Spotting'}
                                                lableStyle={styles.secText}
                                                bgColor={'#e6b3a8'}
                                            />
                                        </div>
                                        <div className={styles.childDiv}>
                                            <Buttons
                                                onClick={handleHeavy}
                                                buttonStyle={styles.secondaryButton}
                                                lable={'Full Flow'}
                                                lableStyle={styles.primartText}
                                                bgColor={styles.primaryDisableColor}
                                            />
                                        </div>

                                    </div>
                                </div>
                                {periodUpdated ?
                                    <div className={styles.startTextDiv}>
                                        <p className={styles.nextProject}>Next Projected Start Date {nextMonth}/{nextStartDate}</p>
                                    </div>
                                    : null
                                }
                            </div>
                            {/* } */}
                            {removeDate &&
                                <div className={styles.removeRow}>
                                    <Buttons
                                        primary
                                        onClick={handleRemove}
                                        buttonStyle={styles.removeButton}
                                        lable={'Remove'}
                                        lableStyle={styles.removeTitle}
                                        bgColor={styles.primaryDisableColor}
                                    />
                                </div>
                            }
                        </div>
                    </span>
                </>
            </Layout>
        </>
        
    );
}

export default PeriodTracker;
