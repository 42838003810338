import { useEffect, useState } from "react";
import styles from "./Calendar.module.scss";
import {
    startOfMonth,
    endOfMonth,
    isSameMonth,
    isSameDay,
    startOfWeek,
    endOfWeek,
    addDays,
    format
} from "date-fns";

const Calendar = (props) => {
    const {
        onDateClick,
        selectedDate,
        appointment = [],
        medication = [],
        task = [],
    } = props;

    const [currentMonth] = useState(new Date());
    const [currentDate] = useState(new Date());
    const [, setCellHighLight] = useState(0);

    useEffect(() => {
        document.title = "Message Screen";
        window.addEventListener("scroll", handleScroll);
        setCellHighLight(0);
    }, []);
    const handleScroll = (e) => {
        window.console.log(e);
    };

    const RenderHeader = () => {
        const dateFormat = "MMMM yyyy";
        let d = new Date();
        let monthlist = [];
        let today = new Date(d.getFullYear(), d.getMonth()-1, 1);
        monthlist.push(new Date(d.getFullYear(), d.getMonth()-1, 1));
        for (let i = 0; i < 5; i++) {
            today.setMonth(today.getMonth() + 1);
            monthlist.push(new Date(today.toLocaleDateString()));
        }

        return monthlist?.map((calendarmonth, index) => (
            <div key={index} className={styles.monthDates}>
                <div
                    id={`calendarHeader_${index}`}
                    className={`${styles.header} ${styles.stickyHeader}
                                ${isSameMonth(calendarmonth,new Date()) ? "currentMonthSection" : ""}
                            `}
                >
                    <div className="align-items-center">
                        <div className={styles.monthText}>
                            {format(calendarmonth, dateFormat)}
                        </div>
                    </div>
                </div>
                <RenderCells
                    month={calendarmonth}
                    position={index}
                />
            </div>
        ));
    };

    const RenderDays = () => {
        const dateFormat = "EEE";
        const days = [];
        let startDate = startOfWeek(currentMonth);
        for (let i = 0; i < 7; i++) {
            days.push(
                <div className={`${styles.col}`} key={i}>
                    <div className={styles.sticky}>
                        {format(addDays(startDate, i), dateFormat)}
                    </div>
                </div>
            );
        }
        return (
            <div id="daysName" className={`${styles.days} px-5 py-1`}>
                {days}
            </div>
        );
    };

    const RenderCells = (props) => {
        const monthStart = startOfMonth(props.month);
        const monthEnd = endOfMonth(monthStart);
        const startDate = startOfWeek(monthStart);
        const endDate = endOfWeek(monthEnd);
        const dateFormat = "d";
        const rows = [];
        let days = [];
        let day = startDate;
        let formattedDate = "";
        while (day <= endDate) {
            for (let i = 0; i < 7; i++) {
                formattedDate = format(day, dateFormat);
                const cloneDay = day;
                const formattedDay = format(cloneDay, "yyyy-MM-dd");

                days.push(
                    <div key={i + "cells"} className={`col`}>

                        <div
                            className={`cell ${!isSameMonth(day, monthStart)
                                    ? styles.disabled
                                    : isSameDay(day,selectedDate)
                                        ? `${styles.currDate}
                                        ${isSameDay(day, currentDate) && isSameDay(day, selectedDate) ? styles.selectCircle : ""}`
                                        : isSameDay(day, currentDate)
                                            ? styles.bold
                                            : isSameDay(day, selectedDate)
                                                ? styles.currentDate
                                                : ""
                                } ${styles.calendarDate}`}
                            key={day}
                            onClick={(e) => onDateClick(e, cloneDay)}
                        >
                            <span className="number">{formattedDate}</span>
                        </div>
                        <div className={styles.startDiv}>
                            {isSameMonth(day, monthStart) ?
                                <>
                                    { task?.includes(formattedDay) ? <span className={styles.startDot}/> : null }
                                    { appointment?.includes(formattedDay) ? <span className={styles.startDot1}/> : null }
                                    { medication?.includes(formattedDay) ? <span className={styles.startDot2}/> : null }
                                </>
                            : null }
                        </div>
                    </div>
                );
                day = addDays(day, 1);
            }
            rows.push(
                <div
                    className={styles.row}
                    key={day}
                    style={{ marginBottom: "1px" }}
                >
                    {days}
                </div>
            );
            days = [];
        }
        return (
            <div
                id={`calendarDays_${props.position}`}
                className={`${props.position === 0
                        ? styles.CalendarDays +
                        " " +
                        styles.currentDaySection
                        : styles.CalendarDays
                    }`}
            >
                {rows}
            </div>
        );
    };

    return (
        <div className={styles.calendar}>
            <RenderDays />
            <RenderHeader />
        </div>
    );
};

export default Calendar;
