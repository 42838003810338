
import { combineReducers } from "redux";
import { getBasicReducer } from "../../../core/utils";
import * as Constants from "./constants";

export const menuPageData = combineReducers({
    addReferal: getBasicReducer({
        request: Constants.REFERAL_ADD,
        success: Constants.REFERAL_SUCCESS,
        failure: Constants.REFERAL_ERROR,
        clear: Constants.REFERAL_CLEAR,
    }),
    pageInfo: getBasicReducer({
        request: Constants.MENU_GET_PAGE,
        success: Constants.MENU_GET_PAGE_SUCCESS,
        failure: Constants.MENU_GET_PAGE_ERROR,
        clear: Constants.MENU_GET_PAGE_CLEAR,
    }),
    clinicInfo: getBasicReducer({
        request: Constants.CLINIC_REQUEST,
        success: Constants.CLINIC_REQUEST_SUCCESS,
        failure: Constants.CLINIC_REQUEST_ERROR,
        clear: Constants.CLINIC_REQUEST_CLEAR,
    })
});