import * as Constants from './constants';

export const sendToApproveRequest= (data) => ({
    type: Constants.NOTIFY_SEND_TO_APPROVE_REQUEST,
    payload: data,
});

export const sendToApproveSuccess = (data) => ({
    type: Constants.NOTIFY_SEND_TO_APPROVE_SUCCESS,
    payload: data,
});

export const sendToApproveError = (error) => ({
    type: Constants.NOTIFY_SEND_TO_APPROVE_ERROR,
    payload: error,
});

export const sendToApproveClear = () => ({
    type: Constants.NOTIFY_SEND_TO_APPROVE_CLEAR,
});
