export const initialState = {
    isLoading: false,
    response: null,
    error: null,
};
/**
 * @func Reducer getBasicReducer
 * @param state
 * @param action
 */

export default function getBasicReducer(actions, customInitialState) {
    const {add, success, request, failure, clear} = actions;
    return function(state = initialState, action) {
    
    switch (action.type) {
        /* CRUD */
        case add:
            return {
                ...state,
                ...action.payload,
            };
        case request:
            return {
                ...state,
                isLoading: true,
                response: null,
                error: null,
            };

        case success:
            return {
                ...state,
                isLoading: false,
                response: action.payload,
                error: null,
            };
        case failure:
            return {
                ...state,
                isLoading: false,
                response: null,
                error: action.payload,
            };
        case clear:
            return {
            ...initialState,
            };
        default:
            return state;
        }
    };
}

export const initialStateBasicSelect= {
    isLoading: false,
    selectedItem: null,
    error: null,
};

export const getBasicSelectReducer = actions => {
    const {select, clear} = actions;
    return function(state = initialStateBasicSelect, action) {
    switch (action.type) {
        /* CRUD */
        case select:
            return {
                ...state,
                selectedItem: {...state.selectedItem, ...action.payload},
            };

        case clear:
            return {
                ...initialStateBasicSelect,
                selectedItem: null,
            };

        default:
            return state;
        }
    };
};