import styles from './Header.module.scss';
import { ReactComponent as Logo } from '../../assets/images/logo.svg';
import { motion } from "framer-motion";

function AuthHeader() {
    return (
        <header className={'text-center ' + styles.header}>
            <motion.div
                initial={{ scale: 0, opacity: 0 }}
                animate={{ scale: 1, opacity: 1 }}
                transition={{ duration: .3, ease: "easeOut", delay: .1 }}
            >
                <Logo className={styles.logo}/>
            </motion.div>
        </header>
  )
}

export default AuthHeader;
