import * as Constants from './constants';

export const videoList = (data) => ({
    type: Constants.VIDEO_GET_LIST,
    payload: data,
});

export const videoListSuccess = (data) => ({
    type: Constants.VIDEO_GET_SUCCESS,
    payload: data,
});

export const videoListError = (error) => ({
    type: Constants.VIDEO_GET_ERROR,
    payload: error,
});

export const videoListClear = () => ({
    type: Constants.VIDEO_GET_CLEAR
});

export const infoVideoComplete = (data) => ({
    type: Constants.INFO_VIDEO_COMPLETE,
    payload: data,
});

export const infoVideoSuccess = (data) => ({
    type: Constants.INFO_VIDEO_COMPLETE_SUCCESS,
    payload: data,
});

export const infoVideoError = (error) => ({
    type: Constants.INFO_VIDEO_COMPLETE_ERROR,
    payload: error,
});

export const infoVideoClear = () => ({
    type: Constants.INFO_VIDEO_COMPLETE_CLEAR
});


// Get Video Info information
export const getVideoInfo = (data) => ({
    type: Constants.INFO_VIDEO_GET,
    payload: data,
});

export const getVideoInfoSuccess = (data) => ({
    type: Constants.INFO_VIDEO_GET_SUCCESS,
    payload: data,
});

export const getVideoInfoError = (error) => ({
    type: Constants.INFO_VIDEO_GET_ERROR,
    payload: error,
});

export const getVideoInfoClear = () => ({
    type: Constants.INFO_VIDEO_GET_CLEAR
});


// Get Session Video Info information
export const getSessionVideoInfo = (data) => ({
    type: Constants.GET_INFO_SESSION_VIDEO,
    payload: data,
});

export const getSessionVideoInfoSuccess = (data) => ({
    type: Constants.GET_INFO_SESSION_SUCCESS,
    payload: data,
});

export const getSessionVideoInfoError = (error) => ({
    type: Constants.GET_INFO_SESSION_ERROR,
    payload: error,
});

export const getSessionVideoInfoClear = () => ({
    type: Constants.GET_INFO_SESSION_CLEAR
});


// Info Session Completed Viewing
export const infoVideoCompleted = (data) => ({
    type: Constants.INFO_VIDEO_COMPLETED,
    payload: data,
});

export const infoVideoCompletedSuccess = (data) => ({
    type: Constants.INFO_VIDEO_COMPLETED_SUCCESS,
    payload: data,
});

export const infoVideoCompletedError = (error) => ({
    type: Constants.INFO_VIDEO_COMPLETED_ERROR,
    payload: error,
});

export const infoVideoCompletedClear = () => ({
    type: Constants.INFO_VIDEO_COMPLETED_CLEAR
});

