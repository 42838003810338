export const TASK_TYPE = {
    APPOINTMENT : 1,
    TASK : 2,
    MEDICATION : 3
}

export const TASK_TYPE_ID = {
    ADDITINAL_INFO: 3,
    TASK_SCHEDULE: 4,
    ACUITY: 4,
    UPLOAD: 5,
    PROFILE: 2,
    PENDING_QUESTIONS: 6,
    NOTIFY: 6,
    LAB_WORK: 11,
    APPOINTMENT: 10,
    SESSION: 9,
    TRAVEL_FORM: 8,
    DOCU_SIGN: 7,
    TRAVEL_HANDOUT: 12
}
export const TASK_SUB_TYPE = {
    SCHEDULE_PHOTO_SHOOT_4: 3,
    SCHEDULE_MEDICAL_SCREENING_3: 2,
    MEDICAL_SCREENING_4: 4,
    PHYSICAL_EXAM_4: 3,
    PSYCHOLOGICAL_EXAM_4: 6,
    SCHEDULE_PCP_OBGYN_4: 7,
    SCHEDULE_INTERVIEW_4: 1,
    SCHEDULE_AUDIO_INTERVIEW_4: 9,
    ADDITINAL_INFO_3: 1,
    UPLOAD_GOVT_ID_5: 1,
    SUBMIT_6_10_CURRENT_PHOTOS_5:6,
    SUBMIT_4_5_CHILDHOOD_PHOTOS_5:5,
    SUBMIT_PAP_SMEAR_RESULTS_5:2,
    SUBMIT_ACADEMIC_TRANSCRIPTS_5:3,
    SUBMIT_MEDICAL_RECORD_5:4,
    COMPLETE_PERSONAL_PROFILE_2:1,
    COMPLETE_FAMILY_PROFILE_2:2,
    COMPLETE_ESSAYS_2: 3,
    ANSWER_PENDING_QUESTIONS: 3,
    INFECTION_DISEASE : 4,
    SCHEDULE_LAB_WORK:1,
    TESTING_KIT:2,
    GENETIC_TESTING_KIT: 3,
    ADHOC_TAASK_MESSAGE_UPDATE: 1,
    APPOINTMENT_SCHEDULE_LAB_WORK_10: 5,
    APPOINTMENT_SCHEDULE_DOCTOR_10: 2,
    APPOINTMENT_SCHEDULE_PHYS_EXAM_10: 9,
    APPOINTMENT_SCHEDULE_GENERAL_10:1 ,
    APPOINTMENT_SCHEDULE_PHOTO_SHOOT_10:4,
    APPOINT_SCHEDULE_INTERVIEW_10:7,
    APPOINTMENT_SCHEDULE_PSYCHOLOGICAL_EVAL_10:3,
    APPOINTMENT_SCHEDULE_INFECTIOUS_DISEASE_TESTING_10:6,
    COMPLETE_INFO_SESSION_9:1,
    APPOINT_SCHEDULE_LAB_WORK: 5,
    NOTIFY_CLINIC_PERIOD: 4,
    DOCU_SIGN_PAGE: 1,
    TRAVEL_FORM_SUBTYPE: 1,
    TRAVEL_HANDOUT_SUBTYPE: 1,
}
export const PROFILE_ID = {
    DEFAULT : 2,
    COMPLETE_PERSONAL_PROFILE : 5,
    COMPLETE_FAMILY_PROFILE : 6,
    COMPLETE_ESSAYS : 7
}

