import React, { useEffect } from "react";
import { useCookies } from 'react-cookie';
import { CheckIsLoggedIn, Logout } from '../services/Common';
import { useNavigate } from "react-router-dom";
import useHandleLogOut from './hooks/useHandleLogOut';
import * as Actions from "./state/logout/actions";
import { Endpoints } from "../core/networking";
import { useSelector, useDispatch } from "react-redux";
import Loader from "../components/ui/Loader";

const LogoutPage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {
        logoutData: {
            logoutUser
        },
    } = useSelector((state) => state);
    const { response: logoutVal, isLoading: loading, error } = logoutUser || {};
    const [cookies, setCookie, removeCookie] = useCookies(['wp-login-user-token']);
    const logOut = useHandleLogOut(setCookie, removeCookie);

    useEffect(() => {
        dispatch(Actions.logoutUserClear());
        let user = { email: cookies['wp-login-user-token'] }; //Buffer.from(pwd,'base64')
        if (user)
            dispatch(Actions.logoutUserRequest(user));
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (logoutVal) {
            logOut();
            dispatch(Actions.logoutUserClear());
            navigate("/", { replace: true });
        }
        
        // eslint-disable-next-line
    }, [logoutVal]); //dispatch

    return (
        <>
            {loading && <Loader />}
        </>
    );
};

export default LogoutPage;
