import React, { useEffect, useState, useRef} from "react";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { ReactComponent as CalendarIcon } from "../../assets/images/calendar-icon.svg";
import styles from "./TaskCalendar.module.scss";
import Layout from "../../components/ui/Layout";
import Calendar from "../components/Calendar";
import Loader from "../../components/ui/Loader";
import * as Actions from "../state/calendar/actions";
import * as HomeActions from "../state/home/actions";
import { Endpoints } from "../../core/networking";
import { isEmpty } from "lodash";
import { format, parseISO, isEqual, getYear, getMonth, getDate } from "date-fns";
import { TASK_TYPE, TASK_SUB_TYPE, TASK_TYPE_ID, PROFILE_ID } from "./type";
import ListModal from "./Modal";
import { IoIosArrowBack } from "react-icons/io";
import ListOfTask from "./List";
import { Scrollbars} from "react-custom-scrollbars-2";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import advancedFormat from "dayjs/plugin/advancedFormat";
import { TASK_STATUS } from "../../utils/constants";
import BackgroundFetch from "../components/BackgroundFetch";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);

const TaskCalendar = () => {
    const { state } = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const calendarScrollRef = useRef();
    const {
        loginData: { loginUser },
        homeData: { getNotificationInfo },
        calendarData: { donorEvent, },
        bgData: { calendarEvents, notifications },
    } = useSelector((state) => state);

    const { response: notificationInfo, isLoading: notificationLoading } = getNotificationInfo || {};

    const { response: calendarEvent, isLoading: eventDonorLoading } = donorEvent || {};
    const { response: bgCalendarEvent } = calendarEvents || {};
    const { response: bgNotificationInfo } = notifications || {};
    const { response: loginVal } = loginUser || {};

    const [selectedReminder, setSelectedReminder] = useState("");
    const [showReminderModal, setShowReminderModal] = useState(false);
    const [selectedDate, setSelectedDate] = useState();
    const [task, setTask] = useState(null);
    const [appointment, setAppointment] = useState(null);
    const [meditation, setMeditation] = useState(null);
    const [countOfTask, setCountOfTask] = useState(0);
    const [currentDate, setCurrentDate] = useState(null);
    const [, setNavigateDate] = useState([]);
    const [allTask, setAllTask] = useState([]);
    const [allAppointment, setAllAppointment] = useState([]);
    const [allMeditation, setAllMeditation] = useState([]);
    const [notificationContent, setNotificationContent] = useState([]);

    const timeZones = {
        1: "US/Eastern",
        2: "US/Central",
        3: "US/Mountain",
        4: "US/Pacific",
    }

    const taskTypes = [
        TASK_TYPE_ID.PROFILE,
        TASK_TYPE_ID.ADDITINAL_INFO,
        TASK_TYPE_ID.TASK_SCHEDULE,
        TASK_TYPE_ID.UPLOAD,
        TASK_TYPE_ID.PENDING_QUESTIONS,
        TASK_TYPE_ID.DOCU_SIGN,
        TASK_TYPE_ID.TRAVEL_FORM,
        TASK_TYPE_ID.SESSION,
        TASK_TYPE_ID.LAB_WORK,
        TASK_TYPE_ID.TRAVEL_HANDOUT
    ];

    useEffect(() => {
        document.title = "Task calendar";
        let applicantID = loginVal?.data?.applicant_id;
        if (loginVal?.data?.applicant_id !== "") {
            const taskEndPoint = Endpoints.CALENDAR_DONOR_EVENT + applicantID;
            let params = {
                endpoint: taskEndPoint,
                method: "GET",
            };
            dispatch(Actions.getDonorEventRequest(params));

            const notificationEndpoint = Endpoints.NOTIFICATION_GET_DATA + applicantID;
            let notify = {
                endpoint: notificationEndpoint,
                method: "GET",
            };
            dispatch(HomeActions.notificationInfo(notify));
        }

        dispatch(Actions.doctorAppointSubmitClear());
        dispatch(Actions.schedulePhotoGraphyClear());
        dispatch(Actions.photoGrapherListClear());
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        let elm = document.querySelector(".currentMonthSection");
        if (elm) {
            calendarScrollRef.current.scrollTop(elm.offsetTop-30);
        }
    }, [eventDonorLoading]);

    useEffect(() => {

        let calEvent = bgCalendarEvent || calendarEvent;

        if (!isEmpty(calEvent)) {
            let defaultSelectedDate = (selectedDate || new Date());
            const currDate = parseISO(format(defaultSelectedDate, 'yyyy-MM-dd'));// new Date();
            const task = calEvent?.CalendarEvents.filter(
                (i) => taskTypes.some((id) => i.TaskType === id)
            ).map((j) => j.EventDate);
            const appointment = calEvent?.CalendarEvents.filter(
                (i) => i.TaskType === TASK_TYPE_ID.APPOINTMENT
            ).map((j) => j.EventDate);
            const medication = calEvent?.CalendarEvents.filter(
                (i) => i.EventTypeID === TASK_TYPE.MEDICATION
            ).map((j) => j.EventDate);

            setTask(task);
            setAppointment(appointment);
            setMeditation(medication);
            getAllTaskList(currDate);
        }
    }, [calendarEvent, bgCalendarEvent]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        let notifications = bgNotificationInfo || notificationInfo;

        if (notifications !== null && notifications !== undefined) {
            let notification = {
                unreadMsgs: notifications?.filter((notification) => (notification.DonorNotificationTypeID === 2 && notification.IsAcknowledged === false)),
                allTasks: notifications?.filter((notification) => (notification.DonorNotificationTypeID === 1 && notification.IsAcknowledged === false)),
                videos: notifications?.filter((notification) => (notification.DonorNotificationTypeID === 3 && notification.IsAcknowledged === false)),
                calendar: notifications?.filter((notification) => (notification.DonorNotificationTypeID === 4 && notification.IsAcknowledged === false)),
            }

            if (notification) {
                setNotificationContent(notification);
            }
        }
    }, [notificationInfo, bgNotificationInfo]);

    const handleAcknowledgeNotification = (taskId) => {
        let taskNotification = notificationContent.allTasks.filter(notification => (notification.LinkedObjectID === taskId));
        if (!isEmpty(taskNotification)) {
            let notificationID = taskNotification[0]?.NotificationID;
            let params = {
                endpoint: Endpoints.NOTIFICATION_ACKNOWLEDGE + notificationID,
                method: "PUT",
            };
            dispatch(HomeActions.notificationAcknowledgeRequest(params));
        }
    }


    const getAllTaskList = (day) => {
        let calEvent = bgCalendarEvent || calendarEvent;

        const selectedD = calEvent?.CalendarEvents?.filter((i) =>
            isEqual(parseISO(i.EventDate), day)
        ).map((j) => j.EventDate);

        const selectedTask = calEvent?.CalendarEvents?.filter(
            (i) => taskTypes.some((id) => i.TaskType === id) &&
                isEqual(parseISO(i.EventDate), day)
        );

        const selectedAppointment = calEvent?.CalendarEvents?.filter(
            (i) =>
                i.TaskType === TASK_TYPE_ID.APPOINTMENT &&
                isEqual(parseISO(i.EventDate), day)
        );

        const selectedMeditation = calEvent?.CalendarEvents?.filter(
            (i) =>
                i.EventTypeID === TASK_TYPE.MEDICATION &&
                isEqual(parseISO(i.EventDate), day)
        );

        setAllTask(selectedTask);
        setAllAppointment(selectedAppointment);
        setAllMeditation(selectedMeditation);
        setCountOfTask(selectedD?.length);
        setSelectedDate(day);
        const currDate = format(day, 'yyyy-MM-dd');
        setCurrentDate(format(parseISO(currDate), "MMMM dd"));
        if (selectedTask) {
            setNavigateDate(selectedTask);
        }
    }

    const handleDate = (event, day) => {
        if (bgCalendarEvent || calendarEvent) {
            getAllTaskList(day)
        }
        setSelectedDate(day);
    };

    const scheduleMedication = (data) => { };
    const scheduleAppointment = (data) => {
        if(data?.TaskType === TASK_TYPE_ID.APPOINTMENT && [TASK_STATUS.NEED_APPROVAL, TASK_STATUS.COMPLETE].includes(data?.TaskStatus)) {
            return false
        }
        if (
            data?.TaskType === TASK_TYPE_ID.TASK_SCHEDULE &&
            [
                TASK_SUB_TYPE.SCHEDULE_PHOTO_SHOOT_4,
                TASK_SUB_TYPE.MEDICAL_SCREENING_4,
                TASK_SUB_TYPE.PHYSICAL_EXAM_4,
                TASK_SUB_TYPE.PSYCHOLOGICAL_EXAM_4,
                TASK_SUB_TYPE.SCHEDULE_PCP_OBGYN_4,
            ].includes(data.TaskSubType)
        ) {
            //Appointment
            navigate("/home/task-schedule", {
                state: {
                    id: data?.AssociatedID,
                    page: "Schedule",
                    taskname: "Schedule Photo Shoot",
                    taskInfo: data,
                    title: "Choose a convenient Doctor`s office and call to make your appointment.", // will remove once get data in api
                },
            });
        } else if(data?.TaskType === TASK_TYPE_ID.APPOINTMENT) {
            // Go to appointments
            navigate("/appointment", {
                state: {
                    id: data?.AssociatedID,
                    taskInfo: data,
                },
            });
        }
    };
    // 6, sub 3 answer qusetion
    // 11, 1 complete lab work done
    // 11 2 Complete Testing Kit DONE
    // 3, 1AdHoc task message updates test
    const scheduleTask = (data) => {
        handleAcknowledgeNotification(data?.AssociatedID);
        switch (true) {
            case data?.TaskType === TASK_TYPE_ID.PROFILE: {
                let section_id = PROFILE_ID.DEFAULT;
                if (data.SubTaskType === TASK_SUB_TYPE.COMPLETE_PERSONAL_PROFILE_2) {
                    section_id = PROFILE_ID.COMPLETE_PERSONAL_PROFILE;
                } else if (data.SubTaskType === TASK_SUB_TYPE.COMPLETE_FAMILY_PROFILE_2) {
                    section_id = PROFILE_ID.COMPLETE_FAMILY_PROFILE;
                } else if (data.SubTaskType === TASK_SUB_TYPE.COMPLETE_ESSAYS_2) {
                    section_id = PROFILE_ID.COMPLETE_ESSAYS;
                }
                navigate("/home/provide-info", {
                    state: {
                        url:
                            process.env.REACT_APP_SITE_DOMAIN +
                            "/donors/application/?appTask=1&livdap_section-id=" + section_id,
                        pageTitle: data?.EventName
                    },
                });
                break;
            }
            case (data?.TaskType === TASK_TYPE_ID.ADDITINAL_INFO &&
                ([TASK_SUB_TYPE.SCHEDULE_MEDICAL_SCREENING_3,
                TASK_SUB_TYPE.ADHOC_TAASK_MESSAGE_UPDATE,
                TASK_SUB_TYPE.PHYSICAL_EXAM_4,
                ].includes(data.TaskSubType))) ||
                (data?.TaskType === TASK_TYPE_ID.PENDING_QUESTIONS &&
                    data.TaskSubType === TASK_SUB_TYPE.ANSWER_PENDING_QUESTIONS): {
                    data.ID=data?.AssociatedID;
                    navigate("/home/additional-info", {
                        state: {
                            id: data?.AssociatedID,
                            taskname: data?.EventName,
                            message: data?.EventDescription,
                            taskInfo: data,
                        },
                    });
                    break;
                }
            case data?.TaskType === TASK_TYPE_ID.TASK_SCHEDULE && data.TaskSubType === TASK_SUB_TYPE.SCHEDULE_PHOTO_SHOOT_4: {
                data.ID=data?.AssociatedID;
                navigate("/home/task-schedule", {
                    state: {
                        id: data?.AssociatedID,
                        taskname: "Schedule Photo Shoot",
                        taskInfo: data,
                    },
                });
                break;
            }
            case data?.TaskType === TASK_TYPE_ID.TASK_SCHEDULE &&
                [
                    TASK_SUB_TYPE.PSYCHOLOGICAL_EXAM_4,
                    TASK_SUB_TYPE.SCHEDULE_PCP_OBGYN_4,
                ].includes(data.TaskSubType): {
                    data.ID=data?.AssociatedID;
                    navigate("/home/psychological-evaluation", {
                        state: {
                            id: data?.AssociatedID,
                            taskname: data?.EventName,
                            taskInfo: data,
                        },
                    });
                    break;
                }
            case data?.TaskType === TASK_TYPE_ID.ACUITY &&
                [
                    TASK_SUB_TYPE.SCHEDULE_INTERVIEW_4,
                    TASK_SUB_TYPE.SCHEDULE_AUDIO_INTERVIEW_4,
                ].includes(data.TaskSubType): {
                    data.ID=data?.AssociatedID;
                    navigate("/acuity", {
                        state: {
                            id: data?.AssociatedID,
                            page: "acuity",
                            taskname: data?.EventName,
                            message: data?.EventDescription,
                            taskInfo: data,
                        },
                    });
                    break;
                }
            case data?.TaskType === TASK_TYPE_ID.UPLOAD &&
                [
                    TASK_SUB_TYPE.UPLOAD_GOVT_ID_5,
                    TASK_SUB_TYPE.SUBMIT_6_10_CURRENT_PHOTOS_5,
                    TASK_SUB_TYPE.SUBMIT_4_5_CHILDHOOD_PHOTOS_5,
                    TASK_SUB_TYPE.SUBMIT_PAP_SMEAR_RESULTS_5,
                    TASK_SUB_TYPE.SUBMIT_ACADEMIC_TRANSCRIPTS_5,
                    TASK_SUB_TYPE.SUBMIT_MEDICAL_RECORD_5
                ].includes(data.TaskSubType): {
                    data.ID=data?.AssociatedID;
                    data.minUpload = 0;
                    data.maxUpload = 0;

                    if(TASK_SUB_TYPE.SUBMIT_4_5_CHILDHOOD_PHOTOS_5 === data.TaskSubType)
                    {
                        data.minUpload = 4;
                        data.maxUpload = 5;
                    }
                    if(TASK_SUB_TYPE.SUBMIT_6_10_CURRENT_PHOTOS_5 === data.TaskSubType)
                    {
                        data.minUpload = 6;
                        data.maxUpload = 10;
                    }
                    navigate("/home/upload", {
                        state: {
                            id: data?.AssociatedID,
                            page: "upload",
                            taskname: data?.EventName,
                            message: data?.EventDescription,
                            taskInfo: data,
                        },
                    });
                    break;
                }
            case data?.TaskType === TASK_TYPE_ID.LAB_WORK &&
                ([TASK_SUB_TYPE.SCHEDULE_LAB_WORK, TASK_SUB_TYPE.INFECTION_DISEASE]).includes(data.TaskSubType): {
                    data.ID=data?.AssociatedID;
                    navigate("/home/complete-lab", {
                        state: {
                            id: data?.AssociatedID,
                            pageTitle: data?.EventName,
                            taskInfo: data
                        },
                    });
                    break;
                } case data?.TaskType === TASK_TYPE_ID.LAB_WORK &&
                    ([TASK_SUB_TYPE.TESTING_KIT, TASK_SUB_TYPE.GENETIC_TESTING_KIT]).includes(data.TaskSubType): {
                    data.ID=data?.AssociatedID;
                    navigate("/home/testing-kit", {
                        state: {
                            id: data?.AssociatedID,
                            taskInfo: data
                        },
                    });
                    break;
                } case data?.TaskType === TASK_TYPE_ID.APPOINTMENT &&
                ([TASK_SUB_TYPE.APPOINTMENT_SCHEDULE_LAB_WORK_10,
                    TASK_SUB_TYPE.APPOINTMENT_SCHEDULE_PHYS_EXAM_10,
                    TASK_SUB_TYPE.APPOINTMENT_SCHEDULE_DOCTOR_10,
                    TASK_SUB_TYPE.APPOINTMENT_SCHEDULE_GENERAL_10,
                    TASK_SUB_TYPE.APPOINTMENT_SCHEDULE_INFECTIOUS_DISEASE_TESTING_10,
                    TASK_SUB_TYPE.APPOINTMENT_SCHEDULE_PHOTO_SHOOT_10,
                    TASK_SUB_TYPE.APPOINT_SCHEDULE_INTERVIEW_10,
                    TASK_SUB_TYPE.APPOINTMENT_SCHEDULE_PSYCHOLOGICAL_EVAL_10
                    ]).includes(data.TaskSubType): {
                    data.ID=data?.AssociatedID;
                    navigate("/appointment", {
                        state: {
                            id: data?.AssociatedID,
                            taskInfo: data
                        },
                    });
                    break;
                } case data?.TaskType === TASK_TYPE_ID.NOTIFY && ([TASK_SUB_TYPE.NOTIFY_CLINIC_PERIOD]).includes(data.TaskSubType): {
                    data.ID=data?.AssociatedID;
                    navigate("/home/notify-clinic", {
                        state: {
                            taskInfo: data,
                            prevPage: "/task-calendar"
                        },
                    });
                    break;
                }

                case data?.TaskType === TASK_TYPE_ID.SESSION && data.TaskSubType === TASK_SUB_TYPE.COMPLETE_INFO_SESSION_9: {
                    data.ID=data?.AssociatedID;
                    navigate("/home/video-session", {
                        state: {
                            id: data?.AssociatedID,
                            taskname: "Complete Info Session",
                            taskInfo: data,
                        },
                    });
                    break;
                }
                case data?.TaskType === TASK_TYPE_ID.DOCU_SIGN && data.TaskSubType === TASK_SUB_TYPE.DOCU_SIGN_PAGE: {
                    data.ID = data?.AssociatedID;
                    navigate("/home/docu-sign", {
                        state: {
                            taskInfo: data
                        },
                    });
                    break;
                }
                case data?.TaskType === TASK_TYPE_ID.TRAVEL_FORM && ([TASK_SUB_TYPE.TRAVEL_FORM_SUBTYPE]).includes(data.TaskSubType): {
                    data.ID = data?.AssociatedID;
                    navigate("/travel-form", {
                        state: {
                            taskInfo: data,
                            prevPage: "/task-calendar"
                        },
                    });
                    break;
                }
                case data?.TaskType === TASK_TYPE_ID.TRAVEL_HANDOUT && ([TASK_SUB_TYPE.TRAVEL_HANDOUT_SUBTYPE]).includes(data.TaskSubType): {
                    data.ID = data?.AssociatedID;
                    navigate("/home/travel-program-handout", {
                        state: {
                            taskInfo: data,
                            prevPage: "/task-calendar"
                        },
                    });
                    break;
                }
                default:
                    return null
        }
    };
    const showReminderModel = () => {
        setShowReminderModal(!showReminderModal);
    };

    const handleCalendarLink = (val) => {
        let [start, end] = formatDate(val.EventDateTime, val.TimezoneID);
        console.log(val);
        console.log('dates ===== ', start, end);

        let dtParams = '';
        let alarm = {
            trigger: '',
            repeat: '',
            duration: '',
        }

        if(val.EventTime === "12:00 AM"){ // all day event
            dtParams = ';VALUE=DATE'; // change format to DATE
            alarm.trigger = '-PT12H';
            alarm.repeat = '0';
            alarm.duration = 'PT12H';
        } else {
            dtParams = ';TZID=' + timeZones[val.TimezoneID];
            alarm.trigger = '-PT2H';
            alarm.repeat = '1';
            alarm.duration = 'PT1H';
        }

        let eventDescription = val.EventDescription;

        eventDescription = eventDescription.replace(/\r\n/g, "<br />");
        eventDescription = eventDescription.replace(/\t/g, "&nbsp;&nbsp;&nbsp;&nbsp;");

        let eventLocation = val.EventLocation;
        //"Texas Fertility Center, 6500 N Mopac Expressway Building 1, Suite 1200, Austin, TX, 78731, United States";//val.EventLocation;
        eventLocation = eventLocation.replace(/,/g, "\\,");

        // Create the .ics URL
        let url = [
            'BEGIN:VCALENDAR',
            'VERSION:2.0',
            'BEGIN:VEVENT',
            'DTSTART' + dtParams + ':' + start,
            'DTEND' + dtParams + ':' + end,
            'SUMMARY:' + val.EventName,
            'X-ALT-DESC;FMTTYPE=text/html:'+ eventDescription,
            // 'DESCRIPTION;ENCODING=QUOTED-PRINTABLE:tet=0D=0D=0D=0ATEST12312',//+ val.EventDescription,
            "LOCATION:" + eventLocation,//val.EventLocation,
            'BEGIN:VALARM',
            'TRIGGER:' + alarm.trigger,
            'REPEAT:' + alarm.repeat,
            'DURATION:' + alarm.duration,
            'ACTION:DISPLAY',
            'DESCRIPTION:Reminder:' + val.EventName,
            'END:VALARM',
            'END:VEVENT',
            'END:VCALENDAR',
        ].join('\n');
        let blob = new Blob([url], { type: 'text/calendar;charset=utf-8' });
        const currentDate = new Date();
        const day = getDate(currentDate);
        const year = getYear(currentDate);
        const month = getMonth(currentDate) + 1;
        if (/msie\s|trident\/|edge\//i.test(window.navigator.userAgent)) {
            // Open/Save link in IE and Edge
            window.navigator.msSaveBlob(blob, `reminder_${month}${year}.ics`);
        } else {
            // Open/Save link in Modern Browsers
            const url1 = 'data:text/calendar;charset=utf8,' + url;
            const fileName = `reminder_${day}_${month}_${year}.ics`;
            downloadICSFile(url1, fileName);
        }
    };
    const downloadICSFile =(url, fileName) =>{
        const temporaryAnchor = document.createElement('a');
        temporaryAnchor.href = encodeURI(url);
        temporaryAnchor.setAttribute('download', fileName);
        temporaryAnchor.click();
    }
    const formatDate = (dateString, zone) => {
        let dateTime = new Date(dateString);
        let nextDay = dayjs(dateTime).add(1, 'day');
        let nextDateString = dayjs(nextDay).tz(timeZones[zone],true).format('YYYY-MM-DDTHH:mm:ss');
        let startDateEndTime = dayjs(dateTime).add(1, 'hour');
        let startDateEndTimeString = dayjs(startDateEndTime).tz(timeZones[zone],true).format('YYYY-MM-DDTHH:mm:ss');

        // console.log(dateString, nextDateString);
        let startDate = dateString.replace(/[-:]/g, "");
        let time = startDate.split('T')[1];
        let endDate = time !== "000000" ? startDateEndTimeString.replace(/[-:]/g, "") : nextDateString.replace(/[-:]/g, "");

        if(time === "000000") { // remove time if all day event
            startDate = startDate.split('T')[0];
            endDate = endDate.split('T')[0];
        }

        return [startDate, endDate]
    };

    return (
        <Layout showHeader="false">
            <BackgroundFetch requestBy={['calevents']} />
            {(eventDonorLoading || notificationLoading) && <Loader />}
            {showReminderModal && (
                <ListModal
                    selectedReminder={selectedReminder}
                    handleReminder={setSelectedReminder}
                    showReminderModel={() => showReminderModel()}
                />
            )}
            <div className={styles.pageTitleSection}>
                <h1>
                    <CalendarIcon className={styles.headerIcon} />
                    <span className={styles.taskText}>Task Calendar</span>
                </h1>
                <Link
                    to={state?.prevPage ? state?.prevPage : "/home"}
                    state={{ menu: state?.menu }}
                >
                    <IoIosArrowBack
                        className={`${styles.backIcon} float-end position-absolute`}
                    />
                </Link>
            </div>
            <div className={styles.screenLayout}>
                <div className={styles.screenoutLayout}>
                    <Scrollbars
                            ref={calendarScrollRef}
                            className="scroll-container"
                        >
                        <div className={styles.calendarContainer}>
                            <Calendar
                                onDateClick={(event, day) => handleDate(event, day)}
                                selectedDate={selectedDate}
                                task={task}
                                appointment={appointment}
                                medication={meditation}
                                type={"task"}
                            />
                        </div>
                    </Scrollbars>
                </div>
            </div>
           
            <div className={styles.mainDiv}>
                <div className={styles.taskLegend}>
                    <ul className={styles.lineOftask}>
                        <li className={styles.task}>Task</li>
                        <li className={`${styles.medication} d-none`}>Medication</li>
                        <li className={styles.appointment}>Appointment</li>
                    </ul>
                    <div className={styles.taskInfo}>
                        <span className={styles.selectedMonth}>{currentDate}</span>
                        <span className={styles.noOfTasks}>
                            {countOfTask === 0 ? 0 + " Tasks" : countOfTask > 1 ? countOfTask + " Tasks" : countOfTask + " Task"}
                        </span>
                    </div>
                </div>
                <div className={styles.taskList}>
                    {allAppointment?.length !== 0 || allTask?.length !== 0 || allMeditation?.length !== 0 ?
                        <ul>
                            {allAppointment?.map((val, key) => {
                                let disabled = (val.TaskType === TASK_TYPE_ID.APPOINTMENT) && [TASK_STATUS.NEED_APPROVAL, TASK_STATUS.COMPLETE].includes(val.TaskStatus);

                                return (
                                    <ListOfTask
                                        key={key}
                                        handleOnClick={() => scheduleAppointment(val)}
                                        event={val}
                                        handleModel={() => showReminderModel()}
                                        handleCaleLink={() => handleCalendarLink(val)}
                                        showAlert={false}
                                        disabled={disabled}
                                    />
                                );
                            })}
                            {allTask?.map((val, key) => {
                                return (
                                    <ListOfTask
                                        key={key}
                                        handleOnClick={() => scheduleTask(val)}
                                        event={val}
                                        handleModel={() => showReminderModel()}
                                        handleCaleLink={() => handleCalendarLink(val)}
                                        showAlert={false}
                                    />
                                );
                            })}
                            {allMeditation?.map((val, key) => {
                                return (
                                    <ListOfTask
                                        key={key}
                                        handleOnClick={() =>
                                            scheduleMedication(val)
                                        }
                                        event={val}
                                        handleModel={() => showReminderModel()}
                                        showButton={true}
                                        showAlert={false}
                                    />
                                );
                            })}
                        </ul>
                        : <ul className={styles.noTask}> <span >You don't have any tasks due on this day.</span> </ul>
                    }
                </div>
            </div>
        </Layout>
    );
};

export default TaskCalendar;
