import styles from "./Alert.module.scss";
import { motion } from "framer-motion";

const Alert = (props) => {
    return (
        <motion.div className=""
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: .2, delay: 0.3 }}
        >
            <div className={styles.loginFrmValidationError}>
                <span className={styles.exlIcon}>!</span>
                <span className={styles.errMsg}>
                    {props.message}
                </span>
            </div>
        </motion.div>
    );
}

export default Alert;
