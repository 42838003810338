
import { combineReducers } from "redux";
import { getBasicReducer } from "../../../core/utils";
import * as Constants from "./constants";

export const calculatorData = combineReducers({
    calculation: getBasicReducer({
        request: Constants.BMI_CALCULATOER_REQUEST,
        success: Constants.BMI_CALCULATOER_SUCCESS,
        failure: Constants.BMI_CALCULATOER_ERROR,
        clear: Constants.BMI_CALCULATOER_CLEAR,
    }),
});