/*screen name + methodname+ actiontype*/

export const TEST_KIT_SEND_TO_APPROVE_REQUEST = 'TEST_KIT_SEND_TO_APPROVE_REQUEST';
export const TEST_KIT_SEND_TO_APPROVE_SUCCESS = 'TEST_KIT_SEND_TO_APPROVE_SUCCESS';
export const TEST_KIT_SEND_TO_APPROVE_ERROR = 'TEST_KIT_SEND_TO_APPROVE_ERROR';
export const TEST_KIT_SEND_TO_APPROVE_CLEAR = 'TEST_KIT_SEND_TO_APPROVE_CLEAR';

export const LAB_WORK_GET_REQUEST = 'LAB_WORK_GET_REQUEST';
export const LAB_WORK_GET_SUCCESS = 'LAB_WORK_GET_SUCCESS';
export const LAB_WORK_GET_ERROR = 'LAB_WORK_GET_ERROR';
export const LAB_WORK_GET_CLEAR = 'LAB_WORK_GET_CLEAR';

export const LAB_APPOINTMENT_ADD_REQUEST = 'LAB_APPOINTMENT_ADD_REQUEST';
export const LAB_APPOINTMENT_ADD_SUCCESS = 'LAB_APPOINTMENT_ADD_SUCCESS';
export const LAB_APPOINTMENT_ADD_ERROR = 'LAB_APPOINTMENT_ADD_ERROR';
export const LAB_APPOINTMENT_ADD_CLEAR = 'LAB_APPOINTMENT_ADD_CLEAR';

export const LAB_FILE_DOWNLOAD_REQUEST = 'LAB_FILE_DOWNLOAD_REQUEST';
export const LAB_FILE_DOWNLOAD_SUCCESS = 'LAB_FILE_DOWNLOAD_SUCCESS';
export const LAB_FILE_DOWNLOAD_ERROR = 'LAB_FILE_DOWNLOAD_ERROR';
export const LAB_FILE_DOWNLOAD_CLEAR = 'LAB_FILE_DOWNLOAD_CLEAR';

export const LAB_BLOOD_GET_REQUEST = 'LAB_BLOOD_GET_REQUEST';
export const LAB_BLOOD_GET_SUCCESS = 'LAB_BLOOD_GET_SUCCESS';
export const LAB_BLOOD_GET_ERROR = 'LAB_BLOOD_GET_ERROR';
export const LAB_BLOOD_GET_CLEAR = 'LAB_BLOOD_GET_CLEAR';
