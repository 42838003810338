/*screen name + methodname+ actiontype*/
export const TRAVEL_GET_LIST_REQUEST = 'TRAVEL_GET_LIST_REQUEST';
export const TRAVEL_GET_LIST_SUCCESS = 'TRAVEL_GET_LIST_SUCCESS';
export const TRAVEL_GET_LIST_ERROR = 'TRAVEL_GET_LIST_ERROR';
export const TRAVEL_GET_LIST_CLEAR = 'TRAVEL_GET_LIST_CLEAR';

export const ADD_TRAVEL_REQUEST = 'ADD_TRAVEL_REQUEST';
export const ADD_TRAVEL_SUCCESS = 'ADD_TRAVEL_SUCCESS';
export const ADD_TRAVEL_ERROR = 'ADD_TRAVEL_ERROR';
export const ADD_TRAVEL_CLEAR = 'ADD_TRAVEL_CLEAR';

export const TRAVEL_GET_DETAILS_REQUEST = 'TRAVEL_GET_DETAILS_REQUEST';
export const TRAVEL_GET_DETAILS_SUCCESS = 'TRAVEL_GET_DETAILS_SUCCESS';
export const TRAVEL_GET_DETAILS_ERROR = 'TRAVEL_GET_DETAILS_ERROR';
export const TRAVEL_GET_DETAILS_CLEAR = 'TRAVEL_GET_DETAILS_CLEAR';

export const TRAVEL_GET_ITINERY_REQUEST = 'TRAVEL_GET_ITINERY_REQUEST';
export const TRAVEL_GET_ITINERY_SUCCESS = 'TRAVEL_GET_ITINERY_SUCCESS';
export const TRAVEL_GET_ITINERY_ERROR = 'TRAVEL_GET_ITINERY_ERROR';
export const TRAVEL_GET_ITINERY_CLEAR = 'TRAVEL_GET_ITINERY_CLEAR';

export const TRAVEL_DETAILS_DATA = 'TRAVEL_DETAILS_DATA';
export const TRAVEL_DETAILS_DATA_SUCCESS = 'TRAVEL_DETAILS_DATA_SUCCESS';
export const TRAVEL_DETAILS_DATA_ERROR = 'TRAVEL_DETAILS_DATA_ERROR';
export const TRAVEL_DETAILS_DATA_CLEAR = 'TRAVEL_DETAILS_DATA_CLEAR';

export const TRAVEL_HANDOUT_APPROVE_REQUEST = 'TRAVEL_HANDOUT_APPROVE_REQUEST';
export const TRAVEL_HANDOUT_APPROVE_SUCCESS = 'TRAVEL_HANDOUT_APPROVE_SUCCESS';
export const TRAVEL_HANDOUT_APPROVE_ERROR = 'TRAVEL_HANDOUT_APPROVE_ERROR';
export const TRAVEL_HANDOUT_APPROVE_CLEAR = 'TRAVEL_HANDOUT_APPROVE_CLEAR';
