/*screen name + methodname+ actiontype*/
/*Acuity Screen */
export const ACUITY_SLOTS_GET_LIST_REQUEST = 'ACUITY_SLOTS_GET_LIST_REQUEST';
export const ACUITY_SLOTS_GET_LIST_SUCCESS = 'ACUITY_SLOTS_GET_LIST_SUCCESS';
export const ACUITY_SLOTS_GET_LIST_ERROR = 'ACUITY_SLOTS_GET_LIST_ERROR';
export const ACUITY_SLOTS_GET_LIST_CLEAR = 'ACUITY_SLOTS_GET_LIST_CLEAR';

export const ADD_ACUITY_INTERVIEW_REQUEST = 'ADD_ACUITY_INTERVIEW_REQUEST';
export const ADD_ACUITY_INTERVIEW_SUCCESS = 'ADD_ACUITY_INTERVIEW_SUCCESS';
export const ADD_ACUITY_INTERVIEW_ERROR = 'ADD_ACUITY_INTERVIEW_ERROR';
export const ADD_ACUITY_INTERVIEW_CLEAR = 'ADD_ACUITY_INTERVIEW_CLEAR';

export const ACUITY_DAYS_GET_LIST_REQUEST = 'ACUITY_DAYS_GET_LIST_REQUEST';
export const ACUITY_DAYS_GET_LIST_SUCCESS = 'ACUITY_DAYS_GET_LIST_SUCCESS';
export const ACUITY_DAYS_GET_LIST_ERROR = 'ACUITY_DAYS_GET_LIST_ERROR';
export const ACUITY_DAYS_GET_LIST_CLEAR = 'ACUITY_DAYS_GET_LIST_CLEAR';

export const ACUITY_TIME_SLOT_GET_LIST_REQUEST = 'ACUITY_TIME_SLOT_GET_LIST_REQUEST';
export const ACUITY_TIME_SLOT_GET_LIST_SUCCESS = 'ACUITY_TIME_SLOT_GET_LIST_SUCCESS';
export const ACUITY_TIME_SLOT_GET_LIST_ERROR = 'ACUITY_TIME_SLOT_GET_LIST_ERROR';
export const ACUITY_TIME_SLOT_GET_LIST_CLEAR = 'ACUITY_TIME_SLOT_GET_LIST_CLEAR';