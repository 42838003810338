import { forwardRef } from "react";
import DatePicker from "react-datepicker";
import { addDays } from "date-fns";

const DateTimePicker = forwardRef((props, ref) => {
    const {
        startDate,
        monthFormat = "MMMM",
        yearDropdown = 15,
        mode = "select",
        timeFormat = "h:mm aa",
        caption = "Time",
        dateFormat = "MMMM d, yyyy h:mm aa",
        onChange,
        customInput
    } = props;

    return (
        <DatePicker
            selected={startDate}
            customInput ={customInput}
            // className={styles.dateinputTxt}
            onChange={onChange}
            disabledKeyboardNavigation={false}
            showTimeSelect
            peekNextMonth
            showMonthDropdown
            useShortMonthInDropdown
            showYearDropdown
            dropdownMode={mode}
            dateFormatCalendar={monthFormat}
            yearDropdownItemNumber={yearDropdown}
            scrollableYearDropdown
            timeFormat={timeFormat}
            timeIntervals={30}
            timeCaption={caption}
            dateFormat={dateFormat}
            minDate={new Date()}
            maxDate={addDays(new Date(), 90)}
            calendarClassName="clsCalendarClsName"
            popperPlacement="bottom"
            ref={ref}
        />
    );
});
export default DateTimePicker;
