import styles from "./Menu.module.scss";
import { useState,useEffect } from "react";
import { motion } from "framer-motion";
import { useCookies } from 'react-cookie';
import { Link } from 'react-router-dom';
import useHandleLogOut from '../../pages/hooks/useHandleLogOut';
import { ReactComponent as PeriodActiveTracker } from '../../assets/images/period-tracker-icon-active.svg';
import { Scrollbars } from "react-custom-scrollbars-2";
import { debounce } from "lodash";
import { Button } from 'react-bootstrap';

function NavigationMenu() {
  const [cookies, setCookie, removeCookie] = useCookies(['wp-login-user-token']);
  const [scrollbarHeight, setScrollBarHeight] = useState(window.screen.availHeight);
  const sidebar = {
    open: (height = 700) => ({
      clipPath: `circle(${height * 2 + 200}px at 40px 40px)`,
      transition: {
        type: "spring",
        stiffness: 20,
        restDelta: 2
      }
    }),
    closed: {
      clipPath: "circle(22px at 37px 40px)",
      transition: {
        delay: 0.05,
        type: "spring",
        stiffness: 400,
        damping: 40
      }
    }
  };

  const ulVariants = {
    open: {
      transition: { staggerChildren: 0.07, delayChildren: 0.2 }
    },
    closed: {
      transition: { staggerChildren: 0.05, staggerDirection: -1 }
    }
  };
  const logOut = useHandleLogOut(setCookie, removeCookie);

  const getContentHeight = () => {
      let mainElement = document.getElementsByClassName("app");
      let footerElement = document.querySelector(".footerElement");

      // 8 - additional space to avoid main element scroll
      return mainElement[0]?.offsetHeight - footerElement.clientHeight - 8;
  }

    useEffect(() => {
        // set scroll height
        const debouncedHandleResize = debounce(function handleResize() {
            setScrollBarHeight(getContentHeight());
        }, 300);

        setScrollBarHeight(getContentHeight());
        window.addEventListener("resize", debouncedHandleResize);

        // cleanup listener
        return _ => {
            window.removeEventListener('resize', debouncedHandleResize);
        }
    }, [scrollbarHeight]);

  return (
    <motion.div className={" " + styles.background} variants={sidebar}>

        <Scrollbars
              renderTrackVertical={props => <div {...props} className="track-vertical"/>}
              autoHeight
              autoHeightMax={scrollbarHeight}
              style={{ height: scrollbarHeight }}
              className={`${styles.menuScroll} scroll-container`}
        >
          <motion.ul variants={ulVariants} className={styles.mainMenu}>
              <li>
                <Link to='/tutorial' state={{ prevPage: window.location.pathname,menu:"open" }}>App Tutorial</Link>
              </li>
                {
                    cookies["HasTravelItinerary"] === "true" &&
                    <li>
                        <Link to='/travel-details' state={{ prevPage: window.location.pathname, menu: "open" }}>My Travel Details</Link>
                    </li>
                }
              
              <li>
                <Link to='/my-clinic' state={{ prevPage: window.location.pathname,menu:"open" }}>My Clinic</Link>
              </li>
              <li>
                <h3 className={`${styles.subMenu} mb-2`}>Information</h3>
                      <ul>
                          <li><Link to='/photoguide' state={{ prevPage: window.location.pathname, menu: "open" }}>Egg Donor Photo Guidelines</Link></li>
                          <li><Link to='/faqs' state={{ prevPage: window.location.pathname,menu:"open" }}>FAQs</Link></li>
                          <li><Link to='/eligibilty' state={{ prevPage: window.location.pathname,menu:"open" }}>Eligibility Requirements</Link></li>
                          <li><Link to='/egg-process' state={{ prevPage: window.location.pathname, menu: "open" }}>Egg Donor Process</Link></li>
                          <li>
                            <Link to='/id-non-id' state={{ prevPage: window.location.pathname,menu:"open" }}>ID Option Vs Non-ID Option</Link>
                          </li>
                          <li>
                            <Link to='/travel-program' state={{ prevPage: window.location.pathname,menu:"open" }}>Travel Program</Link>
                          </li>
                          <li>
                              <Link to='/travel-handout' state={{ prevPage: window.location.pathname, menu: "open" }}>Travel Program Handout</Link>
                          </li>
                          <li>
                            <Link to='/day-of-retrieval-tips' state={{ prevPage: window.location.pathname,menu:"open" }}>Day of Retrieval Tips</Link>
                          </li>
                          <li>
                            <Link to='/zika-zones' state={{ prevPage: window.location.pathname,menu:"open" }}>Zika zones
                              <span style={{display:"inline-block",fontSize:"0.85em",width:"100%"}}>(Where NOT to travel while cycling)</span>
                            </Link>
                          </li>
                          <li>
                              <Link to='/pwa-instructions' state={{ prevPage: window.location.pathname, menu: "open" }}>Add to Mobile Device</Link>
                          </li>
                          <li>
                              <a href="https://mdrmedcoach.com/" target="_self" rel="noopener noreferrer">
                                  Injection Tutorials
                              </a>
                          </li>
                          <li id="injectionCoach" style={{display:"none"} }>
                              <a href="https://www.mdrmedcoach.com" target="_self" rel="noopener noreferrer">
                                  Injection Coach
                              </a>
                          </li>
                    </ul>
              </li>
              <li>
                <Link to='/bmi-calculator' state={{ prevPage: window.location.pathname,menu:"open" }}>BMI Calculator</Link>
              </li>
              <li>
                <Link to='/period-tracker'  state={{ prevPage: window.location.pathname, menu:"open",  }}>Period Tracker</Link>
                <PeriodActiveTracker className={styles.PTmenuImg}/>
              </li>
              <li>
                <Link to='/task-calendar' state={{ prevPage: window.location.pathname,menu:"open", }}>Task Calendar</Link>
              </li>
              <li>
                <Link to='/blogs' state={{ prevPage: window.location.pathname,menu:"open" }}>Blogs</Link>
              </li>
              <li>
                  <Link to='/locations' state={{ prevPage: window.location.pathname,menu:"open" }}>Locations</Link>
              </li>
              <li>
                <Link to='/refer-a-friend' state={{ prevPage: window.location.pathname,menu:"open" }}>Refer a Friend</Link>
              </li>
              <li>
                <Link to='/video-library' state={{ prevPage: window.location.pathname,menu:"open" }}>Video Library</Link>
              </li>
              <li>
                <Link to='/profile' state={{ prevPage: window.location.pathname,menu:"open" }}>My Profile</Link>
              </li>
               <li>
                <Link to='/logout' >Logout</Link>
              </li>
            </motion.ul>
        </Scrollbars>
      </motion.div>
  );
}
export default NavigationMenu;
