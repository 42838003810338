
import { combineReducers } from "redux";
import { getBasicReducer } from "../../../core/utils";
import * as Constants from "./constants";

export const logoutData = combineReducers({
    logoutUser: getBasicReducer({
        request: Constants.LOGOUT_USER_REQUEST,
        success: Constants.LOGOUT_USER_SUCCESS,
        failure: Constants.LOGOUT_USER_ERROR,
        clear: Constants.LOGOUT_USER_CLEAR,
    }),
});
