import * as Constants from './constants';

/* Task actions */
export const taskFetchRequest = (data) => ({
    type: Constants.BG_TASK_FETCH_REQUEST,
    payload: data,
});

export const taskFetchSuccess = (data) => ({
    type: Constants.BG_TASK_FETCH_SUCCESS,
    payload: data,
});

export const taskFetchError = (error) => ({
    type: Constants.BG_TASK_FETCH_ERROR,
    payload: error,
});

export const taskFetchClear = () => ({
    type: Constants.BG_TASK_FETCH_CLEAR,
});

/* Notification actions */
export const notificationInfo = (data) => ({
    type: Constants.BG_NOTIFICATION_GET_REQUEST,
    payload: data,
});

export const notificationInfoSuccess = (data) => ({
    type: Constants.BG_NOTIFICATION_GET_SUCCESS,
    payload: data,
});

export const notificationInfoError = (error) => ({
    type: Constants.BG_NOTIFICATION_GET_ERROR,
    payload: error,
});

export const notificationInfoClear = () => ({
    type: Constants.BG_NOTIFICATION_GET_CLEAR,
});

/* Calendar actions */
export const getDonorEventRequest = (data) => ({
    type: Constants.BG_CALENDAR_GET_DONOR_EVENT_REQUEST,
    payload: data,
});
export const getDonorEventSuccess = (data) => ({
    type: Constants.BG_CALENDAR_GET_DONOR_EVENT_SUCCESS,
    payload: data,
});
export const getDonorEventError = (error) => ({
    type: Constants.BG_CALENDAR_GET_DONOR_EVENT_ERROR,
    payload: error,
});
export const getDonorEventClear = () => ({
    type: Constants.BG_CALENDAR_GET_DONOR_EVENT_CLEAR,
});

/* Message actions */
export const msgGetListRequest = (data) => ({
    type: Constants.BG_MESSAGE_GET_LIST_REQUEST,
    payload: data,
});

export const msgGetListSuccess = (data) => ({
    type: Constants.BG_MESSAGE_GET_LIST_SUCCESS,
    payload: data,
});

export const msgGetListError = (error) => ({
    type: Constants.BG_MESSAGE_GET_LIST_ERROR,
    payload: error,
});

export const msgGetListClear = () => ({
    type: Constants.BG_MESSAGE_GET_LIST_CLEAR,
});
