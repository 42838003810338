import React, { useEffect } from "react";
import styles from "./Description.module.scss";
import { Button } from "react-bootstrap";

export default function TaskDescription (props) {
    useEffect(() => {
        
    }, []);

    return (
        <>
            {
                props?.taskDetails && (props?.taskDetails?.RejectReason || props?.taskDetails?.AdHocDescription || props?.taskDetails?.EventDescription) &&
                <div className={`wrapper ${props.styles} ${styles.taskDescription}`} id="taskDescription">
                    {
                        (props?.taskDetails?.IsRejected && props?.taskDetails?.RejectReason) &&
                        <p className={`text-left ${styles.rejectedDescription}`}>
                            <strong>Updates needed:</strong><br />
                            {props?.taskDetails?.RejectReason}
                        </p>
                    }
                    {
                        (props?.taskDetails?.AdHocDescription || props?.taskDetails?.EventDescription) &&
                        <div className={`text-left ${styles.adhocTaskDescription}`}>
                            {
                                   (props?.taskDetails?.TaskType === 11 && (props?.taskDetails?.SubTaskType === 3 || props?.taskDetails?.TaskSubType === 3)) ?
                                    <p style={{ textAlign: "center" }}>
                                        <Button variant="link" onClick={() => props.showDesc()}>
                                            Click here to view instructions
                                        </Button>
                                    </p>
                                    :
                                    <>
                                        {props?.taskDetails?.AdHocDescription ?? props?.taskDetails?.EventDescription}
                                    </>
                            }
                            
                        </div>
                    }
                </div>
            }
        </>
    );
}
