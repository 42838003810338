import React from "react";
import PageTitle from "../components/PageTitle";

const ListHeader = (props) => {
    const { taskname, handleNavigation, title, titleLine, appointmentShow } = props;
    return (
        <>
            <PageTitle
                title={taskname}
                onClose={handleNavigation}
            />
            {appointmentShow ?
                <div
                    className="wrapper py-3 mt-0 d-flex flex-column"
                    style={{ fontSize: "18px", whiteSpace: "pre-wrap", paddingLeft: 20, paddingRight: 20 }}
                >
                    {title}
                    {titleLine ? '\n' + titleLine : ''}
                </div>
                : null
            }
        </>
    );
};

export default ListHeader;
