import { useEffect, useRef, useState } from "react";
import { useLocation, Link } from "react-router-dom";
import { BsXLg } from "react-icons/bs";
import { useSelector, useDispatch } from "react-redux";
import Loader from "../components/ui/Loader";
import customParseFormat from "dayjs/plugin/customParseFormat";
import Form from "react-bootstrap/Form";
import dayjs from "dayjs";
import * as Actions from "./state/message/actions";
import styles from "./Blog.module.scss";
import Layout from "../components/ui/Layout";
import { IoIosArrowBack } from "react-icons/io";
import { Scrollbars } from "react-custom-scrollbars-2";
import { debounce } from "lodash";
import parse from 'html-react-parser';

dayjs.extend(customParseFormat);
function BlogPage() {
    const dispatch = useDispatch();
    let { state } = useLocation();
    const {
        notificationData: { blogCateg, blogCategList, blogDetails },
    } = useSelector((state) => state);
    const { response: blogCate, isLoading: blogCateLoading } = blogCateg || {};
    const { response: blogCateList, isLoading: cateListLoading } =
        blogCategList || {};
    const { response: blogDetail, isLoading: detailLoading } =
    blogDetails || {};

    const [blogPost, setBlogPost] = useState([]);
    const [blogCategory, setBlogCategory] = useState([]);
    const [perPage] = useState(5);
    const [pager, setPager] = useState(1);
    const [totalPage, setTotalPage] = useState(0);
    const [pageno, setPagerno] = useState();
    const blogpostList = useRef([]);
    const loadMore = useRef(1);
    const blogRef = useRef();
    const [postDetails, setPostDetails] = useState([]);
    const [scrollbarHeight, setScrollBarHeight] = useState(window.screen.availHeight);

    useEffect(() => {
        document.title = "Blog Page";
        dispatch(Actions.blogDetailClear());
        blogpostList.current = [];
        getCategories();
        setPostDetails([]);
        setPager(1);

        if (document.getElementById("pagerElm") !== null)
            document.getElementById("pagerElm").textContent = 1;

        setBlogPost([]);
        blogpostList.current = [];
        window.console.log("===postDetails==", postDetails)
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        
        if (
            typeof blogDetail !== "undefined" &&
            blogDetail !== null &&
            blogDetail
        ) {
            setPostDetails(blogDetail);
        }
    }, [blogDetail]);

    useEffect(() => {
        if (
            typeof blogCateList !== "undefined" &&
            blogCateList !== null &&
            blogCateList
        ) {
            const { headers, data } = blogCateList;
            setTotalPage(parseInt(headers["x-wp-totalpages"]));
            let bloglist = data;
            if (blogpostList.current.length > 0) {
                bloglist = [...blogpostList.current, ...data];
                bloglist = [
                    ...new Map(
                        bloglist.map((item) => [item["id"], item])
                    ).values(),
                ];
            }
            blogpostList.current = bloglist;
            setBlogPost([...blogPost, ...data]);
            if (totalPage === parseInt(pageno)) {
                loadMore.current = 0;
            } else {
                loadMore.current = 1;
            }
        }
        // eslint-disable-next-line
    }, [blogCateList]);

    useEffect(() => {
        if (
            typeof blogCate !== "undefined" &&
            blogCate !== null &&
            blogCate
        ) {
            
            let eggdonorInfo = blogCate.filter(item => item['slug'] === "egg-donor");
            if (eggdonorInfo?.length > 0) {
                setBlogCategory(eggdonorInfo[0].id);
            }
        }
        // eslint-disable-next-line
    }, [blogCate]);

    useEffect(() => {
        if (blogCategory) {
            fetchBlogs(blogCategory);
        }

    }, [blogCategory]);// eslint-disable-line react-hooks/exhaustive-deps
    

    const getContentHeight = () => {
        let mainElement = document.getElementsByClassName("app");
        let headerElement = document.querySelector('.headerElement');
        let footerElement = document.querySelector('.footerElement');
        return mainElement[0]?.offsetHeight - (headerElement?.clientHeight + footerElement?.clientHeight);
    }

    useEffect(() => {
        // set scroll window height
        setScrollBarHeight(getContentHeight());

        const debouncedHandleResize = debounce(function handleResize() {
            setScrollBarHeight(getContentHeight());
        }, 300);
        window.addEventListener("resize", debouncedHandleResize);

        // cleanup listener
        return _ => {
            window.removeEventListener('resize', debouncedHandleResize);
        }
    }, [scrollbarHeight]);

    const getCategories = async () => {
        let params = {
            order: "asc",
            hide_empty: true,
        };
        dispatch(Actions.getCategoriesRequest(params));
    };

    const fetchBlogs = async (catid) => {
        let pagerno =
            document.getElementById("pagerElm") != null
                ? document.getElementById("pagerElm").textContent
                : 1;
        setPagerno(pagerno);
        const params = {
            per_page: perPage,
            page: pagerno,
            order: "desc",
        };
        
        if (catid !== "") {
            params.categories = catid;
        }

        dispatch(Actions.getCateListRequest(params));
    };


    const blogScroll = async (e) => {
        const bottom =
            e.target.scrollHeight - e.target.scrollTop - 0 ===
            e.target.clientHeight;
        if (
            bottom &&
            totalPage !== parseInt(document.getElementById("pagerElm").textContent)
        ) {
            console.log("Blog bottom");
        }
    };

    function changePager(e) {
        setPager(e.target.value);
        window.console.log(e);
    }
    const loadMoreResults = () => {
        setPager(pager + 1);
        document.getElementById("pagerElm").textContent =
            parseInt(document.getElementById("pagerElm").textContent) + 1;
        fetchBlogs(blogCategory);
    };

    const backBlogPage = () => {
        setPostDetails([]);
    };

    const handleBlogDetail = async (postID) => {
        dispatch(Actions.blogDetailRequest(postID));
    };


    return (
        <>
            {(blogCateLoading || cateListLoading || detailLoading) && <Loader />}
            <Layout callback={blogScroll} showHeader="false">
                {Object.keys(postDetails).length > 0 ? (
                    <>
                        <div className={`${styles.pageBlogHeader} position-relative d-flex align-items-center justify-content-between headerElement`}>
                            <div
                                className={`${styles.blogBack} cursor-pointer d-flex align-items-center`}
                                onClick={() => backBlogPage()}
                            >
                                <IoIosArrowBack
                                    className={`${styles.blogbackArrow} me-1`}
                                />
                                <span>Back</span>
                            </div>
                            <Link onClick={() => setPostDetails([])}>
                                <BsXLg className={`${styles.closeIcon}`}/>
                            </Link>
                        </div>
                        <Scrollbars
                            id="blogContainer"
                            autoHeight
                            autoHeightMax={scrollbarHeight}
                            className="scroll-container"
                            ref={blogRef}
                        >
                            <div className={styles.blogPage}>
                                <div className={styles.posts} id="blogPostLists">
                                    <div className={styles.blogImage}
                                        style={{backgroundImage: `url(${postDetails.featured_image_src_large[0]})`}}
                                    >
                                     
                                    </div>
                                    <div className={styles.postDesc}>
                                        <h3
                                            className={styles.postTitle}
                                        >{parse(postDetails.title.rendered)}</h3>
                                        <div className={styles.postMisc}>
                                            <span>
                                                {dayjs(postDetails.date).format("MMM D,YYYY")}
                                            </span>{" "}
                                            -
                                            {postDetails.category_info &&
                                                postDetails.category_info.map(
                                                    (category, i) => {
                                                        let separator = postDetails.category_info.length - 1 === i ? "" : ",";
                                                        return (
                                                            <span key={category.term_id}>
                                                                {" "}
                                                                {category.name}
                                                                {separator}{" "}
                                                            </span>
                                                        );
                                                    }
                                                )}
                                        </div>

                                        <p>{parse(postDetails.content.rendered)}</p>
                                    </div>
                                </div>
                            </div>
                        </Scrollbars>
                    </>
                ) : (
                    <>
                        <div className={`${styles.pageBlogHeader} position-relative d-flex justify-content-end headerElement`}>
                            <Link to={state?.prevPage ? state?.prevPage : "/home"} state={{ menu: state?.menu }}>
                                <BsXLg
                                    className={`${styles.closeIcon}`}
                                />
                            </Link>
                        </div>
                        <div className={`${styles.blogListTitle}`}>
                            Egg Donor
                        </div>
                            <div className={styles.blogSearch} style={{ display: "none" }}>
                               
                            <Form.Text
                                style={{ display: "none" }}
                                id="pagerElm"
                                value={pager}
                                onChange={changePager}
                            >
                                {pager}
                            </Form.Text>
                        </div>
                        <div className={styles.blogContainer} id="blogContainer">
                            <div className={styles.blogList}>
                                <Scrollbars
                                    autoHeight
                                    autoHeightMax={scrollbarHeight - 120}
                                    className="scroll-container"
                                    ref={blogRef}
                                >
                                    <ul id="blogPostLists">
                                        {blogpostList &&
                                            blogpostList.current.map((post) => {
                                                return (
                                                    <li
                                                        key={post.id}
                                                        className={`${styles.posts} `}
                                                    >
                                                        <div
                                                            className={
                                                                styles.blogImage
                                                            }
                                                            style={{
                                                                backgroundImage: `url(${post.featured_image_src_large[0]})`,
                                                            }}
                                                        >
                                                       
                                                        </div>
                                                        <div
                                                            className={
                                                                styles.postDesc
                                                            }
                                                        >
                                                            <h3
                                                                className={
                                                                    styles.postTitle
                                                                }
                                                            >
                                                                {parse(post.title.rendered) }
                                                            </h3>
                                                            <div
                                                                className={
                                                                    styles.postMisc
                                                                }
                                                            >
                                                                <span>
                                                                    {dayjs(
                                                                        post.date
                                                                    ).format(
                                                                        "MMM D,YYYY"
                                                                    )}
                                                                </span>{" "}
                                                                -
                                                                {post.category_info &&
                                                                    post.category_info.map(
                                                                        (
                                                                            category,
                                                                            i
                                                                        ) => {
                                                                            let separator = post.category_info.length - 1 === i ? "" : ",";
                                                                            return (
                                                                                <span key={category.term_id}>
                                                                                    {" "}
                                                                                    {category.name}
                                                                                    {separator}{" "}
                                                                                </span>
                                                                            );
                                                                        }
                                                                    )}
                                                            </div>
                                                            <p
                                                                className={
                                                                    styles.excerptMsg
                                                                }
                                                            >
                                                                {parse(post.excerpt.rendered)}
                                                            </p>
                                                            <div
                                                                style={{
                                                                    textAlign:
                                                                        "center",
                                                                    marginTop:
                                                                        "30px",
                                                                    marginBottom:
                                                                        "30px",
                                                                }}
                                                            >
                                                                <Link className={styles.readMore}
                                                                    onClick={() => handleBlogDetail(post.id)}
                                                                    href="#"
                                                                >
                                                                    Read More
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </li>
                                                );
                                            })}
                                    </ul>
                                    {loadMore.current === 1 && (
                                        <button
                                            onClick={loadMoreResults}
                                            className={styles.loadmore}
                                        >
                                            Load More
                                        </button>
                                    )}
                                </Scrollbars>
                            </div>
                        </div>
                    </>
                )}
            </Layout>
        </>

    );
}

export default BlogPage;
