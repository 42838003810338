import { takeLatest, put, call } from "redux-saga/effects";
import {Endpoints} from '../../../core/networking';
import * as Constants from "./constants";
import * as Actions from "./actions";
import AxiosInstance from "../../../services/AxiosInstance";
import {ERROR_TYPES} from '../types';

export function* watchMenuPageSaga() {
  yield takeLatest(Constants.MENU_GET_PAGE, getPageData);
  yield takeLatest(Constants.REFERAL_ADD, submitReferalForm);
  yield takeLatest(Constants.CLINIC_REQUEST, getClinicInfo);
}

export function* getPageData(action) {
  try {
    const pramas = action.payload;
    const response = yield call(taskNetworkRequest, pramas);
    
    if (response.status === 200) {
      yield put(Actions.menuPageSuccess(response.data));
    } else {
      yield put(
        Actions.menuPageError({
          errorCode: ERROR_TYPES.INVALID_DATA,
          message: ERROR_TYPES.INVALID_DATA,
        })
      );
    }
  } catch (error) {
    yield put(Actions.menuPageError(error));
  }
}

export function taskNetworkRequest(params){
  const url = Endpoints.GET_MENU_PAGE;
  const response =  AxiosInstance.get(url, { params });
  return response;
}

export function* submitReferalForm(action) {
  try {
    const response = yield call(addReferalForm, action.payload);
    if (response.status === 200) {
      yield put(Actions.referalRequestSuccess(response.data));
    } else {
      yield put(
        Actions.referalRequestError({
          errorCode: ERROR_TYPES.INVALID_DATA,
          message: ERROR_TYPES.INVALID_DATA,
        })
      );
    }
  } catch (error) {
    yield put(Actions.referalRequestError(error));
  }
}

export function fairfaxRequest(params){
  const url = Endpoints.FAIR_FAX_PORTAL_API;
  const response =  AxiosInstance.post(url, { params });
  return response;
}

export function* getClinicInfo(action) {
  try {
    const pramas = action.payload;
    const response = yield call(fairfaxRequest, pramas);
    
    if (response.status === 200) {
      yield put(Actions.myClinicGetSuccess(response.data));
    } else {
      yield put(
        Actions.myClinicGetError({
          errorCode: ERROR_TYPES.INVALID_DATA,
          message: ERROR_TYPES.INVALID_DATA,
        })
      );
    }
  } catch (error) {
    yield put(Actions.myClinicGetError(error));
  }
}

export function addReferalForm(params){
  const url = Endpoints.SUBMIT_REFERAL_FORM;
  const response =  AxiosInstance.post(url, params );
  return response;
}
