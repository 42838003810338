import styles from "./MyClinic.module.scss";
import Layout from "../components/ui/Layout";
import { useLocation } from 'react-router-dom';
import { useEffect,useState} from "react";
import Loader from "../components/ui/Loader";
import { useSelector, useDispatch } from "react-redux";
import * as Actions from "./state/menupage/actions";
import { Endpoints } from "../core/networking";
import { debounce } from "lodash";
import { Scrollbars } from "react-custom-scrollbars-2";
import PageTitle from "./components/PageTitle";

const MyClinic = () => {
    const dispatch = useDispatch();
    let { state } = useLocation();
    const {
        loginData: { loginUser },
        menuPageData: { clinicInfo },
    } = useSelector((state) => state);
    const { response: loginVal } = loginUser || {};
    const { response: clinicVal, isLoading: clinicLoading } = clinicInfo || {};
    const [clinicData,setClinicData] = useState([]);
    const [scrollbarHeight, setScrollBarHeight] = useState(window.screen.availHeight);

    const getClinicAddress = (clinicType) => {
        let address = "";
        if (clinicType === "screening") {
            address += clinicData?.screeningClinicAddress ? clinicData?.screeningClinicAddress.trim() + ", " : "";
            address += clinicData?.screeningClinicCity ? clinicData?.screeningClinicCity.trim() + "," : "";
            address += clinicData?.screeningClinicState ? " " + clinicData?.screeningClinicState.trim() : "";
            address += clinicData?.screeningClincZipCode ? " " + clinicData?.screeningClincZipCode.trim() : "";
        }

        if (clinicType === "retrieval") {
            address += clinicData?.retrievalClinicAddress ? clinicData?.retrievalClinicAddress.trim() + ", " : "";
            address += clinicData?.retrievalClinicCity ? clinicData?.retrievalClinicCity.trim() + "," : "";
            address += clinicData?.retrievalClinicState ? " " + clinicData?.retrievalClinicState.trim() : "";
            address += clinicData?.retrievalClincZipCode ? " " + clinicData?.retrievalClincZipCode.trim() : "";
        }

        return address;
    };

    function renderClinicAddress(clinicType) {

        if (clinicType === "screening") {
            return (
                <>
                    {clinicData?.screeningClinicAddress && <>{clinicData?.screeningClinicAddress.trim()},<br /></>}
                    {clinicData?.screeningClinicCity && <>{clinicData?.screeningClinicCity.trim()}, </>}
                    {clinicData?.screeningClinicState && <>{clinicData?.screeningClinicState.trim()}</>}
                    {clinicData?.screeningClincZipCode && <> {clinicData?.screeningClincZipCode.trim()}</>}
                </>               
            )
        }

        if (clinicType === "retrieval") {
            return (
                <>
                    {clinicData?.retrievalClinicAddress && <>{clinicData?.retrievalClinicAddress.trim()},<br /></>}
                    {clinicData?.retrievalClinicCity && <>{clinicData?.retrievalClinicCity.trim()}, </>}
                    {clinicData?.retrievalClinicState && <>{clinicData?.retrievalClinicState.trim()}</>}
                    {clinicData?.retrievalClincZipCode && <> {clinicData?.retrievalClincZipCode.trim()}</>}
                </>
            )
        }
    }

    const getContentHeight = () => {
        let mainElement = document.getElementsByClassName("app");
        let headerElement = document.querySelector('.homePageTitle');
        let footerElement = document.querySelector('.footerElement');

        return mainElement[0]?.offsetHeight - (headerElement.clientHeight + footerElement.clientHeight + 12);
    }

    useEffect(() => {
        const debouncedHandleResize = debounce(function handleResize() {
            setScrollBarHeight(getContentHeight());
        }, 300);

        // set scroll window height
        setScrollBarHeight(getContentHeight());
        window.addEventListener("resize", debouncedHandleResize);

        // cleanup listener
        return _ => {
            window.removeEventListener('resize', debouncedHandleResize);
        }
    }, [scrollbarHeight]);

    useEffect(() => {
        document.title = 'My Clinic';
        dispatch(Actions.myClinicGetClear());
        let applicantID = loginVal?.data?.applicant_id;
        if (applicantID !== "") {
            const taskEndPoint = Endpoints.MY_CLINIC_GET_DATA + applicantID;//"163581"//applicantID;
            let params = { endpoint: taskEndPoint, method: "GET" };
            dispatch(Actions.myClinicGetRequest(params));
        }
    },[dispatch, loginVal?.data?.applicant_id]);

    useEffect(() => {
        window.console.log("===clinicVal===",clinicVal);
        if (typeof clinicVal !== "undefined" && clinicVal) {
          
            setClinicData(clinicVal);
		}
	}, [clinicVal]);

    return (
        <>  {(clinicLoading) && <Loader />}
            <Layout showHeader="false">
                <div className={styles.stickyHeader}>
                    <PageTitle
                        title="My Clinic"
                        headerClass="homePageTitle"
                        linkTo={state?.prevPage ? state?.prevPage : -1}
                        linkState={{ menu: state?.menu }}
                    />
                </div>
                {
                    clinicData !== undefined &&
                    <Scrollbars
                        autoHeight
                        autoHeightMax={scrollbarHeight}
                        className="scroll-container"
                    >
                    <div className={`position-relative ${styles.clinicContainer}`} id="myclinicContainer">
                                <h4 className={styles.heading}>Your Fairfax EggBank Coordinator</h4>
                                <div className={styles.coOrdinator}>
                                    <span className={styles.head}>{clinicData?.fairfaxCoordinatorName}</span>
                                    {
                                        clinicData?.fairfaxCoordinatorEmail &&
                                        <a href={`mailto:${clinicData?.fairfaxCoordinatorEmail}`}>{clinicData?.fairfaxCoordinatorEmail}</a>
                                    }
                                    {
                                        clinicData?.fairfaxCoordinatorPhone &&
                                        <a href={`tel:${clinicData?.fairfaxCoordinatorPhone}`}>{clinicData?.fairfaxCoordinatorPhone}</a>
                                    }
                                </div>
                                <div className={styles.yourCoordinator}>
                                    {
                                        (clinicData?.retrievalClinicID || clinicData?.screeningClinicID || clinicData?.dmaMyClinicCoordinators) &&
                                        <>
                                            <h3 className={styles.heading} >Your Clinic Info</h3>

                                            <h4 className={styles.subheading} >Screening Clinic</h4>

                                            <p className={styles.head} style={{ paddingLeft: "10px", marginTop: "10px", marginBottom: "0px" }}>{clinicData?.screeningClinicName}</p>
                                            <div>
                                                <a target="_blank" rel="noreferrer"
                                                    href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(getClinicAddress("screening"))}`}
                                                        style={{ marginBottom: "2px", display: "inline-block", paddingLeft: "10px" }}
                                                >
                                                    {renderClinicAddress("screening")}
                                                </a>
                                                {

                                                    (clinicData?.dmaMyScreeningClinicEmail) &&
                                                    <>
                                                        <a href={`mailto:${clinicData?.dmaMyScreeningClinicEmail}`} style={{ display: "inline-block", width: "100%", paddingLeft: "10px", marginBottom: "15px" }}>
                                                            {clinicData?.dmaMyScreeningClinicEmail}
                                                        </a>
                                                    </>
                                                }
                                            </div>

                                            <h4 className={styles.subheading} >Retrieval Clinic</h4>

                                            <p className={styles.head} style={{ paddingLeft: "10px", marginTop: "10px", marginBottom: "0px" }}>{clinicData?.retrievalClinicName}</p>
                                            <div>
                                                <a target="_blank" rel="noreferrer"
                                                    href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(getClinicAddress("retrieval"))}`}
                                                    style={{ marginBottom: "2px", display: "inline-block", paddingLeft: "10px" }}
                                                >
                                                    {renderClinicAddress("retrieval")}
                                                </a>
                                                {

                                                    (clinicData?.dmaMyretrievalClinicEmail) &&
                                                    <>
                                                        <a href={`mailto:${clinicData?.dmaMyretrievalClinicEmail}`} style={{ display: "inline-block", width: "100%", paddingLeft: "10px", marginBottom: "15px" }}>
                                                            {clinicData?.dmaMyretrievalClinicEmail}
                                                        </a>
                                                    </>
                                                }
                                            </div>

                                            {
                                                (clinicData?.dmaMyClinicCoordinators && clinicData?.dmaMyClinicCoordinators?.length > 0) &&
                                                <div className={styles.coOrdinator} style={{ paddingLeft: "10px" }}>
                                                        {
                                                            clinicData.dmaMyClinicCoordinators.map(
                                                                (clinicOrdinator, counter) => {
                                                                    return (
                                                                        <div className={styles.yourContact} key={counter}>
                                                                            <span className={styles.clinicCordName}>{clinicOrdinator?.clinicCoordinatorName}</span>
                                                                            {
                                                                                clinicOrdinator?.clinicContactEmail &&
                                                                                <a href={`mailto:${clinicOrdinator?.clinicContactEmail}`} style={{ display: "inline-block", width: "100%" }}>
                                                                                        {clinicOrdinator?.clinicContactEmail}
                                                                                </a>
                                                                            }
                                                                            {
                                                                                clinicOrdinator?.clinicContactPhone &&
                                                                                <a href={`tel:${clinicOrdinator?.clinicContactPhone}`} style={{ display: "inline-block", width: "100%" }}>
                                                                                        {clinicOrdinator?.clinicContactPhone}

                                                                                </a>
                                                                            } <br />
                                                                        </div>
                                                                    );
                                                                }
                                                            )
                                                        }
                                                </div>
                                            }
                                        </>
                                    }
                                    
                                </div>
                            
                    </div>
                    </Scrollbars>
                }
            </Layout>
        </>
    );
}

export default MyClinic;
