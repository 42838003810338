import styles from "./TestingKit.module.scss";
import Layout from "../components/ui/Layout";
import { useLocation,useNavigate } from 'react-router-dom';
import { useEffect,useState} from "react";
import Loader from "../components/ui/Loader";
import { useSelector, useDispatch } from "react-redux";
import * as Actions from "./state/testkit/actions";
import { Endpoints } from "../core/networking";
import TaskDescription from "./components/TaskDescription";
import PageTitle from "./components/PageTitle";
import { Button } from "react-bootstrap";
import { createPortal } from "react-dom";
import { Scrollbars } from "react-custom-scrollbars-2";


const TestingKit = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    let { state } = useLocation();
    const [scrollbarHeight, setScrollBarHeight] = useState(window.screen.availHeight);
    const {
        testkitData: { completedTests },
    } = useSelector((state) => state);
    const { response: testComplete, isLoading: testCompleteLoading } = completedTests || {};
    const [taskInfo, setTaskInfo] = useState([]);
    const [instructionsModal, setInstructionsModal] = useState(false);

    useEffect(() => {
        // set scroll window height
        const mainElement = document.getElementsByClassName("app");
        setScrollBarHeight(mainElement[0]?.offsetHeight - 200);
        window.addEventListener("resize", function () {
            setScrollBarHeight(mainElement[0]?.offsetHeight - 200);
        });
    }, [scrollbarHeight]);

    useEffect(() => {
        document.title = 'Testing Kit';
        setTaskInfo(location?.state?.taskInfo);
        window.console.log(location?.state?.taskInfo);
        dispatch(Actions.sendToApproveClear());
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    const submitTestComplete = () => {
        if (taskInfo?.ID) {
            const taskcompleteEndpoint =
                Endpoints.HOME_COMPLETED_DONOR + taskInfo?.ID;
            let params = {
                endpoint: taskcompleteEndpoint,
                method: "PUT",
            };
            dispatch(Actions.sendToApproveRequest(params));
        }
    }

    const handleClose = () => {
        setInstructionsModal(false);
    }

    function openInstructionModel() {
        setInstructionsModal(true);
    }

    useEffect(() => {
        if (testComplete?.status === 200) {
            dispatch(Actions.sendToApproveClear());
            navigate(-1);
        }
    }, [testComplete]);// eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            {(testCompleteLoading) && <Loader />}
            {
                instructionsModal && createPortal(
                    <div className={styles.testingKitModal}>{window.console.log("---scrollbarHeight----", scrollbarHeight) }
                        <div className={styles.modalOverlay}></div>
                        <div className={styles.contactModal}>
                            <div className={styles.modalContent}>
                                <div className={styles.modalTitle}>Instructions</div>
                                <div className={styles.instructions}>
                                    <Scrollbars autoHeight autoHeightMax={scrollbarHeight} className="scroll-container">
                                        <span
                                            className={`${styles.spanKitInstructions} float-end`}
                                            role="textarea"
                                            contentEditable={false}
                                            suppressContentEditableWarning={true}
                                        >
                                            {taskInfo?.AdHocDescription ?? taskInfo?.EventDescription}
                                        </span>
                                    </Scrollbars>
                                    <div className={`${styles.footer} text-center`}>
                                        <Button className="px-4 py-2" onClick={handleClose}>Close</Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ,
                    document.querySelector('.app')
                )
            }
            <Layout showHeader="false">
                <div className={styles.stickyHeader}>
                    <PageTitle
                        title={taskInfo?.EventName ? taskInfo?.EventName : taskInfo?.TaskName}
                        headerClass="homePageTitle"
                        linkTo={state?.prevPage ? state?.prevPage : -1}
                        linkState={{ menu: state?.menu }}
                    />
                </div>
                <div className={`position-relative ${styles.videoContainer}`} id="testingContainer">
                    <div className={styles.scrollContent}>
                        <div className={styles.testingSection}>
                            <TaskDescription taskDetails={taskInfo} showDesc={openInstructionModel} />
                            
                            {
                                taskInfo?.TrackingNumber &&
                                <div className={styles.testingTrackPackage}>
                                    <span>You can track the package here:</span>
                                        <a href={taskInfo.TrackingNumber} target="_blank" rel="noopener noreferrer">
                                        {taskInfo?.TrackingNumber}
                                    </a>
                                </div>
                            }
                            <div className={styles.testingCompleteInfo}>
                                <p>Once you receive the testing kit. Follow the instructions and return using provided mailing label.</p>
                            </div>
                            <div className={styles.completeButton}>
                                <input type="button" onClick={() => submitTestComplete()} className={styles.button} value="Test Completed" />
                            </div>
                        </div>
                    </div>
                </div>                
            </Layout>
        </>
    );
}

export default TestingKit;
