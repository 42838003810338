
import { combineReducers } from "redux";
import { getBasicReducer } from "../../../core/utils";
import * as Constants from "./constants";

export const notificationData = combineReducers({
    msgList: getBasicReducer({
        request: Constants.MESSAGE_GET_LIST_REQUEST,
        success: Constants.MESSAGE_GET_LIST_SUCCESS,
        failure: Constants.MESSAGE_GET_LIST_ERROR,
        clear: Constants.MESSAGE_GET_LIST_CLEAR,
    }),
    msgUserList: getBasicReducer({
        request: Constants.MSG_GET_USER_LIST_REQUEST,
        success: Constants.MSG_GET_USER_LIST_SUCCESS,
        failure: Constants.MSG_GET_USER_LIST_ERROR,
        clear: Constants.MSG_GET_USER_LIST_CLEAR,
    }),
    addMessage: getBasicReducer({
        request: Constants.ADD_MESSAGE_REQUEST,
        success: Constants.ADD_MESSAGE_SUCCESS,
        failure: Constants.ADD_MESSAGE_ERROR,
        clear: Constants.ADD_MESSAGE_CLEAR,
    }),
    messageInfo: getBasicReducer({
        request: Constants.GET_MESSAGE_INFO_REQUEST,
        success: Constants.GET_MESSAGE_INFO_SUCCESS,
        failure: Constants.GET_MESSAGE_INFO_ERROR,
        clear: Constants.GET_MESSAGE_INFO_CLEAR,
    }),
    // blog screen
    blogCateg: getBasicReducer({
        request: Constants.GET_CATEGORIES_REQUEST,
        success: Constants.GET_CATEGORIES_SUCCESS,
        failure: Constants.GET_CATEGORIES_ERROR,
        clear: Constants.GET_CATEGORIES_CLEAR,
    }),
    blogCategList: getBasicReducer({
        request: Constants.GET_CATEGORIES_LIST_REQUEST,
        success: Constants.GET_CATEGORIES_LIST_SUCCESS,
        failure: Constants.GET_CATEGORIES_LIST_ERROR,
        clear: Constants.GET_CATEGORIES_LIST_CLEAR,
    }),
    blogDetails: getBasicReducer({
        request: Constants.BLOG_DETAILS_REQUEST,
        success: Constants.BLOG_DETAILS_SUCCESS,
        failure: Constants.BLOG_DETAILS_ERROR,
        clear: Constants.BLOG_DETAILS_CLEAR,
    }),
    fileDownload: getBasicReducer({
        request: Constants.UPLOAD_FILE_DOWNLOAD_REQUEST,
        success: Constants.UPLOAD_FILE_DOWNLOAD_SUCCESS,
        failure: Constants.UPLOAD_FILE_DOWNLOAD_ERROR,
        clear: Constants.UPLOAD_FILE_DOWNLOAD_CLEAR,
    }),
});
