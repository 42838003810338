import axios from 'axios';
import { createBrowserHistory } from 'history';

const history = createBrowserHistory({ forceRefresh: true });

const axiosInstance = axios.create({
    baseURL : process.env.REACT_APP_API_URL
});

axiosInstance.interceptors.request.use(
    request => {
        const token = localStorage.getItem('token');
        request.headers['MOBILEAPP'] = true;
        if (token) {
            request.headers['Authorization'] = 'Bearer ' + token
        }
        return request;
    },
    config => {
        return config
    },
    error => {
        Promise.reject(error)
    }
)

axiosInstance.interceptors.response.use(
    (response) => {
        // Extract the status code from the response
        const statusCode = response.status;
        return response;
    },
    (error) => {
        // Handle errors here
        if (error?.response) {
            // redirect to logout
            if (error.response?.status === 403 || error.response?.status === 401) {
                if (error.response?.data?.code === "jwt_auth_invalid_token" ||
                    error.response?.data?.code === "jwt_auth_bad_config" ||
                    error.response?.data?.code === "jwt_auth_bad_request" ||
                    error.response?.data?.code === "jwt_auth_bad_auth_header" ||
                    error.response?.data?.code === "network_token_blacklisted") {
                    window.location.href = "/";
                }

            }
        }
        return Promise.reject(error);
    }
)

export default axiosInstance;
