import * as Constants from './constants';

export const msgGetListRequest = (data) => ({
    type: Constants.MESSAGE_GET_LIST_REQUEST,
    payload: data,
});

export const msgGetListSuccess = (data) => ({
    type: Constants.MESSAGE_GET_LIST_SUCCESS,
    payload: data,
});

export const msgGetListError = (error) => ({
    type: Constants.MESSAGE_GET_LIST_ERROR,
    payload: error,
});

export const msgGetListClear = () => ({
    type: Constants.MESSAGE_GET_LIST_CLEAR,
});

export const getMsgUserListRequest= (data) => ({
    type: Constants.MSG_GET_USER_LIST_REQUEST,
    payload: data,
});

export const getMsgUserListSuccess = (data) => ({
    type: Constants.MSG_GET_USER_LIST_SUCCESS,
    payload: data,
});

export const getMsgUserListError = (error) => ({
    type: Constants.MSG_GET_USER_LIST_ERROR,
    payload: error,
});

export const getMsgUserListClear = () => ({
    type: Constants.MSG_GET_USER_LIST_CLEAR,
});

export const addMsgRequest= (data) => ({
    type: Constants.ADD_MESSAGE_REQUEST,
    payload: data,
});

export const addMsgSuccess = (data) => ({
    type: Constants.ADD_MESSAGE_SUCCESS,
    payload: data,
});

export const addMsgError = (error) => ({
    type: Constants.ADD_MESSAGE_ERROR,
    payload: error,
});

export const addMsgClear = () => ({
    type: Constants.ADD_MESSAGE_CLEAR,
});

export const getMsgInfoRequest= (data) => ({
    type: Constants.GET_MESSAGE_INFO_REQUEST,
    payload: data,
});

export const getMsgInfoSuccess = (data) => ({
    type: Constants.GET_MESSAGE_INFO_SUCCESS,
    payload: data,
});

export const getMsgInfoError = (error) => ({
    type: Constants.GET_MESSAGE_INFO_ERROR,
    payload: error,
});

export const getMsgInfoClear = () => ({
    type: Constants.GET_MESSAGE_INFO_CLEAR,
});

/* Blog */
export const getCategoriesRequest = (data) => ({
    type: Constants.GET_CATEGORIES_REQUEST,
    payload: data,
});

export const getCategoriesSuccess = (data) => ({
    type: Constants.GET_CATEGORIES_SUCCESS,
    payload: data,
});

export const getCategoriesError = (error) => ({
    type: Constants.GET_CATEGORIES_ERROR,
    payload: error,
});

export const getCategoriesClear = () => ({
    type: Constants.GET_CATEGORIES_CLEAR,
});

export const getCateListRequest = (data) => ({
    type: Constants.GET_CATEGORIES_LIST_REQUEST,
    payload: data,
});

export const getCateListSuccess = (data) => ({
    type: Constants.GET_CATEGORIES_LIST_SUCCESS,
    payload: data,
});

export const getCateListError = (error) => ({
    type: Constants.GET_CATEGORIES_LIST_ERROR,
    payload: error,
});

export const getCateListClear = () => ({
    type: Constants.GET_CATEGORIES_LIST_CLEAR,
});

export const blogDetailRequest = (data) => ({
    type: Constants.BLOG_DETAILS_REQUEST,
    payload: data,
});

export const blogDetailSuccess = (data) => ({
    type: Constants.BLOG_DETAILS_SUCCESS,
    payload: data,
});

export const blogDetailError = (error) => ({
    type: Constants.BLOG_DETAILS_ERROR,
    payload: error,
});

export const blogDetailClear = () => ({
    type: Constants.BLOG_DETAILS_CLEAR,
});

export const fileDownloadRequest= (data) => ({
    type: Constants.UPLOAD_FILE_DOWNLOAD_REQUEST,
    payload: data,
});

export const fileDownloadSuccess = (data) => ({
    type: Constants.UPLOAD_FILE_DOWNLOAD_SUCCESS,
    payload: data,
});

export const fileDownloadError = (error) => ({
    type: Constants.UPLOAD_FILE_DOWNLOAD_ERROR,
    payload: error,
});

export const fileDownloadClear = () => ({
    type: Constants.UPLOAD_FILE_DOWNLOAD_CLEAR,
});
