import styles from "./NotifyClinic.module.scss";
import Layout from "../components/ui/Layout";
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from "react";
import Loader from "../components/ui/Loader";
import { useSelector, useDispatch } from "react-redux";
import * as Actions from "./state/notify/actions";
import * as menuActions from "./state/menupage/actions";
import { Endpoints } from "../core/networking";
import { BsChevronRight } from "react-icons/bs";
import TaskDescription from "./components/TaskDescription";
import PageTitle from "./components/PageTitle";
import { createPortal } from "react-dom";
import { motion } from "framer-motion";


const NotifyClinic = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    let { state } = useLocation();
    const [scrollbarHeight, setScrollBarHeight] = useState(window.screen.availHeight);
    const {
        loginData: { loginUser },
        notifyData: { completedTask },
        menuPageData: { clinicInfo }
    } = useSelector((state) => state);
    const { response: completedResponse, isLoading: completedlLoading } = completedTask || {};
    const { response: loginVal } = loginUser || {};
    const { response: clinicVal, isLoading: clinicLoading } = clinicInfo || {};
    const [taskInfo, setTaskInfo] = useState([]);
    const [coOrdinator, setcoOrdinator] = useState([]);
    const [showNotifyModal, setShowNotifyModal] = useState(false);

    useEffect(() => {
        // set scroll window height
        const mainElement = document.getElementsByClassName("app");
        const dropdownElement = document.querySelector(".dropdownElement");
        setScrollBarHeight(mainElement[0]?.offsetHeight - dropdownElement?.offsetHeight);
        window.addEventListener("resize", function () {
            setScrollBarHeight(mainElement[0]?.offsetHeight - dropdownElement?.offsetHeight);
        });
    }, [scrollbarHeight]);

    useEffect(() => {
        if (typeof clinicVal !== "undefined" && clinicVal) {
            setcoOrdinator(clinicVal);
        }
    }, [clinicVal]);

    useEffect(() => {
        document.title = 'Notify Clinic';
        setTaskInfo(location?.state?.taskInfo);
        dispatch(Actions.sendToApproveClear());

        let applicantID = loginVal?.data?.applicant_id;
        dispatch(menuActions.myClinicGetClear());

        if (applicantID) {
            let myclinicEndpoint = Endpoints.MY_CLINIC_GET_DATA + applicantID;
            let param = { endpoint: myclinicEndpoint, method: "GET" };
            dispatch(menuActions.myClinicGetRequest(param));
        }

        setShowNotifyModal(false);
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (typeof completedResponse !== 'undefined' && completedResponse !== null && completedResponse) {
            dispatch(Actions.sendToApproveClear());
            navigate(-1);
        }
    }, [completedResponse]);// eslint-disable-line react-hooks/exhaustive-deps

    const handleSubmit = () => {
        setShowNotifyModal(true);
    }

    const submitNotifyComplete = () => {
        
        let tID = (taskInfo?.ID ?? taskInfo?.AssociatedID);
        if (!tID) {
            tID = location?.state?.notifyTaskID;
        }
        if (tID) {
            const taskcompleteEndpoint =
                Endpoints.HOME_COMPLETED_DONOR + tID;
            let params = {
                endpoint: taskcompleteEndpoint,
                method: "PUT"
            };

            dispatch(Actions.sendToApproveRequest(params));
        }
    }

    const sendMessageToCoordinator = () => {


        if (coOrdinator && (coOrdinator?.primaryClinicCoordinatorID || coOrdinator?.fairfaxCoordinatorID || coOrdinator?.dmaMyClinicCoordinators?.length > 0)) {

            let coOrdinatorList = [];
            
            if (coOrdinator?.primaryClinicCoordinatorID  !== null)
            {
                coOrdinatorList.push({
                    ID: coOrdinator.primaryClinicCoordinatorID,
                    Name: coOrdinator.primaryClinicCoordinatorName,
                    selected: true
                });
            }


            if(coOrdinator?.fairfaxCoordinatorID !== null)
            {
                coOrdinatorList.push({
                    ID:  coOrdinator.fairfaxCoordinatorID,
                    Name: coOrdinator.fairfaxCoordinatorName,
                    selected: true
                });
            }

            if (coOrdinator && coOrdinator?.dmaMyClinicCoordinators) {
                
                for (let i = 0; i < coOrdinator?.dmaMyClinicCoordinators?.length; i++) {
                    coOrdinatorList.push({
                        ID: coOrdinator.dmaMyClinicCoordinators[i].clinicCoordinatorID,
                        Name: coOrdinator.dmaMyClinicCoordinators[i].clinicCoordinatorName,
                        selected: true
                    });
                }
            }
                      
            navigate("/messages", {
                    state: {
                            redirect: "/home/notify-clinic",
                            notifyTaskID: taskInfo?.ID,
                            receipients : coOrdinatorList,
                            newMessage: true,
                            messageSubject:"Day 1 of period"
                    }
            });

        }
    }
    const closePopup = () => {
        setShowNotifyModal(false);
    }
    const gotToSubmitNotify = () => {
        setShowNotifyModal(false);
        submitNotifyComplete();
    }

    return (
        <>
            {(completedlLoading || clinicLoading) && <Loader />}
            {
                showNotifyModal && createPortal(
                    <div className={`${styles.notifyModalContainer} position-absolute w-100 top-0 start-0 end-0 bottom-0`}>
                        <motion.div className={`${styles.notifyModal} position-absolute top-50`}
                            initial={{ opacity: 0, scale: 0.5, translateY: '-50%' }}
                            animate={{ opacity: 1, scale: 1, translateY: '-50%' }}
                            transition={{ duration: 0.3 }}
                        >
                            <div className={styles.notifyModal} >
                                <div className={styles.modalHeader}>
                                    Have you messaged your clinic to notify them?
                                </div>
                                <div className={styles.modalDetails}>
                                    If not, click No and then click Send message to notify them.
                                </div>
                                <div className={styles.modalFooter}>
                                    <input type="button" onClick={() => gotToSubmitNotify()} className={styles.footerbutton} value="Yes" />
                                    <input type="button" onClick={() => closePopup()} className={styles.footerbutton} value="No" />
                                </div>
                            </div>
                        </motion.div>
                    </div>,
                    document.querySelector('.app')
                )
            }
            <Layout showHeader="false">
                <div className={styles.stickyHeader}>
                    <PageTitle
                        title="Notify Clinic on Day 1 of Period"
                        headerClass="homePageTitle"
                        linkTo={state?.prevPage ? state?.prevPage : "/home"}
                    />
                </div>
                <div className={`position-relative ${styles.notifycontainer}`} id="notifycontainer">
                    <div className={styles.notifySection}>
                        <div className={styles.messageSection}>
                            <TaskDescription styles={`px-0 py-0 my-2`} taskDetails={taskInfo} />
                            

                            <p className={styles.taskDescription}>
                                Please contact your coordinator and notify them when you start your period.
                            </p>
                            <div className={`text-center`}>
                                <button className={`position-relative ${styles.roundedBackgroundButton}`} onClick={() => sendMessageToCoordinator()} >
                                    Send Message
                                    <BsChevronRight className={styles.buttonIcon} />
                                </button>
                            </div>
                        </div>
                        <div className={`position-relative ${styles.completeButton}`}>
                            <button className={`${styles.button}`} onClick={() => handleSubmit() }>
                                Task Complete
                                <BsChevronRight className={styles.buttonIcon} />
                            </button>
                        </div>
                    </div>
                </div>
            </Layout>

        </>
    );
}

export default NotifyClinic;
