import * as Constants from './constants';

export const menuPageRequest = (data) => ({
    type: Constants.MENU_GET_PAGE,
    payload: data,
});

export const menuPageSuccess = (data) => ({
    type: Constants.MENU_GET_PAGE_SUCCESS,
    payload: data,
});

export const menuPageError = (error) => ({
    type: Constants.MENU_GET_PAGE_ERROR,
    payload: error,
});

export const menuPageClear = () => ({
    type: Constants.MENU_GET_PAGE_CLEAR
});

export const addReferalRequest = (data) => ({
    type: Constants.REFERAL_ADD,
    payload: data,
});

export const referalRequestSuccess = (data) => ({
    type: Constants.REFERAL_SUCCESS,
    payload: data,
});

export const referalRequestError = (error) => ({
    type: Constants.REFERAL_ERROR,
    payload: error,
});

export const referalRequestClear = () => ({
    type: Constants.REFERAL_CLEAR
});

export const myClinicGetRequest = (data) => ({
    type: Constants.CLINIC_REQUEST,
    payload: data,
});

export const myClinicGetSuccess = (data) => ({
    type: Constants.CLINIC_REQUEST_SUCCESS,
    payload: data,
});

export const myClinicGetError = (error) => ({
    type: Constants.CLINIC_REQUEST_ERROR,
    payload: error,
});

export const myClinicGetClear = () => ({
    type: Constants.CLINIC_REQUEST_CLEAR
});
