import { call, put, takeLatest } from "redux-saga/effects";
import { Endpoints } from '../../../core/networking';
import * as Constants from "./constants";
import * as Actions from "./actions";
import AxiosInstance from "../../../services/AxiosInstance";
import { ERROR_TYPES } from '../types';

export function* watchBgSaga() {
    yield takeLatest(Constants.BG_TASK_FETCH_REQUEST, bgTaskFetchRequest);
    yield takeLatest(Constants.BG_NOTIFICATION_GET_REQUEST, bgNotificationFetchRequest);
    yield takeLatest(Constants.BG_CALENDAR_GET_DONOR_EVENT_REQUEST, bgGetDonorEvent);
    yield takeLatest(Constants.BG_MESSAGE_GET_LIST_REQUEST, bgMsgListRequest);
}

export function* bgTaskFetchRequest(action) {
    try {
        const params = action.payload;
        const response = yield call(networkRequest, params);
        if (response.status === 200) {
            yield put(Actions.taskFetchSuccess(response.data));
        } else {
            yield put(
                Actions.taskFetchError({
                    errorCode: ERROR_TYPES.INVALID_DATA,
                    message: ERROR_TYPES.INVALID_DATA,
                })
            );
        }
    } catch (error) {
        yield put(Actions.taskFetchError(error));
    }
}

export function* bgNotificationFetchRequest(action)
{
    try {
        const params = action.payload;
        const response = yield call(networkRequest, params);
        if (response.status === 200) {
            yield put(Actions.notificationInfoSuccess(response.data));
        } else {
            yield put(
                Actions.notificationInfoError({
                    errorCode:ERROR_TYPES.INVALID_DATA,
                    message: ERROR_TYPES.INVALID_DATA,
                })
            );
        }
    } catch (error) {
        yield put(Actions.notificationInfoError(error));
    }
}

export function* bgGetDonorEvent(action) {
    try {
        const params = action.payload;
        const response = yield call(networkRequest, params);
        if (response.status === 200) {
            yield put(Actions.getDonorEventSuccess(response.data));
        } else {
            yield put(
                Actions.getDonorEventError({
                    errorCode:ERROR_TYPES.INVALID_DATA,
                    message: ERROR_TYPES.INVALID_DATA,
                })
            );
        }
    } catch (error) {
        yield put(Actions.getDonorEventError(error));
    }
}

export function* bgMsgListRequest(action) {
    try {
        const params = action.payload;
        const response = yield call(networkRequest, params);
        if (response.status === 200) {
            yield put(Actions.msgGetListSuccess(response.data));
        } else {
            yield put(
                Actions.msgGetListError({
                    errorCode: ERROR_TYPES.INVALID_DATA,
                    message: ERROR_TYPES.INVALID_DATA,
                })
            );
        }
    } catch (error) {
        yield put(Actions.msgGetListError(error));
    }
}

export function networkRequest(params){
    return AxiosInstance.post(Endpoints.FAIR_FAX_PORTAL_API, {params});
}
