import { ReactComponent as TaskComplete } from '../assets/images/task-complete.svg';
import { ReactComponent as TaskProgress } from '../assets/images/task-progress.svg';
import styles from "./HeaderProgress.module.scss";

const HeaderProgress = (props) => {
    let progressData = props?.progressData;
    
    let currentStep = progressData?.ID;

    const Step = ({stepNo, label}) => {
        return (
            <div className={styles.steps}>
                <span style={{display:"block",width:"100%"}}>
                    <span className={styles.bubbleImg}>
                        { currentStep > stepNo ? <TaskComplete/> : (currentStep === stepNo ? <TaskProgress/> : null) }
                    </span>
                    <label>{ label }</label>
                </span>
            </div>
        );
    }
    
    return (
        <div className={` ${(progressData.ID < 5 || progressData.ID === undefined) ? styles.progressSection : styles.progressSection + " " + styles.threeProgressStrip}`}>
            <span className={styles.progressStrip}></span>
            <div className={"d-flex align-items-center justify-content-between " + styles.bubbleSection}>
                { 
                    (progressData.ID < 5 || progressData.ID === undefined) ?
                    <>
                        <Step stepNo="1" label="Info"/>
                        <Step stepNo="2" label="Interview"/>
                        <Step stepNo="3" label="Screening"/>
                        <Step stepNo="4" label="Review"/>
                    </>
                    :
                    <>
                        <Step stepNo="5" label="Active"/>
                        <Step stepNo="6" label="In Cycle"/>
                        <Step stepNo="7" label="On Hold"/>
                    </>
                }
                
            </div>
        </div>
    );
}

export default HeaderProgress;
