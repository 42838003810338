import * as Constants from './constants';

export const travelListRequest = (data) => ({
    type: Constants.TRAVEL_GET_LIST_REQUEST,
    payload: data,
});

export const travelListSuccess = (data) => ({
    type: Constants.TRAVEL_GET_LIST_SUCCESS,
    payload: data,
});

export const travelListError = (error) => ({
    type: Constants.TRAVEL_GET_LIST_ERROR,
    payload: error,
});

export const travelListClear = () => ({
    type: Constants.TRAVEL_GET_LIST_CLEAR,
});

export const addTravelRequest= (data) => ({
    type: Constants.ADD_TRAVEL_REQUEST,
    payload: data,
});

export const addTravelSuccess = (data) => ({
    type: Constants.ADD_TRAVEL_SUCCESS,
    payload: data,
});

export const addTravelError = (error) => ({
    type: Constants.ADD_TRAVEL_ERROR,
    payload: error,
});

export const addTravelClear = () => ({
    type: Constants.ADD_TRAVEL_CLEAR,
});


export const donorListRequest = (data) => ({
    type: Constants.TRAVEL_GET_DETAILS_REQUEST,
    payload: data,
});

export const donorListSuccess = (data) => ({
    type: Constants.TRAVEL_GET_DETAILS_SUCCESS,
    payload: data,
});

export const donorListError = (error) => ({
    type: Constants.TRAVEL_GET_DETAILS_ERROR,
    payload: error,
});

export const donorListClear = () => ({
    type: Constants.TRAVEL_GET_DETAILS_CLEAR,
});

export const getTravelItinerary = (data) => ({
    type: Constants.TRAVEL_GET_ITINERY_REQUEST,
    payload: data,
});

export const getTravelItinerarySuccess = (data) => ({
    type: Constants.TRAVEL_GET_ITINERY_SUCCESS,
    payload: data,
});

export const getTravelItineraryError = (error) => ({
    type: Constants.TRAVEL_GET_ITINERY_ERROR,
    payload: error,
});

export const getTravelItineraryClear = () => ({
    type: Constants.TRAVEL_GET_ITINERY_CLEAR,
});



export const getTravelDetails = (data) => ({
    type: Constants.TRAVEL_DETAILS_DATA,
    payload: data,
});

export const getTravelDetailsSuccess = (data) => ({
    type: Constants.TRAVEL_DETAILS_DATA_SUCCESS,
    payload: data,
});

export const getTravelDetailsError = (error) => ({
    type: Constants.TRAVEL_DETAILS_DATA_ERROR,
    payload: error,
});

export const getTravelDetailsClear = () => ({
    type: Constants.TRAVEL_DETAILS_DATA_CLEAR,
});



export const sendToApproveRequest = (data) => ({
    type: Constants.TRAVEL_HANDOUT_APPROVE_REQUEST,
    payload: data,
});

export const sendToApproveSuccess = (data) => ({
    type: Constants.TRAVEL_HANDOUT_APPROVE_SUCCESS,
    payload: data,
});

export const sendToApproveError = (error) => ({
    type: Constants.TRAVEL_HANDOUT_APPROVE_ERROR,
    payload: error,
});

export const sendToApproveClear = () => ({
    type: Constants.TRAVEL_HANDOUT_APPROVE_CLEAR,
});
