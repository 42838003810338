
import { combineReducers } from "redux";
import { getBasicReducer } from "../../../core/utils";
import * as Constants from "./constants";

export const profilePageData = combineReducers({
    updatePwd: getBasicReducer({
        request: Constants.PROFILE_PWD_UPDATE,
        success: Constants.PROFILE_PWD_SUCCESS,
        failure: Constants.PROFILE_PWD_ERROR,
        clear: Constants.PROFILE_PWD_CLEAR,
    }),
    donorProfile: getBasicReducer({
        request: Constants.DMA_PROFILE_DONOR_GET,
        success: Constants.DMA_PROFILE_DONOR_SUCCESS,
        failure: Constants.DMA_PROFILE_DONOR_ERROR,
        clear: Constants.DMA_PROFILE_DONOR_CLEAR,
    })
});
