import Layout from "../components/ui/Layout";
import React ,{ useEffect,useState} from "react";
import Camera, { FACING_MODES, IMAGE_TYPES } from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';
import styles from "./UploadScreen.module.scss";
import {BsChevronRight,BsChevronLeft} from "react-icons/bs";

import ReactCrop, {
  centerCrop,
  makeAspectCrop,
} from 'react-image-crop'
import 'react-image-crop/src/ReactCrop.scss';
import { useNavigate} from 'react-router-dom';

const PhotoScreen = () => {
   const navigate = useNavigate();
   const [photouri, setPhotoUri] = useState("");
   const [crop, setCrop] = useState({ aspect: 16 / 9 });
   
   const [, setCompletedCrop] = useState("");
   const [output, setOutput] = useState(null);

    useEffect(() => {
        document.title = 'Take Picture';
    },[]);

    function handleTakePhoto (dataUri) {
        // Do stuff with the photo...
        //console.log('takePhoto',dataUri);
        setPhotoUri(dataUri);
    }

    function handleTakePhotoAnimationDone (dataUri) {
      // Do stuff with the photo...
      //console.log('takePhoto',dataUri);
    }

    function handleCameraError (error) {
      console.log('handleCameraError', error);
    }

    function handleCameraStart (stream) {
      console.log('handleCameraStart');
    }

    function handleCameraStop () {
      console.log('handleCameraStop');
    }

    function handleCancelImage() {
      setPhotoUri("");
    }

    function onImageLoad(e) {
      const { naturalWidth: width, naturalHeight: height } = e.currentTarget;

      const crop = centerCrop(
        makeAspectCrop(
          {
            // You don't need to pass a complete crop into
            // makeAspectCrop or centerCrop.
            unit: '%',
            width: 90,
          },
          16 / 9,
          width,
          height
        ),
        width,
        height
      )

      setCrop(crop)
    }

    function onCropCompleted(c)
    {
      //window.console.log(c);
      setCompletedCrop(c);
    }

    function handleCroppedImage() {
      let image = document.getElementById("croppedImg");//document.createElement('img');
      
      const canvas = document.createElement('canvas');
      const scaleX = image.naturalWidth / image.width;
      const scaleY = image.naturalHeight / image.height;
      canvas.width = crop.width;
      canvas.height = crop.height;
      const ctx = canvas.getContext('2d');

      const pixelRatio = window.devicePixelRatio;
      canvas.width = crop.width * pixelRatio;
      canvas.height = crop.height * pixelRatio;
      ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
      ctx.imageSmoothingQuality = 'high';
      ctx.drawImage(
        image,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        crop.width,
        crop.height,
      );

      // Converting to base64
      const base64Image = canvas.toDataURL('image/jpeg');
      setPhotoUri("");
      setOutput(base64Image);
      navigate('/home/upload',{state:{camerapic:base64Image}});
    }

    return (
        <Layout showHeader="false">

            <div className={`position-relative`} style={{background: "#353D56",padding:"20px"}}>
                <h1 className="fw-normal text-white text-center">
                    Take Picture
                </h1>
            </div>

             {
              output ?
              <>
                 <img src={output} alt=""/>
              </>
              : photouri ?
              <>
                <div className={styles.croppedImage}>
                    <ReactCrop
                      crop={crop}
                      onChange={c => setCrop(c)}
                      //aspect={16 / 9}
                      onComplete={(c) => onCropCompleted(c)}
                    >
                    <img id="croppedImg" src={photouri} onLoad={onImageLoad} alt="" />
                    </ReactCrop>

                    <button className={`position-relative float-end me-4 mt-4`} onClick={handleCroppedImage}>
                        Proceed&nbsp;<BsChevronRight className={styles.buttonIcon}/>
                    </button>
                    <button className={`position-relative float-start ms-4 mt-4`} onClick={handleCancelImage}>
                      <BsChevronLeft className={styles.cancelButtonIcon}/>&nbsp;Cancel
                    </button>
                </div>
              </>
             :
             <>

             <Camera
                onTakePhoto = { (dataUri) => { handleTakePhoto(dataUri); } }
                onTakePhotoAnimationDone = { (dataUri) => { handleTakePhotoAnimationDone(dataUri); } }
                onCameraError = { (error) => { handleCameraError(error); } }
                idealFacingMode = {FACING_MODES.ENVIRONMENT}
                idealResolution = {{width: 640, height: 480}}
                imageType = {IMAGE_TYPES.JPG}
                imageCompression = {0.97}
                isMaxResolution = {false}
                isImageMirror = {false}
                isSilentMode = {false}
                isDisplayStartCameraError = {true}
                isFullscreen = {false}
                sizeFactor = {1}
                onCameraStart = { (stream) => { handleCameraStart(stream); } }
                onCameraStop = { () => { handleCameraStop(); } }
              />

              <button className={`position-relative float-start ms-4 mt-4`} onClick={() => navigate(-1)}>
                        <BsChevronLeft className={styles.cancelButtonIcon}/>&nbsp;Cancel
              </button>
             </>
             }
        </Layout>
    );
}

export default PhotoScreen;
