import styles from "./ProfilePage.module.scss";
import Layout from "../components/ui/Layout";
import { useLocation } from 'react-router-dom';
import { useEffect,useState,useRef} from "react";
import Loader from "../components/ui/Loader";
import { useSelector, useDispatch } from "react-redux";
import * as Actions from "./state/profile/actions";
import { Scrollbars } from "react-custom-scrollbars-2";
import { debounce } from "lodash";
import PageTitle from "./components/PageTitle";

const ProfilePage = () => {
    const dispatch = useDispatch();
    let { state } = useLocation();
    const {
        loginData: { loginUser },
        profilePageData: { updatePwd },
    } = useSelector((state) => state);
    const { response: loginVal } = loginUser || {};
    const { response: pwdResponse, isLoading: pwdLoading,error } = updatePwd || {};
    
    const curPasswordRef = useRef();
    const newPasswordRef = useRef();
    const renterPasswordRef = useRef();
    const InitiatePwdFormControl = {
        currentPassword: "",
        newPassword: "",
        reenterPassword: "",
    };
    const [changePwdData,setChangePwdData] = useState(InitiatePwdFormControl);
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [pwdChangeResponse, setPwdChangeResponse] = useState({});
    const [pwdChangeError, setPwdChangeError] = useState([]);
    const [scrollbarHeight, setScrollBarHeight] = useState("");
    const msgInputRef = useRef();

    const InitiateError = {
        currentPassword: {
            dirty: false,
            error: false,
            message: "",
        },
        newPassword: {
            dirty: false,
            error: false,
            message: "",
        },
        reenterPassword: {
            dirty: false,
            error: false,
            message: "",
        },
    };
    const [formErrors, setFormErrors] = useState(InitiateError);

    useEffect(() => {
        document.title = 'Profile Page';
        dispatch(Actions.updatePwdClear());
        setPwdChangeResponse({});
        setPwdChangeError({});
        // eslint-disable-next-line
    },[]);

    useEffect(() => {
        if(error) {
            //window.console.log(error);
            setPwdChangeError(error?.response?.data);
        }
    },[error]);


    useEffect(() => {
		if (typeof pwdResponse !== "undefined" && pwdResponse) {
            setPwdChangeResponse(pwdResponse);
		}
	}, [pwdResponse]);

    const getContentHeight = () => {
        const mainElement = document.getElementsByClassName("app");
        const footerElement = document.querySelector(".footerElement");
        const pageTitle = document.querySelector(".homePageTitle");

        // 5px is additional gap added to prevent app container scroll
        return mainElement[0]?.offsetHeight - (footerElement?.offsetHeight + pageTitle?.offsetHeight + 5);
    }

    useEffect(() => {
        const debouncedHandleResize = debounce(function handleResize() {
            setScrollBarHeight(getContentHeight());
        }, 300);

        // set scroll height
        setScrollBarHeight(getContentHeight());
        window.addEventListener("resize", debouncedHandleResize);

        // cleanup listener
        return _ => {
            window.removeEventListener('resize', debouncedHandleResize);
        }
	}, [scrollbarHeight]);

    const resetElement = () => {
        setPwdChangeResponse({});
        setChangePwdData(InitiatePwdFormControl);
        setFormErrors(InitiateError);
    }

    const saveElement = () => {
        let isValid = true;
        setFormSubmitted(true);
        const nextErrors = InitiateError;//JSON.parse(JSON.stringify(formErrors));

        const { currentPassword, newPassword, reenterPassword } = changePwdData;

        if(!currentPassword)
        {
            nextErrors.currentPassword.error = true;
            nextErrors.currentPassword.message = "required";
            isValid = false;
        }
        if(!newPassword)
        {
            nextErrors.newPassword.error = true;
            nextErrors.newPassword.message = "required";
            isValid = false;
        }
        if(!reenterPassword)
        {
            nextErrors.reenterPassword.error = true;
            nextErrors.reenterPassword.message = "required";
            isValid = false;
        }

        if(isValid && (newPassword !== reenterPassword))
        {
            nextErrors.newPassword.error = true;
            nextErrors.newPassword.message = "mismatch";
            nextErrors.reenterPassword.error = true;
            nextErrors.reenterPassword.message = "mismatch";
            isValid = false;
        }
        if(!isValid)
        {
            setFormErrors(nextErrors);
        }else {
            const formData = new FormData();
            setFormErrors(InitiateError);
            formData.append("user_mail",loginVal?.data?.user_email);
            formData.append("currentPwd",btoa(changePwdData.currentPassword));
            formData.append("newPwd",btoa(changePwdData.newPassword));
            formData.append("reenterpwd",btoa(changePwdData.reenterPassword));
            dispatch(Actions.updatePwdRequest(formData));
            setChangePwdData(InitiatePwdFormControl);
        }

        return false;
    }

    return (
        <>
            {(pwdLoading) && <Loader />}
            <Layout showHeader="false">
                <div className={styles.stickyHeader}>
                    <PageTitle
                        title="User Profile"
                        headerClass="homePageTitle"
                        linkTo={state?.prevPage ? state?.prevPage : "/home"}
                        linkState={{ menu: state?.menu }}
                    />
                </div>
                <div className={`position-relative`} id="profileContainer">
                    <Scrollbars
                        autoHeight
                        autoHeightMax={scrollbarHeight}
                        ref={msgInputRef}
                        className="scroll-container"
                    >
                        <div className="profileInfo" style={{position:"relative",color:"#353D56",display:"block",padding:"10px 40px 10px 40px",textAlign:"left",fontSize:"18px",marginTop:"20px"}}>
                            <label style={{width:"30%",fontWeight:"600"}}>Name</label>
                            {loginVal?.data?.user_fname}&nbsp;{loginVal?.data?.user_lname}
                        </div>
                        <div className="profileInfo" style={{color:"#353D56",display:"block",padding:"0px 40px 10px 40px",textAlign:"left",fontSize:"18px",marginTop:"5px"}}>
                            <label style={{width:"30%",fontWeight:"600"}}>Email</label><a style={{textDecoration:"none"}} href={`mailto:${loginVal?.data?.user_email}`}>{loginVal?.data?.user_email}</a>
                        </div>
                        <div className="profileInfo" style={{color:"#353D56",display:"block",padding:"0px 40px 10px 40px",textAlign:"left",fontSize:"18px",marginTop:"5px"}}>
                            <label style={{width:"30%",fontWeight:"600"}}>Donor ID</label>{loginVal?.data?.donorID}
                        </div>
                        <hr style={{width:"85%",height:"1px",margin:"10px auto"}}/>

                        <div className={`${styles.changePwdContainer} profileInfo`}>
                            <h4>Change Password</h4>
                            <div className={`${styles.element} ${formSubmitted === true && formErrors.currentPassword.error === true ? styles.error : ""}`}>
                                <input type="password" ref={curPasswordRef} value={changePwdData.currentPassword || ""}
                                    onChange={e => setChangePwdData({ ...changePwdData, currentPassword: e.target.value })}
                                placeholder="Current Password" name="currentPassword" id="currentPassword"/>
                            </div>
                            <div className={`${styles.element} ${formSubmitted === true && formErrors.newPassword.error === true ? styles.error : ""}`}>
                                <input type="password" ref={newPasswordRef} value={changePwdData.newPassword || ""}
                                onChange={e => setChangePwdData({ ...changePwdData, newPassword: e.target.value })}
                                placeholder="New Password" name="newPassword" id="newPassword"/>
                            </div>
                            <div className={`${styles.element} ${formSubmitted === true && formErrors.reenterPassword.error === true ? styles.error : ""}`}>
                                <input type="password" ref={renterPasswordRef} value={changePwdData.reenterPassword || ""}
                                onChange={e => setChangePwdData({ ...changePwdData, reenterPassword: e.target.value })}
                                placeholder="Reenter new Password" name="reenterPassword" id="reenterPassword"/>
                            </div>
                            <div className={styles.element}>
                                <button className={styles.cancelButton} onClick={()=>{resetElement()}}>
                                    Cancel
                                </button>
                                <button className={styles.saveButton} onClick={()=>{saveElement()}}>
                                    Save
                                </button>
                            </div>
                            {
                                (formErrors?.currentPassword?.message === "required" ||
                                formErrors?.newPassword?.message === "required" ||
                                formErrors?.reenterPassword?.message === "required" ) ?
                                    <p className="text-danger my-3 fw-bold text-center">
                                        Please fill all the fields.
                                    </p>
                                :
                                <>
                                    {
                                        (formErrors?.reenterPassword?.error === true && formErrors?.reenterPassword?.message === "mismatch") ?
                                        <p className="text-danger my-3 fw-bold text-center">
                                            Passwords do not match.
                                        </p>
                                        :
                                        <>
                                        {
                                            (pwdChangeResponse.code === "success") ?
                                                <>
                                                    <p className="text-success my-3 fw-bold text-center">
                                                        {pwdChangeResponse?.message}
                                                    </p>
                                                </>
                                                :
                                                <>
                                                    {(() => {
                                                        switch (pwdChangeError?.code) {
                                                            case "incorrect_password":
                                                            case "password_mismatch":
                                                                    return (<p className="text-danger my-3 fw-bold text-center">
                                                                        {pwdChangeError?.message}
                                                                    </p>);
                                                            default: return <></>;
                                                        }
                                                    })()}
                                                </>

                                            }
                                        </>
                                    }
                                </>
                            }

                        </div>
                    </Scrollbars>
                </div>
            </Layout>
        </>
    );
}

export default ProfilePage;
