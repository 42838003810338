import { takeLatest, put, call } from "redux-saga/effects";
import {Endpoints} from '../../../core/networking';
import * as Constants from "./constants";
import * as Actions from "./actions";
import AxiosInstance from "../../../services/AxiosInstance";
import {ERROR_TYPES} from '../types';

export function* watchNotifySaga() {
    yield takeLatest(Constants.NOTIFY_SEND_TO_APPROVE_REQUEST, sendApproveRequest); 
}

export function* sendApproveRequest(action) {
  try {
    const pramas = action.payload;
      const response = yield call(taskNetworkRequest, pramas);
    if (response.status === 200) {
      yield put(Actions.sendToApproveSuccess(response));
    } else {
      yield put(
        Actions.sendToApproveError({
          errorCode: ERROR_TYPES.INVALID_DATA,
          message: ERROR_TYPES.INVALID_DATA,
        })
      );
    }
  } catch (error) {
    yield put(Actions.sendToApproveError(error));
  }
}

export function taskNetworkRequest(params){
  // const url = Endpoints.GET_DONOR_TASK.replace('applicantID')     ;
  const url1 = Endpoints.FAIR_FAX_PORTAL_API;
  const response =  AxiosInstance.post(url1, { params });
  return response;
}
