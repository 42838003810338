import React, { useEffect, useRef, useState } from "react";
import styles from "./PsychologEvaluation.module.scss";
import { useNavigate, useLocation } from "react-router-dom";
import { format } from "date-fns";
import { BsChevronRight } from "react-icons/bs";
import { useSelector, useDispatch } from "react-redux";
import { isEmpty } from "lodash";
import { Scrollbars } from "react-custom-scrollbars-2";
import { Endpoints } from "../../core/networking";
import Loader from "../../components/ui/Loader";
import SheduleCalendar from "../TaskCalendar/SheduleCalendar";
import BookingList from "../TaskCalendar/BookingList";
import ListHeader from "../TaskCalendar/ListHeader";
import * as Actions from "../state/appointment/actions";
import { TASK_SUB_TYPE, TASK_TYPE_ID } from "../TaskCalendar/type";
import TimeZone from "../components/TimeZone";
import TaskDescription from "../components/TaskDescription";
import { createPortal } from "react-dom";
import { motion } from "framer-motion";

const PsychologEvaluation = () => {
    const { state } = useLocation();
    const { taskname, taskInfo } = state || {};
    const location= useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {
        loginData: { loginUser },
        appointmentData: {
            mind360Contact,
            psychoSubmit,
            psychologistList,
            pcbObgynSubmit,
        },
    } = useSelector((state) => state);
    const { response: loginVal} = loginUser || {};
    const {  isLoading: contactLoading } =
        mind360Contact || {};
    const { response: psychoSubmitted, isLoading: psychoLoading } =
        psychoSubmit || {};
    const { response: psychoList, isLoading: psychoListLoading } =
        psychologistList || {};
    const { response: pcbGynSubmit, isLoading: pcbGynSubmitLoading } =
        pcbObgynSubmit || {};

    const [handleAppoint, setHandleAppoint] = useState(false);
    const [showCalendarText, setShowCalendarText] = useState(false);
    const [startDate, setStartDate] = useState();
    const [scrollHeight, setScrollHeight] = useState(0);
    const [selectedValue, setSelectedValue] = useState(0);
    const [taskMainText, setTaskMainText] = useState("");
    const [taskText, setTaskText] = useState("");
    const [timeZone, setTimeZone] = useState(0);
    const [, setHeight] = useState(0);
    const containerRef = useRef(null);
    const [, setPageMsg]=useState("");
    const [, setPageTitle]=useState("");
    const [textView, setTextView] = useState("");
    const [showPsychModal, setShowPsychModal] = useState(false);

    useEffect(() => {
        setHeight(containerRef?.current?.clientHeight);
        setHandleAppoint(false);
        dispatch(Actions.psychoSubmitAppoinClear());
        dispatch(Actions.pcbObgynSubmitClear());
        setShowPsychModal(false);
        
        //let applicantID = loginVal?.data?.applicant_id;
        window.addEventListener("resize", getSchedularHeight);

        switch (true) {
            case taskInfo?.TaskType === TASK_TYPE_ID.TASK_SCHEDULE &&
                (taskInfo?.TaskSubType === TASK_SUB_TYPE.PSYCHOLOGICAL_EXAM_4 ||
                    taskInfo?.SubTaskType ===
                        TASK_SUB_TYPE.PSYCHOLOGICAL_EXAM_4): {
                setTextView(<strong>Call or email provider directly to schedule an appointment</strong>);
                setTaskMainText("\nSelect a provider to schedule your");
                setTaskText("Psychological Evaluation.");
                setShowCalendarText(true);
                const taskEndPoint = Endpoints.PSYCHOLOOGICAL_MIND_360CONTACT;
                let params = {
                    endpoint: taskEndPoint,
                    method: "GET",
                };
                const taskEndPoints = Endpoints.PSYCHOLOGIST_LIST + taskInfo?.ID;
                // applicantID;
                let param = {
                    endpoint: taskEndPoints,
                    method: "GET",
                };
                dispatch(Actions.psychoSubmitAppoinClear());
                dispatch(Actions.pcbObgynSubmitClear());
                dispatch(Actions.getPsychoMindContactRequest(params));
                dispatch(Actions.getPsychologistListRequest(param));
                break;
            }
            case taskInfo?.TaskType === TASK_TYPE_ID.TASK_SCHEDULE &&
                (taskInfo?.TaskSubType === TASK_SUB_TYPE.SCHEDULE_PCP_OBGYN_4 ||
                    taskInfo?.SubTaskType ===
                    TASK_SUB_TYPE.SCHEDULE_PCP_OBGYN_4): {
                    setTaskMainText("");//taskInfo?.AdHocDescription
                    //setTaskText(" personal doctor for ...");
                setHandleAppoint(!handleAppoint);
                setShowCalendarText(true);
                dispatch(Actions.pcbObgynSubmitClear());
                dispatch(Actions.psychoSubmitAppoinClear());
                break;
                }

            default: {
                return null;
            }
        }
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(()=>{
     if(location?.state?.taskInfo)
        setPageTitle(location.state.taskname === '' ? location?.state?.taskInfo?.TaskName : location.state.taskname);
     if (location?.state?.taskInfo?.IsRejected)
         setPageMsg(location?.state?.taskInfo?.RejectReason);
     else
         setPageMsg(location?.state?.taskInfo?.Comment);
    }, [location.state.taskname, location?.state?.taskInfo]);

    useEffect(() => {
        if (!isEmpty(psychoSubmitted)) {
            dispatch(Actions.psychoSubmitAppoinClear());
            navigate(-1);
        }
    }, [psychoSubmitted]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!isEmpty(pcbGynSubmit)) {
            dispatch(Actions.pcbObgynSubmitClear());
            navigate(-1);
        }
    }, [pcbGynSubmit]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        getSchedularHeight();
    }, [selectedValue]);

    const getSchedularHeight = () => {
        const taskDescription = document.getElementById("taskDescription");
        setScrollHeight(window.innerHeight - taskDescription?.offsetHeight);
    };

    useEffect(() => { // set scroll window height
        let mainElement = document.getElementsByClassName("app");
        let taskDescription = document.getElementById("taskDescription");
        setScrollHeight(mainElement[0]?.offsetHeight);
        window.addEventListener("resize", function () {
            setScrollHeight(mainElement[0]?.offsetHeight - taskDescription?.offsetHeight);
        });
    }, [scrollHeight]);

    const handleSubmit = () => {
        if (
            taskInfo?.TaskType === TASK_TYPE_ID.TASK_SCHEDULE &&
            (taskInfo?.TaskSubType === TASK_SUB_TYPE.PSYCHOLOGICAL_EXAM_4 ||
                taskInfo?.SubTaskType === TASK_SUB_TYPE.PSYCHOLOGICAL_EXAM_4)
        ) {
            const formData = new FormData();
            let applicantID = loginVal?.data?.applicant_id;
            formData.append("ApplicantID", applicantID);
            formData.append(
                "TaskID",
                taskInfo?.AssociatedID ? taskInfo?.AssociatedID : taskInfo.ID
            );
            formData.append("ClinicEntityID", selectedValue?.ClinicEntityID);
            formData.append(
                "AppointmentDateTime",
                format(startDate, "yyyy-MM-dd'T'HH:mm:ss")
            );
            formData.append("TimezoneID", timeZone);
            formData.append(
                "endpoint",
                Endpoints.PSYCHOLOOGICAL_SUBMIT_APPOINTMENT
            );
            dispatch(Actions.psychoSubmitAppointRequest(formData));
        } else {
            const formData = new FormData();
            formData.append(
                "taskID",
                taskInfo?.AssociatedID ? taskInfo?.AssociatedID : taskInfo.ID
            );
            formData.append("appointmentTimezoneID", timeZone);
            formData.append(
                "appointmentDateTime",
                format(startDate, "yyyy-MM-dd'T'HH:mm:ss")
            );
            formData.append("endpoint", Endpoints.SUBMIT_PCP_OBGYN);
            formData.append("method", "PUT");
            dispatch(Actions.pcbObgynSubmitRequest(formData));
        }
    };

    const handleGoBack = () => {
        navigate(-1);
    };
    const handleDate = (date) => {
        setStartDate(date);
    };
    const handleAppointment = () => {
        setShowPsychModal(true);
    };
    const closePopup = () => {
        setShowPsychModal(false);
    }
    const selectedTimeZone = (zone) => {
        setTimeZone(zone);
    };
    const goToSchedule = () => {
        setShowPsychModal(false);
        setHandleAppoint(!handleAppoint);
        setShowCalendarText(!showCalendarText);
    }

    return (
        <>
            {((contactLoading && psychoListLoading) || psychoLoading || pcbGynSubmitLoading ) && <Loader />}
            <>
                {
                   showPsychModal && createPortal(
                        <div className={`${styles.psychModalContainer} position-absolute w-100 top-0 start-0 end-0 bottom-0`}>
                            <motion.div className={`${styles.psychModal} position-absolute top-50`}
                                initial={{ opacity: 0, scale: 0.5, translateY: '-50%' }}
                                animate={{ opacity: 1, scale: 1, translateY: '-50%' }}
                                transition={{ duration: 0.3 }}
                            >
                               <div className={styles.psychModal} >
                                   <div className={styles.modalHeader}>
                                       Have you scheduled an appointment?
                                   </div>
                                   <div className={styles.modalDetails}>
                                        If not, contact the provider to schedule an appointment before entering details
                                   </div>
                                   <div className={styles.modalFooter}>
                                       <input type="button" onClick={() => goToSchedule()} className={styles.footerbutton} value="Yes" />
                                       <input type="button" onClick={() => closePopup()} className={styles.footerbutton} value="No" />
                                   </div>
                                </div>
                            </motion.div>
                        </div>,
                        document.querySelector('.app')
                    )
                }
                <ListHeader
                    taskname={taskname}
                    handleNavigation={handleGoBack}

                     title={
                         <>
                             <TaskDescription styles={`px-0 py-0 my-2`} taskDetails={taskInfo} />
                            {textView}
                            {taskMainText}
                        </>
                     }
                    titleLine={taskText}
                    appointmentShow={showCalendarText}
                />
                {handleAppoint ? (
                    <div
                        style={
                            taskInfo?.TaskType === 4 &&
                            (taskInfo?.TaskSubType === 6 || taskInfo?.SubTaskType === 6)
                                ? { marginTop: "40px" }
                                : null
                        }
                    >
                       <div style={{ marginLeft: "20px" }}>
                           <strong>Call, email or go online to schedule an appointment.</strong><p />
                             Enter the date and time of your scheduled appointment.
                       </div>
                    </div>
                ) : null}
                {taskInfo?.TaskType === 4 &&
                (taskInfo?.TaskSubType === 6 || taskInfo?.SubTaskType === 6) ? (
                    !handleAppoint && (
                        <div className={styles.mindContact}>
                        </div>
                    )
                ) : null}
                {taskInfo?.TaskType === 4 &&
                (taskInfo?.TaskSubType === 6 || taskInfo?.SubTaskType === 6) ? (
                    <div className="float-end position-relative mt-0" id="doctorContainer"
                        >
                        {handleAppoint ? (
                            <BookingList
                                id={`check_${selectedValue.ClinicEntityID}`}
                                name={selectedValue.Name}
                                address={selectedValue.AddressOne}
                                city={selectedValue.City}
                                zip={selectedValue.ZipCode}
                                phone={selectedValue.Phone}
                                email={selectedValue.Email}
                                regionCode={selectedValue.RegionCode}
                                website={selectedValue.Website}
                                schedulingUrl={selectedValue?.SchedulingURL}
                                schedulingIns={selectedValue?.SchedulingInstructions}
                                hideCheckBox={handleAppoint}
                                selectedValue={selectedValue}
                            />
                        ) : (
                            <Scrollbars
                                autoHeight
                                autoHeightMax={scrollHeight - 450}
                                className={`scroll-container`}
                            >
                            {
                                psychoList && psychoList?.map((val) => {
                                    return (
                                        <BookingList
                                            key={val.ClinicEntityID}
                                            checkedValue={
                                                val.ClinicEntityID ===
                                                selectedValue.ClinicEntityID
                                            }
                                            id={`check_${val.ClinicEntityID}`}
                                            lable={val.Name}
                                            type={"checkbox"}
                                            onHandleCheckBox={() =>
                                                setSelectedValue(val)
                                            }
                                            name={val.Name}
                                            address={val.AddressOne}
                                            city={val.City}
                                            zip={val.ZipCode}
                                            phone={val.Phone}
                                            regionCode={val.RegionCode}
                                            website={val.Website}
                                            email={val.Email}
                                            schedulingUrl={val?.SchedulingURL}
                                            schedulingIns={val?.SchedulingInstructions}
                                            hideCheckBox={handleAppoint}
                                        />
                                    );
                                })
                            }
                            </Scrollbars>
                        )}
                    </div>
                ) : null}
                {handleAppoint ? (
                    <div className="mt-4">
                        <TimeZone
                            class={"timeZoneContainer"}
                            selectedTimeZone={selectedTimeZone}
                        />
                        <SheduleCalendar
                            handleDate={handleDate}
                            startDate={startDate}
                            selectedDoctor={timeZone}
                            handleSubmit={() => handleSubmit()}
                        />
                    </div>
                ) : (
                    <div className="d-flex justify-content-end pe-4 mt-4">
                        <button
                                className={`${styles.button} position-relative px-4`}
                            onClick={handleAppointment}
                            disabled={selectedValue === 0}
                        >
                            Enter Appointment Details
                            <BsChevronRight className={styles.buttonIcon} />
                        </button>
                    </div>
                )}
            </>
        </>
    );
};
export default PsychologEvaluation;
