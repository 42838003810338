import * as Constants from './constants';

export const logoutUserRequest = (data) => ({
    type: Constants.LOGOUT_USER_REQUEST,
    payload: data,
});

export const logoutUserSuccess = (data) => ({
    type: Constants.LOGOUT_USER_SUCCESS,
    payload: data,
});
export const logoutUserError = (error) => ({
    type: Constants.LOGOUT_USER_ERROR,
    payload: error,
});

export const logoutUserClear = () => ({
    type: Constants.LOGOUT_USER_CLEAR,
});
