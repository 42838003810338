import { takeLatest, put, call } from "redux-saga/effects";
import {Endpoints} from '../../../core/networking';
import * as Constants from "./constants";
import * as Actions from "./actions";
import AxiosInstance from "../../../services/AxiosInstance";
import {ERROR_TYPES} from '../types';

export function* watchProfilePage() {
  yield takeLatest(Constants.DMA_PROFILE_DONOR_GET, getDonorDMARequest);
  yield takeLatest(Constants.PROFILE_PWD_UPDATE, submitPwdChange);
}

export function* getDonorDMARequest(action) {
    try {
        const pramas = action.payload;
        const response = yield call(taskNetworkRequest, pramas);
        if (response.status === 200) {
            yield put(Actions.getDonorProfileSuccess(response.data));
        } else {
            yield put(
                Actions.getDonorProfileError({
                    errorCode: ERROR_TYPES.INVALID_DATA,
                    message: ERROR_TYPES.INVALID_DATA,
                })
            );
        }
    } catch (error) {
        yield put(Actions.getDonorProfileError(error));
    }
}

export function* submitPwdChange(action) {
  try {
    const response = yield call(submitPwdRequest, action.payload);
    if (response.status === 200) {
      yield put(Actions.updatePwdSuccess(response.data));
    } else {
      yield put(
        Actions.updatePwdError({
          errorCode: ERROR_TYPES.INVALID_DATA,
          message: ERROR_TYPES.INVALID_DATA,
        })
      );
    }
  } catch (error) {
    yield put(Actions.updatePwdError(error));
  }
}

export function taskNetworkRequest(params){
  const url = Endpoints.FAIR_FAX_PORTAL_API;
  const response =  AxiosInstance.post(url, { params });
  return response;
}

export function submitPwdRequest(params){
  const url = Endpoints.UPDATE_USER_PWD;
  const response =  AxiosInstance.post(url, params );
  return response;
}
