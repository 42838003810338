import * as Constants from './constants';

export const infoGetMsgListRequest = (data) => ({
    type: Constants.INFO_MESSAGE_GET_LIST_REQUEST,
    payload: data,
});

export const infoGetMsgListSuccess = (data) => ({
    type: Constants.INFO_MESSAGE_GET_LIST_SUCCESS,
    payload: data,
});

export const infoGetMsgListError = (error) => ({
    type: Constants.INFO_MESSAGE_GET_LIST_ERROR,
    payload: error,
});

export const infoGetMsgListClear = () => ({
    type: Constants.INFO_MESSAGE_GET_LIST_CLEAR,
});

export const addInfoMsgRequest= (data) => ({
    type: Constants.INFO_ADD_MESSAGE_REQUEST,
    payload: data,
});

export const addInfoMsgSuccess = (data) => ({
    type: Constants.INFO_ADD_MESSAGE_SUCCESS,
    payload: data,
});

export const addInfoMsgError = (error) => ({
    type: Constants.INFO_ADD_MESSAGE_ERROR,
    payload: error,
});

export const addInfoMsgClear = () => ({
    type: Constants.INFO_ADD_MESSAGE_CLEAR,
});

export const fileDownloadRequest= (data) => ({
    type: Constants.UPLOAD_FILE_DOWNLOAD_REQUEST,
    payload: data,
});

export const fileDownloadSuccess = (data) => ({
    type: Constants.UPLOAD_FILE_DOWNLOAD_SUCCESS,
    payload: data,
});

export const fileDownloadError = (error) => ({
    type: Constants.UPLOAD_FILE_DOWNLOAD_ERROR,
    payload: error,
});

export const fileDownloadClear = () => ({
    type: Constants.UPLOAD_FILE_DOWNLOAD_CLEAR,
});

export const taskSubmitRequest = (data) => ({
    type: Constants.INFO_TASK_SUBMIT_REQUEST,
    payload: data,
});

export const taskSubmitSuccess = (data) => ({
    type: Constants.INFO_TASK_SUBMIT_SUCCESS,
    payload: data,
});

export const taskSubmitError = (error) => ({
    type: Constants.INFO_TASK_SUBMIT_ERROR,
    payload: error,
});

export const taskSubmitClear = () => ({
   type: Constants.INFO_TASK_SUBMIT_CLEAR,
});
