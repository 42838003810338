import React, { useEffect, useState, useRef } from "react";
import styles from "./BMICalculator.module.scss";
import Layout from "../components/ui/Layout";
import { Row, Col, Form, Button } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import * as Actions from "./state/calculator/actions";
import { Endpoints } from "../core/networking";
import Loader from "../components/ui/Loader";
import { useLocation} from "react-router-dom";
import { Scrollbars } from "react-custom-scrollbars-2";
import PageTitle from "./components/PageTitle";


const BMICalculator = () => {
  const dispatch = useDispatch();
  let { state } = useLocation();
  const {
    calculatorData: { calculation },
  } = useSelector((state) => state);
  const { response: calculated, isLoading: loading } = calculation || {};
  const [validated, setValidated] = useState(false);
  const [scrollbarHeight, setScrollBarHeight] = useState("");
  const [calculatedValue, setCalculatedValue] = useState('');
  const bmiContainerRef = useRef(null);

  useEffect(() => {
    document.title = "BMI Calculator";
    dispatch(Actions.calculationClear());
  }, [dispatch]);

  useEffect(() => {
    if (calculated) {
      setCalculatedValue(calculated?.BMIValue);
    }
  }, [calculated]);

  useEffect(() => {
    const footerElement = document.querySelector(".footerElement");
    setScrollBarHeight(window.innerHeight-footerElement?.clientHeight);
    window.addEventListener("resize", function () {
      setScrollBarHeight(window.innerHeight-footerElement?.clientHeight);
    });
  }, [scrollbarHeight]);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity()) {
      const formData = new FormData(form);
      const data = Object.fromEntries(formData.entries());
      const taskEndPoint =
        Endpoints.BMI_CALCULATOR +
        data?.poundsTxt +
        "/" +
        data?.feeTxt +
        "/" +
        data?.inchesText;
      setCalculatedValue('')
      let params = {
        endpoint: taskEndPoint,
        method: "GET",
      };
      dispatch(Actions.calculationRequest(params));
      setValidated(true);
    } else {
      setValidated(false);
    }
    event.preventDefault();
    event.stopPropagation();
  };

  return (
    <Layout showHeader="false">
      {loading && <Loader />}
        <PageTitle
            linkTo={state?.prevPage ? state?.prevPage : -1}
            linkState={{ menu: state?.menu }}
            lightBg={true}
            shadow={false}
        />
      <div className={styles.BmiPageTitle}>BMI Calculator</div>
      <Scrollbars
          autoHeight
          autoHeightMax={scrollbarHeight-120}
          className="scroll-container"
          ref={bmiContainerRef}
      >
        <div className={styles.BmiContainer}>
          <div className={styles.BMICalculator}>
            <Form
              validated={validated}
              onSubmit={handleSubmit}
            >
              <Row>
                <Col className={styles.sectionHead}>Height</Col>
              </Row>
              <Row style={{ marginTop: "10px" }}>
                <Col xs={6}>
                  <span className={styles.labelHead}>
                    Feet
                  </span>
                  <Form.Control
                    required
                    name="feeTxt"
                    pattern="[0-9]*"
                    type="text"
                    className={styles.inputBox}
                    id="feetTxt"
                    aria-describedby="FeetTextbox"
                  />
                </Col>
                <Col xs={6}>
                  <span className={styles.labelHead}>
                    Inches
                  </span>
                  <Form.Control
                    required
                    name="inchesText"
                    pattern="[0-9]*"
                    type="text"
                    className={styles.inputBox}
                    id="inchesText"
                    aria-describedby="InchesTextbox"
                  />
                </Col>
              </Row>
              <Row style={{ marginTop: "30px" }}>
                <Col className={styles.sectionHead}>Weight</Col>
              </Row>
              <Row style={{ marginTop: "10px" }}>
                <Col xs={12}>
                  <span className={styles.labelHead}>
                    Pounds
                  </span>
                  <Form.Control
                    required
                    name="poundsTxt"
                    pattern="[0-9]*"
                    type="text"
                    className={styles.inputBox}
                    id="poundsTxt"
                    aria-describedby="PoundTextBox"
                  />
                </Col>
              </Row>
              <Row style={{ marginTop: "50px" }}>
                <Col>
                  <Button
                    type="submit"
                    className={styles.BmiCalcButton}
                  >
                    Calculate
                  </Button>
                </Col>
              </Row>
              <Row style={{ marginTop: "50px" }}>
                <Col className={styles.sectionHead}>BMI</Col>
              </Row>
            </Form>

            <Row style={{ marginTop: "0px" }}>
              <Col xs={12}>
                <Form.Control
                  readOnly
                  type="text"
                  className={styles.inputBox}
                  id="bmiTxt"
                  value={calculatedValue}
                  aria-describedby="BMITextBox"
                />
              </Col>
            </Row>
          </div>
        </div>
      </Scrollbars>
    </Layout>
  );
};

export default BMICalculator;
