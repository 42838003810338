import { useEffect, useState, useRef } from "react";
import styles from "./AdditionalInfoScreen.module.scss";
import Layout from "../components/ui/Layout";
import TaskDescription from "./components/TaskDescription";
import { useNavigate, useLocation } from "react-router-dom";
import { BsFillXCircleFill } from "react-icons/bs";
import { SlPaperPlane } from "react-icons/sl";
import { FaPaperclip } from "react-icons/fa";
import customParseFormat from "dayjs/plugin/customParseFormat";
import dayjs from "dayjs";
import { Scrollbars } from "react-custom-scrollbars-2";
import { useSelector, useDispatch } from "react-redux";
import Loader from "../components/ui/Loader";
import * as Action from "./state/additionalinfo/actions";
import { Endpoints } from "../core/networking";
import { debounce } from "lodash";
import { getFileTypeIcon, getMimeType } from "../components/util";
import { DATE_TIME_FORMAT, TASK_SUB_TYPE, TASK_TYPE } from "../utils/constants";
import { format } from "date-fns";
import PageTitle from "./components/PageTitle";

dayjs.extend(customParseFormat);
const AdditionalInfoScreen = () => {
    const location = useLocation();
    const {state} = location;
    const dispatch = useDispatch();
    const {
        addInfoData: { msgList, addMessage, fileDownload, taskSubmit },
	} = useSelector((state) => state);
	const { response: addedMsg, isLoading: addMsgloading } = addMessage || {};
    const { response: downloadedFile, isLoading: fileLoading } = fileDownload || {};
    const { response: msgTask, isLoading: msgloading } = msgList || {};
    const {isLoading: taskSubmitLoading } = taskSubmit || {};
    const [msgInput, setMsgInput] = useState("");
    const [attachFile, setAttachFile] = useState("");
    const navigate = useNavigate();
    const fileref = useRef();
    const msgInputElementRef = useRef();
    const msgInputRef = useRef();
    const [, setIsLoading] = useState(false);
    const [messageList, setMessageList] = useState(null);
    const [messageThreadId, setMessageThreadId] = useState(0);
    const [repliedToMessageId, setRepliedToMessageId] = useState(0);
    const [scrollHeight, setScrollHeight] = useState(0);
    const [fileData, setFileData] = useState(null);
    const [scrollbarHeight, setScrollBarHeight] = useState(window.screen.availHeight);
    const [, setSubmitModal] = useState(false);
    const [taskInfo, setTaskInfo] = useState([]);
    const [, setMsgOverFlow] = useState(false);
    const [, setMsgBoxHeight] = useState(0);

    useEffect(() => {
        document.title = "Provide Additional Info";
        fetchMessages();
        dispatch(Action.fileDownloadClear());
        setTaskInfo(location?.state?.taskInfo);
        dispatch(Action.taskSubmitClear()); //?
        window.addEventListener("resize", getMessageHeight);
    }, [location?.state?.taskInfo]); // eslint-disable-line react-hooks/exhaustive-deps

    const getContentHeight = () => {
        let mainElement = document.getElementsByClassName("app");
        let subject = document.querySelector(".subjectDiv");
        let inputSection = document.querySelector(".inputBottomSection");
        return mainElement[0]?.offsetHeight - (subject?.clientHeight || 0) - inputSection.clientHeight - 55;
    }

    useEffect(() => {
        const debouncedHandleResize = debounce(function handleResize() {
            setScrollBarHeight(getContentHeight());
            window.console.log('debounce - ', getContentHeight());
        }, 300);
        // set scroll window height
        setScrollBarHeight(getContentHeight());
        window.addEventListener("resize", debouncedHandleResize);
        
        // cleanup listener
        return _ => {
            window.removeEventListener('resize', debouncedHandleResize);
        }
        // eslint-disable-next-line
    }, [scrollbarHeight, msgInput, addedMsg, msgTask]);

    useEffect(() => {
        if (typeof addedMsg !== "undefined" && addedMsg) {
            fetchMessages();
            setMsgInput("");
            setAttachFile("");
            msgInputElementRef.current.textContent = "";
            dispatch(Action.addInfoMsgClear());
        }
        // eslint-disable-next-line
    }, [addedMsg]);

    useEffect(() => {
        getMessageHeight();

    }, [messageList]);

    function setMsgInputBoxHeight() {
        const mainElement = document.getElementsByClassName("app");
        const inputBox = document.getElementsByClassName("inputkeyContainer");
        if (inputBox[0]?.scrollHeight > 0) {
            let maxHeight = 0.50 * mainElement[0]?.clientHeight;
            if (inputBox[0]?.scrollHeight > maxHeight) {
                setMsgBoxHeight(maxHeight);
                setMsgOverFlow(true);
            }
            else {
                setMsgOverFlow(false);
            }
        }
    }

    useEffect(() => {
        setMsgInputBoxHeight();
    }, [msgInput]);

    useEffect(() => {
       // window.console.log("Download file----", downloadedFile);
        if (
            typeof downloadedFile !== "undefined" &&
            downloadedFile !== null &&
            downloadedFile && fileData !=null
        ) {
         
            let binaryString = window.atob(downloadedFile);
            // window.console.log(binaryString)
            let binaryLen = binaryString.length;
            let bytes = new Uint8Array(binaryLen);

            for (let i = 0; i < binaryLen; i++) {
                let ascii = binaryString.charCodeAt(i);
                bytes[i] = ascii;
            }

            let blob = new Blob([bytes], { type: getMimeType(fileData) });
            let link = URL.createObjectURL(blob);

            dispatch(Action.fileDownloadClear());

            const a = document.createElement('a');
            a.href = link;
            a.download = fileData.FileName; // Specify the file name
            a.target = "_blank";
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);

            // Clean up
            URL.revokeObjectURL(link);
        }
        // eslint-disable-next-line
    }, [downloadedFile]);

    useEffect(() => {
        if (typeof msgTask !== "undefined" && msgTask !== null && msgTask) {
            let taskMessages = msgTask?.MessageList ?? [];
            if (taskMessages.length > 0) {
                const messageList = taskMessages[taskMessages.length - 1];
                if (messageList.Messages.length > 0) {
                    const lastMsg =
                        messageList.Messages[messageList.Messages.length - 1];
                    setRepliedToMessageId(lastMsg.MessageID);
                }
            }
            setMessageThreadId(msgTask.MessageThreadID);
            setMessageList(taskMessages);
            setIsLoading(false);            

            if (taskMessages.length > 1 || taskMessages[0]?.Messages.length > 1) {
                setTimeout(() => {
                    msgInputRef.current.scrollToBottom();
                }, 50);
            }
        }
    }, [msgTask]);

    const fetchMessages = async () => {
        const id = location.state.taskInfo?.AssociatedID || location.state.id;
        const timeOffset = (new Date()).getTimezoneOffset();
        const taskEndPoint = Endpoints.UPLOAD_GET_MSG_FOR_TASK + id + '/' + timeOffset;
        let params = { endpoint: taskEndPoint, method: "GET" };
        dispatch(Action.infoGetMsgListRequest(params));
    };

    // This function calculates width and height of the list
    const getMessageHeight = () => {
        setScrollHeight(window.innerHeight);
    };
    function handleChange(event) {
        setMsgInput(event.target.textContent);
    }
    function handleMessageFile() {
        fileref.current.click();
    }

    const fileChangeHandler = (event) => {
        setAttachFile(event.target.files[0]);
    };

    const removeFile = () => {
        setAttachFile("");
    };

    const handleMessageSubmission = async () => {
       
        if (msgInputElementRef.current.outerText !== "" || attachFile !== "") {
            const formData = new FormData();
            /*Update the formData object*/
            setIsLoading(true);
            formData.append("MessageText", msgInputElementRef.current.outerText);
            formData.append("IsReply", true);
            formData.append("Attachments", attachFile);
            formData.append("MessageThreadID", messageThreadId);
            formData.append("RepliedToMessageID", repliedToMessageId);
            formData.append("endpoint", "messages/AddMessage");
            
            dispatch(Action.addInfoMsgRequest(formData));
        }
    };

    const handleClose = () => {
        setSubmitModal(false);
        navigate(-1);
    }

    const downloadAttachment = async (file) => {

        dispatch(Action.fileDownloadClear());
        setFileData(file)
        const taskEndPoint = file.URL;
        let params = {
            endpoint: taskEndPoint,
            mime: getMimeType(file),
            method: "GET",
        };
        dispatch(Action.fileDownloadRequest(params));
    };

    function Attachment(attachment) {
        const attachmentInfo = attachment.attachDetails;
        let filename = attachmentInfo.FileName.split(".").pop().toLowerCase();
        let Icon = getFileTypeIcon(filename);

        return (
            <button
                onClick={async () =>
                    await downloadAttachment(attachmentInfo)
                }
                className={styles.attachmentButton}
            >
                <span className="flex-shrink-0">
                    <Icon />
                </span>
                <span className="text-break text-start lh-sm ms-2">{attachmentInfo.FileName}</span>
            </button>
        )
    }

    const Message = ({info}) => {
        let fromMessage = info.Type === "Question";
        let messageName = info.Name;
        let nameStyle = "";
        if(
           ( location?.state?.taskInfo?.TaskType === TASK_TYPE.ADDITIONAL_INFO &&
                location?.state?.taskInfo?.SubTaskType === TASK_SUB_TYPE.SCHEDULE_MEDICAL_SCREENING_3)
            ||
            (
                location?.state?.taskInfo?.TaskType === TASK_TYPE.ADDITIONAL_INFO &&
                location?.state?.taskInfo?.TaskSubType === 2
            )
            ||
            (
                location?.state?.taskInfo?.TaskType === TASK_TYPE.ADDITIONAL_INFO &&
                location?.state?.taskInfo?.SubTaskType === 3
            )
            ||
            (
                location?.state?.taskInfo?.TaskType === TASK_TYPE.ADDITIONAL_INFO &&
                location?.state?.taskInfo?.TaskSubType === 3
            )
        ){
            messageName = "Coordinator";
            nameStyle = styles.highlight;
        }

        return (
            <div className="mb-2 pb-1">
                <div className={fromMessage ? styles.questionContainer : styles.answerContainer}>
                    { fromMessage &&
                        <div className={`${styles.msgByName} ${nameStyle}`}>
                            {messageName}
                        </div>
                    }
                    <div className={`d-flex${fromMessage ? '' : ' justify-content-end'}`}>
                        <div className={fromMessage ? styles.questionTag : styles.answerTag}>
                            {info.Content}
                            {info.Attachments?.map(
                                (attachInfo) =>
                                    (
                                        <div key={attachInfo.MessageAttachmentID}
                                            className={`${fromMessage ? 'text-start' : 'text-end'} w-100`}>
                                            <div className={`${styles.attachmentContainer} d-inline-block`}>
                                                <div className={`${styles.attachment}`}>
                                                    <Attachment attachDetails={attachInfo}/>
                                                </div>
                                            </div>
                                        </div>
                                    )
                            )}
                        </div>
                        <div className={fromMessage ? styles.questionTime : styles.answerTime}>
                            
                            {format(new Date(info.MessageDateTime.replace('Z',"")), DATE_TIME_FORMAT.MESSAGES)}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const FileAttachControl = () => {
        return (
            !attachFile ? (
                <>
                    <input
                        ref={fileref}
                        className="d-none"
                        type="file"
                        name="file"
                        onChange={fileChangeHandler}
                    />
                    <button onClick={handleMessageFile}>
                        Attach File <FaPaperclip />
                    </button>
                </>
            ) : (
                <>
                    <div className="flex-grow-1 text-truncate">
                        {attachFile.name}
                    </div>
                    <div className="flex-shrink-0">
                        <BsFillXCircleFill
                            className={styles.circleClose}
                            onClick={removeFile}
                        />
                    </div>
                </>
            )
        );
    };

    return (
        <> 
            {(msgloading || fileLoading || addMsgloading || taskSubmitLoading) && <Loader />}
            <Layout showHeader="false" showFooter="false">
                <PageTitle
                    title={location.state ? location.state.taskname : ""}
                    onClose={handleClose}
                />
                <TaskDescription styles={`px-3 py-2`} taskDetails={taskInfo}/>
               
                <div className={`position-relative`} id="messageContainer">
                    <Scrollbars
                        style={{ height: scrollbarHeight - 100 }}
                        autoHeight
                        autoHeightMax={scrollHeight - 100}
                        className={`${styles.msgContainer} scroll-container`}
                        ref={msgInputRef}
                    >
                        {messageList &&
                            messageList.map((message) => {
                                return (
                                    <div key={message.MessageDate} className={`${styles.messages} msgList`}>
                                        <div className={styles.msgDate}>
                                            
                                            {dayjs(message.MessageDate,"YYYY-MM-DD").format("dddd, MMMM DD")}
                                        </div>
                                        {message.Messages &&
                                            message.Messages.map(
                                                (msgInfo) => <Message key={msgInfo.MessageID} info={msgInfo} />
                                            )
                                        }
                                    </div>
                                );
                            })}
                    </Scrollbars>
                </div>
                <div className="w-100 bg-white position-absolute start-0 end-0 bottom-0 pb-3">
                    <div className={`inputBottomSection pb-1 mx-auto ${styles.inputBox}`}>
                        <div className="position-relative">
                            <Scrollbars
                                autoHeight
                                autoHeightMax={400}
                                className={styles.spanTextArea}
                            >
                                <div
                                    className={styles.spanTextAreaBox + " inputkeyContainer "}
                                    // eslint-disable-next-line
                                    role="textarea"
                                    onInput={handleChange}
                                    contentEditable={true}
                                    ref={msgInputElementRef}
                                    suppressContentEditableWarning={true}
                                >
                                </div>
                            </Scrollbars>
                            <button className={`${styles.inputIcon} ${msgInput || attachFile ? styles.active : " disabled"} `}
                             onClick={handleMessageSubmission}>
                                <SlPaperPlane
                                    className={styles.sendMsg}
                                />
                            </button>
                        </div>

                        <div className={`position-relative d-flex w-100 ${styles.attachmentWrap}`}>
                            {
                                (state?.taskInfo.TaskType === TASK_TYPE.ADDITIONAL_INFO && [2,3].includes(state?.taskInfo.SubTaskType|| state?.taskInfo.TaskSubType)) ||
                                (state?.taskInfo.TaskType === TASK_TYPE.SCHEDULE && [6,7].includes(state?.taskInfo.SubTaskType || state?.taskInfo.TaskSubType))
                                ? null : <FileAttachControl />
                            }
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    );
};

export default AdditionalInfoScreen;
