/*screen name + methodname+ actiontype*/
export const INFO_MESSAGE_GET_LIST_REQUEST = 'INFO_MESSAGE_GET_LIST_REQUEST';
export const INFO_MESSAGE_GET_LIST_SUCCESS = 'INFO_MESSAGE_GET_LIST_SUCCESS';
export const INFO_MESSAGE_GET_LIST_ERROR = 'INFO_MESSAGE_GET_LIST_ERROR';
export const INFO_MESSAGE_GET_LIST_CLEAR = 'INFO_MESSAGE_GET_LIST_CLEAR';

export const INFO_ADD_MESSAGE_REQUEST = 'INFO_ADD_MESSAGE_REQUEST';
export const INFO_ADD_MESSAGE_SUCCESS = 'INFO_ADD_MESSAGE_SUCCESS';
export const INFO_ADD_MESSAGE_ERROR = 'INFO_ADD_MESSAGE_ERROR';
export const INFO_ADD_MESSAGE_CLEAR = 'INFO_ADD_MESSAGE_CLEAR';

export const UPLOAD_FILE_DOWNLOAD_REQUEST = 'UPLOADFILE_DOWNLOAD_REQUEST';
export const UPLOAD_FILE_DOWNLOAD_SUCCESS = 'UPLOADFILE_DOWNLOAD_SUCCESS';
export const UPLOAD_FILE_DOWNLOAD_ERROR = 'UPLOFILE_DOWNLOAD_ERROR';
export const UPLOAD_FILE_DOWNLOAD_CLEAR = 'UPLOFILE_DOWNLOAD_CLEAR';

export const INFO_TASK_SUBMIT_REQUEST = 'INFO_TASK_SUBMIT_REQUEST';
export const INFO_TASK_SUBMIT_SUCCESS = 'INFO_TASK_SUBMIT_SUCCESS';
export const INFO_TASK_SUBMIT_ERROR = 'INFO_TASK_SUBMIT_ERROR';
export const INFO_TASK_SUBMIT_CLEAR = 'INFO_TASK_SUBMIT_CLEAR';

