
import { combineReducers } from "redux";
import { getBasicReducer } from "../../../core/utils";
import * as Constants from "./constants";

export const docuData = combineReducers({
    completedTask: getBasicReducer({
        request: Constants.DOCU_SEND_TO_APPROVE_REQUEST,
        success: Constants.DOCU_SEND_TO_APPROVE_SUCCESS,
        failure: Constants.DOCU_SEND_TO_APPROVE_ERROR,
        clear: Constants.DOCU_SEND_TO_APPROVE_CLEAR,
    }),
    getDocuUrlLink: getBasicReducer({
        request: Constants.DOCU_URL_REQUEST,
        success: Constants.DOCU_URL_SUCCESS,
        failure: Constants.DOCU_URL_ERROR,
        clear: Constants.DOCU_URL_CLEAR,
    }),
});


