/*screen name + methodname+ actiontype*/
export const HOME_TASK_FETCH_REQUEST = 'HOME_TASK_FETCH_REQUEST';
export const THOME_TASK_FETCH_SUCCESS = 'HOME_TASK_FETCH_SUCCESS';
export const HOME_TASK_FETCH_ERROR = 'HOME_TASK_FETCH_ERROR';
export const HOME_TASK_FETCH_CLEAR = 'HOME_TASK_FETCH_CLEAR';

export const HOME_TASK_SEND_TO_APPROVE_REQUEST = 'HOME_TASK_SEND_TO_APPROVE_REQUEST';
export const HOME_TASK_SEND_TO_APPROVE_SUCCESS = 'HOME_TASK_SEND_TO_APPROVE_SUCCESS';
export const HOME_TASK_SEND_TO_APPROVE_ERROR = 'HOME_TASK_SEND_TO_APPROVE_ERROR';
export const HOME_TASK_SEND_TO_APPROVE_CLEAR = 'HOME_TASK_SEND_TO_APPROVE_CLEAR';

export const HOME_PROGRESS_GET_DATA = 'HOME_PROGRESS_GET_DATA';
export const HOME_PROGRESS_GET_SUCCESS = 'HOME_PROGRESS_GET_SUCCESS';
export const HOME_PROGRESS_GET_ERROR = 'HOME_PROGRESS_GET_ERROR';
export const HOME_PROGRESS_GET_CLEAR = 'HOME_PROGRESS_GET_CLEAR';

export const HOME_TASK_IFRAME_FETCH_REQUEST = 'HOME_TASK_IFRAME_FETCH_REQUEST';
export const HOME_TASK_IFRAME_FETCH_SUCCESS = 'HOME_TASK_IFRAME_FETCH_SUCCESS';
export const HOME_TASK_IFRAME_FETCH_ERROR = 'HOME_TASK_IFRAME_FETCH_ERROR';
export const HOME_TASK_IFRAME_FETCH_CLEAR = 'HOME_TASK_IFRAME_FETCH_CLEAR';

export const NOTIFICATION_GET_DATA = 'NOTIFICATION_GET_DATA';
export const NOTIFICATION_GET_SUCCESS = 'NOTIFICATION_GET_SUCCESS';
export const NOTIFICATION_GET_ERROR = 'NOTIFICATION_GET_ERROR';
export const NOTIFICATION_GET_CLEAR = 'NOTIFICATION_GET_CLEAR';

export const NOTIFICATION_ACKNOWLEDGE_REQUEST = 'NOTIFICATION_ACKNOWLEDGE_REQUEST';
export const NOTIFICATION_ACKNOWLEDGE_SUCCESS = 'NOTIFICATION_ACKNOWLEDGE_SUCCESS';
export const NOTIFICATION_ACKNOWLEDGE_ERROR = 'NOTIFICATION_ACKNOWLEDGE_ERROR';
export const NOTIFICATION_ACKNOWLEDGE_CLEAR = 'NOTIFICATION_ACKNOWLEDGE_CLEAR';
