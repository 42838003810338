/*screen name + methodname+ actiontype*/
export const LOGIN_USER_REQUEST = 'LOGIN_USER_REQUEST';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_ERROR = 'LOGIN_USER_ERROR';
export const LOGIN_USER_CLEAR = 'LOGIN_USER_CLEAR';
export const LOGIN_RESET_REQUEST = 'LOGIN_RESET_REQUEST';

/* forget password screen  */
export const VERIFIED_PIN_REQUEST = 'VERIFIED_PIN_REQUEST';
export const VERIFIED_PIN_SUCCESS = 'VERIFIED_PIN_SUCCESS';
export const VERIFIED_PIN_ERROR = 'VERIFIED_PIN_ERROR';
export const VERIFIED_PIN_CLEAR = 'VERIFIED_PIN_CLEAR';

export const UPDATE_PASSWORD_REQUEST = 'UPDATE_PASSWORD_REQUEST';
export const UPDATE_PASSWORD_SUCCESS = 'UPDATE_PASSWORD_SUCCESS';
export const UPDATE_PASSWORD_ERROR = 'UPDATE_PASSWORD_ERROR';
export const UPDATE_PASSWORD_CLEAR = 'UPDATE_PASSWORD_CLEAR';

export const VALIDATE_MAIL_REQUEST = 'VALIDATE_MAIL_REQUEST';
export const VALIDATE_MAIL_SUCCESS = 'VALIDATE_MAIL_SUCCESS';
export const VALIDATE_MAIL_ERROR = 'VALIDATE_MAIL_ERROR';
export const VALIDATE_MAIL_CLEAR = 'VALIDATE_MAIL_CLEAR';

export const UPDATED_PASSWORD_RESET = 'UPDATED_PASSWORD_RESET';

export const REJECT_STATUS_REQUEST = 'REJECT_STATUS_REQUEST';
export const REJECT_STATUS_SUCCESS = 'REJECT_STATUS_SUCCESS';
export const REJECT_STATUS_ERROR = 'REJECT_STATUS_ERROR';
export const REJECT_STATUS_CLEAR = 'REJECT_STATUS_CLEAR';
