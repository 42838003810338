import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Endpoints } from "../../core/networking";
import * as Actions from "../state/background/actions";

const BackgroundFetch = (props) => {
    const {
        requestBy = [],
    } = props;
    const dispatch = useDispatch();
    const {
        loginData: { loginUser },
    } = useSelector((state) => state);
    const { response: loginVal } = loginUser || {};

    useEffect(() => {
        // clear the tasks results.
        dispatch(Actions.taskFetchClear());
        dispatch(Actions.notificationInfoClear());
        dispatch(Actions.getDonorEventClear());
        dispatch(Actions.msgGetListClear());

        let interval = setInterval(() => {
            makeApiRequests();
        }, 120000);

        let messageInterval = setInterval(() => {
            fetchMessages(loginVal?.data?.applicant_id);
        }, 60000);

        return () => {
            clearInterval(interval);
            clearInterval(messageInterval);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const makeApiRequests = () => {
        let applicantID = loginVal?.data?.applicant_id;
        if (applicantID !== "" && applicantID !== undefined && applicantID !== null) {
            if (requestBy?.includes("tasks"))
                fetchTasks(applicantID);

            if (requestBy?.includes("notify"))
                fetchNotifications(applicantID);

            if (requestBy?.includes("calevents"))
                fetchCalendarEvents(applicantID);
        }
    }

    const fetchTasks = (id) => {
        const taskEndPoint = Endpoints.GET_DONOR_TASK + id;
        let params = {
            endpoint: taskEndPoint,
            method: "GET",
        };
        
        dispatch(Actions.taskFetchRequest(params));
    }

    const fetchNotifications = (id) => {
        const notificationEndpoint = Endpoints.NOTIFICATION_GET_DATA + id;
        let notify = {
            endpoint: notificationEndpoint,
            method: "GET",
        };
        
        dispatch(Actions.notificationInfo(notify));
    }

    const fetchCalendarEvents = (id) => {
        const calendarEventEndPoint = Endpoints.CALENDAR_DONOR_EVENT + id;
        let params = {
            endpoint: calendarEventEndPoint,
            method: "GET",
        };
        
        dispatch(Actions.getDonorEventRequest(params));
    }

    const fetchMessages = (id) => {
        if (id !== "" && id !== undefined && id !== null && requestBy?.includes("messages")) {
            const messageEndPoint = Endpoints.MESSAGE_GET_MSG_LIST + id;
            let params = {
                endpoint: messageEndPoint,
                method: "GET"
            };
           
            dispatch(Actions.msgGetListRequest(params));
        }
    }

    return <></>;
}

export default BackgroundFetch;
