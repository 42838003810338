import customParseFormat from "dayjs/plugin/customParseFormat";
import dayjs from "dayjs";

dayjs.extend(customParseFormat);


export const dayMonthYearFormat = (date) =>{
    return dayjs(date, "MM-DD-YYYY").format("D MMM YYYY");
}

export const dayMonYFormat = (date) =>{
    return new Date(dayjs(date, "MM-DD-YYYY").format("D MMM YYYY"));
}
export const monthDayYearFormat = (date) =>{
    return dayjs(date, "MM-DD-YYYY")
}

export const toLocalDateTime = (date) => {
    return new Date(date.getTime() - date.getTimezoneOffset() * 60 * 1000);
}

export const removeAnchor = (str) => {
    if (typeof str == "undefined")
        return str.replace(/<a[^>]*>|<\/a>/g, "");
    else return "";
}

export const MimeType = (attachment) => {
        let mimeType = "";
        if(attachment!=null)
        {
            let filename = attachment.FileName.split(".").pop().toLowerCase();
            switch (filename) {
                case "jpg":
                case "jpeg":
                    mimeType = "image/jpeg";
                    break;
                case "png":
                    mimeType = "image/png";
                    break;
                case "doc":
                case "docx":
                    mimeType = "application/msword";
                    break;
                case "pdf":
                    mimeType = "application/pdf";
                    break;
                case "xlsx":
                case "xls":
                    mimeType = "application/vnd.ms-excel";
                    break;
                case "zip":
                    mimeType = "application/zip";
                    break;
                case "txt":
                    mimeType = "text/plain";
                    break;
                default:
                    mimeType = "text/plain";
                    break;
                // code block
            }
        }
        return mimeType;
    }
