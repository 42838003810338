/*screen name + methodname+ actiontype*/

export const DOCU_SEND_TO_APPROVE_REQUEST = 'DOCU_SEND_TO_APPROVE_REQUEST';
export const DOCU_SEND_TO_APPROVE_SUCCESS = 'DOCU_SEND_TO_APPROVE_SUCCESS';
export const DOCU_SEND_TO_APPROVE_ERROR = 'DOCU_SEND_TO_APPROVE_ERROR';
export const DOCU_SEND_TO_APPROVE_CLEAR = 'DOCU_SEND_TO_APPROVE_CLEAR';

export const DOCU_URL_REQUEST = 'DOCU_URL_REQUEST';
export const DOCU_URL_SUCCESS = 'DOCU_URL_SUCCESS';
export const DOCU_URL_ERROR = 'DOCU_URL_ERROR';
export const DOCU_URL_CLEAR = 'DOCU_URL_CLEAR';
