import styles from "./CompleteLab.module.scss";
import Layout from "../components/ui/Layout";
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState, useRef, forwardRef } from "react";
import { BsXLg } from "react-icons/bs";
import Loader from "../components/ui/Loader";
import { useSelector, useDispatch } from "react-redux";
import * as Actions from "./state/testkit/actions";
import { Scrollbars } from "react-custom-scrollbars-2";
import { Endpoints } from "../core/networking";
import { FiChevronRight } from "react-icons/fi";
import DateTimePicker from "../components/core/DateTimePicker";
import { BiCalendarAlt } from "react-icons/bi";
import { format } from 'date-fns';
import { createPortal } from "react-dom";
import { MimeType } from "../utils";
import TimeZone from "./components/TimeZone";
import TaskDescription from "./components/TaskDescription";
import PageTitle from "./components/PageTitle";

const CompleteLab = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    let { state } = useLocation();
    const [fileName, setFileName] = useState("");
    const [fileInfo, setFileInfo] = useState(null);
    const [scrollbarHeight, setScrollBarHeight] = useState(window.screen.availHeight);
    const {
        loginData: { loginUser },
        testkitData: { submitAppointment, getLabWorkSchedule, getLabAttachment, getLabBoodDrawLists },
    } = useSelector((state) => state);
    const { response: loginVal } = loginUser || {};

    const { response: labScheduleList, isLoading: labScheduleLoading } = getLabWorkSchedule || {};
    const { response: labAttachment, isLoading: labAttachmentLoading } = getLabAttachment || {};
    const { response: appointmentResponse, isLoading: submitAppointmentLoading } = submitAppointment || {};
    const { response: labBloodDrawResponse, isLoading: labBloodDrawLoading } = getLabBoodDrawLists || {};

    const [taskInfo, setTaskInfo] = useState([]);
    const [page, setPage] = useState(1);
    const [startDate, setStartDate] = useState();
    const [showLabCorpLocation, setShowLabCorpLocation] = useState(false);
    const [labFiles, setLabFiles] = useState([]);
    const [bloodLocations, setBloodLocations] = useState([]);
    const [zone, setZone] = useState(3);
    const calendarRef = useRef();
    const inputRef = useRef();

    useEffect(() => {
        // set scroll window height
        let mainElement = document.getElementsByClassName("app");
        setScrollBarHeight(mainElement[0]?.offsetHeight - 190);
        window.addEventListener("resize", function () {
            mainElement = document.getElementsByClassName("app");
            setScrollBarHeight(mainElement[0]?.offsetHeight - 190);
        });
    }, [scrollbarHeight]);

    useEffect(() => {
        document.title = 'Complete Lab';
        setPage(1);
        setFileInfo(null);
        setFileName("");
        setShowLabCorpLocation(false);
        setTaskInfo(location?.state?.taskInfo ?? []);
        dispatch(Actions.fileDownloadClear());

        dispatch(Actions.sendLabAppointmentClear());
        dispatch(Actions.getLabScheduleClear());

        if (location?.state?.taskInfo?.ID) {
            const taskEndPoint = Endpoints.GET_TASK_DOCUMENT + location?.state?.taskInfo?.ID;
            let param = { endpoint: taskEndPoint, method: "GET" };
            // get tasks data
            dispatch(Actions.getLabScheduleData(param));
        }

        if (loginVal?.data?.applicant_id !== "") {
            const taskEndPoint = Endpoints.GET_BLOOD_DRAW_LABS + loginVal?.data?.applicant_id;
            let param = { endpoint: taskEndPoint, method: "GET" };
            dispatch(Actions.getBloodDrawLabs(param));
        }

    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    const submitLabAppointment = () => {
        if (taskInfo?.ID) {
            const taskcompleteEndpoint = Endpoints.LAB_APPOINTMENT;

            const formData = new FormData();
            formData.append("method", "PUT");
            formData.append("taskID", taskInfo?.ID);
            formData.append("appointmentDateTime", format(startDate, 'yyyy-MM-dd HH:mm:ss'));
            formData.append("appointmentTimezoneID", zone);
            formData.append("endpoint", taskcompleteEndpoint);
            //window.console.log("--formdaata--", formData);
            dispatch(Actions.sendLabAppointment(formData));
        }
    }

    useEffect(() => {
        if (
            typeof labAttachment !== "undefined" &&
            labAttachment !== null &&
            labAttachment && fileName && fileInfo
        ) {
           

            let binaryString = window.atob(labAttachment);
            // window.console.log(binaryString)
            let binaryLen = binaryString.length;
            let bytes = new Uint8Array(binaryLen);

            for (let i = 0; i < binaryLen; i++) {
                let ascii = binaryString.charCodeAt(i);
                bytes[i] = ascii;
            }

            let blob = new Blob([bytes], { type: MimeType(fileInfo) });
            let link = URL.createObjectURL(blob);
            //window.open(link, '_blank');


            dispatch(Actions.fileDownloadClear());

            const a = document.createElement('a');
            a.href = link;
            a.download = fileName; // Specify the file name
            a.target = "_blank";
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);

            // Clean up
            URL.revokeObjectURL(link);



        }
    }, [labAttachment, fileName]);

    useEffect(() => {
        if (appointmentResponse?.status === 200) {
            dispatch(Actions.sendLabAppointmentClear());
            navigate(-1);
        }
    }, [appointmentResponse]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (labScheduleList) {
            setLabFiles(labScheduleList);
            dispatch(Actions.fileDownloadClear());
        }
    }, [labScheduleList]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (labBloodDrawResponse) {
            setBloodLocations(labBloodDrawResponse);
            dispatch(Actions.getLabBloodDrawClear());
        }
    }, [labBloodDrawResponse]);// eslint-disable-line react-hooks/exhaustive-deps

    const CustomInput = forwardRef((props: any, ref) => {
        return <input {...props} ref={ref}
            readOnly={true}
            className={styles.customDatePickerStyle}
            onFocus={(e) => e.target.readOnly = true}
            onKeyDown={(e) => {
                e.preventDefault();
            }} />;
    });


    const downloadAttachment = async (file) => {
        const taskEndPoint = file.URL;
        //let filename = file.FileName;
        setFileName(file.FileName);
        setFileInfo(file);
        let params = {
            endpoint: taskEndPoint,
            mime: MimeType(file),
            method: "GET",
        };
        dispatch(Actions.fileDownloadRequest(params));
    };

    const openDatepicker = () => {
        calendarRef.current.setOpen(true);
    };

    const selectedTimeZone = (zone) => {
        setZone(zone);
    }
    const isSubmitDisabled = !startDate;
    return (
        <>
            {(submitAppointmentLoading || labScheduleLoading || labAttachmentLoading || labBloodDrawLoading) && <Loader />}
            {
                showLabCorpLocation && createPortal(
                    <div className={styles.labOverlay}>
                        <div className={`${styles.labLocationContainer} position-absolute top-50 `} >
                            <BsXLg
                                onClick={() => setShowLabCorpLocation(false)}
                                className={`${styles.labCloseIcon} position-absolute`}
                            />
                            <h3>Lab Locations</h3>
                            <ul style={{ height: scrollbarHeight - 250} }>
                                {
                                    bloodLocations?.BloodDrawLocations &&
                                    bloodLocations?.BloodDrawLocations.map(
                                        (locations, counter) => {
                                            return (
                                                <li className={styles.locationsInfoLists} key={counter} onClick={async () => null} >
                                                    <h4 className={styles.locationHeading}>{locations.Name}</h4>
                                                    <span>{locations.AddressOne}</span>
                                                    <span>{locations.City}&nbsp;{locations.State}&nbsp;{locations.ZipCode}</span>
                                                    <span>{locations.Phone}</span>
                                                </li>
                                            );
                                        }
                                    )
                                }
                            </ul>
                        </div>
                    </div>,
                    document.querySelector('.app')
                )
            }
            <Layout showHeader="false">
                <div className={styles.stickyHeader}>
                    <PageTitle
                        title={location?.state?.pageTitle}
                        headerClass="homePageTitle"
                        linkTo={state?.prevPage ? state?.prevPage : -1}
                        linkState={{ menu: state?.menu }}
                    />
                </div>
                <div className={`position-relative ${styles.completeLabContainer}`} id="completelabContainer">
                    {
                        page === 1 &&
                        <Scrollbars
                            autoHeight
                            className="scroll-container"
                            autoHeightMax={scrollbarHeight}
                            style={{ marginBottom: "20px" }}
                        >
                            <div className={styles.step1}>
                                <div className={styles.completeLabContent}>
                                    <TaskDescription styles={`px-0 py-2`} taskDetails={taskInfo} />

                                    <div className={styles.attachmentLists}>
                                        {labFiles?.length > 0 &&
                                            <h3 className="fw-bold">Lab order{labFiles?.length > 1 && "s"}:</h3>
                                        }
                                        <ul>
                                            {
                                                labFiles != null &&
                                                labFiles.map(
                                                    (list, counter) => {
                                                        return (
                                                            <li className={styles.labAttachment} key={counter} onClick={async () => await downloadAttachment(list)} >
                                                                <span>{list.FileName}</span>
                                                            </li>
                                                        );
                                                    }
                                                )
                                            }
                                        </ul>
                                    </div>
                                    {
                                        (bloodLocations?.BloodDrawLocations && bloodLocations?.BloodDrawLocations?.length > 0) && (taskInfo.UseOtherLabs === true) &&
                                        <div className={styles.labLocations}>
                                            <button onClick={() => setShowLabCorpLocation(true)}>Lab Locations</button>
                                        </div>
                                    }
                                    {
                                        (taskInfo.UseLabcorp === true) &&
                                        <>
                                            <div className={styles.appointmentText}>
                                                Visit Labcorp online to schedule an appointment for your lab tests. Walk-ins are available at some locations.
                                            </div>
                                            <div className={styles.scheduleAppointment}>
                                                <a href="https://www.labcorp.com/labs-and-appointments" rel="noreferrer" target="_blank" className={`${styles.appointmentButton} `}>
                                                    Schedule Labcorp Appointment
                                                </a>
                                            </div>
                                            <div className={styles.linkToappointment}>
                                                or go to:
                                                <a target="_blank" href="https://www.labcorp.com/labs-and-appointments" rel="noreferrer">
                                                    https://www.labcorp.com/labs-and-appointments
                                                </a>
                                            </div>
                                        </>
                                    }
                                </div>
                                <div className={styles.completeButton}>
                                    <div onClick={() => setPage(2)} className={`${styles.appointmentButton} `}>
                                        Enter Appointment Details
                                        <FiChevronRight className={styles.scheduleArrow} />
                                    </div>
                                </div>
                            </div>
                        </Scrollbars>
                    }

                    {
                        page === 2 &&
                        <div className={styles.step2}>
                            <p className={styles.testingCompleteInfo}>
                                Enter the date and time for your scheduled appointment or provide the date you plan to go to Labcorp.
                            </p>

                            <div>
                                <TimeZone class={"timeZoneContainer"} selectedTimeZone={selectedTimeZone} />
                            </div>

                            <div className={styles.step2Input}>
                                <div className={`${styles.datepickerWrap} mx-auto`}>
                                    <div className={`${styles.datepickerTxt} position-relative`}>
                                        <DateTimePicker
                                            startDate={startDate}
                                            customInput={<CustomInput inputRef={inputRef} />}
                                            onChange={(date) => setStartDate(date)}
                                            ref={calendarRef}
                                        />
                                        <BiCalendarAlt className={styles.calendarIcon} onClick={openDatepicker} />
                                    </div>
                                </div>
                            </div>

                            <div className={`${styles.submitButton} `}>
                                <div onClick={startDate && !isSubmitDisabled ? () => submitLabAppointment() : () => null}
                                    className={`${styles.button} ${startDate && !isSubmitDisabled ? styles.active : ""}`}
                                    disabled={isSubmitDisabled}>
                                    Submit
                                    <FiChevronRight className={styles.scheduleArrow} />
                                </div>
                            </div>
                        </div>
                    }

                </div>
            </Layout>

        </>
    );
}

export default CompleteLab;
