/*screen name + methodname+ actiontype*/
export const MESSAGE_GET_LIST_REQUEST = 'MESSAGE_GET_LIST_REQUEST';
export const MESSAGE_GET_LIST_SUCCESS = 'MESSAGE_GET_LIST_SUCCESS';
export const MESSAGE_GET_LIST_ERROR = 'MESSAGE_GET_LIST_ERROR';
export const MESSAGE_GET_LIST_CLEAR = 'MESSAGE_GET_LIST_CLEAR';

export const MSG_GET_USER_LIST_REQUEST = 'MSG_GET_USER_LIST_REQUEST';
export const MSG_GET_USER_LIST_SUCCESS = 'MSG_GET_USER_LIST_SUCCESS';
export const MSG_GET_USER_LIST_ERROR = 'MSG_GET_USER_LIST_ERROR';
export const MSG_GET_USER_LIST_CLEAR = 'MSG_GET_USER_LIST_CLEAR';

export const ADD_MESSAGE_REQUEST = 'ADD_MESSAGE_REQUEST';
export const ADD_MESSAGE_SUCCESS = 'ADD_MESSAGE_SUCCESS';
export const ADD_MESSAGE_ERROR = 'ADD_MESSAGE_ERROR';
export const ADD_MESSAGE_CLEAR = 'ADD_MESSAGE_CLEAR';

export const GET_MESSAGE_INFO_REQUEST = 'GET_MESSAGE_INFO_REQUEST';
export const GET_MESSAGE_INFO_SUCCESS = 'GET_MESSAGE_INFO_SUCCESS';
export const GET_MESSAGE_INFO_ERROR = 'GET_MESSAGE_INFO_ERROR';
export const GET_MESSAGE_INFO_CLEAR = 'GET_MESSAGE_INFO_CLEAR';

/* Blog screen */
export const GET_CATEGORIES_REQUEST = 'GET_CATEGORIES_REQUEST';
export const GET_CATEGORIES_SUCCESS = 'GET_CATEGORIES_SUCCESS';
export const GET_CATEGORIES_ERROR = 'GET_CATEGORIES_ERROR';
export const GET_CATEGORIES_CLEAR = 'GET_CATEGORIES_CLEAR';

export const GET_CATEGORIES_LIST_REQUEST = 'GET_CATEGORIES_LIST_REQUEST';
export const GET_CATEGORIES_LIST_SUCCESS = 'GET_CATEGORIES_LIST_SUCCESS';
export const GET_CATEGORIES_LIST_ERROR = 'GET_CATEGORIES_LIST_ERROR';
export const GET_CATEGORIES_LIST_CLEAR = 'GET_CATEGORIES_LIST_CLEAR';

export const BLOG_DETAILS_REQUEST = 'BLOG_DETAILS_REQUEST';
export const BLOG_DETAILS_SUCCESS = 'BLOG_DETAILS_SUCCESS';
export const BLOG_DETAILS_ERROR = 'BLOG_DETAILS_ERROR';
export const BLOG_DETAILS_CLEAR = 'BLOG_DETAILS_CLEAR';

export const UPLOAD_FILE_DOWNLOAD_REQUEST = 'UPLOADFILE_DOWNLOAD_REQUEST';
export const UPLOAD_FILE_DOWNLOAD_SUCCESS = 'UPLOADFILE_DOWNLOAD_SUCCESS';
export const UPLOAD_FILE_DOWNLOAD_ERROR = 'UPLOFILE_DOWNLOAD_ERROR';
export const UPLOAD_FILE_DOWNLOAD_CLEAR = 'UPLOFILE_DOWNLOAD_CLEAR';
