import AxiosInstance from './AxiosInstance';

export function AutoLogin(cookies) {
    let userName = "";
    if(cookies["wp-login-user-token"]!==undefined) {
        userName = cookies["wp-login-user-token"];
    } else if (cookies["wp-user-mobile-login"]!==undefined) {
        userName = cookies["wp-user-mobile-login"];
    }
    let user = {username: userName,password: "",autologin:true};
    return AxiosInstance.post("ffwpmobapi/v1/login",{user})
    .then((response) => {
        return response;
    }, error => {
        console.log(`error - ${error}`);
        return false;
    });
}

export function CheckIsLoggedIn(cookies) {
    return cookies["wp-login-user-token"] !== undefined
}

export function Logout(setCookie, removeCookie) {
    localStorage.removeItem("token");
    setCookie('front-end-app','0', { path: '/',domain: process.env.REACT_APP_COOKIE_DOMAIN });
    setCookie('front-end-logout',true, { path: '/',domain: process.env.REACT_APP_COOKIE_DOMAIN });
    setCookie('wp-user-mobile-login',null, { path: '/',domain: process.env.REACT_APP_COOKIE_DOMAIN });
    setCookie('dma-remember-me',null, { path: '/',domain: process.env.REACT_APP_COOKIE_DOMAIN });
    
    removeCookie("wp-login-user-token",{domain: process.env.REACT_APP_COOKIE_DOMAIN});
    removeCookie("wp-user-mobile-login",{domain: process.env.REACT_APP_COOKIE_DOMAIN});
    removeCookie("HasTravelItinerary", { domain: process.env.REACT_APP_COOKIE_DOMAIN });
    removeCookie("dma-remember-me",{domain: process.env.REACT_APP_COOKIE_DOMAIN});
}

export function GetPhotoInstructions() {
    return (
        <>
            <p>Please keep in mind that the photographs selected from this shoot will become part of your profile and patients will be able to view these photos to help them in the decision-making process of selecting a donor.  These photos will help you tremendously in the selection process, so please keep these pointers in mind:
            </p>
            <ul>
                <li>Bring/wear a nice top, jeans are okay to wear.</li>
                <li>Select solid colors for your outfits (you can bring 2-3 outfits), avoid flashy prints or bold colors.</li>
                <li>Use neutral or natural colors for your makeup</li>
                <li>Make sure the outfits you select do not expose a lot of skin.  Keep in mind that our patients prefer to see more "conservative" outfits.</li>
                <li>Tattoos are considered identifying, so if you have tattoos, please make sure they are covered up for this appointment.</li>
                <li>Please remove facial piercings for this appointment as well, if you have any.</li>
            </ul>
            <p>
                There will be several photos taken that day, but we will be selecting a few photos from the shoot to add to your profile.
            </p>
            <p style={{ fontWeight:"bold" }}>
                Please - if anything changes and you need to reschedule your appointment for any reason NOTIFY THE PHOTOGRAPHER IMMEDIATELY.  We request you provide 48 hours&rsquo; notice of any appointment changes. Appointments will be made at an offsite location. <u>If you do not notify her in a timely manner that you are unable to make it to the appointment, she will charge us a no-show/late cancellation fee, and this will be deducted from your screening compensation.</u> Thank you! Additionally, please note that if you are more than 15 minutes late to your appointment, you may need to reschedule as she generally has a tight schedule of appointments throughout the day.  Please be timely!
            </p>
            <p>
                <a href="/photoguide"
                    rel="noreferrer"
                    className={`position-relative d-inline-block`}>
                    Photoshoot Guidelines
                </a>
            </p>
        </>
    )
}

export function getCookie(cname) {/* eslint-disable */
    let matches = document.cookie.match(new RegExp(
        "(?:^|; )" + cname.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
    ));
    return matches ? decodeURIComponent(matches[1]) : "";
}


export function getQueryParams() {
    const params = new URLSearchParams(window.location.search);
    const state = {};
    for (const [key, value] of params.entries()) {
        state[key] = value;
    }
    return state;
}
