/* eslint-disable no-lone-blocks */
import { useState, useEffect,useRef } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { motion } from "framer-motion";
import { BsChevronUp } from "react-icons/bs";
import { Button, Row, Col } from "react-bootstrap";
import { useCookies } from "react-cookie";
import { ReactComponent as Logo } from "../../assets/images/logo.svg";
import { ReactComponent as Cross } from "../../assets/images/cross-icon.svg";

import LeftNavImg from "../../assets/images/Tutorial/Left-Button.png";
import RightNavImg from "../../assets/images/Tutorial/Right-Button.png";
import HamMenu from "../../assets/images/Tutorial/Menu-Link.png";

import TaskListImage from "../../assets/images/Tutorial/TaskList/Task-List.jpg";
import UploadScreenImage from "../../assets/images/Tutorial/TaskList/Upload-Screen.jpg";
import BrowseFile from "../../assets/images/Tutorial/TaskList/Browse-File.jpg";
import SendMessage from "../../assets/images/Tutorial/TaskList/Task-Messaging.jpg";
import TaskSubmitted from "../../assets/images/Tutorial/TaskList/Task-Submitted.jpg";
import ReceiveConfirmation from "../../assets/images/Tutorial/TaskList/Message-Confirmation.jpg";

import PTTaskScreen from "../../assets/images/Tutorial/PeriodTracker/Task-Screen.jpg";
import PeriodTracker1 from "../../assets/images/Tutorial/PeriodTracker/Period-Tracker-1.jpg";
import PeriodTracker2 from "../../assets/images/Tutorial/PeriodTracker/Period-Tracker-2.jpg";
import PeriodTracker3 from "../../assets/images/Tutorial/PeriodTracker/Period-Tracker-3.jpg";
import PeriodTracker4 from "../../assets/images/Tutorial/PeriodTracker/Period-Tracker-4.jpg";

import TaskCalendarScreen from "../../assets/images/Tutorial/TaskCalendar/Task-Screen.jpg";
import Calendar1 from "../../assets/images/Tutorial/TaskCalendar/Task-Calendar-01.jpg";
import Calendar2 from "../../assets/images/Tutorial/TaskCalendar/Task-Calendar-02.jpg";
import Calendar3 from "../../assets/images/Tutorial/TaskCalendar/Task-Calendar-03.jpg";

import MessagingScreen from "../../assets/images/Tutorial/Messaging/Task-Screen.jpg";
import Msg1 from "../../assets/images/Tutorial/Messaging/Messaging-01.jpg";
import Msg2 from "../../assets/images/Tutorial/Messaging/Messaging-02.jpg";
import Msg3 from "../../assets/images/Tutorial/Messaging/Messaging-03.jpg";
import Msg4 from "../../assets/images/Tutorial/Messaging/Messaging-04.jpg";
import Msg5 from "../../assets/images/Tutorial/Messaging/Messaging-05.jpg";
import Msg6 from "../../assets/images/Tutorial/Messaging/Messaging-06.jpg";

import styles from "./Welcome.module.scss";
import { CheckIsLoggedIn } from "../../services/Common";
import useHandleLogOut from "../../pages/hooks/useHandleLogOut";
import { ReactComponent as HomeIcon } from '../../assets/images/Menu-Icon-Home.svg';
import { ReactComponent as HomeCal } from '../../assets/images/Menu-Icon-Cal.svg';
import { ReactComponent as HomeChat } from '../../assets/images/Menu-Icon-Chat.svg';
import { ReactComponent as PeriodActiveTracker } from '../../assets/images/period-tracker-icon-active.svg';
import Carousel from 'react-bootstrap/Carousel';

const Welcome = () => {
	const location = useLocation();
	let { state } = useLocation();
	const [tutsMode, setTutsMode] = useState(false);
	const [welcomeBack, setWelcomeBack] = useState("");
	const navigate = useNavigate();
	const [cookies, setCookie, removeCookie] = useCookies(null);

    const [tutorialTitle,setTutorialTitle] = useState("Donor App Tutorials");
	const [index, setIndex] = useState(0);
	const carousellRef = useRef(null);
	const [pagename, setPageName] = useState("");

    const handleTutorialSwipe = (e) => {
        if(!tutsMode) {
            handleSwipe()
        } else if(pagename === "") {
            handleClose()
        }
    }

    const handleSelect = (selectedIndex, e) => {
		setIndex(selectedIndex);
	};

    const slideTutorial = (eventkey, direction) => {
        if ((eventkey === 0 && direction === 'start') || (eventkey === (Object.keys(tutorialPage[pagename][0]).length-1) && direction === 'end')) {
            goToTutorialMenu();
        }			
	}

    const onPrevClick = () => {
        carousellRef.current.prev();
        
        //setIndex(0);
	};
	const onNextClick = () => {
		carousellRef.current.next();
	};

	const goToTutorialMenu = () => {
		setIndex(0);
		setTutorialTitle("Donor App Tutorials");
		setPageName("");
		setTutsMode(true);
	}

	const handleSwipe = () => {
		setTutorialTitle("Donor App Tutorials");
		setIndex(0);
		setPageName("");
		setTutsMode(true);
	};

	const handleClose = () => {
        setTutorialTitle("Donor App Tutorials");
        setTutsMode(false);
        setPageName("");
		if(state?.prevPage)
		{
			navigate(state?.prevPage, {
				state: {
					menu:"open"
				},
			});
		}
	};

	const setTutorialPage = (pagename) => {
		if(pagename === "tasklist")
		{
			setTutorialTitle("Task List");
		}
		else if(pagename === "periodtracker")
		{
			setTutorialTitle("Period Tracker");
		}
		else if(pagename === "taskcalendar")
		{
			setTutorialTitle("Task Calendar");
		}
		else if(pagename === "messaging")
		{
			setTutorialTitle("Messaging");
		}
		setPageName(pagename);
	}

	const tutorialPage = {
		"tasklist" : [
			{
				1 : {
					"img":TaskListImage,
					"screenName":"Open task",
					"taskDesc": "Tap on task to view details",
					"handPointer": true,
					"pos":[
						{
							"top": "45%",
							"left": "49.25%",
						}
					]
				},
				2: {
					"img":UploadScreenImage,
					"screenName":"Choose file",
					"taskDesc": "Tap on the Choose File button to navigate to the file location",
					"handPointer": true,
					"pos":[
						{
							"top": "27%",
							"left": "49.25%",
						}
					]

				},
				3: {
					"img":BrowseFile,
					"screenName":"Choose file",
					"taskDesc": "Select the file to be uploaded",
					"handPointer": true,
					"pos":[
						{
							"top": "49%",
							"left": "20%",
						}
					]

				},
				4: {
					"img":SendMessage,
					"screenName":"Upload file",
					"taskDesc": "Once file is selected, tap Accept and Upload button to send the file",
					"handPointer": true,
					"pos":[
						{
							"top": "92%",
							"left": "70%",
						}
					]
				},
				5: {
					"img":ReceiveConfirmation,
					"screenName":"Receive confirmation",
					"taskDesc": "Message confirms that file has been uploaded successfully",
					"handPointer": false
				},
				6: {
					"img":TaskSubmitted,
					"screenName":"Task Submitted",
					"taskDesc": "Task moves to Submitted for Approval",
					"handPointer": false
				}
			}
		],
		"periodtracker":[
			{
				1 : {
					"img":PTTaskScreen,
					"screenName":"Period Tracker",
					"taskDesc": "Tap on the icon to open the Period Tracker",
					"handPointer": true,
					"pos":[
						{
							"top": "95%",
							"left": "80%"
						}
					]

				},
				2 : {
					"img":PTTaskScreen,
					"screenName":"Open Period Tracker",
					"taskDesc": "Or you can access Period Tracker from the main menu",
					"handPointer": true,
					"pos":[
						{
							"top": "7%",
							"left": "8%"
						}
					]

				},
				3: {
					"img":PeriodTracker1,
					"screenName":"Period Start Date",
					"taskDesc": "Tap on the first day of your period and tap the Start button",
					"handPointer": true,
					"pos":[
						{
							"top": "30%",
							"left": "20%"
						}
					]
				},
				4: {
					"img":PeriodTracker2,
					"screenName":"Record daily flow",
					"taskDesc": "Tap light or heavy to record flow for each day of your period",
					"handPointer": true,
					"pos":[
						{
							"top": "70%",
							"left": "70%"
						}
					]
				},
				5: {
					"img":PeriodTracker3,
					"screenName":"Adjust previous entries",
					"taskDesc": "Select a previously entered day to change flow or delete entry",
					"handPointer": true,
					"pos":[
						{
							"top": "71%",
							"left": "45%"
						}
					]
				},
				6: {
					"img":PeriodTracker4,
					"screenName":"Projected next period start",
					"taskDesc": "Your next projected period start date is automatically estimated, which you can edit",
					"handPointer": false
				}
			}
		],
		"taskcalendar":[
			{
				1 : {
					"img":TaskCalendarScreen,
					"screenName":"Open Task Calendar",
					"taskDesc": "Tap on the icon to open the Task Calendar.",
					"handPointer": true,
					"pos":[
						{
							"top": "96%",
							"left": "37%"
						}
					]
				},
				2: {
					"img":Calendar1,
					"screenName":"View Calendar",
					"taskDesc": "Click on date with dot below to view event or task",
					"handPointer": true,
					"pos":[
						{
							"top": "29%",
							"left": "47%"
						}
					]
				},
				3: {
					"img":Calendar2,
					"screenName":"View events for the date",
					"taskDesc": "Tap on task to view details",
					"handPointer": true,
					"pos":[
						{
							"top": "55%",
							"left": "50%"
						}
					]
				},
				4: {
					"img":Calendar3,
					"screenName":"Add to personal calendar",
					"taskDesc": "Tap calendar icon to add to personal calendar",
					"handPointer": true,
					"pos":[
						{
							"top": "55%",
							"left": "89%"
						}
					]
				}
			}
		],
		"messaging": [
			{
				1 : {
					"img":MessagingScreen,
					"screenName":"Open Messaging",
					"taskDesc": "Tap the icon to open messaging",
					"handPointer": true,
					"pos":[
						{
							"top": "96%",
							"left": "59%"
						}
					]
				},
				2: {
					"img":Msg1,
					"screenName":"Start message",
					"taskDesc": "Tap on the Start New Message button to create a message",
					"handPointer": true,
					"pos":[
						{
							"top": "87%",
							"left": "90%"
						}
					]

				},
				3: {
					"img":Msg2,
					"screenName":"Choose contact(s)",
					"taskDesc": "Choose from your specific clinic contacts and tap Start Message button",
					"handPointer": true,
					"pos":[
						{
							"top": "67%",
							"left": "48%"
						}
					]
				},
				4: {
					"img":Msg3,
					"screenName":"Set a subject",
					"taskDesc": "Enter a subject so message threads are easy to find",
					"handPointer": true,
					"pos":[
						{
							"top": "80%",
							"left": "50%"
						}
					]
				},
				5: {
					"img":Msg3,
					"screenName":"Enter message content",
					"taskDesc": "Tap in message content area to begin typing message",
					"handPointer": true,
					"pos":[
						{
							"top": "86%",
							"left": "50%"
						}
					]
				},
				6: {
					"img":Msg4,
					"screenName":"Send message",
					"taskDesc": "Tap the send message icon to deliver message",
					"handPointer": true,
					"pos":[
						{
							"top": "59%",
							"left": "80%"
						}
					]
				},
				7: {
					"img":Msg5,
					"screenName":"Message history",
					"taskDesc": "Tap to view messages. Unread messages indicated with an orange dot",
					"handPointer": true,
					"pos":[
						{
							"top": "20%",
							"left": "45%"
						}
					]
				},
				8: {
					"img":Msg6,
					"screenName":"View message chain",
					"taskDesc": "All messages in message chains can be viewed",
					"handPointer": false
				}
			}
		]
	}

	const logOut = useHandleLogOut(setCookie, removeCookie);
    useEffect(() => {
		setPageName("");
		if (typeof cookies["welcome-back-user"] == "undefined") {
			setWelcomeBack("");
			setCookie("welcome-back-user", 1, {
				path: "/",
				domain: process.env.REACT_APP_COOKIE_DOMAIN,
			});
		} else setWelcomeBack("Back");
		if (!CheckIsLoggedIn(cookies)) {
            navigate("/logout", { replace: true });
		}
        // eslint-disable-next-line
	}, [welcomeBack]);

	const ProgressDots = (steps,active) => {
		let span = [];
		for (let i = 1; i <= steps; i++) {
			span.push(<span key={i} className={(i === parseInt(active) ? styles.active : "")}></span>);
		}
		return span;
	}

	const TutorialPagination = (props) => { //totalPage
		return (
			<>
				<div className={styles.bottomNav}>
					<Row className="g-0">
                        <Col className="text-start">
							<Button
								type="button"
                                onClick={(index !== 0 ? () => onPrevClick() : () => goToTutorialMenu())}
								className={`${styles.navbutton} d-flex align-items-center`}
							>
								<motion.label
									className={`${styles.navIcon} btn-icon`}
								>
									<img src={LeftNavImg} alt="Previous Button"/>
								</motion.label>
								<span className="ms-2">Back</span>
							</Button>
						</Col>

						<Col>
							<Button
								type="button"
								className={`${styles.navbutton}`}
								onClick={()=>goToTutorialMenu()}
							>
								<img style={{width:"39px",height:"39px"}} src={HamMenu} alt="Menu Button"/>
							</Button>
						</Col>

						<Col className="text-end">
							{ (Object.keys(tutorialPage[pagename][0]).length !== (index+1)) ?
								<Button
									type="button"
									className={`${styles.navbutton}`}
									onClick={()=>onNextClick()}
								>
									<span className="me-2">Next</span>
									<motion.label
										className={`${styles.navIcon} btn-icon`}
									>
										<img src={RightNavImg} alt="Next Button"/>
									</motion.label>
								</Button>
								:
								<Button
									style={{float:"right"}}
									className={`${styles.navbutton} ${styles.lastScreen}`}
									onClick={()=>goToTutorialMenu()}
								>
									&nbsp;Done
								</Button>
							}
						</Col>
					</Row>
				</div>
			</>
		);
	}

    useEffect(() => {
		if (location.pathname === "/tutorial") {
			setTutsMode(true);
			setPageName("tasklist");
		}
	}, [location.pathname]);

	return (
		<div className={`${styles.bg} flex-grow-1 d-flex flex-column ${tutsMode ? styles.tutorials : ""} ${pagename === "" ? styles.mainPage : ""}`}>

			{!tutsMode ? (
				<>
					<motion.div
						className={`${styles.logoContainer} d-flex justify-content-center align-items-center`}
						initial={{ scale: 0, opacity: 0 }}
						animate={{ scale: 1, opacity: 1 }}
						transition={{
							duration: 0.3,
							ease: "easeOut",
							delay: 0.1,
						}}
					>
						<Logo className={styles.logo} />
					</motion.div>
					<motion.div
						className={`${styles.welcomeContainer} d-flex flex-column justify-content-center align-items-center`}
						initial={{ y: -20, opacity: 0 }}
						animate={{ y: 0, opacity: 1 }}
						transition={{
							duration: 0.3,
							ease: "easeOut",
							delay: 0.8,
						}}
					>
						<div
							className={`${styles.welcomeText} fw-light text-white text-center`}
						>
							Welcome {welcomeBack}
						</div>
						{/*<div className={`${styles.userName} fw-light text-white`}>
							 {localStorage.getItem("name")}
						</div>*/}
						<Link to="/home" className="text-white">
							Get Started
						</Link>
					</motion.div>
				</>
			) :

				<div className={`${styles.close} p-1`}>
					{
						state?.prevPage === "/home" ?
						<Link to={state?.prevPage ? state?.prevPage : "/welcome"} state={{ menu: state?.menu }}>
							<Cross className={styles.cross}/>
						</Link>
						:
						<div onClick={handleClose}>
							<Cross className={styles.cross}/>
						</div>
					}
				</div>
			}
			<motion.div
				className={styles.bottomCurve}
				layout
				transition={{
					type: "ease",
					duration: 0.5,
				}}
                onTouchStart={handleTutorialSwipe}
				>

						<div className={styles.ellipse}>
							{!tutsMode &&
							<>
								<div
									className={styles.swipeBtn}
									onClick={handleSwipe}
								>
									<BsChevronUp />
								</div>
								<div className={`${styles.swipeText} text-white`}>
									Swipe up for tutorial
								</div>
							</>
							}
						</div>


			</motion.div>

			<motion.div
				className="bg-secondary d-flex flex-column justify-content-center position-relative text-center"
				layout
				style={{
					height: tutsMode ? "100%" : "1px",
					flexGrow: tutsMode ? 1 : 0,
				}}
				transition={{
					type: "ease",
					duration: 0.5
				}}
                onTouchMove={handleTutorialSwipe}
			>
				{
				   tutsMode &&
					<h3 className={`${styles.tutorialsTitle} ${pagename === "" && styles.mainPage} fw-normal`}>
						{tutorialTitle}
					</h3>
				}

				{tutsMode ? (
					<div className="w-100 h-100 d-flex">
						{
							(() => {
									if(pagename!=="") {
										return (
											<motion.div
												className={`px-4 flex-grow-1 d-flex flex-column`}
												initial={{ x: 500 }}
												animate={{ x: 0 }}
												transition={{
													duration: 1,
													type: "ease"
												}}
											>
												<div className={styles.slides} >
													<Carousel
                                                        fade
														ref={carousellRef}
                                                        onSlide={slideTutorial}
														interval={null}
														indicators={false}
														activeIndex={index}
														controls={false}
														onSelect={handleSelect}
                                                        autoPlay={false}
                                                        className="flex-grow-1 top-0"
                                                    >
													{
														tutorialPage[pagename].map((pagelist,index) => {
															{
																return(
																	pagelist && Object.keys(pagelist).map(function(screens,pageno){
																		return (
																			<Carousel.Item key={screens} className={styles.slides}>
                                                                                <div className={styles.tutScreenshots}
                                                                                    style={{position:"relative",backgroundImage:'url('+pagelist[screens]["img"]+')'}}>
                                                                                        { pagelist[screens]["handPointer"] === true && pagelist[screens]?.['pos']?.[0] &&
                                                                                            <figure className={styles.hand} style={{top:pagelist[screens]?.['pos']?.[0].top,left:pagelist[screens]?.['pos']?.[0].left}}></figure>
                                                                                        }
                                                                                        { pagelist[screens]["handPointer"] === true && pagelist[screens]?.['pos']?.[1] &&
                                                                                            <figure className={styles.hand} style={{top:pagelist[screens]?.['pos']?.[1].top,left:pagelist[screens]?.['pos']?.[1].left}}></figure>
                                                                                        }
                                                                                </div>
																			</Carousel.Item>

																		);
																	})
																)

															}

														})
													}
													</Carousel>
													<Carousel
														slide={false}
														interval={null}
														indicators={false}
														activeIndex={index}
														controls={false}
														autoPlay={false}
                                                        className="pt-1 pb-3 top-0"
                                                    >
													{
														tutorialPage[pagename].map((pagelist,index) => {
															{
																return(
																	pagelist && Object.keys(pagelist).map(function(screens,pageno){
																		return (
																			<Carousel.Item key={screens} className={styles.slides}>
																				<div className={styles.progressDots}>
																					{ProgressDots(Object.keys(pagelist).length,screens)}
																				</div>
																			</Carousel.Item>
																		);
																	})
																)

															}
														})
													}
													</Carousel>
													<Carousel
														fade
														interval={15000}
														indicators={false}
														activeIndex={index}
														controls={false}
														autoPlay={false}
                                                        className="pb-3 top-0"
                                                    >
													{
														tutorialPage[pagename].map((pagelist,index) => {
															{
																return(
																	pagelist && Object.keys(pagelist).map(function(screens,pageno){
																		return (
																			<Carousel.Item key={screens} className={styles.slides}>
																				<div className={`${styles.tutorialsText} text-center text-white w-100 mt-0 px-2`}>
																					{pagelist[screens]["screenName"]}
																					<span className={`${styles.smallText} mt-2`}>
																						{pagelist[screens]["taskDesc"]}
																					</span>
																				</div>
																			</Carousel.Item>
																		);
																	})
																)

															}
														})
													}
													</Carousel>
													<TutorialPagination/>
												</div>
											</motion.div>
										)
									} else {
										return (
											<>
												<div
													className={`${styles.tutorialScreen} flex-grow-1 d-flex align-items-center justify-content-center mb-4`}
												>
													<motion.div
														style={{ textAlign: "center" }}
														className="px-4 flex-grow-1 d-flex flex-column"
														initial={{ y: "50vw" }}
														animate={{ y: 0 }}
														transition={{ duration: .65, origin: 1 }}
													>
														<ul className={styles.TutorialLists}>
															<li onClick={()=>setTutorialPage("tasklist")}>
																<span className={styles.menuimg}><HomeIcon/></span>
																<span className={styles.tutName}>Task List</span>
															</li>
															<li onClick={()=>setTutorialPage("periodtracker")}>
																<span className={styles.menuimg}><PeriodActiveTracker/></span>
																<span className={styles.tutName}>Period Tracker</span>
															</li>
															<li onClick={()=>setTutorialPage("taskcalendar")}>
																<span className={styles.menuimg}><HomeCal/></span>
																<span className={styles.tutName}>Task Calendar</span>
															</li>
															<li onClick={()=>setTutorialPage("messaging")}>
																<span className={styles.menuimg}><HomeChat/></span>
																<span className={styles.tutName}>Messaging</span>
															</li>
														</ul>
														<div className={`${styles.tutorialsText} text-center text-white w-100 px-2`}
															style={{
																marginTop: "5vh",
																fontSize: "22px"
															}}
														>
															Learn how to use the app by choosing a tutorial
														</div>
													</motion.div>
												</div>
											</>
										)

									}
							})()
						}
					</div>

				) : (
					<></>
				)}
			</motion.div>
		</div>
	);
};

export default Welcome;
