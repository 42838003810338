import { takeLatest, put, call } from "redux-saga/effects";
import {Endpoints} from '../../../core/networking';
import * as Constants from "./constants";
import * as Actions from "./actions";
import AxiosInstance from "../../../services/AxiosInstance";
import {ERROR_TYPES} from '../types';

export function* watchNotificationSaga() {
  yield takeLatest(Constants.MESSAGE_GET_LIST_REQUEST, msgListRequest);
  yield takeLatest(Constants.MSG_GET_USER_LIST_REQUEST, getUserListRequest);
  yield takeLatest(Constants.GET_MESSAGE_INFO_REQUEST, getMessInfoRequest);
  yield takeLatest(Constants.ADD_MESSAGE_REQUEST, addMessageRequest);
  yield takeLatest(Constants.GET_CATEGORIES_REQUEST, blogCalegoryRequest);
  yield takeLatest(Constants.GET_CATEGORIES_LIST_REQUEST, calegoryListRequest);
  yield takeLatest(Constants.BLOG_DETAILS_REQUEST, blogsDetailRequest);
}

export function* msgListRequest(action) {
  try {
    const pramas = action.payload;
    const response = yield call(taskNetworkRequest, pramas); 
    if (response.status === 200) {
      yield put(Actions.msgGetListSuccess(response.data));
    } else {
      yield put(
        Actions.msgGetListError({
          errorCode: ERROR_TYPES.INVALID_DATA,
          message: ERROR_TYPES.INVALID_DATA,
        })
      );
    }
  } catch (error) {
    yield put(Actions.msgGetListError(error));
  }
}

export function* getUserListRequest(action) {
  try {
    const pramas = action.payload;
    const response = yield call(taskNetworkRequest, pramas);
    if (response.status === 200) {
      yield put(Actions.getMsgUserListSuccess(response.data));
    } else {
      yield put(
        Actions.getMsgUserListError({
          errorCode:ERROR_TYPES.INVALID_DATA,
          message: ERROR_TYPES.INVALID_DATA,
        })
      );
    }
  } catch (error) {
    yield put(Actions.getMsgUserListError(error));
  }
}

export function* getMessInfoRequest(action) {
  try {
    const pramas = action.payload;
    const response = yield call(taskNetworkRequest, pramas);
    if (response.status === 200) {
      yield put(Actions.getMsgInfoSuccess(response.data));
    } else {
      yield put(
        Actions.getMsgInfoError({
          errorCode: ERROR_TYPES.INVALID_DATA,
          message: ERROR_TYPES.INVALID_DATA,
        })
      );
    }
  } catch (error) {
    yield put(Actions.getMsgInfoError(error));
  }
}

export function taskNetworkRequest(params){
  const url = Endpoints.FAIR_FAX_PORTAL_API;
  const response =  AxiosInstance.post(url, { params });
  return response;
}

export function* addMessageRequest(action) {
  try {
    const response = yield call(addMsgNetRequest, action.payload);
    if (response.status === 200) {
      yield put(Actions.addMsgSuccess(response.data));
    } else {
      yield put(
        Actions.addMsgError({
          errorCode: ERROR_TYPES.INVALID_DATA,
          message: ERROR_TYPES.INVALID_DATA,
        })
      );
    }
  } catch (error) {
    yield put(Actions.addMsgError(error));
  }
}

export function addMsgNetRequest(params){
  const url = Endpoints.UPLOAD_PORTAL_MSG;
  const response =  AxiosInstance.post(url, params );
  return response;
}

export function* blogCalegoryRequest(action) {
  try {
    const response = yield call(categoryNetwork, action.payload);
    if (response.status === 200) {
      yield put(Actions.getCategoriesSuccess(response.data));
    } else {
      yield put(
        Actions.getCategoriesError({
          errorCode: ERROR_TYPES.INVALID_DATA,
          message: ERROR_TYPES.INVALID_DATA,
        })
      );
    }
  } catch (error) {
    yield put(Actions.getCategoriesError(error));
  }
}

export function categoryNetwork(params){
  const url =  Endpoints.BLOG_CATEGORY+'?' + new URLSearchParams(params).toString();
  const response =  AxiosInstance.get(url, params );
  return response;
}


export function* calegoryListRequest(action) {
  try {
    const response = yield call(categoryListNetwork, action.payload);
    if (response.status === 200) {
      yield put(Actions.getCateListSuccess(response));
    } else {
      yield put(
        Actions.getCateListError({
          errorCode: ERROR_TYPES.INVALID_DATA,
          message: ERROR_TYPES.INVALID_DATA,
        })
      );
    }
  } catch (error) {
    yield put(Actions.getCateListError(error));
  }
}
export function categoryListNetwork(params){
  const url =  Endpoints.BLOG_CATEGORY_LIST+'?' + new URLSearchParams(params).toString();
  const response =  AxiosInstance.get(url);
  return response;
}

export function* blogsDetailRequest(action) {
  try {
    const response = yield call(blogDetailNetwork, action.payload);
    if (response.status === 200) {
      yield put(Actions.blogDetailSuccess(response.data));
    } else {
      yield put(
        Actions.blogDetailError({
          errorCode: ERROR_TYPES.INVALID_DATA,
          message: ERROR_TYPES.INVALID_DATA,
        })
      );
    }
  } catch (error) {
    yield put(Actions.blogDetailError(error));
  }
}

export function blogDetailNetwork(postID){
  const url =  Endpoints.BLOG_CATEGORY_LIST+ '/' +postID;
  const response =  AxiosInstance.get(url);
  return response;
}
