import * as Constants from './constants';

export const sendToApproveRequest= (data) => ({
    type: Constants.APPOINTMENT_APPROVE_REQUEST,
    payload: data,
});

export const sendToApproveSuccess = (data) => ({
    type: Constants.APPOINTMENT_APPROVE_SUCCESS,
    payload: data,
});

export const sendToApproveError = (error) => ({
    type: Constants.APPOINTMENT_APPROVE_ERROR,
    payload: error,
});

export const sendToApproveClear = () => ({
    type: Constants.APPOINTMENT_APPROVE_CLEAR,
});

// Get Task Document
export const getTaskDocument= (data) => ({
    type: Constants.TASK_DOCUMENT_REQUEST,
    payload: data,
});

export const getTaskDocumentSuccess = (data) => ({
    type: Constants.TASK_DOCUMENT_SUCCESS,
    payload: data,
});

export const getTaskDocumentError = (error) => ({
    type: Constants.TASK_DOCUMENT_ERROR,
    payload: error,
});

export const getTaskDocumentClear = () => ({
    type: Constants.TASK_DOCUMENT_CLEAR,
});

    
// Download Doc Files
export const fileDownloadRequest= (data) => ({
    type: Constants.TASK_DOC_DOWNLOAD_REQUEST,
    payload: data,
});

export const fileDownloadSuccess = (data) => ({
    type: Constants.TASK_DOC_DOWNLOAD_SUCCESS,
    payload: data,
});

export const fileDownloadError = (error) => ({
    type: Constants.TASK_DOC_DOWNLOAD_ERROR,
    payload: error,
});

export const fileDownloadClear = () => ({
    type: Constants.TASK_DOC_DOWNLOAD_CLEAR,
});

// Get Appointment Interview
export const getAppointmentSchedInterview = (data) => ({
    type: Constants.APP_SCHEDULE_INTERVIEW_GET,
    payload: data,
});

export const getAppointmentSchedInterviewSuccess = (data) => ({
    type: Constants.APP_SCHEDULE_INTERVIEW_SUCCESS,
    payload: data,
});

export const getAppointmentSchedInterviewError = (error) => ({
    type: Constants.APP_SCHEDULE_INTERVIEW_ERROR,
    payload: error,
});

export const getAppointmentSchedInterviewClear = () => ({
    type: Constants.APP_SCHEDULE_INTERVIEW_CLEAR,
});

export const getPsychoMindContactRequest = (data) => ({
    type: Constants.PSYCHOLOOGICAL_MIND_360CONTACT_REQUEST,
    payload: data,
});

export const psychoMindContactSuccess = (data) => ({
    type: Constants.PSYCHOLOOGICAL_MIND_360CONTACT_SUCCESS,
    payload: data,
});

export const psychoMindContactError = (error) => ({
    type: Constants.PSYCHOLOOGICAL_MIND_360CONTACT_ERROR,
    payload: error,
});

export const psychoMindContactClear = () => ({
    type: Constants.PSYCHOLOOGICAL_MIND_360CONTACT_CLEAR,
});


export const psychoSubmitAppointRequest = (data) => ({
    type: Constants.PSYCHOLOOGICAL_SUBMIT_APPOINTMENT_REQUEST,
    payload: data,
});
export const psychoSubmitAppoinSuccess = (data) => ({
    type: Constants.PSYCHOLOOGICAL_SUBMIT_APPOINTMENT_SUCCESS,
    payload: data,
});
export const psychoSubmitAppoinError = (error) => ({
    type: Constants.PSYCHOLOOGICAL_SUBMIT_APPOINTMENT_ERROR,
    payload: error,
});
export const psychoSubmitAppoinClear = () => ({
    type: Constants.PSYCHOLOOGICAL_SUBMIT_APPOINTMENT_CLEAR,
});

export const getPsychologistListRequest= (data) => ({
    type: Constants.PSYCHOLOGIST_LIST_REQUEST,
    payload: data,
});
export const psychologistListSuccess = (data) => ({
    type: Constants.PSYCHOLOGIST_LIST_SUCCESS,
    payload: data,
});
export const psychologistListError = (error) => ({
    type: Constants.PSYCHOLOGIST_LIST_ERROR,
    payload: error,
});
export const psychologistListClear = () => ({
    type: Constants.PSYCHOLOGIST_LIST_CLEAR,
});

export const pcbObgynSubmitRequest= (data) => ({
    type: Constants.SUBMIT_PCP_OBGYN_SUBMIT_REQUEST,
    payload: data,
});
export const pcbObgynSubmitSuccess = (data) => ({
    type: Constants.SUBMIT_PCP_OBGYN_SUBMIT_SUCCESS,
    payload: data,
});
export const pcbObgynSubmitError = (error) => ({
    type: Constants.SUBMIT_PCP_OBGYN_SUBMIT_ERROR,
    payload: error,
});
export const pcbObgynSubmitClear = () => ({
    type: Constants.SUBMIT_PCP_OBGYN_SUBMIT_ERROR,
});