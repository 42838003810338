import styles from "./DocuSign.module.scss";
import Layout from "../components/ui/Layout";
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from "react";
import Loader from "../components/ui/Loader";
import { useSelector, useDispatch } from "react-redux";
import * as Actions from "./state/docusign/actions";
import { Endpoints } from "../core/networking";
import TaskDescription from "./components/TaskDescription";
import PageTitle from "./components/PageTitle";
import { useCookies } from 'react-cookie';
import { getCookie } from '../services/Common';


const DocuSign = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    let { state } = useLocation();
    const [scrollbarHeight, setScrollBarHeight] = useState(window.screen.availHeight);
    const {
        docuData: { completedTask, getDocuUrlLink },
    } = useSelector((state) => state);
    const { response: completedResponse, isLoading: completedlLoading } = completedTask || {};
    const { response: docuLinkResponse, isLoading: docuLinkLoading } = getDocuUrlLink || {};
    
    const [taskInfo, setTaskInfo] = useState([]);
    const [documentCompleted, setDocumentCompleted] = useState(false);
    const [, setDocumentResponse] = useState("");
    const [cookies, setCookie, removeCookie] = useCookies(null);

    useEffect(() => {
        // set scroll window height
        const mainElement = document.getElementsByClassName("app");
        const dropdownElement = document.querySelector(".dropdownElement");
        setScrollBarHeight(mainElement[0]?.offsetHeight - dropdownElement?.offsetHeight);
        window.addEventListener("resize", function () {
            setScrollBarHeight(mainElement[0]?.offsetHeight - dropdownElement?.offsetHeight);
        });
    }, [scrollbarHeight]);

    function isPwa() {
        return ["fullscreen", "standalone", "minimal-ui"].some(
            (displayMode) => window.matchMedia('(display-mode: ' + displayMode + ')').matches
        );
    }

    // User has switched back to the tab
    const focusToDMA = () => {
        if (getCookie("pwaApplication") === "1" && !document.hidden) {
            removeCookie("pwaApplication", { path: '/', domain: process.env.REACT_APP_COOKIE_DOMAIN });
        } 
    };

    useEffect(() => {
        document.title = 'Docu Sign';
        window.console.log(location?.state?.taskInfo);
        setTaskInfo(location?.state?.taskInfo);
        
        window.addEventListener("visibilitychange", focusToDMA);
        
        setDocumentResponse("");
        dispatch(Actions.sendToApproveClear());
        dispatch(Actions.getDocuUrlClear());
        
        let docuFormSignedStatus = setInterval(() => {
            if (getCookie("docusignCompleted_" + location?.state?.taskInfo?.ID) === "1") {
                setDocumentCompleted(true);
                clearInterval(docuFormSignedStatus);
            }
        }, 2000); 

        return () => {
            clearInterval(docuFormSignedStatus);
        };

    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (cookies["docusignCompleted_" + location?.state?.taskInfo?.ID] === "1") {
            window.console.log("1");
            setDocumentCompleted(true);
        }
    }, [cookies, location?.state?.taskInfo?.ID]);

    useEffect(() => {
        if (typeof completedResponse !== 'undefined' && completedResponse !== null && completedResponse) {
            removeCookie("docusignCompleted_" + taskInfo?.ID, { path: '/', domain: process.env.REACT_APP_COOKIE_DOMAIN });
            dispatch(Actions.sendToApproveClear());
            navigate(-1);
        }
    }, [completedResponse]);// eslint-disable-line react-hooks/exhaustive-deps


    const fetchDocuSignUrl = () => {
        setDocumentResponse("");
        dispatch(Actions.getDocuUrlClear());
        
        if (isPwa()) {
            setCookie('pwaApplication', 1, { path: '/', domain: process.env.REACT_APP_COOKIE_DOMAIN });
        } else {
            removeCookie("pwaApplication", { path: '/', domain: process.env.REACT_APP_COOKIE_DOMAIN });
        }
        if (taskInfo?.ID) {
            let docuUrlEndpoint = Endpoints.GET_DOCU_DOCUMENT + taskInfo?.ID;
            let docParam = { endpoint: docuUrlEndpoint, method: "GET" };
            dispatch(Actions.getDocuUrlRequest(docParam));
        }
    }


    useEffect(() => {
       
        if (docuLinkResponse) {            
            setDocumentResponse(docuLinkResponse);
            let docuLink = docuLinkResponse.replace(/"/g, ""); // the response comes with double quote
            window.location.href = docuLink;
        }

        dispatch(Actions.getDocuUrlClear());
    }, [docuLinkResponse]);// eslint-disable-line react-hooks/exhaustive-deps

    const submitDocSignComplete = () => {
        let tID = (taskInfo?.ID ?? taskInfo?.AssociatedID);
        if (!tID) {
            tID = location?.state?.notifyTaskID;
        }
        if (tID) {
            const taskcompleteEndpoint =
                Endpoints.HOME_COMPLETED_DONOR + tID;
            let params = {
                endpoint: taskcompleteEndpoint,
                method: "PUT"
            };

            dispatch(Actions.sendToApproveRequest(params));
        }
    }

    return (
        <>
            {(completedlLoading || docuLinkLoading) && <Loader />}
            {
                <Layout showHeader="false">
                    <div className={styles.stickyHeader}>
                        <PageTitle
                            title="Sign Documents"
                            headerClass="homePageTitle"
                            linkTo={state?.prevPage ? state?.prevPage : "/home"}
                        />
                    </div>
                    <div className={`position-relative ${styles.notifycontainer}`} id="notifycontainer">
                        <div className={styles.notifySection}>
                            <TaskDescription styles={`px-3 py-3 my-2`} taskDetails={taskInfo} />
                            <div className={styles.messageSection}>
                               
                                <div className={styles.taskDescription}>
                                    Please complete these documents for Screening.
                                </div>
                                <div className={`text-center`}>
                                    <button onClick={() => fetchDocuSignUrl()} className={`${styles.roundedBackgroundButton}`} >
                                        View Documents
                                    </button>
                                </div>
                                <p style={{ fontSize: "13px" } }>If you already signed this document, no further action is required. This task will automatically be moved to Submitted for Approval in a few minutes when the signed document is processed.</p>
                            </div>
                            {
                                documentCompleted &&
                                <div className={`position-relative ${styles.completeButton}`}>
                                    <button className={`${styles.button}`} onClick={() => submitDocSignComplete()}>
                                        Documents Completed
                                    </button>
                                </div>
                            }
                        </div>
                    </div>
                </Layout>

            }



        </>
    );
}

export default DocuSign;
