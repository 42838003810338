import { takeLatest, put, call } from "redux-saga/effects";
import {Endpoints} from '../../../core/networking';
import * as Constants from "./constants";
import * as Actions from "./actions";
import AxiosInstance from "../../../services/AxiosInstance";
import {ERROR_TYPES} from '../types';

export function* watchSchedularSaga() {
  yield takeLatest(Constants.ACUITY_SLOTS_GET_LIST_REQUEST, getAcuitySlots);
  yield takeLatest(Constants.ADD_ACUITY_INTERVIEW_REQUEST, addInterviewSlot);
  yield takeLatest(Constants.ACUITY_DAYS_GET_LIST_REQUEST, getAcuityDays);
}

export function* getAcuityDays(action) {
  try {
    const pramas = action.payload;
    const response = yield call(taskNetworkRequest, pramas);
    if (response.status === 200) {
      yield put(Actions.acuityAvailableDaysSuccess(response.data));
    } else {
      yield put(
        Actions.acuityAvailableDaysError({
          errorCode: ERROR_TYPES.INVALID_DATA,
          message: ERROR_TYPES.INVALID_DATA,
        })
      );
    }
  } catch (error) {
    yield put(Actions.acuityAvailableDaysError(error));
  }
}

export function* getAcuitySlots(action) {
  try {
    const pramas = action.payload;
    const response = yield call(taskNetworkRequest, pramas);
    if (response.status === 200) {
      yield put(Actions.acuityInterviewSlotsSuccess(response.data));
    } else {
      yield put(
        Actions.acuityInterviewSlotsError({
          errorCode: ERROR_TYPES.INVALID_DATA,
          message: ERROR_TYPES.INVALID_DATA,
        })
      );
    }
  } catch (error) {
    yield put(Actions.acuityInterviewSlotsError(error));
  }
}

export function taskNetworkRequest(params){
  const url = Endpoints.FAIR_FAX_PORTAL_API;
  const response =  AxiosInstance.post(url, { params });
  return response;
}

export function addInterviewSlotRequest(params){
  const url = Endpoints.PORTAL_POST;
  const response =  AxiosInstance.post(url, params );
  return response;
}

export function* addInterviewSlot(action) {
  try {
    const response = yield call(addInterviewSlotRequest, action.payload);
    if (response.status === 200) {
      yield put(Actions.addAcuitySuccess(response));
    } else {
      yield put(
        Actions.addAcuityError({
          errorCode: ERROR_TYPES.INVALID_DATA,
          message: ERROR_TYPES.INVALID_DATA,
        })
      );
    }
  } catch (error) {
    yield put(Actions.addAcuityError(error));
  }
}
