import { takeLatest, put, call } from "redux-saga/effects";
import {Endpoints} from '../../../core/networking';
import * as Constants from "./constants";
import * as Actions from "./actions";
import AxiosInstance from "../../../services/AxiosInstance";
import {ERROR_TYPES} from '../types';

export function* watchCalendarSaga() {
  yield takeLatest(Constants.CALENDAR_GET_DONOR_EVENT_REQUEST, getDonorEvent);
  yield takeLatest(Constants.CALENDAR_GET_EVENT_REQUEST, getEvent); 
  yield takeLatest(Constants.CALENDAR_ADD_DONOR_EVENT_REQUEST, addDonorEvent);
  yield takeLatest(Constants.PERIOD_TRACKER_ADD_DATE_REQUEST, periodTrackerAddDate);
  yield takeLatest(Constants.PERIOD_TRACKER_GET_DONAR_DETAIL_REQUEST,  periodTGetDonorDetail);
  yield takeLatest(Constants.PERIOD_TRACKER_UPDATE_DATE_REQUEST,  periodTUpdateDate);
  yield takeLatest(Constants.PERIOD_TRACKER_DELETE_SDATE_REQUEST, deletePeriodStartDate);
  yield takeLatest(Constants.CALENDAR_GET_DOCTOR_LIST_REQUEST, getDoctorList);
  yield takeLatest(Constants.CALENDAR_TASK_SCHEDULER_DOCTOR_REQUEST, doctorScheduler);
  yield takeLatest(Constants.CALENDAR_SCHEDULE_PHOTOGRAPHER_REQUEST, photoGrapherScheduler);
  yield takeLatest(Constants.CALENDAR_PHOTOGRAPHER_LIST_REQUEST, photoGrapherList);
  yield takeLatest(Constants.CALENDAR_PSYCHOLOGIST_LIST_REQUEST, psychlogistList);
  yield takeLatest(Constants.CALENDAR_SCHEDULE_PSYCHOLOGIST_REQUEST, psychlogistScheduler);
}
export function* getDonorEvent(action) {
  try {
    const pramas = action.payload;
    const response = yield call(donorEventNetwork, pramas);
    if (response.status === 200) {
      yield put(Actions.getDonorEventSuccess(response.data));
    } else {
      yield put(
        Actions.getDonorEventError({
          errorCode:ERROR_TYPES.INVALID_DATA,
          message: ERROR_TYPES.INVALID_DATA,
        })
      );
    }
  } catch (error) {
    yield put(Actions.getDonorEventError(error));
  }
}
export function* getEvent(action) {
  try {
    const pramas = action.payload;
    const response = yield call(donorEventNetwork, pramas);
    if (response.status === 200) {
      yield put(Actions.getEventSuccess(response.data));
    } else {
      yield put(
        Actions.getEventError({
          errorCode: ERROR_TYPES.INVALID_DATA,
          message: ERROR_TYPES.INVALID_DATA,
        })
      );
    }
  } catch (error) {
    yield put(Actions.getEventError(error));
  }
}
export function* addDonorEvent(action) {
  try {
    const pramas = action.payload;
    const response = yield call(addDonorNetwork, pramas);
    if (response.status === 200) {
      yield put(Actions.addDonorEventSuccess(response.data));
    } else {
      yield put(
        Actions.addDonorEventError({
          errorCode: ERROR_TYPES.INVALID_DATA,
          message: ERROR_TYPES.INVALID_DATA,
        })
      );
    }
  } catch (error) {
    yield put(Actions.addDonorEventError(error));
  }
}
export function addDonorNetwork(params){
  const url1 = Endpoints.PORTAL_POST;
  const response =  AxiosInstance.post(url1, params );
  return response;
}
export function* psychlogistList(action) {
  try {
    const pramas = action.payload;
    const response = yield call(donorEventNetwork, pramas);
    if (response.status === 200) {
      yield put(Actions.psychologistListSuccess(response.data));
    } else {
      yield put(
        Actions.psychologistListError({
          errorCode:ERROR_TYPES.INVALID_DATA,
          message: ERROR_TYPES.INVALID_DATA,
        })
      );
    }
  } catch (error) {
    yield put(Actions.psychologistListError(error));
  }
}
export function* photoGrapherList(action) {
  try {
    const pramas = action.payload;
    const response = yield call(donorEventNetwork, pramas);
    if (response.status === 200) {
      yield put(Actions.photoGrapherListSuccess(response.data));
    } else {
      yield put(
        Actions.photoGrapherListError({
          errorCode:ERROR_TYPES.INVALID_DATA,
          message: ERROR_TYPES.INVALID_DATA,
        })
      );
    }
  } catch (error) {
    yield put(Actions.photoGrapherListError(error));
  }
}
// export function donorEventNetwork(params){
//   const url1 = Endpoints.FAIR_FAX_PORTAL_API;
//   const response =  AxiosInstance.post(url1, { params });
//   return response;
// }
export function* getDoctorList(action) {
  try {
    const pramas = action.payload;
    const response = yield call(donorEventNetwork, pramas);
    if (response.status === 200) {
      yield put(Actions.getDoctorListSuccess(response.data));
    } else {
      yield put(
        Actions.getDoctorListError({
          errorCode: ERROR_TYPES.INVALID_DATA,
          message: ERROR_TYPES.INVALID_DATA,
        })
      );
    }
  } catch (error) {
    yield put(Actions.getDoctorListError(error));
  }
}
export function donorEventNetwork(params){
  const url1 = Endpoints.FAIR_FAX_PORTAL_API;
  const response =  AxiosInstance.post(url1, { params });
  return response;
}
// export function doctorListNetwork(params){
//   const url = Endpoints.FAIR_FAX_PORTAL_API;
//   const response =  AxiosInstance.post(url, { params });
//   return response;
// }


export function* doctorScheduler(action) {
  try {
    const pramas = action.payload;
    const response = yield call(submitNetwork, pramas);
    if (response.status === 200) {
      yield put(Actions.doctorAppointSubmitSuccess(response));
    } else {
      yield put(
        Actions.doctorAppointSubmitError({
          errorCode: ERROR_TYPES.INVALID_DATA,
          message: ERROR_TYPES.INVALID_DATA,
        })
      );
    }
  } catch (error) {
    yield put(Actions.doctorAppointSubmitError(error));
  }
}

export function* photoGrapherScheduler(action) {
  try {
    const pramas = action.payload;
    const response = yield call(submitNetwork, pramas);
    if (response.status === 200) {
      yield put(Actions.schedulePhotoGraphySuccess(response));
    } else {
      yield put(
        Actions.schedulePhotoGraphyError({
          errorCode: ERROR_TYPES.INVALID_DATA,
          message: ERROR_TYPES.INVALID_DATA,
        })
      );
    }
  } catch (error) {
    yield put(Actions.schedulePhotoGraphyError(error));
  }
}
export function* psychlogistScheduler(action) {
  try {
    const pramas = action.payload;
    const response = yield call(submitNetwork, pramas);
    if (response.status === 200) {
      yield put(Actions.psychologistSuccess(response));
    } else {
      yield put(
        Actions.psychologistError({
          errorCode: ERROR_TYPES.INVALID_DATA,
          message: ERROR_TYPES.INVALID_DATA,
        })
      );
    }
  } catch (error) {
    yield put(Actions.psychologistError(error));
  }
}
export function submitNetwork(params){
  const url1 = Endpoints.PORTAL_POST;
  const response =  AxiosInstance.post(url1, params );
  return response;
}

/*period tracker  */

export function* periodTrackerAddDate(action) {
  try {
    const pramas = action.payload;
    const response = yield call(addPeriodTrackerNetwork, pramas);
    if (response.status === 200) {
      yield put(Actions.pdTaddDateSuccess(response));
    } else {
      yield put(
        Actions.pdTaddDateError({
          errorCode:ERROR_TYPES.INVALID_DATA,
          message: ERROR_TYPES.INVALID_DATA,
        })
      );
    }
  } catch (error) {
    yield put(Actions.pdTaddDateError(error));
  }
}
export function addPeriodTrackerNetwork(params){
  const url1 = Endpoints.PORTAL_POST;
  const response =  AxiosInstance.post(url1,  params );
  return response;
}

export function* periodTGetDonorDetail(action) {
  try {
    const pramas = action.payload;
    const response = yield call(periodTrackerNetwork, pramas);
    if (response.status === 200) {
      yield put(Actions.pdTgetDonorDetailSuccess(response.data));
    } else {
      yield put(
        Actions.pdTgetDonorDetailError({
          errorCode: ERROR_TYPES.INVALID_DATA,
          message: ERROR_TYPES.INVALID_DATA,
        })
      );
    }
  } catch (error) {
    yield put(Actions.pdTgetDonorDetailError(error));
  }
}
export function periodTrackerNetwork(params){
  const url1 = Endpoints.FAIR_FAX_PORTAL_API;
  const response =  AxiosInstance.post(url1, { params });
  return response;
}
export function* periodTUpdateDate(action) {
  try {
    const pramas = action.payload;
    const response = yield call(periodTUpdaterNetwork, pramas);
    if (response.status === 200) {
      yield put(Actions.pdTupdateDatelSuccess(response));
    } else {
      yield put(
        Actions.pdTupdateDatelError({
          errorCode: ERROR_TYPES.INVALID_DATA,
          message: ERROR_TYPES.INVALID_DATA,
        })
      );
    }
  } catch (error) {
    yield put(Actions.pdTupdateDatelError(error));
  }
}
export function periodTUpdaterNetwork(params){
  const url1 = Endpoints.PORTAL_POST;
  const response =  AxiosInstance.post(url1, params );
  return response;
}

export function* deletePeriodStartDate(action) {
  try {
    const pramas = action.payload;
    const response = yield call(deletePeriodDateNetwork, pramas);
    if (response.status === 200) {
      yield put(Actions.deletPeriodStartDateSuccess(response));
    } else {
      yield put(
        Actions.deletPeriodStartDateError({
          errorCode: ERROR_TYPES.INVALID_DATA,
          message: ERROR_TYPES.INVALID_DATA,
        })
      );
    }
  } catch (error) {
    yield put(Actions.deletPeriodStartDateError(error));
  }
}
export function deletePeriodDateNetwork(params){
  const url1 = Endpoints.FAIR_FAX_PORTAL_API;
  const response =  AxiosInstance.post(url1,  {params} );
  return response;
}