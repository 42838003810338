import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router } from 'react-router-dom';
import { CookiesProvider } from "react-cookie";
import { Provider } from "react-redux";
import {store, persistor, sagaMiddleware} from "./store";
import { PersistGate } from 'redux-persist/integration/react';
import rootSaga from "../src/store/rootSaga";

sagaMiddleware.run(rootSaga);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<Provider store={store}>
		<PersistGate persistor={persistor}>
			<Router>
                <CookiesProvider>
                    <App />
				</CookiesProvider>
			</Router>
		</PersistGate>
	</Provider>
);

serviceWorkerRegistration.unregister();
reportWebVitals();
