import { call, put, takeLatest } from "redux-saga/effects";
import { Endpoints } from '../../../core/networking';
import * as Constants from "./constants";
import * as Actions from "./actions";
import AxiosInstance from "../../../services/AxiosInstance";
import { ERROR_TYPES } from '../types';


export function* watchUserLogOutSaga() {
  yield takeLatest(Constants.LOGOUT_USER_REQUEST, logoutRequest);
}


export function* logoutRequest(action) {
    try {
        const pramas = action.payload;
        const response = yield call(logoutNetworkCall, pramas);
        if (response.status === 200) {
            yield put(Actions.logoutUserSuccess(response));
        } else {
            yield put(
                Actions.logoutUserError({
                    errorCode: ERROR_TYPES.INVALID_DATA,
                    message: ERROR_TYPES.INVALID_DATA,
                })
            );
        }
    } catch (error) {
        if (error?.response?.status === 403) {
            yield put(
                Actions.logoutUserError({
                    errorCode: error.response.statusText,
                    message: error.response.data.message

                })
            );
        } else {
            yield put(Actions.logoutUserError(error));
        }
    }
}

export function logoutNetworkCall(user) {
    const url = Endpoints.DMA_LOGOUT;
    const response = AxiosInstance.post(url, { user });
    return response;
}
