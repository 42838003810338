import { takeLatest, put, call } from "redux-saga/effects";
import {Endpoints} from '../../../core/networking';
import * as Constants from "./constants";
import * as Actions from "./actions";
import AxiosInstance from "../../../services/AxiosInstance";
import {ERROR_TYPES} from '../types';

export function* watchUploadSaga() {
  yield takeLatest(Constants.UPLOAD_GET_MSG_TASK_REQUEST, uploadMsgRequest);
  yield takeLatest(Constants.UPLOAD_PORTAL_FILE_REQUEST, portalUploadImage);
  yield takeLatest(Constants.UPLOAD_FILE_DOWNLOAD_REQUEST, fileDownloadgRequest);

}

export function* uploadMsgRequest(action) {
  try {
    const pramas = action.payload;
    const response = yield call(networkRequest, pramas);
    if (response.status === 200) {
      yield put(Actions.getUploadMsgTaskSuccess(response.data));
    } else {
      yield put(
        Actions.getUploadMsgTaskError({
          errorCode: ERROR_TYPES.INVALID_DATA,
          message: ERROR_TYPES.INVALID_DATA,
        })
      );
    }
  } catch (error) {
    yield put(Actions.getUploadMsgTaskError(error));
  }
}
export function networkRequest(params){
  const url = Endpoints.FAIR_FAX_PORTAL_API;
  const response =  AxiosInstance.post(url, { params });
  return response;
}
export function* portalUploadImage(action) {
  try {
    // const pramas = action.payload;
    const response = yield call(networkPortalRequest, action.payload);
    if (response.status === 200) {
      yield put(Actions.portalFileSuccess(response));
    } else {
      yield put(
        Actions.portalFileError({
          errorCode:ERROR_TYPES.INVALID_DATA,
          message: ERROR_TYPES.INVALID_DATA,
        })
      );
    }
  } catch (error) {
    yield put(Actions.portalFileError(error));
  }
}

export function networkPortalRequest(params){
  // const {messageSubmit,formData} = params;
  const url = Endpoints.UPLOAD_PORTAL_FILE;
  const response =  AxiosInstance.post(url, params);
  return response;
}

export function* fileDownloadgRequest(action) {
  try {
    const response = yield call(downloadNetworkRequest, action.payload);
    if (response.status === 200) {
      yield put(Actions.fileDownloadSuccess(response.data));
    } else {
      yield put(
        Actions.fileDownloadError({
          errorCode:ERROR_TYPES.INVALID_DATA,
          message: ERROR_TYPES.INVALID_DATA,
        })
      );
    }
  } catch (error) {
    yield put(Actions.fileDownloadError(error));
  }
}

export function downloadNetworkRequest(params){
  const url = Endpoints.UPLOAD_PORTAL_FILE_UPLOAD;
  const response =  AxiosInstance.post(url, { params });
  return response;
}
