import React ,{ useEffect,useState} from "react";

import Webcam from "react-webcam";

import { FiRefreshCw } from "react-icons/fi";

const FACING_MODE_USER = "user";
const FACING_MODE_ENVIRONMENT = "environment";

const videoConstraints = {
  width: 300,
  height: 400,
  facingMode: FACING_MODE_USER
};

const CameraScreen = (props) => {   
  const webcamRef = React.useRef(null);
  const [recordedChunks, setRecordedChunks] = useState([]);
  const [facingMode, setFacingMode] = useState(FACING_MODE_USER);

  const handleFlip = React.useCallback(() => {
    setFacingMode(
      prevState =>
        prevState === FACING_MODE_USER
          ? FACING_MODE_ENVIRONMENT
          : FACING_MODE_USER
    );
  }, []);

  const handlePicCapture = () => {
    const imageSrc = webcamRef.current.getScreenshot();
    props.onClickPicture(imageSrc);
  }


  const handleDownload = React.useCallback(() => {
    if (recordedChunks.length) {
      const blob = new Blob(recordedChunks, {
        type: "video/webm",
      });
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      document.body.appendChild(a);
      a.style = "display: none";
      a.href = url;
      a.download = "react-webcam-stream-capture.webm";
      a.click();
      window.URL.revokeObjectURL(url);
      setRecordedChunks([]);
    }
  }, [recordedChunks]);

  
    useEffect(() => {
        document.title = 'Take Picture';
    },[]);
   
    return (
      <>
          <Webcam
            audio={false}
            height={"100%"}
            ref={webcamRef}
            screenshotFormat="image/jpeg"
            width={"100%"}
            videoConstraints={{
              ...videoConstraints,
              facingMode
            }}
          />
          <button onClick={() => handleFlip()} ><FiRefreshCw /></button>
          <button onClick={() => handlePicCapture()} style={{margin:"20px",background:"#353D56",padding:"10px 20px",color:"#fff",borderRadius:"50px"}}>Take Picture</button>
          
          {recordedChunks.length > 0 && (
            <button onClick={handleDownload}>Download</button>
          )}
      </>
    );
}

export default CameraScreen;
