import { useEffect, useRef, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { BsChevronRight } from "react-icons/bs";
import { BiCalendarAlt } from "react-icons/bi";
import Form from "react-bootstrap/Form";
import { format} from "date-fns";
import { isEmpty } from "lodash";
import { Scrollbars } from "react-custom-scrollbars-2";
import { useSelector, useDispatch } from "react-redux";
import * as Actions from "../pages/state/calendar/actions";
import { Endpoints } from "../core/networking";
import Loader from "../components/ui/Loader";
import CheckBox from "../components/core/Checkbox";
import DateTimePicker from "../components/core/DateTimePicker";
import styles from "./Schedular.module.scss";
import Layout from "../components/ui/Layout";
import PageTitle from "./components/PageTitle";

const Schedular = () => {
    const { state } = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { details, taskname , title} = state || {};
    const {
        loginData: { loginUser },
        calendarData: {
            doctorList,
            scheduleDoctor,
            photoGrapherList,
            schedulePhotoGraphy
        },
    } = useSelector((state) => state);

    const { response: getDoctorList, isLoading: doctorListLoading } =
        doctorList || {};
    const { response: sheduleAppoint, isLoading: sheduleLoading } =
        scheduleDoctor || {};
    const { response: photoGraphList, isLoading: photoGhraphLoading } =
        photoGrapherList || {};
    const {  isLoading:  schedulePhotoLoading } =
        schedulePhotoGraphy || {};
    const { response: loginVal } = loginUser || {};
    const [, setHeight] = useState(0);
    const [scrollHeight, setScrollHeight] = useState(0);
    const [doctors, setDoctor] = useState([]);
    const [isDoctorSelected, setIsDoctorSelected] = useState(0);
    const [startDate, setStartDate] = useState();
    const [photoGrapher, setPhotoGrapher] = useState([]);
    const containerRef = useRef(null);
    const calendarRef = useRef();

    useEffect(() => {
        document.title = "Photo Shoot";
        setHeight(containerRef?.current?.clientHeight);
        dispatch(Actions.doctorAppointSubmitClear());
        let applicantID = loginVal?.data?.applicant_id;
        if (loginVal?.data?.applicant_id !== "") {
            const taskEndPoint = Endpoints.CALENDAR_DOCTOR_LIST + applicantID;
            let params = {
                endpoint: taskEndPoint,
                method: "GET",
            };

            const photoEndPoint = Endpoints.CALENDAR_SCHEDULE_PHOTOGRAPHER_LIST + applicantID;
            let param = {
                endpoint: photoEndPoint,
                method: "GET",
            };
            dispatch(Actions.getDoctorListRequest(params));
            dispatch(Actions.photoGrapherListRequest(param));
        }
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!isEmpty(sheduleAppoint) && sheduleAppoint.statusText==='OK') {
            navigate("/task-calendar");
        }
    }, [sheduleAppoint]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!isEmpty(getDoctorList)) {
            setDoctor(getDoctorList);
        }
    }, [getDoctorList]);

    useEffect(() => {
        if (!isEmpty(photoGraphList)) {
            setPhotoGrapher(photoGraphList);
        }
    }, [photoGraphList]);



    useEffect(() => {
        getSchedularHeight();
    }, [doctors]);

    const getSchedularHeight = () => {
        setScrollHeight(window.innerHeight);
    };

   const goBack = () => {
    navigate(-1);
     };

    const handleSubmit = () => {
        const formData = new FormData();
        formData.append("ApplicantID", details?.ApplicantID);
        formData.append("TaskID", details?.EventTypeID);
        formData.append("ClinicEntityID", isDoctorSelected?.ClinicEntityID);
        formData.append("AppointmentDateTime", format(startDate, "yyyy-MM-dd"));
        formData.append("endpoint", Endpoints.CALENDAR_DOCTOR_APPOINTNTMENT_SUBMIT);
        dispatch(Actions.doctorAppointSubmitRequest(formData));
    };

    const openDatepicker = () => {
        calendarRef.current.setOpen(true);
    };

    return (
        <Layout showHeader="false">
            {doctorListLoading || sheduleLoading ||  photoGhraphLoading ||  schedulePhotoLoading  ? (
                <Loader />
            ) : (
                <>
                    <PageTitle
                        title={taskname}
                        onClose={goBack}
                    />
                    <div className="wrapper px-3 py-3 mt-4 flex-grow-1 d-flex flex-column" style={{ fontSize: "18px" }}>
                        {title}
                    </div>

                    <div
                        className={`float-end position-relative`}
                        id="doctorContainer"
                    >
                        <Form>
                            <Scrollbars
                                autoHeight
                                autoHeightMax={scrollHeight - 520}
                                className="scroll-container"
                            >
                                <ul ref={containerRef} className={styles.docUL}>
                                    {
                                    // doctors?.map((doctor) => {
                                        photoGrapher?.map((doctor) => {
                                        return (
                                            <li
                                                key={doctor.ClinicEntityID+'unique'}
                                                className={`${styles.docLists} doctorLists`}
                                            >
                                                <span
                                                    className={styles.docChkBox}
                                                >
                                                    <CheckBox
                                                        checked={
                                                            doctor.ClinicEntityID ===
                                                            isDoctorSelected.ClinicEntityID
                                                        }
                                                        id={`check_${doctor.ClinicEntityID}`}
                                                        label={doctor.Name}
                                                        type={"checkbox"}
                                                        onChange={() =>
                                                            setIsDoctorSelected(
                                                                doctor
                                                            )
                                                        }
                                                    />
                                                </span>
                                                <div>
                                                    <label
                                                        className={
                                                            styles.docName
                                                        }
                                                        htmlFor={`check_${doctor.ClinicEntityID}`}
                                                    >
                                                        {doctor.Name}
                                                    </label>
                                                    <span
                                                        className={
                                                            styles.docaddress
                                                        }
                                                    >
                                                        {doctor.AddressOne +
                                                            " " +
                                                            doctor.City +
                                                            " " +
                                                            doctor.RegionName +
                                                            " " +
                                                            doctor.ZipCode +
                                                            " " +
                                                            doctor.Phone}
                                                    </span>
                                                </div>
                                            </li>
                                        );
                                    })}
                                </ul>
                            </Scrollbars>
                        </Form>
                    </div>
                    <div
                        className="px-3 py-3 flex-grow-1 d-flex flex-column"
                        style={{ fontSize: "16px" }}
                    >
                        Enter the date and time of your scheduled appointment.
                    </div>
                    <div className={`${styles.datepickerWrap} mx-auto`}>
                        <div className={styles.datepickerTxt}>
                            <DateTimePicker
                                startDate={startDate}
                                onChange={(date) => setStartDate(date)}
                                ref={calendarRef}
                            />
                            <BiCalendarAlt className={styles.calendarIcon} onClick={openDatepicker} />
                        </div>
                    </div>
                    <div
                        className={styles.submitSchedular}
                        style={{ marginTop: "20px" }}
                    >
                        <button
                            className={`position-relative float-end me-4`}
                            onClick={handleSubmit}
                            disabled ={isDoctorSelected && startDate ? false : true}
                        >
                            Submit &nbsp;{" "}
                            <BsChevronRight className={styles.buttonIcon} />
                        </button>
                    </div>
                </>
            )}
        </Layout>
    );
};

export default Schedular;
