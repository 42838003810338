import { takeLatest, put, call } from "redux-saga/effects";
import {Endpoints} from '../../../core/networking';
import * as Constants from "./constants";
import * as Actions from "./actions";
import AxiosInstance from "../../../services/AxiosInstance";
import {ERROR_TYPES} from '../types';

export function* watchTestKitSaga() {
    yield takeLatest(Constants.TEST_KIT_SEND_TO_APPROVE_REQUEST, sendApproveRequest);
    yield takeLatest(Constants.LAB_APPOINTMENT_ADD_REQUEST, sendLabAppointment);
    yield takeLatest(Constants.LAB_WORK_GET_REQUEST, getLabScheduleRequest);
    yield takeLatest(Constants.LAB_FILE_DOWNLOAD_REQUEST, fileDownloadRequest);
    yield takeLatest(Constants.LAB_BLOOD_GET_REQUEST, getBloodDrawGetRequest);    
}

export function* getLabScheduleRequest(action) {
  try {
    const pramas = action.payload;
    const response = yield call(taskNetworkRequest, pramas); 
    if (response.status === 200) {
      yield put(Actions.getLabScheduleSuccess(response.data));
    } else {
      yield put(
        Actions.getLabScheduleError({
          errorCode: ERROR_TYPES.INVALID_DATA,
          message: ERROR_TYPES.INVALID_DATA,
        })
      );
    }
  } catch (error) {
    yield put(Actions.getLabScheduleError(error));
  }
}

export function* getBloodDrawGetRequest(action) {
    try {
        const pramas = action.payload;
        const response = yield call(taskNetworkRequest, pramas);
        if (response.status === 200) {
            yield put(Actions.getLabBloodDrawSuccess(response.data));
        } else {
            yield put(
                Actions.getLabBloodDrawError({
                    errorCode: ERROR_TYPES.INVALID_DATA,
                    message: ERROR_TYPES.INVALID_DATA,
                })
            );
        }
    } catch (error) {
        yield put(Actions.getLabBloodDrawError(error));
    }
}

export function* fileDownloadRequest(action) {
  try {
    const response = yield call(downloadNetworkRequest, action.payload);
    if (response.status === 200) {
      yield put(Actions.fileDownloadSuccess(response.data));
    } else {
      yield put(
        Actions.fileDownloadError({
          errorCode:ERROR_TYPES.INVALID_DATA,
          message: ERROR_TYPES.INVALID_DATA,
        })
      );
    }
  } catch (error) {
    yield put(Actions.fileDownloadError(error));
  }
}

export function* sendApproveRequest(action) {
  try {
    const pramas = action.payload;
      const response = yield call(taskNetworkRequest, pramas);
    if (response.status === 200) {
      yield put(Actions.sendToApproveSuccess(response));
    } else {
      yield put(
        Actions.sendToApproveError({
          errorCode: ERROR_TYPES.INVALID_DATA,
          message: ERROR_TYPES.INVALID_DATA,
        })
      );
    }
  } catch (error) {
    yield put(Actions.sendToApproveError(error));
  }
}

export function* sendLabAppointment(action) {
  try {
    const response = yield call(addNetworkRequest, action.payload);
    if (response.status === 200) {
      yield put(Actions.sendLabAppointmentSuccess(response));
    } else {
      yield put(
        Actions.sendLabAppointmentError({
          errorCode: ERROR_TYPES.INVALID_DATA,
          message: ERROR_TYPES.INVALID_DATA,
        })
      );
    }
  } catch (error) {
    yield put(Actions.sendLabAppointmentError(error));
  }
}

export function addNetworkRequest(params){
  // const url = Endpoints.GET_DONOR_TASK.replace('applicantID')     ;
  const url1 = Endpoints.PORTAL_POST;
  const response =  AxiosInstance.post(url1, params);
  return response;
}

export function taskNetworkRequest(params){
  // const url = Endpoints.GET_DONOR_TASK.replace('applicantID')     ;
  const url1 = Endpoints.FAIR_FAX_PORTAL_API;
  const response =  AxiosInstance.post(url1, { params });
  return response;
}

export function downloadNetworkRequest(params){
  const url = Endpoints.UPLOAD_PORTAL_FILE_UPLOAD;
  const response =  AxiosInstance.post(url, { params });
  return response;
}
