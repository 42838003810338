import * as Constants from './constants';

export const sendToApproveRequest= (data) => ({
    type: Constants.DOCU_SEND_TO_APPROVE_REQUEST,
    payload: data,
});

export const sendToApproveSuccess = (data) => ({
    type: Constants.DOCU_SEND_TO_APPROVE_SUCCESS,
    payload: data,
});

export const sendToApproveError = (error) => ({
    type: Constants.DOCU_SEND_TO_APPROVE_ERROR,
    payload: error,
});

export const sendToApproveClear = () => ({
    type: Constants.DOCU_SEND_TO_APPROVE_CLEAR,
});

// Get Docu Url
export const getDocuUrlRequest = (data) => ({
    type: Constants.DOCU_URL_REQUEST,
    payload: data,
});

export const getDocuUrlSuccess = (data) => ({
    type: Constants.DOCU_URL_SUCCESS,
    payload: data,
});

export const getDocuUrlError = (error) => ({
    type: Constants.DOCU_URL_ERROR,
    payload: error,
});

export const getDocuUrlClear = () => ({
    type: Constants.DOCU_URL_CLEAR,
});
