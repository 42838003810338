import styles from "./ProvideAdditionalInfo.module.scss";
import Layout from "../components/ui/Layout";
import {useLocation,useNavigate} from 'react-router-dom';
import { useEffect, useState } from "react";
import Loader from "../components/ui/Loader";
import { useCookies } from 'react-cookie';
import PageTitle from "./components/PageTitle";


const ProvideInfo = () => {
    const location = useLocation();
    const { state } = location;
    const navigate = useNavigate();
    const [iframeSrc,setIframeSrc] = useState("");
    const [iframeHeight,setIframeHeight] = useState(0);
    const [loading, setLoading] = useState(true);
    const [cookies] = useCookies([]);

    useEffect(() => {
        document.title = 'Additional Information';

        let locationurl = location?.state?.url ?? "";

        let paramObj = {};

        if (cookies['front-end-app'])
            paramObj['front-end-app'] = cookies['front-end-app'];

        if (cookies['front-end-logout'])
            paramObj['front-end-logout'] = cookies['front-end-logout'];

        if (cookies['wp-login-user-token'])
            paramObj['wp-login-user-token'] = cookies['wp-login-user-token'];

        const iframeParams = '&' + new URLSearchParams(paramObj).toString();



        locationurl = locationurl + iframeParams;
        setIframeSrc(locationurl);
        window.console.log("--locationurl----", encodeURI(locationurl));
        setIframeSize();
        const donorAppIframe = document.getElementById('donorapplicationIframe');
        donorAppIframe.addEventListener('load', iframeLoad, true)

    }, [cookies, location?.state?.url]);

     const goBack = () => {
        navigate("/home");
    };

    function setIframeSize() {
        
        const titleContainer = document.getElementsByClassName("titleContainer");
        const footerElement = document.querySelector(".footerElement");
        
        setIframeHeight(window.innerHeight - titleContainer[0]?.clientHeight - footerElement?.clientHeight-6);
    }

    function iframeLoad()
    {
        setLoading(false);
    }

    window.onresize = function (event) {
        setIframeSize();
    };

    return (
        <>
            <Layout showHeader="false" className={styles.test123} style={{overflow:"hidden"}}>
            {(loading) && <Loader/>}
                <PageTitle
                    title={state?.pageTitle}
                    onClose={goBack}
                    headerClass="titleContainer"
                />
                <iframe title="DonorApplication" id="donorapplicationIframe" crossOrigin="anonymous" SameSite="None" sandbox="allow-same-origin allow-scripts allow-popups allow-forms" style={{width:"100%",height:(iframeHeight)+"px"}} target="_self" src={iframeSrc}/>
            </Layout>
        </>
    )
}

export default ProvideInfo;
