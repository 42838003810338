
import { combineReducers } from "redux";
import { getBasicReducer } from "../../../core/utils";
import * as Constants from "./constants";

export const uploadData = combineReducers({
    uploadFileTask: getBasicReducer({
        request: Constants.UPLOAD_PORTAL_FILE_REQUEST,
        success: Constants.UPLOAD_PORTAL_FILE_SUCCESS,
        failure: Constants.UPLOAD_PORTAL_FILE_ERROR,
        clear: Constants.UPLOAD_PORTAL_FILE_CLEAR,
    }),/*
    portalMsg: getBasicReducer({
        request: Constants.UPLOAD_PORTAL_MSG_REQUEST,
        success: Constants.UPLOAD_PORTAL_MSG_SUCCESS,
        failure: Constants.UPLOAD_PORTAL_MSG_ERROR,
        clear: Constants.UPLOAD_PORTAL_MSG_CLEAR,
    }),*/
    fileDownload: getBasicReducer({
        request: Constants.UPLOAD_FILE_DOWNLOAD_REQUEST,
        success: Constants.UPLOAD_FILE_DOWNLOAD_SUCCESS,
        failure: Constants.UPLOAD_FILE_DOWNLOAD_ERROR,
        clear: Constants.UPLOAD_FILE_DOWNLOAD_CLEAR,
    }),
});