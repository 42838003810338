import * as Constants from './constants';

export const sendToApproveRequest= (data) => ({
    type: Constants.TEST_KIT_SEND_TO_APPROVE_REQUEST,
    payload: data,
});

export const sendToApproveSuccess = (data) => ({
    type: Constants.TEST_KIT_SEND_TO_APPROVE_SUCCESS,
    payload: data,
});

export const sendToApproveError = (error) => ({
    type: Constants.TEST_KIT_SEND_TO_APPROVE_ERROR,
    payload: error,
});

export const sendToApproveClear = () => ({
    type: Constants.TEST_KIT_SEND_TO_APPROVE_CLEAR,
});

// Complete Lab Report
export const sendLabAppointment= (data) => ({
    type: Constants.LAB_APPOINTMENT_ADD_REQUEST,
    payload: data,
});

export const sendLabAppointmentSuccess = (data) => ({
    type: Constants.LAB_APPOINTMENT_ADD_SUCCESS,
    payload: data,
});

export const sendLabAppointmentError = (error) => ({
    type: Constants.LAB_APPOINTMENT_ADD_ERROR,
    payload: error,
});

export const sendLabAppointmentClear = () => ({
    type: Constants.LAB_APPOINTMENT_ADD_CLEAR,
});

// Get Lab Schedule
export const getLabScheduleData= (data) => ({
    type: Constants.LAB_WORK_GET_REQUEST,
    payload: data,
});

export const getLabScheduleSuccess = (data) => ({
    type: Constants.LAB_WORK_GET_SUCCESS,
    payload: data,
});

export const getLabScheduleError = (error) => ({
    type: Constants.LAB_WORK_GET_ERROR,
    payload: error,
});

export const getLabScheduleClear = () => ({
    type: Constants.LAB_WORK_GET_CLEAR,
});

// Get Lab Schedule
export const getBloodDrawLabs = (data) => ({
    type: Constants.LAB_BLOOD_GET_REQUEST,
    payload: data,
});

export const getLabBloodDrawSuccess = (data) => ({
    type: Constants.LAB_BLOOD_GET_SUCCESS,
    payload: data,
});

export const getLabBloodDrawError = (error) => ({
    type: Constants.LAB_BLOOD_GET_ERROR,
    payload: error,
});

export const getLabBloodDrawClear = () => ({
    type: Constants.LAB_BLOOD_GET_CLEAR,
});

    
// Download Lab Files
export const fileDownloadRequest= (data) => ({
    type: Constants.LAB_FILE_DOWNLOAD_REQUEST,
    payload: data,
});

export const fileDownloadSuccess = (data) => ({
    type: Constants.LAB_FILE_DOWNLOAD_SUCCESS,
    payload: data,
});

export const fileDownloadError = (error) => ({
    type: Constants.LAB_FILE_DOWNLOAD_ERROR,
    payload: error,
});

export const fileDownloadClear = () => ({
    type: Constants.LAB_FILE_DOWNLOAD_CLEAR,
});
