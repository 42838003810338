
import { combineReducers } from "redux";
import { getBasicReducer } from "../../../core/utils";
import * as Constants from "./constants";

export const homeData = combineReducers({
    submittedTasks: getBasicReducer({
        request: Constants.HOME_TASK_FETCH_REQUEST,
        success: Constants.THOME_TASK_FETCH_SUCCESS,
        failure: Constants.HOME_TASK_FETCH_ERROR,
        clear: Constants.HOME_TASK_FETCH_CLEAR,
    }),
    approveTasks: getBasicReducer({
        request: Constants.HOME_TASK_SEND_TO_APPROVE_REQUEST,
        success: Constants.HOME_TASK_SEND_TO_APPROVE_SUCCESS,
        failure: Constants.HOME_TASK_SEND_TO_APPROVE_ERROR,
        clear: Constants.HOME_TASK_SEND_TO_APPROVE_CLEAR,
    }),
    progressData: getBasicReducer({
        request: Constants.HOME_PROGRESS_GET_DATA,
        success: Constants.HOME_PROGRESS_GET_SUCCESS,
        failure: Constants.HOME_PROGRESS_GET_ERROR,
        clear: Constants.HOME_PROGRESS_GET_CLEAR,
    }),
    iframeTaskStatus: getBasicReducer({
        request: Constants.HOME_TASK_IFRAME_FETCH_REQUEST,
        success: Constants.HOME_TASK_IFRAME_FETCH_SUCCESS,
        failure: Constants.HOME_TASK_IFRAME_FETCH_ERROR,
        clear: Constants.HOME_TASK_IFRAME_FETCH_CLEAR,
    }),
    getNotificationInfo: getBasicReducer({
        request: Constants.NOTIFICATION_GET_DATA,
        success: Constants.NOTIFICATION_GET_SUCCESS,
        failure: Constants.NOTIFICATION_GET_ERROR,
        clear: Constants.NOTIFICATION_GET_CLEAR,
    }),
    acknowledgeNotification: getBasicReducer({
        request: Constants.NOTIFICATION_ACKNOWLEDGE_REQUEST,
        success: Constants.NOTIFICATION_ACKNOWLEDGE_SUCCESS,
        error: Constants.NOTIFICATION_ACKNOWLEDGE_ERROR,
        clear: Constants.NOTIFICATION_ACKNOWLEDGE_CLEAR,
    }),
});


