import { takeLatest, put, call } from "redux-saga/effects";
import {Endpoints} from '../../../core/networking';
import * as Constants from "./constants";
import * as Actions from "./actions";
import AxiosInstance from "../../../services/AxiosInstance";
import {ERROR_TYPES} from '../types';

export function* watchVideoPage() {
    yield takeLatest(Constants.VIDEO_GET_LIST, videosListRequest);
    yield takeLatest(Constants.INFO_VIDEO_COMPLETE, sendApproveRequest);
    yield takeLatest(Constants.INFO_VIDEO_GET, getVideoInfo);
    yield takeLatest(Constants.GET_INFO_SESSION_VIDEO, getInfoSessionVideo);
    yield takeLatest(Constants.INFO_VIDEO_COMPLETED, infoVideoFullySeen);
}

export function* videosListRequest(action) {
    try {
        const params = action.payload;
        const response = yield call(videoListNetworkRequest, params);
        if (response.status === 200) {
            yield put(Actions.videoListSuccess(response.data));
        } else {
            yield put(
                Actions.videoListError({
                    errorCode: ERROR_TYPES.INVALID_DATA,
                    message: ERROR_TYPES.INVALID_DATA,
                })
            );
        }
    } catch (error) {
        yield put(Actions.videoListError(error));
    }
}

export function* getInfoSessionVideo(action) {
    try {
        const params = action.payload;
        const response = yield call(taskNetworkRequest, params);
        if (response.status === 200) {
            yield put(Actions.getSessionVideoInfoSuccess(response.data));
        } else {
            yield put(
                Actions.getSessionVideoInfoError({
                    errorCode: ERROR_TYPES.INVALID_DATA,
                    message: ERROR_TYPES.INVALID_DATA,
                })
            );
        }
    } catch (error) {
        yield put(Actions.getSessionVideoInfoError(error));
    }
}


export function* getVideoInfo(action) {
    try {
        const params = action.payload;
        const response = yield call(taskInfoVideoRequest, params);
        if (response.status === 200) {
            yield put(Actions.getVideoInfoSuccess(response.data));
        } else {
            yield put(
                Actions.getVideoInfoError({
                    errorCode: ERROR_TYPES.INVALID_DATA,
                    message: ERROR_TYPES.INVALID_DATA,
                })
            );
        }
    } catch (error) {
        yield put(Actions.getVideoInfoError(error));
    }
}



export function* sendApproveRequest(action) {
  try {
    const params = action.payload;
      const response = yield call(postApproveRequest, params);
    if (response.status === 200) {
      yield put(Actions.infoVideoSuccess(response));
    } else {
      yield put(
        Actions.infoVideoError({
          errorCode: ERROR_TYPES.INVALID_DATA,
          message: ERROR_TYPES.INVALID_DATA,
        })
      );
    }
  } catch (error) {
    yield put(Actions.infoVideoError(error));
  }
}


export function* infoVideoFullySeen(action) {
    try {
        const params = action.payload;
        const response = yield call(postApproveRequest, params);
        if (response.status === 200) {
            yield put(Actions.infoVideoCompletedSuccess(response));
        } else {
            yield put(
                Actions.infoVideoCompletedError({
                    errorCode: ERROR_TYPES.INVALID_DATA,
                    message: ERROR_TYPES.INVALID_DATA,
                })
            );
        }
    } catch (error) {
        yield put(Actions.infoVideoCompletedError(error));
    }
}

export function taskInfoVideoRequest(params) {
    const url = Endpoints.GET_REMOTE_DATA_INFO;
    const response = AxiosInstance.post(url, { params });
    return response;
}
export function postApproveRequest(params) {
    const url = Endpoints.PORTAL_POST;
    const response = AxiosInstance.post(url, params);
    return response;
}

export function taskNetworkRequest(params) {
    const url = Endpoints.FAIR_FAX_PORTAL_API;
    const response = AxiosInstance.post(url, { params });
    return response;
}

export function videoListNetworkRequest(params) {
    const url = Endpoints.VIDEO_PORTAL_API;
    const response = AxiosInstance.post(url, { params });
    return response;
}


