import { useState, useRef, useEffect } from "react";
import styles from './Header.module.scss';
import { useCookies } from 'react-cookie';
import { motion } from "framer-motion";
import { ReactComponent as Logo } from '../../assets/images/logo-horizontal.svg';
import { ReactComponent as MenuHamIcon } from '../../assets/images/menu-icon.svg';
import { ReactComponent as Bell } from '../../assets/images/notification-Icon.svg';
import { ReactComponent as CrossIcon } from '../../assets/images/cross-icon.svg';
import { useDimensions } from "./use-dimensions";
import NavigationMenu from "./Menu";
import { CheckIsLoggedIn } from '../../services/Common';
import useHandleLogOut from '../../pages/hooks/useHandleLogOut';
import { FiX } from "react-icons/fi";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { createPortal } from "react-dom";

function Header(props) {
    const navigate = useNavigate();
	let { state } = useLocation();
	const [isOpen, setIsOpen] = useState(false);
	const containerRef = useRef(null);
	const { width } = useDimensions(containerRef);
	const [cookies, setCookie, removeCookie] = useCookies(['wp-login-user-token']);
    const [showNotification,setShowNotification] = useState(false);

	const logOut = useHandleLogOut(setCookie, removeCookie);
	const [totalCount,setTotalCount] = useState(0);

    let { unreadMsgs, calendar, tasks, videos, newTasks} = props?.dueTasksCounter ?? [];


    useEffect(() => {
        if (!CheckIsLoggedIn(cookies)) {
            navigate("/logout", { replace: true });
        }
        if (state?.menu === "open") {
            setIsOpen(true);
        }
    }, []);// eslint-disable-line react-hooks/exhaustive-deps



    const showNotificationModel = () => {
        setShowNotification(!showNotification);
    }

	const closeNotification = () => {
        setShowNotification(false);
    }

    const handleMenuClose = () => {
        navigate(null, { replace: true, state: { menu: "closed" } });
        setIsOpen(false);
    }

	useEffect(() => {
        let count = 0
        
        if (props?.dueTasksCounter?.tasks !== undefined) count = count + props?.dueTasksCounter?.tasks?.length;
        if (props?.dueTasksCounter?.newTasks !== undefined) count = count + props?.dueTasksCounter?.newTasks?.length;
		if(props?.dueTasksCounter?.unreadMsgs !== undefined) count = count + props?.dueTasksCounter?.unreadMsgs?.length;
		if(props?.dueTasksCounter?.videos !== undefined) count = count + props?.dueTasksCounter?.videos?.length;
		if(props?.dueTasksCounter?.calendar !== undefined) count = count + props?.dueTasksCounter?.calendar?.length;
        
		setTotalCount(count);
	}, [props]);

	return (
		<>
				<header className={"d-flex align-items-center justify-content-between bg-primary text-white headerElement " + styles.innerHeader} >
					<div className={styles.menuIconContainer}>
						{!isOpen ?
							<motion.span className={styles.crossMenuIcon}
                                         initial={{ x: 0 }}
                                         onClick={() => setIsOpen(true)}
                            >
								<MenuHamIcon className={styles.menuIcon} />
							</motion.span>
							:
							<motion.span className={styles.crossMenuIcon}
                                         initial={{ x: 0 }}
                                         animate={{ x: (width - 80) }}
                                         onClick={handleMenuClose}
                            >
								<CrossIcon className={styles.menuIcon} />
							</motion.span>
						}
					</div>
					<motion.nav className={styles.headerNav}
						initial={false}
						animate={isOpen ? "open" : "closed"}
						ref={containerRef}
					>
						<NavigationMenu />
					</motion.nav>
					  {!isOpen ?
                    <div className={styles.head}><Logo className={styles.logo} /></div>: ''}
					<div className={styles.notification} onClick={()=>showNotificationModel()}>
							{
								totalCount > 0 &&
								<span className={`d-flex align-items-center justify-content-center ${styles.counter}`}>{totalCount}</span>
							}

							<Bell className={styles.bell}/>
					</div>
				</header>
				{
					showNotification && totalCount > 0 && createPortal(
						<div className={`${styles.notificationModalContainer} position-absolute w-100 top-0 start-0 end-0 bottom-0`}>
							<motion.div className={`${styles.notificationModal} position-absolute top-50`}
								initial={{opacity: 0, scale: 0.5, translateY:'-50%'}}
								animate={{opacity: 1, scale: 1, translateY:'-50%'}}
								transition={{duration: 0.3}}
							>
								<div className={styles.notificationModal} >
									<div className={styles.nofityHeader}>
										<Bell className={styles.notifyBellModel}/>
										<FiX onClick={()=>closeNotification()} className={styles.notifyCrossModel}/>
									</div>
									<div className={styles.notifyDetails}>
                                        <ul>
                                            {
                                                newTasks?.length > 0 &&
                                                <li>
                                                    <span className={styles.notifyText} onClick={() => { setShowNotification(false); }}>
                                                        {newTasks?.length > 1 &&
                                                            <>You have {newTasks?.length} new tasks.</>
                                                        }
                                                        {newTasks?.length === 1 &&
                                                            <>You have a new task.</>
                                                        }
                                                    </span>
                                                </li>
                                            }
											{
												tasks?.length > 0 &&
												<li>
													<span className={styles.notifyText} onClick={()=>{setShowNotification(false);}}>
														{ tasks?.length > 1 &&
															<>You have {props?.dueTasksCounter?.tasks?.length} incomplete tasks past their due dates. Please complete the tasks as soon as you can. Thank you.</>
														}
														{ tasks?.length === 1 &&
															<>You have an incomplete task past its due date. Please complete the task as soon as you can. Thank you.</>
														}
													</span>
												</li>
											}
											{
												unreadMsgs?.length > 0 &&
												<li>
													<span className={styles.notifyText} onClick={()=>{navigate("/messages");}}>
                                                        {unreadMsgs?.length > 1 &&
                                                            <>You have {props?.dueTasksCounter?.unreadMsgs?.length} unread messages. <Link to='/messages' state={{ prevPage: window.location.pathname }}>Click here to view them.</Link></>
                                                        }
                                                        {unreadMsgs?.length === 1 &&
                                                            <>You have a new message. <Link to='/messages' state={{ prevPage: window.location.pathname }}>Click here to view it.</Link></>
                                                        }
													</span>
												</li>
											}
											{
												videos?.length > 0 &&
												<li>
													<span className={styles.notifyText} onClick={()=>{setShowNotification(false);}}>
                                                        {videos?.length > 1 &&
                                                                <>You have new videos uploaded. <Link to='/video-library' state={{ prevPage: window.location.pathname }}>Click here</Link> to view them.</>
                                                        }
                                                        {videos?.length === 1 &&
                                                                <>You have a new video uploaded. <Link to='/video-library' state={{ prevPage: window.location.pathname }}>Click here</Link> to view them.</>
                                                        }
													</span>
												</li>
											}
											{
												calendar?.length > 0 &&
												<li>
													<span className={styles.notifyText} onClick={()=>{setShowNotification(false);}}>
														{ calendar?.length > 1 &&
															<>You have unread messages. Click here to view them.</>
														}
														{ calendar?.length === 1 &&
															<>You have a new message. Click here to view it.</>
														}
													</span>
												</li>
											}
										</ul>
									</div>
								</div>
							</motion.div>
                    	</div>,
						document.querySelector('.app')
					)
				}
		</>
	)
}

export default Header;
