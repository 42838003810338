import * as Constants from './constants';

export const taskFetchRequest = (data) => ({
    type: Constants.HOME_TASK_FETCH_REQUEST,
    payload: data,
});

export const taskFetchSuccess = (data) => ({
    type: Constants.THOME_TASK_FETCH_SUCCESS,
    payload: data,
});

export const taskFetchError = (error) => ({
    type: Constants.HOME_TASK_FETCH_ERROR,
    payload: error,
});

export const taskFetchClear = () => ({
    type: Constants.HOME_TASK_FETCH_CLEAR,
});

export const sendToApproveRequest= (data) => ({
    type: Constants.HOME_TASK_SEND_TO_APPROVE_REQUEST,
    payload: data,
});

export const sendToApproveSuccess = (data) => ({
    type: Constants.HOME_TASK_SEND_TO_APPROVE_SUCCESS,
    payload: data,
});

export const sendToApproveError = (error) => ({
    type: Constants.HOME_TASK_SEND_TO_APPROVE_ERROR,
    payload: error,
});

export const sendToApproveClear = () => ({
    type: Constants.HOME_TASK_SEND_TO_APPROVE_CLEAR,
});

export const progressBarFetchData = (data) => ({
    type: Constants.HOME_PROGRESS_GET_DATA,
    payload: data
});

export const progressBarSuccessData = (data) => ({
    type: Constants.HOME_PROGRESS_GET_SUCCESS,
    payload: data
});

export const progressBarErrorData = (error) => ({
    type: Constants.HOME_PROGRESS_GET_ERROR,
    payload: error
});

export const progressBarClearData = () => ({
    type: Constants.HOME_PROGRESS_GET_CLEAR,
});

export const iframeTaskStatusFetch = (data) => ({
    type: Constants.HOME_TASK_IFRAME_FETCH_REQUEST,
    payload: data,
});

export const iframeTaskStatusSucess = (data) => ({
    type: Constants.HOME_TASK_IFRAME_FETCH_SUCCESS,
    payload: data,
});

export const iframeTasksStatusError = (error) => ({
    type: Constants.HOME_TASK_IFRAME_FETCH_ERROR,
    payload: error,
});

export const iframeTasksStatusClear = () => ({
    type: Constants.HOME_TASK_IFRAME_FETCH_CLEAR,
});

export const notificationInfo = (data) => ({
    type: Constants.NOTIFICATION_GET_DATA,
    payload: data,
});

export const notificationInfoSuccess = (data) => ({
    type: Constants.NOTIFICATION_GET_SUCCESS,
    payload: data,
});

export const notificationInfoError = (error) => ({
    type: Constants.NOTIFICATION_GET_ERROR,
    payload: error,
});

export const notificationInfoClear = () => ({
    type: Constants.NOTIFICATION_GET_CLEAR,
});

export const notificationAcknowledgeRequest = (data) => ({
    type: Constants.NOTIFICATION_ACKNOWLEDGE_REQUEST,
    payload: data,
});

export const notificationAcknowledgeSuccess = (data) => ({
    type: Constants.NOTIFICATION_ACKNOWLEDGE_SUCCESS,
    payload: data,
});

export const notificationAcknowledgeError = (error) => ({
    type: Constants.NOTIFICATION_ACKNOWLEDGE_ERROR,
    payload: error,
});

export const notificationAcknowledgeClear = () => ({
    type: Constants.NOTIFICATION_ACKNOWLEDGE_CLEAR,
});
