/*screen name + methodname+ actiontype*/

export const APPOINTMENT_APPROVE_REQUEST = 'APPOINTMENT_APPROVE_REQUEST';
export const APPOINTMENT_APPROVE_SUCCESS = 'APPOINTMENT_APPROVE_SUCCESS';
export const APPOINTMENT_APPROVE_ERROR = 'APPOINTMENT_APPROVE_ERROR';
export const APPOINTMENT_APPROVE_CLEAR = 'APPOINTMENT_APPROVE_CLEAR';

export const TASK_DOCUMENT_REQUEST = 'TASK_DOCUMENT_REQUEST';
export const TASK_DOCUMENT_SUCCESS = 'TASK_DOCUMENT_SUCCESS';
export const TASK_DOCUMENT_ERROR = 'TASK_DOCUMENT_ERROR';
export const TASK_DOCUMENT_CLEAR = 'TASK_DOCUMENT_CLEAR';

export const TASK_DOC_DOWNLOAD_REQUEST = 'TASK_DOC_DOWNLOAD_REQUEST';
export const TASK_DOC_DOWNLOAD_SUCCESS = 'TASK_DOC_DOWNLOAD_SUCCESS';
export const TASK_DOC_DOWNLOAD_ERROR = 'TASK_DOC_DOWNLOAD_ERROR';
export const TASK_DOC_DOWNLOAD_CLEAR = 'TASK_DOC_DOWNLOAD_CLEAR';

export const APP_SCHEDULE_INTERVIEW_GET = 'APP_SCHEDULE_INTERVIEW_GET';
export const APP_SCHEDULE_INTERVIEW_SUCCESS = 'APP_SCHEDULE_INTERVIEW_SUCCESS';
export const APP_SCHEDULE_INTERVIEW_ERROR = 'APP_SCHEDULE_INTERVIEW_ERROR';
export const APP_SCHEDULE_INTERVIEW_CLEAR = 'APP_SCHEDULE_INTERVIEW_CLEAR';

export const PSYCHOLOOGICAL_MIND_360CONTACT_REQUEST = 'PSYCHOLOOGICAL_MIND_360CONTACT_REQUEST';
export const PSYCHOLOOGICAL_MIND_360CONTACT_SUCCESS = 'PSYCHOLOOGICAL_MIND_360CONTACT_SUCCESS';
export const PSYCHOLOOGICAL_MIND_360CONTACT_ERROR = 'PSYCHOLOOGICAL_MIND_360CONTACT_ERROR';
export const PSYCHOLOOGICAL_MIND_360CONTACT_CLEAR = 'PSYCHOLOOGICAL_MIND_360CONTACT_CLEAR';

export const PSYCHOLOOGICAL_SUBMIT_APPOINTMENT_REQUEST = 'PSYCHOLOOGICAL_SUBMIT_APPOINTMENT_REQUEST';
export const PSYCHOLOOGICAL_SUBMIT_APPOINTMENT_SUCCESS = 'PSYCHOLOOGICAL_SUBMIT_APPOINTMENT_SUCCESS';
export const PSYCHOLOOGICAL_SUBMIT_APPOINTMENT_ERROR = 'PSYCHOLOOGICAL_SUBMIT_APPOINTMENT_ERROR';
export const PSYCHOLOOGICAL_SUBMIT_APPOINTMENT_CLEAR = 'PSYCHOLOOGICAL_SUBMIT_APPOINTMENT_CLEAR';

export const PSYCHOLOGIST_LIST_REQUEST = 'PSYCHOLOGIST_LIST_REQUEST';
export const PSYCHOLOGIST_LIST_SUCCESS = 'PSYCHOLOGIST_LIST_SUCCESS';
export const PSYCHOLOGIST_LIST_ERROR = 'PSYCHOLOGIST_LIST_ERROR';
export const PSYCHOLOGIST_LIST_CLEAR = 'PSYCHOLOGIST_LIST_CLEAR';

export const SUBMIT_PCP_OBGYN_SUBMIT_REQUEST = 'SUBMIT_PCP_OBGYN_SUBMIT_REQUEST';
export const SUBMIT_PCP_OBGYN_SUBMIT_SUCCESS = 'SUBMIT_PCP_OBGYN_SUBMIT_SUCCESS';
export const SUBMIT_PCP_OBGYN_SUBMIT_ERROR = 'SUBMIT_PCP_OBGYN_SUBMIT_ERROR';
export const SUBMIT_PCP_OBGYN_SUBMIT_CLEAR = 'SUBMIT_PCP_OBGYN_SUBMIT_CLEAR';