import styles from "./VideoLibrary.module.scss";
import Layout from "../components/ui/Layout";
import { useLocation } from 'react-router-dom';
import { useEffect,useState,useRef} from "react";
import { ReactComponent as LargePlayButton } from '../assets/images/play-button-large.svg';
import Loader from "../components/ui/Loader";
import { useSelector, useDispatch } from "react-redux";
import * as Actions from "./state/videos/actions";
import { Scrollbars } from "react-custom-scrollbars-2";
import { Endpoints } from "../core/networking";
import { debounce } from "lodash";
import PageTitle from "./components/PageTitle";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(customParseFormat);

const VideoLibrary = () => {
    const dispatch = useDispatch();
    let { state } = useLocation();
    const videoScrollRef = useRef();
    const [scrollbarHeight, setScrollBarHeight] = useState(window.screen.availHeight);
    const {
        loginData: { loginUser },
        videoPageData: { videoLists },
    } = useSelector((state) => state);
    const { response: loginVal } = loginUser || {};
    const { response: videoListResponse, isLoading: videoLoading/*, error*/ } = videoLists || {};
    const [videoSelected, setVideoSelected] = useState({});
    const [dropDownOptions, setDropDownOptions] = useState([]);
    const [libraries, setLibraries] = useState([]);
    const [youtubeVideo,setYoutubeVideo] = useState("");

    const getContentHeight = () => {
        let mainElement = document.getElementsByClassName("app");
        let dropdownElement = document.querySelector(".dropdownElement");
        let headerElement = document.querySelector('.headerElement');
        let footerElement = document.querySelector('.footerElement');

        return mainElement[0]?.offsetHeight - (headerElement.clientHeight + footerElement.clientHeight + dropdownElement?.clientHeight + 12);
    }

    useEffect(() => {
        if(!(youtubeVideo || (videoSelected && Object.keys(videoSelected).length > 0))) {
            // set scroll window height
            setScrollBarHeight(getContentHeight());

            const debouncedHandleResize = debounce(function handleResize() {
                setScrollBarHeight(getContentHeight());
            }, 300);

            window.addEventListener("resize", debouncedHandleResize);

            // cleanup listener
            return _ => {
                window.removeEventListener('resize', debouncedHandleResize);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [scrollbarHeight]);

    useEffect(() => {
        document.title = 'Video Library';
        setVideoSelected({});
        const videoListEndPoint = `${Endpoints.VIDEO_GET_DATA}${loginVal?.data?.applicant_id}`;
        let params = {
            endpoint: videoListEndPoint,
            method: "GET"
        };
        dispatch(Actions.videoList(params));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loginVal?.data?.applicant_id]);

    useEffect(() => {
        if (typeof videoListResponse !== "undefined" && videoListResponse) {
            
            let dropdownLists = [];
            let categorizeLists = [{}];

            for (let i = 0; i < videoListResponse.length; i++) {

                dropdownLists[videoListResponse[i].videoLibraryCategoryID] = videoListResponse[i].categoryName;

                if (typeof categorizeLists[videoListResponse[i].videoLibraryCategoryID] === "undefined") {
                    categorizeLists[videoListResponse[i].videoLibraryCategoryID] = [];
                }
                categorizeLists[videoListResponse[i].videoLibraryCategoryID].push(videoListResponse[i]);
            }

            setDropDownOptions(dropdownLists);

            
            setLibraries(categorizeLists);

        }
    }, [videoListResponse]);

    useEffect(() => {
        const elm = document.getElementById("selectedVideoIframe");
        if (elm != null && videoSelected && Object.keys(videoSelected).length > 0) {

            let iframeHTMLVimeo  = videoSelected.html;

            // get src attribute of iframe html
            var start = iframeHTMLVimeo.indexOf('src="') + 'src="'.length;
            var end = iframeHTMLVimeo.indexOf('"', start + 1);
            var iframeSrc = iframeHTMLVimeo.substring(start, end);

            let updatedVideoSrc = iframeSrc + "&autoplay=1&loop=1";

            iframeHTMLVimeo = iframeHTMLVimeo.replace(/src=".*"/, "src=\"" + updatedVideoSrc +"\" width=\""+videoSelected.width+"\" height=\""+videoSelected.height+"\" frameborder=\"0\" allow=\"autoplay; fullscreen; picture-in-picture\" allowfullscreen title=\""+videoSelected.title+"\" ");
            
            elm.innerHTML = iframeHTMLVimeo;
        }
    }, [videoSelected]);

    function handleDropDown (event)
    {
        let section = null;
        if (event.target.value === "")
            section = document.getElementById("selectButton");
        else
            section = document.getElementById("process_" + event.target.value);

        if(section) {
            
            setTimeout(() => {
                videoScrollRef.current.scrollTop(section.offsetTop - 30);
            }, 50);
        }
    }

    const playVideo = (videoInfo) => {
        setYoutubeVideo("");
        setVideoSelected(videoInfo);
    };

    const playYoutubeVideo = (videoInfo) => {
        console.log(videoInfo);
        
        setYoutubeVideo('https://www.youtube.com/embed/'+videoInfo+'/?controls=1&autoplay=1');
        
    };

    function closePlayer() {
        setYoutubeVideo("");
        setVideoSelected({});
    }

    const renderNewVideoClass = (uploadDate) => {
        let daysPosted = dayjs(Date.now()).diff(uploadDate, 'day');
        if (daysPosted <= 90) {
            return styles.highLightNewVideo 
        }
        return '';
    }

    const VideoItem = ({info}) => {
        return (
            <li className={(renderNewVideoClass(info.uploadDate))} onClick={() => (info.videoType === "YouTube" ? playYoutubeVideo(info.videoHostID) : playVideo(info.videoDetails))}>
                <div className={`${styles.videoImage} text-center`}
                     style={info['videoType'] === "YouTube" ? // youtube
                         { backgroundColor: '#CCCCCC' } :
                         { backgroundImage: `url(${info.videoDetails?.thumbnail_url})` }
                     }
                >
                    <LargePlayButton className={styles.largePlayButton} />
                </div>
                <div>
                    {
                        info.videoName &&
                        <span className={styles.videoTitle}>
                            {info.videoName}
                        </span>
                    }
                    {
                        info.videoDescription &&
                        <span className={styles.videoDesc}>
                            {info.videoDescription}
                        </span>
                    }
                    <span className={styles.videoDate}>
                        {dayjs(info.uploadDate, "M-D-YYYY").format("MM/DD/YYYY")}
                    </span>
                </div>
            </li>
        );
    }

    return (
        <>
            {
                (youtubeVideo || (videoSelected && Object.keys(videoSelected).length > 0)) ?
                    <div className={styles.videoWrapper}>
                        <PageTitle
                            headerClass="homePageTitle"
                            onClose={closePlayer}
                        />
                        <div id="selectedVideoIframe" className={styles.videoIframe}>
                            {
                                youtubeVideo !== "" ?
                                    <iframe title="Infosession Video" width="100%" height="100%" src={youtubeVideo}
                                            sandbox='allow-same-origin allow-forms allow-popups allow-scripts allow-presentation'/>
                                    : <></>
                            }
                        </div>
                    </div>
                    :
                    <>
                        {(videoLoading) && <Loader/>}
                        <Layout showHeader="false">
                            <div className={`${styles.stickyHeader} headerElement`}>
                                <PageTitle
                                    title="Video Library"
                                    headerClass="homePageTitle"
                                    linkTo={state?.prevPage ? state?.prevPage : "/home"}
                                    linkState={{menu: state?.menu}}
                                />
                            </div>
                            <div className={`position-relative ${styles.videoContainer}`} id="videoContainer">
                                <div className="dropdownElement" style={{padding: "10% 10% 10px 10%"}}>
                                    <select id="selectButton" className={styles.selectElement}
                                            onChange={handleDropDown}>
                                        <option value="">Jump to:</option>
                                        {
                                            dropDownOptions && Object.keys(dropDownOptions).map((optionID, optionName) =>
                                                <option key={optionID} value={optionID}>{dropDownOptions[optionID]}</option>
                                            )
                                        }
                                    </select>
                                </div>
                                <Scrollbars
                                    autoHeight
                                    autoHeightMax={scrollbarHeight}
                                    className="scroll-container"
                                    ref={videoScrollRef}
                                >
                                    <div className={styles.scrollContent}>
                                        <div className={styles.videoSection}>
                                            {
                                                dropDownOptions &&
                                                Object.keys(dropDownOptions).map((libraryID, lib) =>
                                                    (
                                                        <>
                                                            {
                                                                libraries[libraryID] && Object.keys(libraries[libraryID])?.length > 0 &&
                                                                <>
                                                                    <h3 key={`lib-${lib + 1}`}
                                                                        id={`process_${libraryID}`}>{dropDownOptions[libraryID]}</h3>
                                                                    <ul>
                                                                        {
                                                                            libraries[libraryID] && Object.keys(libraries[libraryID]).map(
                                                                                (libID, info) =>
                                                                                    <VideoItem key={`${libID}-${info + 1}`}
                                                                                               info={libraries[libraryID][libID]}/>
                                                                            )
                                                                        }
                                                                    </ul>
                                                                </>
                                                            }
                                                        </>
                                                    )
                                                )
                                            }
                                        </div>
                                    </div>
                                </Scrollbars>
                            </div>
                        </Layout>
                    </>
            }
        </>
    );
}

export default VideoLibrary;
