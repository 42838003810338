
import { combineReducers } from "redux";
import { getBasicReducer } from "../../../core/utils";
import * as Constants from "./constants";

export const testkitData = combineReducers({
    completedTests: getBasicReducer({
        request: Constants.TEST_KIT_SEND_TO_APPROVE_REQUEST,
        success: Constants.TEST_KIT_SEND_TO_APPROVE_SUCCESS,
        failure: Constants.TEST_KIT_SEND_TO_APPROVE_ERROR,
        clear: Constants.TEST_KIT_SEND_TO_APPROVE_CLEAR,
    }),    
    submitAppointment: getBasicReducer({
        request: Constants.LAB_APPOINTMENT_ADD_REQUEST,
        success: Constants.LAB_APPOINTMENT_ADD_SUCCESS,
        failure: Constants.LAB_APPOINTMENT_ADD_ERROR,
        clear: Constants.LAB_APPOINTMENT_ADD_CLEAR,
    }),   
    getLabWorkSchedule: getBasicReducer({
        request: Constants.LAB_WORK_GET_REQUEST,
        success: Constants.LAB_WORK_GET_SUCCESS,
        failure: Constants.LAB_WORK_GET_ERROR,
        clear: Constants.LAB_WORK_GET_CLEAR,
    }),  
    getLabAttachment: getBasicReducer({
        request: Constants.LAB_FILE_DOWNLOAD_REQUEST,
        success: Constants.LAB_FILE_DOWNLOAD_SUCCESS,
        failure: Constants.LAB_FILE_DOWNLOAD_ERROR,
        clear: Constants.LAB_FILE_DOWNLOAD_CLEAR,
    }),
    getLabBoodDrawLists: getBasicReducer({
        request: Constants.LAB_BLOOD_GET_REQUEST,
        success: Constants.LAB_BLOOD_GET_SUCCESS,
        failure: Constants.LAB_BLOOD_GET_ERROR,
        clear: Constants.LAB_BLOOD_GET_CLEAR,
    }),
});


