import React from "react";
import styles from "./TaskCalendar.module.scss";
import { FaBell } from "react-icons/fa";
import { BsXLg } from "react-icons/bs";


const ModalView = (props) => {
    const { handleReminder, selectedReminder, showReminderModel } = props;
    return (
        <>
            <div className={styles.modalOverlay}></div>
            <div className={styles.remainderOverlay}>
                <span>
                    <FaBell /> Reminder
                </span>
                <span style={{ float: "right", cursor: "pointer" }}>
                    <BsXLg
                        onClick={() => {
                            showReminderModel(false);
                        }}
                    />
                </span>
                <ul>
                    <li
                        className={`${
                            selectedReminder === "5min"
                                ? styles.selectedReminder
                                : ""
                        }`}
                        onClick={() => handleReminder("5min")}
                    >
                        5 mintues before
                    </li>
                    <li
                        className={`${
                            selectedReminder === "10min"
                                ? styles.selectedReminder
                                : ""
                        }`}
                        onClick={() => handleReminder("10min")}
                    >
                        10 mintues before
                    </li>
                    <li
                        className={`${
                            selectedReminder === "15min"
                                ? styles.selectedReminder
                                : ""
                        }`}
                        onClick={() => handleReminder("15min")}
                    >
                        15 mintues before
                    </li>
                    <li
                        className={`${
                            selectedReminder === "30min"
                                ? styles.selectedReminder
                                : ""
                        }`}
                        onClick={() => handleReminder("30min")}
                    >
                        30 mintues before
                    </li>
                    <li
                        className={`${
                            selectedReminder === "1hr"
                                ? styles.selectedReminder
                                : ""
                        }`}
                        onClick={() => handleReminder("1hr")}
                    >
                        1 hour before
                    </li>
                    <li
                        className={`${
                            selectedReminder === "1day"
                                ? styles.selectedReminder
                                : ""
                        }`}
                        onClick={() => handleReminder("1day")}
                    >
                        1 day before
                    </li>
                </ul>
            </div>
        </>
    );
};

export default ModalView;
