import { takeLatest, put, call } from "redux-saga/effects";
import { Endpoints } from "../../../core/networking";
import * as Constants from "./constants";
import * as Actions from "./actions";
import AxiosInstance from "../../../services/AxiosInstance";
import { ERROR_TYPES } from "../types";

export function* watchAppointmentSaga() {
	yield takeLatest(Constants.APPOINTMENT_APPROVE_REQUEST, sendApproveRequest);
	yield takeLatest(Constants.TASK_DOCUMENT_REQUEST, getTaskDocumentRequest);
	yield takeLatest(Constants.TASK_DOC_DOWNLOAD_REQUEST, fileDownloadRequest);
	yield takeLatest(
		Constants.APP_SCHEDULE_INTERVIEW_GET,
		getScheduleAppInterview
	);
	yield takeLatest(
		Constants.PSYCHOLOOGICAL_MIND_360CONTACT_REQUEST,
		getPsychoMind360Contact
	);
	yield takeLatest(
		Constants.PSYCHOLOOGICAL_SUBMIT_APPOINTMENT_REQUEST,
		psychoSubmitAppointment
	);
	yield takeLatest(Constants.PSYCHOLOGIST_LIST_REQUEST, psychlogistList);
	yield takeLatest(Constants.SUBMIT_PCP_OBGYN_SUBMIT_REQUEST, pcbObgynSubmit);
}
export function* getScheduleAppInterview(action) {
	try {
		const pramas = action.payload;
		const response = yield call(taskNetworkRequest, pramas);
		if (response.status === 200) {
			yield put(
				Actions.getAppointmentSchedInterviewSuccess(response.data)
			);
		} else {
			yield put(
				Actions.getAppointmentSchedInterviewError({
					errorCode: ERROR_TYPES.INVALID_DATA,
					message: ERROR_TYPES.INVALID_DATA,
				})
			);
		}
	} catch (error) {
		yield put(Actions.getAppointmentSchedInterviewError(error));
	}
}

export function* getTaskDocumentRequest(action) {
	try {
		const pramas = action.payload;
		const response = yield call(taskNetworkRequest, pramas);
		if (response.status === 200) {
			yield put(Actions.getTaskDocumentSuccess(response.data));
		} else {
			yield put(
				Actions.getTaskDocumentError({
					errorCode: ERROR_TYPES.INVALID_DATA,
					message: ERROR_TYPES.INVALID_DATA,
				})
			);
		}
	} catch (error) {
		yield put(Actions.getTaskDocumentError(error));
	}
}

export function* fileDownloadRequest(action) {
	try {
		const response = yield call(downloadNetworkRequest, action.payload);
		if (response.status === 200) {
			yield put(Actions.fileDownloadSuccess(response.data));
		} else {
			yield put(
				Actions.fileDownloadError({
					errorCode: ERROR_TYPES.INVALID_DATA,
					message: ERROR_TYPES.INVALID_DATA,
				})
			);
		}
	} catch (error) {
		yield put(Actions.fileDownloadError(error));
	}
}

export function* sendApproveRequest(action) {
	try {
		const pramas = action.payload;
		const response = yield call(taskNetworkRequest, pramas);
		if (response.status === 200) {
			yield put(Actions.sendToApproveSuccess(response));
		} else {
			yield put(
				Actions.sendToApproveError({
					errorCode: ERROR_TYPES.INVALID_DATA,
					message: ERROR_TYPES.INVALID_DATA,
				})
			);
		}
	} catch (error) {
		yield put(Actions.sendToApproveError(error));
	}
}
export function downloadNetworkRequest(params) {
	const url = Endpoints.UPLOAD_PORTAL_FILE_UPLOAD;
	const response = AxiosInstance.post(url, { params });
	return response;
}

export function* getPsychoMind360Contact(action) {
	try {
		const pramas = action.payload;
		const response = yield call(taskNetworkRequest, pramas);
		if (response.status === 200) {
			yield put(
				Actions.psychoMindContactSuccess(response.data)
			);
		} else {
			yield put(
				Actions.psychoMindContactError({
					errorCode: ERROR_TYPES.INVALID_DATA,
					message: ERROR_TYPES.INVALID_DATA,
				})
			);
		}
	} catch (error) {
		yield put(Actions.psychoMindContactError(error));
	}
}
export function taskNetworkRequest(params) {
	const url1 = Endpoints.FAIR_FAX_PORTAL_API;
	const response = AxiosInstance.post(url1, { params });
	return response;
}

export function* psychoSubmitAppointment(action) {
	try {
		const pramas = action.payload;
		const response = yield call(submitNetwork, pramas);
		if (response.status === 200) {
			yield put(
				Actions.psychoSubmitAppoinSuccess(response)
			);
		} else {
			yield put(
				Actions.psychoSubmitAppoinError({
					errorCode: ERROR_TYPES.INVALID_DATA,
					message: ERROR_TYPES.INVALID_DATA,
				})
			);
		}
	} catch (error) {
		yield put(Actions.psychoSubmitAppoinError(error));
	}
}
export function* psychlogistList(action) {
	try {
		const pramas = action.payload;
		const response = yield call(taskNetworkRequest, pramas);
		if (response.status === 200) {
			yield put(Actions.psychologistListSuccess(response.data));
		} else {
			yield put(
				Actions.psychologistListError({
					errorCode: ERROR_TYPES.INVALID_DATA,
					message: ERROR_TYPES.INVALID_DATA,
				})
			);
		}
	} catch (error) {
		yield put(Actions.psychologistListError(error));
	}
}

export function* pcbObgynSubmit(action) {
	try {
		const pramas = action.payload;
		const response = yield call(submitNetwork, pramas);
		if (response.status === 200) {
			yield put(
				Actions.pcbObgynSubmitSuccess(response)
			);
		} else {
			yield put(
				Actions.pcbObgynSubmitError({
					errorCode: ERROR_TYPES.INVALID_DATA,
					message: ERROR_TYPES.INVALID_DATA,
				})
			);
		}
	} catch (error) {
		yield put(Actions.pcbObgynSubmitError(error));
	}
}
export function submitNetwork(params){
	const url1 = Endpoints.PORTAL_POST;
	const response =  AxiosInstance.post(url1, params );
	return response;
}