import React from "react";
import styles from "./Schedular.module.scss";

const getClickableLink = link => {
    return link.startsWith("http://") || link.startsWith("https://") ?
      link
      : `http://${link}`;
  };

const BookingList = (props) => {
    const {
        checkedValue = false,
        id,
        onHandleCheckBox,
        name,
        address,
        city,
        zip,
        email,
        phone,
        regionCode ,
        hideCheckBox,
        website,
        schedulingUrl,
        schedulingIns
    } = props;

    const Address = () => {
        return (
            <>                
                <address className="mt-1 mb-0">
                    {address && <>{address}</>}
                    <br className={!address ? "d-none" : ""} />
                    {city && <>{city}, </>}
                    {regionCode  && <>{regionCode} </>}
                    {zip && <>{zip}</>}
                    <br className={!(city || regionCode || zip) ? "d-none" : ""} />
                    {email && <><a href={`mailto:${email}`}>{email}</a></>}
                    <br className={!email ? "d-none" : ""}/>
                    {phone && <>{phone}</>}
                    <br className={!email ? "d-none" : ""}/>
                    {
                        website &&
                        <a href={getClickableLink(website)}
                            target="_blank"
                            rel="noreferrer"
                            className={`position-relative d-inline-block`}>
                            {website}
                        </a>
                    }
                </address>
            </>
        );
    }

    return (
        <>
            {!hideCheckBox ?
                <div className={styles.checkbox_formElement} key={id} >
                    <label key={id}>
                        <input name="travel_driving"
                            type="radio"
                            value={id}
                            checked={checkedValue}
                            onChange={onHandleCheckBox}
                            className="float-start"
                        />

                        <div className={`${styles.checkAdddress} lh-sm`}>
                            <span className={styles.addName}>{name}</span>
                            {
                                schedulingIns &&
                                <div className={styles.schedulIns}>
                                 {schedulingIns}
                                </div>
                            }
                            <Address />                            
                            {
                                schedulingUrl &&
                                <a href={getClickableLink(schedulingUrl)}
                                    target="_blank"
                                    rel="noreferrer"
                                    className={`${styles.scheduleButton} position-relative d-inline-block px-3 mt-2`}>
                                    Schedule Online
                                </a>
                            }
                        </div>
                    </label>
                </div>
                :
                <div className={styles.address}>
                    <Address />
                </div>
            }
        </>
    );
};

export default BookingList;
