import styles from "./TravelDonor.module.scss";
import Layout from "../../components/ui/Layout";
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState, useRef } from "react";
import {  BsChevronRight } from "react-icons/bs";
import Loader from "../../components/ui/Loader";
import { useSelector, useDispatch } from "react-redux";
import * as Actions from "../state/travel/actions";
import * as LoginActions from "../state/login/actions";
import { Scrollbars } from "react-custom-scrollbars-2";
import { Endpoints } from "../../core/networking";
import { stateSource } from "../../components/core/stateFile";
import { Formik, Form, Field } from 'formik';
import DatePicker from "react-datepicker";
import { subYears } from "date-fns";
import PageTitle from "../components/PageTitle";

const TravelForm = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    let { state } = useLocation();
    const travelInputRef = useRef();
    const {
        loginData: { loginUser, rejectedDonor },
        travelData: { addTravel ,travelDetailsData },
    } = useSelector((state) => state);
    const [scrollbarHeight, setScrollBarHeight] = useState(window.screen.availHeight);
    const [taskInfo, setTaskInfo] = useState([]);
    const { response: loginVal, isLoading: loginLoading } = loginUser || {};
    const { response: addedTravel, isLoading: addTravelLoading } = addTravel || {};
    const { response: rejectedResponse, isLoading: rejectedLoading } = rejectedDonor || {};
    const { response: travelDataResponse, isLoading: travelDetailsLoading } = travelDetailsData || {};
    const [newAddress, setNewAddress] = useState(false);
    const [stateValues, setStateValues] = useState({});
    const [, setDmaStatus] = useState([]);
    const [dmaProfile, setDmaProfile] = useState([]);
    const [myTravelByValue, setMyTravelByValue] = useState("");
    const [companionTravelByValue, setCompanionTravelByValue] = useState("");
    const [companionTravelType, setCompanionTravelType] = useState("");

    useEffect(() => {
        document.title = 'Travel Form';
        dispatch(LoginActions.rejectStatusClear());
        dispatch(Actions.getTravelDetailsClear());
        setCompanionTravelByValue("");
        
        if (stateSource) {
            let sortedDates = stateSource.sort((p1, p2) => (p1.stateName > p2.stateName) ? 1 : (p1.stateName < p2.stateName) ? -1 : 0);            
            setStateValues(sortedDates);
        }
                
        setTaskInfo(location?.state?.taskInfo);
        
        const endPoint = Endpoints.PROGRESS_BAR_DATA + loginVal?.data?.applicant_id;
        let params = {
            endpoint: endPoint,
            method: "GET",
        };
        dispatch(LoginActions.rejectStatusRequest(params));

    }, []);//eslint-disable-line


    useEffect(() => {
        // set scroll window height
        const mainElement = document.getElementsByClassName("app");
        //const footer = document.querySelector(".footerElement");
        const pgTitle = document.querySelector(".homePageTitle");
        setScrollBarHeight(mainElement[0]?.offsetHeight - pgTitle?.offsetHeight); //footer?.offsetHeight - 
        window.addEventListener("resize", function () {
            setScrollBarHeight(mainElement[0]?.offsetHeight - pgTitle?.offsetHeight); //footer?.offsetHeight - 
        });
    }, [scrollbarHeight]);

    useEffect(() => {       
        if(taskInfo?.ID)
        {
            const endpPointTravelDetails = Endpoints.TRAVEL_GET_DETAILS + taskInfo?.ID;
            let profileParams = {
                endpoint: endpPointTravelDetails,
                method: "GET",
            };
            dispatch(Actions.getTravelDetails(profileParams));
        }
    }, [taskInfo]);//eslint-disable-line

    useEffect(() => {
        if (rejectedResponse) {
            setDmaStatus(rejectedResponse);
        }
        dispatch(LoginActions.rejectStatusClear());
        // eslint-disable-next-line
    }, [rejectedResponse]);

    useEffect(() => {
        if (travelDataResponse) {
            setDmaProfile(travelDataResponse);
        }
        // eslint-disable-next-line
    }, [travelDataResponse]);

    useEffect(() => {
        if (addedTravel?.status === 200) {
            dispatch(Actions.addTravelClear());
            navigate("/home");
        }
        // eslint-disable-next-line
    }, [addedTravel]);

    

    useEffect(() => {
        if(dmaProfile?.Address)
        {
            setNewAddress(false);
        }else {
            setNewAddress(true);
        }

        if (dmaProfile?.DonorTravelBy === 13) {
            //driving
            setMyTravelByValue("driving");
        } else if (dmaProfile?.DonorTravelBy === 14) {
            //flying
            setMyTravelByValue("flying");
        }

        if (dmaProfile?.CompanionTravelBy === 9) { //9
            setCompanionTravelByValue("driving");
        }
        else if (dmaProfile?.CompanionTravelBy === 10) {
            setCompanionTravelByValue("flying");
        }
        else if (dmaProfile?.CompanionTravelBy === 27) {
            setCompanionTravelByValue((dmaProfile?.DonorTravelBy === 13 ? "driving" : "flying"));
        }


    }, [dmaProfile])

    function validateEmpty(value) {
        let error;
        if (!value) {
            error = "Required";
        } else {
            error = "";
        }
        return error;
    }

    function validateEmail(value) {
        let error;
        if (!value) {
            error = 'Required';
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
            error = 'Invalid email address';
        }
        return error;
    }


    return (
        <>
            {(addTravelLoading || rejectedLoading || travelDetailsLoading || loginLoading) && <Loader />}
            <Layout showHeader="false" showFooter="false">
                <div className={styles.stickyHeader}>
                    <PageTitle
                        title="Complete Travel Form"
                        headerClass="homePageTitle"
                        linkTo={state?.prevPage ? state?.prevPage : "/home"}
                        linkState={{ menu: state?.menu }}
                    />
                </div>
                <div className={styles.travelFormContainer}>
                    <Scrollbars
                        autoHeight
                        autoHeightMax={scrollbarHeight}
                        className={`${styles.travelContainer}`}
                        ref={travelInputRef}
                    >
                        <div className={styles.formContainer}>
                            <h2 className={styles.mainHeading}>Your Travel Details</h2>
                            
                            <Formik
                                enableReinitialize={true}
                                initialValues={{
                                    legal_name: "",
                                    date_of_birth: "",
                                    street_address: dmaProfile?.Address ?? "",
                                    address2: dmaProfile?.Address2 ?? "",
                                    new_address: false,
                                    companion_travel_driving: false,
                                    companion_travel_flying: false,
                                    city: dmaProfile?.City ?? "",
                                    state: stateSource.filter(state => state.regionCode === dmaProfile?.State)[0]?.code ?? "",
                                    zip: dmaProfile?.ZipCode ?? "",
                                    email: "",
                                    phone: "",
                                    travel_entire: false,
                                    travel_retrieval: false,
                                    travel_driving: false,
                                    travel_flying: false,
                                    preferred_airport: "",
                                    emergency_name: "",
                                    emergency_phone: "",
                                    emergency_relation: "",
                                    emergency_add_notes: "",
                                    companion_name: "",
                                    companion_dob: "",
                                    companion_address: "",
                                    companion_city: "",
                                    companion_state: "",
                                    companion_zip: "",
                                    companion_email: "",
                                    companion_phone: "",
                                    companion_prefered_airport: "",
                                    companion_emergency_name: "",
                                    companion_emergency_phone: "",
                                    companion_emergency_relation: "",
                                    companion_emergency_add_notes: "",
                                    donor_relation:"",
                                    travel_companion: dmaProfile?.HasTravelCompanion
                                }}
                                onSubmit={values => {
                                    // same shape as initial values
                                    const formData = new FormData();

                                    let formValues = [];
                                    /*let TravelItineraryAnswers = [];
                                    let applicant_id = loginVal?.data?.applicant_id;
                                    let user_id = loginVal?.data?.donorID;*/


                                    if (values?.legal_name) {
                                        formValues.push(
                                            { "ItemID": 1, "ListValueID": null, "TextValue": values?.legal_name, "DateValue": null, "CheckValue": null, "OptionID": null }
                                        );
                                    }

                                    if (values?.date_of_birth) {

                                        var date = new Date(values?.date_of_birth),
                                            month = ("0" + (date.getMonth() + 1)).slice(-2),
                                            day = ("0" + date.getDate()).slice(-2),
                                            year = date.getFullYear();

                                        formValues.push(
                                            { "ItemID": 2, "ListValueID": null, "TextValue": [month, day, year].join('/'), "DateValue": null, "CheckValue": null, "OptionID": null }
                                        );
                                    }
                                    if (values?.street_address) {
                                        formValues.push(
                                            { "ItemID": 5, "ListValueID": null, "TextValue": values?.street_address, "DateValue": null, "CheckValue": null, "OptionID": null }
                                        );
                                    }

                                    if(values?.address2) {
                                        formValues.push(
                                            { "ItemID": 65, "ListValueID": null, "TextValue": values?.address2, "DateValue": null, "CheckValue": null, "OptionID": null }
                                        );
                                    }

                                    if (values?.city) {
                                        formValues.push(
                                            { "ItemID": 7, "ListValueID": null, "TextValue": values?.city, "DateValue": null, "CheckValue": null, "OptionID": null }
                                        );
                                    }

                                    if (values?.state) {
                                        formValues.push(
                                            { "ItemID": 8, "ListValueID": values?.state, "TextValue": null, "DateValue": null, "CheckValue": null, "OptionID": null }
                                        );
                                    }

                                    if (values?.zip) {
                                        formValues.push(
                                            { "ItemID": 9, "ListValueID": null, "TextValue": values?.zip, "DateValue": null, "CheckValue": null, "OptionID": null }
                                        );
                                    }

                                    if (values?.email) {
                                        formValues.push(
                                            { "ItemID": 10, "ListValueID": null, "TextValue": values?.email, "DateValue": null, "CheckValue": null, "OptionID": null }
                                        );
                                    }

                                    if (values?.phone) {
                                        formValues.push(
                                            { "ItemID": 3, "ListValueID": null, "TextValue": values?.phone, "DateValue": null, "CheckValue": null, "OptionID": null }
                                        );
                                    }
                                    
                                    if (myTravelByValue === "driving") {
                                        formValues.push(
                                            { "ItemID": 63, "ListValueID": 13, "TextValue": null, "DateValue": null, "CheckValue": null, "OptionID": null }
                                        );
                                    }

                                    if (myTravelByValue === "flying") {
                                        formValues.push(
                                            { "ItemID": 63, "ListValueID": 14, "TextValue": null, "DateValue": null, "CheckValue": null, "OptionID": null }
                                        );
                                    }

                                    if (companionTravelType === "entire") {
                                        formValues.push(
                                            { "ItemID": 79, "ListValueID": 52, "TextValue": null, "DateValue": null, "CheckValue": null, "OptionID": null }
                                        );
                                    }

                                    if (companionTravelType === "retrieval") {
                                        formValues.push(
                                            { "ItemID": 79, "ListValueID": 53, "TextValue": null, "DateValue": null, "CheckValue": null, "OptionID": null }
                                        );
                                    }

                                    if (values?.preferred_airport) {
                                        formValues.push(
                                            { "ItemID": 6, "ListValueID": null, "TextValue": values?.preferred_airport, "DateValue": null, "CheckValue": null, "OptionID": null }
                                        );
                                    }

                                    if (values?.emergency_name) {
                                        formValues.push(
                                            { "ItemID": 11, "ListValueID": null, "TextValue": values?.emergency_name, "DateValue": null, "CheckValue": null, "OptionID": null }
                                        );
                                    }

                                    if (values?.emergency_phone) {
                                        formValues.push(
                                            { "ItemID": 12, "ListValueID": null, "TextValue": values?.emergency_phone, "DateValue": null, "CheckValue": null, "OptionID": null }
                                        );
                                    }
                                    
                                    if (values?.emergency_relation) {
                                        formValues.push(
                                            { "ItemID": 13, "ListValueID": values?.emergency_relation, "TextValue": null, "DateValue": null, "CheckValue": null, "OptionID": null }
                                        );
                                    }

                                    if (values?.emergency_add_notes) {
                                        formValues.push(
                                            { "ItemID": 14, "ListValueID": null, "TextValue": values?.emergency_add_notes, "DateValue": null, "CheckValue": null, "OptionID": null }
                                        );
                                    }
                                    
                                    if (values?.companion_name) {
                                        formValues.push(
                                            { "ItemID": 29, "ListValueID": null, "TextValue": values?.companion_name, "DateValue": null, "CheckValue": null, "OptionID": null }
                                        );
                                    }

                                    if (values?.companion_dob) {
                                        var cdate = new Date(values?.companion_dob),
                                            cmonth = ("0" + (cdate.getMonth() + 1)).slice(-2),
                                            cday = ("0" + cdate.getDate()).slice(-2),
                                            cyear = cdate.getFullYear();
                                        formValues.push(
                                            { "ItemID": 30, "ListValueID": null, "TextValue": [cmonth, cday, cyear].join('/'), "DateValue": null, "CheckValue": null, "OptionID": null }
                                        );
                                    }

                                    if (values?.companion_address) { // missing
                                        formValues.push(
                                            { "ItemID": 70, "ListValueID": null, "TextValue": values?.companion_address, "DateValue": null, "CheckValue": null, "OptionID": null }
                                        );
                                    }

                                    if (values?.companion_city) {
                                        formValues.push(
                                            { "ItemID": 33, "ListValueID": null, "TextValue": values?.companion_city, "DateValue": null, "CheckValue": null, "OptionID": null }
                                        );
                                    }

                                    if (values?.companion_state) {
                                        formValues.push(
                                            { "ItemID": 34, "ListValueID": values?.companion_state, "TextValue": null, "DateValue": null, "CheckValue": null, "OptionID": null }
                                        );
                                    }

                                    if (values?.companion_zip) { // missing
                                        formValues.push(
                                            { "ItemID": 71, "ListValueID": null, "TextValue": values?.companion_zip, "DateValue": null, "CheckValue": null, "OptionID": null }
                                        );
                                    }

                                    if (values?.companion_email) { //missing
                                        formValues.push(
                                            { "ItemID": 72, "ListValueID": null, "TextValue": values?.companion_email, "DateValue": null, "CheckValue": null, "OptionID": null }
                                        );
                                    }

                                    if (values?.companion_phone) {
                                        formValues.push(
                                            { "ItemID": 31, "ListValueID": null, "TextValue": values?.companion_phone, "DateValue": null, "CheckValue": null, "OptionID": null }
                                        );
                                    }

                                    if (companionTravelByValue === "driving") {
                                        formValues.push(
                                            { "ItemID": 36, "ListValueID": 9, "TextValue": null, "DateValue": null, "CheckValue": null, "OptionID": null }
                                        );
                                    }

                                    if (companionTravelByValue === "flying") {
                                        formValues.push(
                                            { "ItemID": 36, "ListValueID": 10, "TextValue": null, "DateValue": null, "CheckValue": null, "OptionID": null }
                                        );
                                    }

                                    if (values?.companion_prefered_airport) {
                                        formValues.push(
                                            { "ItemID": 37, "ListValueID": null, "TextValue": values?.companion_prefered_airport, "DateValue": null, "CheckValue": null, "OptionID": null }
                                        );
                                    }

                                    if (values?.companion_emergency_name) {
                                        formValues.push(
                                            { "ItemID": 38, "ListValueID": null, "TextValue": values?.companion_emergency_name, "DateValue": null, "CheckValue": null, "OptionID": null }
                                        );
                                    }

                                    if (values?.companion_emergency_phone) {
                                        formValues.push(
                                            { "ItemID": 39, "ListValueID": null, "TextValue": values?.companion_emergency_phone, "DateValue": null, "CheckValue": null, "OptionID": null }
                                        );
                                    }

                                    if (values?.companion_emergency_relation) {
                                        formValues.push(
                                            { "ItemID": 40, "ListValueID": values?.companion_emergency_relation, "TextValue": null, "DateValue": null, "CheckValue": null, "OptionID": null }
                                        );
                                    }

                                    if (values?.companion_emergency_add_notes) {
                                        formValues.push(
                                            { "ItemID": 35, "ListValueID": null, "TextValue": values?.companion_emergency_add_notes, "DateValue": null, "CheckValue": null, "OptionID": null }
                                        );
                                    }

                                    if (values?.donor_relation) {
                                        formValues.push(
                                            { "ItemID": 32, "ListValueID": values?.donor_relation, "TextValue": null, "DateValue": null, "CheckValue": null, "OptionID": null }
                                        );
                                    }

                                    
                                   // return false;
                                    //formData.append("TaskID", taskInfo?.ID);
                                    //formData.append("TravelItineraryID", taskInfo?.TravelItineraryID);
                                    formData.append("TravelItineraryAnswers", JSON.stringify(formValues));
                                    formData.append("method","JSON")
                                    formData.append("endpoint", Endpoints.TRAVEL_ADD_DATA + taskInfo?.ID + "/" + taskInfo?.TravelItineraryID);
                                    
                                    dispatch(Actions.addTravelRequest(formData));
                                }}
                            >

                                {({ errors, touched, validateField, validateForm, values }) => (
                                    <Form>
                                        <div className={styles.formElement}>
                                            <div>
                                                <label>
                                                    <label>Full Legal Name <span className={styles.colorRed}>*&nbsp;</span>
                                                        <span>(as stated on your ID):</span></label>
                                                </label>
                                                <Field name="legal_name" validate={validateEmpty} className={styles.controlElement} />
                                                {errors.legal_name && touched.legal_name && <span className={styles.formError}>{errors.legal_name}</span>}
                                            </div>
                                        </div>
                                        <div className={styles.formElement}>
                                            <div>
                                                <label>Date of Birth <span className={styles.colorRed}>*&nbsp;</span>
                                                    <span>(as stated on your ID):</span></label>
                                                <Field name="date_of_birth"
                                                    validate={validateEmpty}>
                                                    {({ field, form }) => (
                                                        <div style={{ textAlign: "center" }}>
                                                            <DatePicker
                                                                name="date_of_birth"
                                                                autoComplete='off'
                                                                showIcon
                                                                peekNextMonth
                                                                showMonthDropdown
                                                                showYearDropdown
                                                                dropdownMode="select"
                                                                className={styles.controlElement}
                                                                selected={field.value}
                                                                dateFormat="MM/dd/yyyy"
                                                                maxDate={subYears(new Date(), 18)}
                                                                onChange={date_of_birth => form.setFieldValue(field.name, date_of_birth)}
                                                                calendarClassName="clsCalendarClsName"
                                                            />
                                                        </div>
                                                    )}
                                                </Field>
                                                {errors.date_of_birth && touched.date_of_birth && <span className={styles.formError}>{errors.date_of_birth}</span>}
                                            </div>
                                        </div>
                                        <div className={`${styles.formElement}`}>
                                            <div className={`${styles.DonorCheckbox}`}>
                                                <label style={{ margin: "10px 0px" }}>
                                                    <Field type="checkbox"
                                                        name="new_address"
                                                        style={{ margin: "0px" }}
                                                        className={styles.checkboxControl}
                                                        checked={newAddress === true ? "checked" : ""}
                                                        onChange={() => setNewAddress(!newAddress)}
                                                    />
                                                    &nbsp;&nbsp;My address has changed
                                                </label>
                                            </div>
                                        </div>
                                        {
                                            newAddress === true ?
                                            <>
                                                <div className={styles.formElement}>
                                                    <div>
                                                        <label>Street Address <span className={styles.colorRed}>*&nbsp;</span></label>
                                                        <Field type="text" name="street_address" readOnly={!newAddress} validate={validateEmpty} className={styles.controlElement} />
                                                        {errors.street_address && touched.street_address && <span className={styles.formError}>{errors.street_address}</span>}
                                                    </div>
                                                </div>
                                                <div className={styles.formElement}>
                                                    <div>
                                                        <label>Address2</label>
                                                        <Field type="text" name="address2" readOnly={!newAddress} className={styles.controlElement} />
                                                    </div>
                                                </div>
                                                <div className={styles.formElement}>
                                                    <div>
                                                        <label>City <span className={styles.colorRed}>*&nbsp;</span></label>
                                                        <Field type="text" name="city" readOnly={!newAddress} validate={validateEmpty} className={styles.controlElement} />
                                                        {errors.city && touched.city && <span className={styles.formError}>{errors.city}</span>}
                                                    </div>
                                                </div>

                                                <div className={styles.formElement}>
                                                    <div className={styles.divBighalf}>
                                                        <label>State <span className={styles.colorRed}>*&nbsp;</span></label>
                                                        <Field as="select" name="state" disabled={!newAddress} validate={validateEmpty} className={styles.selectElement}>
                                                            <option></option>
                                                            {
                                                                stateValues.length > 0 && stateValues.map(({ code, stateName }) => {
                                                                    return <option key={code} value={code}>{stateName}</option>
                                                                })
                                                            }
                                                        </Field>
                                                        {errors.state && touched.state && <span className={styles.formError}>{errors.state}</span>}
                                                    </div>
                                                    <div className={styles.divSmallhalf}>
                                                        <label> Zip <span className={styles.colorRed}>*&nbsp;</span></label>
                                                        <Field type="text" name="zip" readOnly={!newAddress} validate={validateEmpty} className={styles.controlElement} />
                                                        {errors.zip && touched.zip && <span className={styles.formError}>{errors.zip}</span>}
                                                    </div>
                                                </div>
                                            </>
                                            :
                                            <>
                                                <p className={`mb-2`} style={{fontWeight:"bold",color:"#565656"}}>My Address:</p>
                                                {dmaProfile?.Address && <p className={`mb-1`}>{dmaProfile?.Address}</p>}
                                                {dmaProfile?.Address2 && <p className={`mb-1`}>{dmaProfile?.Address2}</p>}
                                                <p className={`mb-1`}>
                                                    {dmaProfile?.City}, {dmaProfile?.State} {dmaProfile?.ZipCode}
                                                </p>
                                                <div style={{display:"none"}}>
                                                    <Field type="text" name="street_address" className={styles.controlElement} />
                                                    <Field type="text" name="address2" className={styles.controlElement} />
                                                    <Field type="text" name="city" className={styles.controlElement} />
                                                    <Field type="text" name="state"  className={styles.controlElement} />
                                                    <Field type="text" name="zip"  className={styles.controlElement} />
                                                </div>
                                            </>
                                        }
                                        

                                        <div className={styles.formElement}>
                                            <div>
                                                <label>Email <span className={styles.colorRed}>*&nbsp;</span></label>
                                                <Field type="text" name="email" validate={validateEmail} className={styles.controlElement} />
                                                {errors.email && touched.email && <span className={styles.formError}>{errors.email}</span>}
                                            </div>
                                        </div>
                                        <div className={styles.formElement}>
                                            <div>
                                                <label>Phone <span className={styles.colorRed}>*&nbsp;</span></label>
                                                <Field type="text" name="phone" validate={validateEmpty} className={styles.controlElement} />
                                                {errors.phone && touched.phone && <span className={styles.formError}>{errors.phone}</span>}
                                            </div>
                                        </div>

                                        <div className={`${styles.formElement} ${styles.DonorCheckbox}`} style={{ margin: "20px 0px 10px",lineHeight:"20px" }}>
                                            <span className={styles.subHeading} style={{ marginBottom: "5px", float: "left" }}>Travel By</span>
                                            <div style={{float:"left"} }>
                                                <label className={styles.roundedCheckbox} style={{ marginBottom: "5px" }}>
                                                    <Field
                                                        type="radio"
                                                        name="travel_driving"
                                                        checked={myTravelByValue === 'driving' ? "checked" : ""}
                                                        //onChange={() => setMyTravelByValue('driving')}
                                                        onChange={(dmaProfile?.DonorTravelBy === 0 ? () => setMyTravelByValue('driving') : () => null)}
                                                        className={styles.checkboxControl}
                                                    />
                                                    &nbsp;&nbsp;Driving
                                                </label>
                                                <label className={styles.roundedCheckbox} style={{ marginBottom: "5px" }}>
                                                    <Field
                                                        type="radio"
                                                        name="travel_flying"
                                                        checked={myTravelByValue === 'flying' ? "checked" : ""}
                                                        //onChange={() => setMyTravelByValue('flying')}
                                                        onChange={(dmaProfile?.DonorTravelBy === 0 ? () => setMyTravelByValue('flying') : () => null)}
                                                        className={styles.checkboxControl}
                                                    />
                                                    &nbsp;&nbsp;Flying
                                                </label>
                                            </div>
                                        </div>
                                        {myTravelByValue === 'flying' &&
                                            <div className={styles.formElement}>
                                                <div>
                                                    <label>Preferred Airport <span className={styles.colorRed}>*&nbsp;</span><br />
                                                        <span>(They may travel out of another airport if needed)</span>
                                                    </label>
                                                    <Field type="text" name="preferred_airport" validate={validateEmpty} className={styles.controlElement} />
                                                    {errors.preferred_airport && touched.preferred_airport && <span className={styles.formError}>{errors.preferred_airport}</span>}
                                                </div>
                                            </div>
                                        }
                                        
                                        <div className={`${styles.formElement} ${styles.DonorCheckbox}`} style={{ margin: "20px 0px 10px", lineHeight: "20px" }}>
                                            <span className={styles.subHeading} style={{ width: "100%", display: "inline-block", marginBottom: "10px" }}>
                                                Please indicate if your travel companion is traveling the entire time with you (the donor) or just coming for the retrieval?
                                            </span>                                            
                                            <label className={styles.roundedCheckbox} style={{ marginBottom: "5px" }}>
                                                <Field
                                                    type="radio"
                                                    name="travel_entire"
                                                    checked={companionTravelType === 'entire' ? "checked" : ""}
                                                    onChange={() => setCompanionTravelType('entire')}
                                                    className={styles.checkboxControl}
                                                />
                                                Entire Time
                                            </label>
                                            &nbsp;&nbsp;&nbsp;&nbsp;
                                            <label className={styles.roundedCheckbox} style={{ marginBottom: "5px" }}>
                                                <Field
                                                    type="radio"
                                                    name="travel_retrieval"
                                                    checked={companionTravelType === 'retrieval' ? "checked" : ""}
                                                    onChange={() => setCompanionTravelType('retrieval')}
                                                    className={styles.checkboxControl}
                                                />
                                                Just for Egg Retrieval</label>
                                        </div>
                                        
                                        <br />
                                        <br />
                                        <h3 className={styles.subHeading}>Your Emergency Contact</h3>
                                        <div className={styles.formElement}>
                                            <div>
                                                <label>Name <span className={styles.colorRed}>*&nbsp;</span></label>
                                                <Field type="text" name="emergency_name" validate={validateEmpty} className={styles.controlElement} />
                                                {errors.emergency_name && touched.emergency_name && <span className={styles.formError}>{errors.emergency_name}</span>}
                                            </div>
                                        </div>
                                        <div className={styles.formElement}>
                                            <div>
                                                <label>Phone <span className={styles.colorRed}>*&nbsp;</span></label>
                                                <Field type="text" name="emergency_phone" validate={validateEmpty} className={styles.controlElement} />
                                                {errors.emergency_phone && touched.emergency_phone && <span className={styles.formError}>{errors.emergency_phone}</span>}
                                            </div>
                                        </div>
                                        <div className={styles.formElement}>
                                            <div>
                                                <label>Relation to You <span className={styles.colorRed}>*&nbsp;</span></label>
                                                <Field as="select" name="emergency_relation" validate={validateEmpty} className={styles.selectElement}>
                                                    <option></option>
                                                    <option value="1">Parent</option>
                                                    <option value="2">GrandParent</option>
                                                    <option value="3">Sibling</option>
                                                    <option value="4">Spouse</option>
                                                    <option value="5">Partner</option>
                                                    <option value="6">Friend</option>
                                                    <option value="54">Other Family Member</option>

                                                </Field>
                                                {errors.emergency_relation && touched.emergency_relation && <span className={styles.formError}>{errors.emergency_relation}</span>}
                                            </div>
                                        </div>
                                        <div className={styles.formElement}>
                                            <div>
                                                <label>Additional Notes</label>
                                                <Field component="textarea" name="emergency_add_notes" className={styles.controlElement} />
                                            </div>
                                        </div>
                                        {
                                            (dmaProfile?.HasTravelCompanion === true)  &&
                                            <>
                                                <div className={`${styles.formElement}`}>
                                                    <div className={`${styles.DonorCheckbox}`}>
                                                        <label style={{ margin: "10px 0px" }}>
                                                            <Field type="checkbox"
                                                                name="travel_companion"
                                                                onChange={() => null}
                                                                className={styles.checkboxControl}
                                                                style={{ marginLeft: "0px" }} />
                                                            &nbsp;&nbsp;Travel Companion
                                                        </label>
                                                    </div>
                                                </div>
                                                {
                                                    values.travel_companion &&
                                                    <>
                                                        <h2 className={styles.mainHeading} style={{ marginTop: "10px" }}>Travel Companion Details</h2>
                                                        <div className={styles.formElement}>
                                                            <div>
                                                                <label>
                                                                    <label>Full Legal Name <span className={styles.colorRed}>*&nbsp;</span></label>
                                                                    <span>(as stated on your ID):</span>
                                                                </label>
                                                                <Field type="text" name="companion_name" validate={validateEmpty} className={styles.controlElement} />
                                                                {errors.companion_name && touched.companion_name && <span className={styles.formError}>{errors.companion_name}</span>}
                                                            </div>
                                                        </div>
                                                        <div className={styles.formElement}>
                                                            <div>
                                                                <label>Date of Birth <span className={styles.colorRed}>*&nbsp;</span>
                                                                    <span>(as stated on your ID):</span></label>
                                                                    <Field name="companion_dob"
                                                                        validate={validateEmpty}>
                                                                        {({ field, form }) => (
                                                                            <div style={{ textAlign: "center" }}>
                                                                                <DatePicker
                                                                                    autoComplete='off'
                                                                                    showIcon
                                                                                    peekNextMonth
                                                                                    showMonthDropdown
                                                                                    showYearDropdown
                                                                                    dropdownMode="select"
                                                                                    className={styles.controlElement}
                                                                                    selected={field.value}
                                                                                    dateFormat="MM/dd/yyyy"
                                                                                    maxDate={subYears(new Date(), 18)}
                                                                                    onChange={date_of_birth => form.setFieldValue(field.name, date_of_birth)}
                                                                                    calendarClassName="clsCalendarClsName"
                                                                                />
                                                                            </div>
                                                                        )}
                                                                    </Field>
                                                                {/*<Field type="text" name="companion_dob" validate={validateEmpty} className={styles.controlElement} />*/}
                                                                {errors.companion_dob && touched.companion_dob && <span className={styles.formError}>{errors.companion_dob}</span>}
                                                            </div>
                                                        </div>
                                                        <div className={styles.formElement}>
                                                            <div>
                                                                <label>Street Address <span className={styles.colorRed}>*&nbsp;</span></label>
                                                                <Field type="text" name="companion_address" validate={validateEmpty} className={styles.controlElement} />
                                                                {errors.companion_address && touched.companion_address && <span className={styles.formError}>{errors.companion_address}</span>}
                                                            </div>
                                                        </div>
                                                        <div className={styles.formElement}>
                                                            <div>
                                                                <label>City <span className={styles.colorRed}>*&nbsp;</span></label>
                                                                <Field type="text" name="companion_city" validate={validateEmpty} className={styles.controlElement} />
                                                                {errors.companion_city && touched.companion_city && <span className={styles.formError}>{errors.companion_city}</span>}
                                                            </div>
                                                        </div>
                                                        <div className={styles.formElement}>
                                                            <div className={styles.divBighalf}>
                                                                <label>State <span className={styles.colorRed}>*&nbsp;</span></label>

                                                                <Field as="select" name="companion_state" validate={validateEmpty} className={styles.selectElement}>
                                                                    <option></option>
                                                                    {
                                                                        stateValues.length > 0 && stateValues.map(({ code, stateName }) => {
                                                                            return <option key={code} value={code}>{stateName}</option>
                                                                        })
                                                                    }
                                                                </Field>
                                                                {errors.companion_state && touched.companion_state && <span className={styles.formError}>{errors.companion_state}</span>}
                                                            </div>
                                                            <div className={styles.divSmallhalf}>
                                                                <label> Zip <span className={styles.colorRed}>*&nbsp;</span></label>
                                                                <Field type="text" name="companion_zip" validate={validateEmpty} className={styles.controlElement} />
                                                                {errors.companion_zip && touched.companion_zip && <span className={styles.formError}>{errors.companion_zip}</span>}
                                                            </div>
                                                        </div>

                                                        <div className={styles.formElement}>
                                                            <div>
                                                                <label>Email <span className={styles.colorRed}>*&nbsp;</span></label>
                                                                <Field type="text" name="companion_email" validate={validateEmail} className={styles.controlElement} />
                                                                {errors.companion_email && touched.companion_email && <span className={styles.formError}>{errors.companion_email}</span>}
                                                            </div>
                                                        </div>

                                                        <div className={styles.formElement}>
                                                            <div>
                                                                <label>Phone <span className={styles.colorRed}>*&nbsp;</span></label>
                                                                <Field type="text" name="companion_phone" validate={validateEmpty} className={styles.controlElement} />
                                                                {errors.companion_phone && touched.companion_phone && <span className={styles.formError}>{errors.companion_phone}</span>}
                                                            </div>
                                                        </div>

                                                        <div className={styles.formElement}>
                                                            <div>
                                                                <label>Relation to Donor <span className={styles.colorRed}>*&nbsp;</span></label>
                                                                <Field as="select" name="donor_relation" validate={validateEmpty} className={styles.selectElement}>
                                                                    <option></option>
                                                                    <option value="15">Parent</option>
                                                                    <option value="16">GrandParent</option>
                                                                    <option value="17">Sibling</option>
                                                                    <option value="18">Spouse</option>
                                                                    <option value="19">Partner</option>
                                                                    <option value="20">Friend</option>
                                                                    <option value="55">Other Family Member</option>
                                                                </Field>
                                                                {errors.donor_relation && touched.donor_relation && <span className={styles.formError}>{errors.donor_relation}</span>}
                                                            </div>
                                                        </div>

                                                        <div className={`${styles.formElement} ${styles.DonorCheckbox}`} style={{ margin: "20px 0px 10px" }}>
                                                            <span className={styles.subHeading} style={{ marginBottom: "5px", float: "left" }}>Travel By</span>
                                                            <div style={{ float: "left" }}>
                                                                <label className={styles.roundedCheckbox} style={{ marginBottom: "5px" }}>
                                                                    <Field
                                                                        type="radio"
                                                                        name="companion_travel_driving"
                                                                        checked={companionTravelByValue === 'driving' ? "checked" : ""}
                                                                        onChange={(dmaProfile?.CompanionTravelBy === 0 ? () => setCompanionTravelByValue('driving') : () => null)}
                                                                        //onChange={() => setCompanionTravelByValue('driving')}
                                                                        className={styles.checkboxControl}
                                                                    />
                                                                 &nbsp;&nbsp;Driving</label>
                                                                &nbsp;&nbsp;&nbsp;&nbsp;
                                                                <label className={styles.roundedCheckbox} style={{ marginBottom: "5px" }}>
                                                                    <Field
                                                                        type="radio"
                                                                        name="companion_travel_flying"
                                                                        className={styles.checkboxControl}
                                                                        checked={companionTravelByValue === 'flying' ? "checked" : ""}
                                                                        onChange={(dmaProfile?.CompanionTravelBy === 0 ? () => setCompanionTravelByValue('flying') : () => null)}
                                                                       // onChange={() => setCompanionTravelByValue('flying')}
                                                                    />
                                                                    &nbsp;&nbsp;Flying</label>
                                                            </div>
                                                        </div>
                                                        {
                                                            companionTravelByValue === 'flying' &&
                                                            <div className={styles.formElement}>
                                                                <div>
                                                                    <label>Preferred Airport <span className={styles.colorRed}>*&nbsp;</span><br />
                                                                        <span>(They may travel out of another airport if needed)</span>
                                                                    </label>
                                                                    <Field type="text" name="companion_prefered_airport" validate={validateEmpty} className={styles.controlElement} />
                                                                    {errors.companion_prefered_airport && touched.companion_prefered_airport && <span className={styles.formError}>{errors.companion_prefered_airport}</span>}

                                                                </div>
                                                            </div>
                                                        }
                                                        <br />
                                                        <br />
                                                        <h3 className={styles.subHeading}>Travel Companion's Emergency Contact</h3>
                                                        <div className={styles.formElement}>
                                                            <div>
                                                                <label>Name <span className={styles.colorRed}>*&nbsp;</span></label>
                                                                <Field type="text" name="companion_emergency_name" validate={validateEmpty} className={styles.controlElement} />
                                                                {errors.companion_emergency_name && touched.companion_emergency_name && <span className={styles.formError}>{errors.companion_emergency_name}</span>}
                                                            </div>
                                                        </div>
                                                        <div className={styles.formElement}>
                                                            <div>
                                                                <label>Phone <span className={styles.colorRed}>*&nbsp;</span></label>
                                                                <Field type="text" name="companion_emergency_phone" validate={validateEmpty} className={styles.controlElement} />
                                                                {errors.companion_emergency_phone && touched.companion_emergency_phone && <span className={styles.formError}>{errors.companion_emergency_phone}</span>}
                                                            </div>
                                                        </div>
                                                        <div className={styles.formElement}>
                                                            <div>
                                                                <label>Relation to Companion <span className={styles.colorRed}>*&nbsp;</span></label>
                                                                <Field as="select" name="companion_emergency_relation" validate={validateEmpty} className={styles.selectElement}>
                                                                    <option></option>
                                                                    <option value="21">Parent</option>
                                                                    <option value="22">GrandParent</option>
                                                                    <option value="23">Sibling</option>
                                                                    <option value="24">Spouse</option>
                                                                    <option value="25">Partner</option>
                                                                    <option value="26">Friend</option>
                                                                    <option value="56">Other Family Member</option>
                                                                </Field>
                                                                {/*<Field type="text" name="companion_emergency_relation" validate={validateEmpty} className={styles.controlElement} />*/}
                                                                {errors.companion_emergency_relation && touched.companion_emergency_relation && <span className={styles.formError}>{errors.companion_emergency_relation}</span>}
                                                            </div>
                                                        </div>

                                                        <div className={styles.formElement}>
                                                            <div>
                                                                <label>Additional Notes</label>
                                                                <Field component="textarea" name="companion_emergency_add_notes" className={styles.controlElement} />
                                                            </div>
                                                        </div>
                                                    </>
                                                }
                                                
                                            </>
                                        }
                                        <p style={{ marginTop: "20px", marginBottom: "0px" }}>
                                            <span style={{ color: "red", fontSize: "12px" }}>* Required</span>
                                        </p>
                                        <div className={`text-center `}>
                                            <button type="submit" className={`${styles.travelSubmit} position-relative`}>
                                                <span>Submit</span>
                                                <BsChevronRight className={styles.buttonIcon} />
                                            </button>
                                        </div>
                                        {/*
                                        <button
                                            type="button"
                                            onClick={() => validateForm().then(() => console.log('blah'))}
                                        >
                                            Validate All
                                        </button>
                                        <button type="submit">Submit</button>
                                        */ }
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </Scrollbars>
                </div>
            </Layout>
        </>
    );
}

export default TravelForm;
