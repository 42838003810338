import { call, put, takeLatest } from "redux-saga/effects";
import { Endpoints } from '../../../core/networking';
import * as Constants from "./constants";
import * as Actions from "./actions";
import AxiosInstance from "../../../services/AxiosInstance";
import { ERROR_TYPES } from '../types';


export function* watchHomeSaga() {
  yield takeLatest(Constants.HOME_TASK_FETCH_REQUEST, homeTaskFetchRequest);
  yield takeLatest(Constants.HOME_TASK_SEND_TO_APPROVE_REQUEST, sendApproveRequest);
  yield takeLatest(Constants.HOME_PROGRESS_GET_DATA, fetchProgressBarData);
  yield takeLatest(Constants.HOME_TASK_IFRAME_FETCH_REQUEST, homeIframeTaskFetchRequest);
  yield takeLatest(Constants.NOTIFICATION_GET_DATA, notificationFetchRequest);
  yield takeLatest(Constants.NOTIFICATION_ACKNOWLEDGE_REQUEST, notificationAcknowledgeRequest);
}

export function* notificationFetchRequest(action)
{
  try {
    const pramas = action.payload;
    const response = yield call(taskNetworkRequest, pramas);
    if (response.status === 200) {
      yield put(Actions.notificationInfoSuccess(response.data));
    } else {
      yield put(
        Actions.notificationInfoError({
          errorCode:ERROR_TYPES.INVALID_DATA,
          message: ERROR_TYPES.INVALID_DATA,
        })
      );
    }
  } catch (error) {
    // const errorObj = getErrorDetails(error, action);
    yield put(Actions.notificationInfoError(error));
  }
}

export function* homeTaskFetchRequest(action) {
  try {
      const pramas = action.payload;
    const response = yield call(taskNetworkRequest, pramas);
    if (response.status === 200) {
      yield put(Actions.taskFetchSuccess(response.data));
    } else {
      yield put(
        Actions.taskFetchError({
          errorCode:ERROR_TYPES.INVALID_DATA,
          message: ERROR_TYPES.INVALID_DATA,
        })
      );
    }
  } catch (error) {
    // const errorObj = getErrorDetails(error, action);
    yield put(Actions.taskFetchError(error));
  }
}

export function* homeIframeTaskFetchRequest(action) {
  try {
    const pramas = action.payload;
    const response = yield call(taskIframeStatus, pramas);
    if (response.status === 200) {
      yield put(Actions.iframeTaskStatusSucess(response.data));
    } else {
      yield put(
        Actions.iframeTasksStatusError({
          errorCode:ERROR_TYPES.INVALID_DATA,
          message: ERROR_TYPES.INVALID_DATA,
        })
      );
    }
  } catch (error) {
    // const errorObj = getErrorDetails(error, action);
    yield put(Actions.iframeTasksStatusError(error));
  }
}


export function* sendApproveRequest(action) {
  try {
    const pramas = action.payload;
      const response = yield call(taskNetworkRequest, pramas);
    if (response.status === 200) {
      yield put(Actions.sendToApproveSuccess(response.data));
    } else {
      yield put(
        Actions.sendToApproveError({
          errorCode: ERROR_TYPES.INVALID_DATA,
          message: ERROR_TYPES.INVALID_DATA,
        })
      );
    }
  } catch (error) {
    yield put(Actions.sendToApproveError(error));
  }
}

export function* fetchProgressBarData(action) {
  try {
    const pramas = action.payload;
    const response = yield call(taskNetworkRequest, pramas);
    if (response.status === 200) {
      yield put(Actions.progressBarSuccessData(response.data));
    } else {
      yield put(
        Actions.taskFetchError({
          errorCode:ERROR_TYPES.INVALID_DATA,
          message: ERROR_TYPES.INVALID_DATA,
        })
      );
    }
  } catch (error) {
    // const errorObj = getErrorDetails(error, action);
    yield put(Actions.progressBarErrorData(error));
  }
}

export function taskNetworkRequest(params) {
    return AxiosInstance.post(Endpoints.FAIR_FAX_PORTAL_API, {params});
}

export function taskIframeStatus(params){
  const url1 = Endpoints.FAIR_FAX_TASK_IFRAME_STATUS;
  const response =  AxiosInstance.post(url1, { params });
  return response;
}

export function* notificationAcknowledgeRequest(action)
{
    try {
        const params = action.payload;
        const response = yield call(taskNetworkRequest, params);
        if (response.status === 200) {
            yield put(Actions.notificationAcknowledgeSuccess(response.data));
        } else {
            yield put(
                Actions.notificationAcknowledgeError({
                    errorCode:ERROR_TYPES.INVALID_DATA,
                    message: ERROR_TYPES.INVALID_DATA,
                })
            );
        }
    } catch (error) {
        yield put(Actions.notificationAcknowledgeError(error));
    }
}
