import * as Constants from './constants';

export const calculationRequest = (data) => ({
    type: Constants.BMI_CALCULATOER_REQUEST,
    payload: data,
});

export const calculationSuccess = (data) => ({
    type: Constants.BMI_CALCULATOER_SUCCESS,
    payload: data,
});

export const calculationError = (error) => ({
    type: Constants.BMI_CALCULATOER_ERROR,
    payload: error,
});

export const calculationClear = () => ({
    type: Constants.BMI_CALCULATOER_CLEAR,
});