import * as Constants from './constants';

export const loginUserRequest = (data) => ({
    type: Constants.LOGIN_USER_REQUEST,
    payload: data,
});

export const loginUserSuccess = (data) => ({
    type: Constants.LOGIN_USER_SUCCESS,
    payload: data,
});
export const loginUserError = (error) => ({
    type: Constants.LOGIN_USER_ERROR,
    payload: error,
});

export const loginUserClear = () => ({
    type: Constants.LOGIN_USER_CLEAR,
});

export const loginReset = () => ({
    type: Constants.LOGIN_RESET_REQUEST,
});

/* forget password screen  */
export const verifiedPinRequest = (data) => ({
    type: Constants.VERIFIED_PIN_REQUEST,
    payload: data,
});

export const verifiedPinSuccess = (data) => ({
    type: Constants.VERIFIED_PIN_SUCCESS,
    payload: data,
});

export const verifiedPinError = (error) => ({
    type: Constants.VERIFIED_PIN_ERROR,
    payload: error,
});

export const verifiedPinClear = () => ({
    type: Constants.VERIFIED_PIN_CLEAR,
});

export const updatePassRequest = (data) => ({
    type: Constants.UPDATE_PASSWORD_REQUEST,
    payload: data,
});

export const updatePassSuccess = (data) => ({
    type: Constants.UPDATE_PASSWORD_SUCCESS,
    payload: data,
});

export const updatePassError = (error) => ({
    type: Constants.UPDATE_PASSWORD_ERROR,
    payload: error,
});

export const updatePassClear = () => ({
    type: Constants.UPDATE_PASSWORD_CLEAR,
});

export const validateEmailRequest = (data) => ({
    type: Constants.VALIDATE_MAIL_REQUEST,
    payload: data,
});

export const validateEmailSuccess = (data) => ({
    type: Constants.VALIDATE_MAIL_SUCCESS,
    payload: data,
});

export const validateEmailError = (error) => ({
    type: Constants.VALIDATE_MAIL_ERROR,
    payload: error,
});

export const validateEmailClear = () => ({
    type: Constants.VALIDATE_MAIL_CLEAR,
});
export const forgetPasswordReset = () => ({
    type: Constants.UPDATED_PASSWORD_RESET,
});


export const rejectStatusRequest = (data) => ({
    type: Constants.REJECT_STATUS_REQUEST,
    payload: data,
});

export const rejectStatusSuccess = (data) => ({
    type: Constants.REJECT_STATUS_SUCCESS,
    payload: data,
});
export const rejectStatusError = (error) => ({
    type: Constants.REJECT_STATUS_ERROR,
    payload: error,
});

export const rejectStatusClear = () => ({
    type: Constants.REJECT_STATUS_CLEAR,
});
