import { takeLatest, put, call } from "redux-saga/effects";
import {Endpoints} from '../../../core/networking';
import * as Constants from "./constants";
import * as Actions from "./actions";
import AxiosInstance from "../../../services/AxiosInstance";
import {ERROR_TYPES} from '../types';

export function* watchCalculatorSaga() {
  yield takeLatest(Constants.BMI_CALCULATOER_REQUEST, calculatorRequest);
}

export function* calculatorRequest(action) {
  try {
    const pramas = action.payload;
    const response = yield call(calculatorNetwork, pramas);
    if (response.status === 200) {
      yield put(Actions.calculationSuccess(response.data));
    } else {
      yield put(
        Actions.calculationError({
          errorCode:ERROR_TYPES.INVALID_DATA,
          message: ERROR_TYPES.INVALID_DATA,
        })
      );
    }
  } catch (error) {
    yield put(Actions.calculationError(error));
  }
}
export function calculatorNetwork(params){
  const url1 = Endpoints.FAIR_FAX_PORTAL_API;
  const response =  AxiosInstance.post(url1, {params});
  return response;
}