
import { combineReducers } from "redux";
import { getBasicReducer } from "../../../core/utils";
import * as Constants from "./constants";

export const schedularData = combineReducers({
    slotList: getBasicReducer({
        request: Constants.ACUITY_SLOTS_GET_LIST_REQUEST,
        success: Constants.ACUITY_SLOTS_GET_LIST_SUCCESS,
        failure: Constants.ACUITY_SLOTS_GET_LIST_ERROR,
        clear: Constants.ACUITY_SLOTS_GET_LIST_CLEAR,
    }),
    daysList: getBasicReducer({
        request: Constants.ACUITY_DAYS_GET_LIST_REQUEST,
        success: Constants.ACUITY_DAYS_GET_LIST_SUCCESS,
        failure: Constants.ACUITY_DAYS_GET_LIST_ERROR,
        clear: Constants.ACUITY_DAYS_GET_LIST_CLEAR,
    }),
    addAcuityInterview: getBasicReducer({
        request: Constants.ADD_ACUITY_INTERVIEW_REQUEST,
        success: Constants.ADD_ACUITY_INTERVIEW_SUCCESS,
        failure: Constants.ADD_ACUITY_INTERVIEW_ERROR,
        clear: Constants.ADD_ACUITY_INTERVIEW_CLEAR,
    }),
});